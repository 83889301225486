import { createContext, useState, useContext, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import rotationsServiceApi from 'shared/api/rotations.service.api';
import { getLang } from 'shared/store/selectors/lang.selector';
import { getUser } from 'shared/store/selectors/user.selector';
import { DateTime, Duration } from 'luxon';
import { useLocation } from 'react-router-dom-v5-compat';
import { useHistory } from 'react-router';
import { isAccountAdmin, isCollaborator } from 'shared/store/selectors/auth.selector';

const RotationsContext = createContext();

export function useRotationsContext() {
  return useContext(RotationsContext);
}

const findAndUpdate = (array, id, update) => {
  return array.map(item => {
    if (item.id === id) {
      return { ...item, ...update };
    }
    return item;
  });
};

/**
 * Rotations Provider
 * @export
 * @param {{
 *  children: React.ReactNode;
 *  analyticsTriggers: {
 *    onSidebarTabChange: (payload) => void;
 *    onSearch: (payload) => void;
 *    onRotationDetailsUpdated: (payload) => void;
 *    onResourceAdded: (payload) => void;
 *    onResourceDeleted: (payload) => void;
 *    onAddTeamMember: (payload) => void;
 *    onRemoveTeamMember: (payload) => void;
 *  }
 * }} { children, analyticsTriggers }
 * @return {*}
 */
export function RotationsProvider({ children, analyticsTriggers }) {
  const history = useHistory();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  const setQueryParams = (key, value) => searchParams.set(key, value);
  const getQueryParams = key => searchParams.get(key);

  const lang = useSelector(getLang('ROTATIONS'));
  const user = useSelector(getUser);

  const [allRotations, setAllRotations] = useState([]);
  const [mySchedule, setMySchedule] = useState([]);
  const [myRotations, setMyRotations] = useState([]);
  const [loadingList, setLoadingList] = useState(false);
  const [errorList, setErrorList] = useState('');

  const [selectedRotation, setSelectedRotation] = useState(null);
  const [loadingRotation, setLoadingRotation] = useState(false);
  const [errorRotation, setErrorRotation] = useState('');
  const isOwner = useSelector(isCollaborator) && selectedRotation?.ownerIds?.includes(user.id);
  const canEdit = useSelector(isAccountAdmin) || isOwner;

  const [filter, setFilter] = useState(getQueryParams('filter') || '');
  const [tab, setTab] = useState(getQueryParams('tab') || lang.MY_SCHEDULE);
  const [rotationId, setRotationId] = useState(getQueryParams('id') || null);

  useEffect(() => {
    if (!rotationId?.length) {
      searchParams.delete('id');
    } else {
      searchParams.set('id', rotationId);
    }
    history.push({ search: searchParams.toString() }, { shallow: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rotationId]);

  useEffect(() => {
    if (!filter?.length) {
      searchParams.delete('filter');
    } else {
      searchParams.set('filter', filter);
    }
    history.push({ search: searchParams.toString() }, { shallow: true });

    // Analytics trigger
    analyticsTriggers?.onSearch({ filter });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  useEffect(() => {
    searchParams.set('tab', tab);

    history.push({ search: searchParams.toString() }, { shallow: true });

    // Analytics trigger
    analyticsTriggers?.onSidebarTabChange({ tab });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);

  const updateRotationName = useCallback(
    (id, name) => {
      setAllRotations(allRotations => findAndUpdate(allRotations, id, { name }));
      setMyRotations(myRotations => findAndUpdate(myRotations, id, { name }));
      setMySchedule(mySchedule => findAndUpdate(mySchedule, id, { name }));
      setSelectedRotation(rotation => ({ ...rotation, name }));
      analyticsTriggers?.onRotationDetailsUpdated({ id, name });
    },
    [analyticsTriggers],
  );

  const removeResourceInRotation = useCallback(
    resourceId => {
      setSelectedRotation(rotation => ({
        ...rotation,
        knowledge: rotation?.knowledge?.filter(({ id }) => id !== resourceId),
      }));
      analyticsTriggers?.onResourceDeleted({ id: selectedRotation.id, resourceId });
    },
    [analyticsTriggers, selectedRotation],
  );

  const addResourceInRotation = useCallback(
    item => {
      setSelectedRotation(rotation => ({
        ...rotation,
        knowledge: (rotation?.knowledge || []).concat(item),
      }));

      analyticsTriggers?.onResourceDeleted({ id: selectedRotation.id, resourceId: item.id });
    },
    [analyticsTriggers, selectedRotation],
  );

  const updateRotationOwners = useCallback(
    ownerIds => {
      setSelectedRotation(rotation => ({ ...rotation, ownerIds }));
      analyticsTriggers?.onRotationDetailsUpdated({ id: selectedRotation.id, ownerIds });
    },
    [analyticsTriggers, selectedRotation],
  );

  const addTraineesToRotation = useCallback(
    trainees =>
      setSelectedRotation(rotation => ({
        ...rotation,
        team: {
          ...rotation?.team,
          trainees,
        },
      })),
    [],
  );

  const removeTeamMember = useCallback(
    (id, group) => {
      setSelectedRotation(rotation => ({
        ...rotation,
        team: {
          ...(rotation?.team || []),
          [group]: rotation.team[group].filter(({ id: memberId }) => memberId !== id),
        },
      }));
      analyticsTriggers?.onRemoveTeamMember({ id: selectedRotation.id, memberId: id, group });
    },
    [analyticsTriggers, selectedRotation],
  );

  const addTeamMembers = useCallback(
    (members, group) => {
      setSelectedRotation(rotation => ({
        ...rotation,
        team: {
          ...rotation?.team,
          [group]: (rotation?.team?.[group] || []).concat(members),
        },
      }));
      analyticsTriggers?.onAddTeamMember({ id: selectedRotation.id, group });
    },
    [analyticsTriggers, selectedRotation],
  );

  const updateRotationData = useCallback(
    payload =>
      setSelectedRotation(rotation => ({
        ...rotation,
        ...payload,
      })),
    [],
  );

  useEffect(() => {
    const routeId = searchParams.get('id');
    if (routeId) {
      setRotationId(routeId);
      return;
    }
    const id = mySchedule[0]?.id || allRotations[0]?.id || myRotations[0]?.id;
    if (id) {
      setRotationId(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mySchedule, allRotations, myRotations, searchParams.get('id')]);

  useEffect(() => {
    const fetchRotations = async () => {
      setLoadingList(true);
      setErrorList('');

      try {
        const [allRotationsRes, myScheduleRes] = await Promise.all([
          rotationsServiceApi.getAllRotations(),
          rotationsServiceApi.getTimeslots({
            userIds: [user.id],
            start: DateTime.now().toJSDate(),
            end: DateTime.now()
              .plus(Duration.fromObject({ month: 12 }))
              .toJSDate(),
          }),
        ]);

        if (allRotationsRes.error || myScheduleRes.error) {
          setErrorList(allRotationsRes.error || myScheduleRes.error);
          return;
        }

        setAllRotations(allRotationsRes.data);
        setMySchedule(
          myScheduleRes.data.map(({ start, end, rotation }) => ({ ...rotation, start, end })),
        );
        setMyRotations(
          allRotationsRes.data.filter(
            rotation => rotation.ownerIds?.includes(user.id) || rotation?.isStaff,
          ),
        );
      } catch (err) {
        setErrorList(err.message);
      } finally {
        setLoadingList(false);
      }
    };

    fetchRotations();
  }, [user.id]);

  useEffect(() => {
    const fetchRotation = async () => {
      if (!rotationId) {
        return;
      }

      setLoadingRotation(true);
      setErrorRotation('');

      try {
        const [rotation, trainees] = await Promise.all([
          rotationsServiceApi.getRotationById(rotationId),
          rotationsServiceApi.getTrainees(rotationId),
        ]);

        if (rotation.error || trainees.error) {
          setErrorRotation(rotation.error || trainees.error);
          return;
        }

        setSelectedRotation(rotation.data);
        addTraineesToRotation(trainees.data);
      } catch (err) {
        setErrorRotation(err.message);
      } finally {
        setLoadingRotation(false);
      }
    };

    fetchRotation();
  }, [rotationId, addTraineesToRotation]);

  const value = {
    lang,
    myRotations,
    mySchedule,
    allRotations,
    tab,
    filter,
    loading: loadingList,
    error: errorList,
    setTab,
    setFilter,
    updateRotationName,
    removeResourceInRotation,
    addResourceInRotation,
    selectedRotation,
    loadingRotation,
    errorRotation,
    setQueryParams,
    getQueryParams,
    setRotationId,
    canEdit,
    updateRotationOwners,
    updateRotationData,
    removeTeamMember,
    addTeamMembers,
  };

  return <RotationsContext.Provider value={value}>{children}</RotationsContext.Provider>;
}
