import React from 'react';
import {
  DirectoryChecklistIcon,
  DirectoryCompendiumIcon,
  DirectoryKnowledgeIcon,
  ItemBinderIcon,
  ItemChecklistIcon,
  ItemCompendiumIcon,
} from 'src/images';
import styles from '../knowledge/knowledge-item-row.module.scss';
import { CATEGORY_TYPES } from 'shared/constants';
import classNames from 'classnames';
import { RenderLabels } from 'src/components/common/categories/components';
import { useSelector } from 'react-redux';
import { getLang } from 'shared/store/selectors/lang.selector';
import { formatFullName } from 'shared/utils';
import { getOwnersByIDs } from 'shared/store/selectors/owners.selector';

const CATEGORY_TYPES_TO_ICON_MAPPER = {
  [CATEGORY_TYPES.KNOWLEDGE]: {
    icon: ItemBinderIcon,
    iconStyle: styles.knowledgeIcon,
    folderIcon: DirectoryKnowledgeIcon,
  },
  [CATEGORY_TYPES.CHECKS]: {
    icon: ItemChecklistIcon,
    iconStyle: styles.checklistIcon,
    folderIcon: DirectoryChecklistIcon,
  },
  [CATEGORY_TYPES.VALUES]: {
    icon: ItemCompendiumIcon,
    iconStyle: styles.labIcon,
    folderIcon: DirectoryCompendiumIcon,
  },
};

const getCategoryType = item => {
  if (item.checks) {
    const category = {
      type: CATEGORY_TYPES.CHECKS,
      isCategory: true,
    };
    if (item.type === CATEGORY_TYPES.CHECKS) {
      category.isCategory = false;
    }
    return category;
  } else if (item.values) {
    const category = {
      type: CATEGORY_TYPES.VALUES,
      isCategory: true,
    };
    if (item.type === CATEGORY_TYPES.VALUES) {
      category.isCategory = false;
    }
    return category;
  } else {
    const category = {
      type: CATEGORY_TYPES.KNOWLEDGE,
      isCategory: true,
    };
    if (item.type === CATEGORY_TYPES.KNOWLEDGE) {
      category.isCategory = false;
    }

    return category;
  }
};

const CategoryItemRow = ({ item, type, onSelect, disabled = false, selected = false }) => {
  const { title, subtitle, labels, ownerIds } = item;
  const { type: categoryType, isCategory } = getCategoryType(item);
  const owners = useSelector(getOwnersByIDs(ownerIds));
  const lang = useSelector(getLang('CONTENT_SELECTOR'));

  const Icon = isCategory
    ? CATEGORY_TYPES_TO_ICON_MAPPER[categoryType].folderIcon
    : CATEGORY_TYPES_TO_ICON_MAPPER[categoryType].icon;

  return (
    <div
      className={classNames([
        'new-design-system',
        styles.container,
        disabled && styles.disabled,
        selected && styles.selected,
      ])}
      onClick={() => !disabled && onSelect(item)}
    >
      <div className={styles.item}>
        <div className={styles.itemContent}>
          <img src={Icon} className={styles.categoryIcon} alt={`${type}-knowledge-item`} />
          <div className={styles.textContainer}>
            <p className={styles.title}>{title}</p>
            {subtitle && <p className={styles.subtitle}>{subtitle}</p>}
            <div className={styles.footer}>
              {owners.length > 0 && (
                <>
                  {lang.OWNED_BY}{' '}
                  {owners.map((owner, index) => (
                    <span key={index}>
                      <strong>{formatFullName(owner)}</strong>
                      {index < owners.length - 1 && ','}
                    </span>
                  ))}
                </>
              )}
            </div>
          </div>
        </div>
        {labels && <RenderLabels labels={labels} styles={styles} />}
      </div>
    </div>
  );
};

export { CategoryItemRow };
