import { Row, Col } from 'antd';
import styles from './content-header.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleArrowLeft } from '@fortawesome/pro-regular-svg-icons';

export const ContentHeader = ({
  actionsRender = () => {},
  centerRender = () => {},
  close = null,
}) => (
  <Row className={styles.root}>
    <Col span={10} md={4} lg={6}>
      <div className={styles.closeButtonWrapper}>
        {close && (
          <button onClick={close}>
            <FontAwesomeIcon icon={faCircleArrowLeft} className={styles.backArrow} />
          </button>
        )}
      </div>
    </Col>

    <Col span={4} md={8} lg={8}>
      <div className={styles.flexCenter}>{centerRender()}</div>
    </Col>

    <Col span={10} md={12} lg={10}>
      <div className={styles.actionsWrapper}>{actionsRender()}</div>
    </Col>
  </Row>
);
