import React from 'react';
import { ChecklistIcon } from 'src/images';
import styles from '../knowledge/knowledge-item-row.module.scss';
import { formatFullName } from 'shared/utils';
import { useSelector } from 'react-redux';
import { getOwnersByIDs } from 'shared/store/selectors/owners.selector';
import { getLang } from 'shared/store/selectors/lang.selector';
import classNames from 'classnames';
import { RenderLabels } from 'src/components/common/categories/components';
import { useVettedOrProvidedLang } from 'shared/hooks/lang.hooks';

const ChecklistItemRow = ({ item, onSelect, disabled = false, selected = false }) => {
  const { title, type, subtitle, ownerIds, labels } = item;
  const owners = useSelector(getOwnersByIDs(ownerIds));
  const lang = useSelector(getLang('CONTENT_SELECTOR'));
  const vettedByLang = useVettedOrProvidedLang(lang.VETTED_BY);

  return (
    <div
      className={classNames([
        'new-design-system',
        styles.container,
        disabled && styles.disabled,
        selected && styles.selected,
      ])}
      onClick={() => !disabled && onSelect(item)}
    >
      <div className={styles.item}>
        <div className={styles.itemContent}>
          <img src={ChecklistIcon} className={styles.itemIcon} alt={`${type}-knowledge-item`} />
          <div className={styles.textContainer}>
            <p className={styles.title}>{title}</p>
            {subtitle && <p className={styles.subtitle}>{subtitle}</p>}
            <div className={styles.footer}>
              {owners && owners.length > 0 && (
                <span>
                  {vettedByLang}{' '}
                  <strong>{owners.map(owner => formatFullName(owner)).join(', ')}</strong>
                </span>
              )}
            </div>
          </div>
        </div>

        {labels && <RenderLabels labels={labels} styles={styles} />}
      </div>
    </div>
  );
};

export { ChecklistItemRow };
