import * as React from 'react';

function SvgComponent({ color = '#2c5789', ...props }) {
  return (
    <svg id="prefix__Extra_Checkmarks" data-name="Extra Checkmarks" viewBox="0 0 22 22" {...props}>
      <path
        id="prefix__Path_288"
        data-name="Path 288"
        className="prefix__cls-1"
        fill={color}
        d="M11 1.9a9.1 9.1 0 109.1 9.1A9.12 9.12 0 0011 1.9zm0 19.92A10.82 10.82 0 1121.82 11 10.82 10.82 0 0111 21.82z"
      />
      <path
        id="prefix__Path_289"
        data-name="Path 289"
        className="prefix__cls-1"
        fill={color}
        d="M12.23 16.18A1.23 1.23 0 1111 14.94a1.23 1.23 0 011.23 1.24z"
      />
      <path
        id="prefix__Path_290"
        data-name="Path 290"
        className="prefix__cls-1"
        fill={color}
        d="M9.57 4.78l.63 8.7h1.6l.63-8.7z"
      />
    </svg>
  );
}

export default SvgComponent;
