import React from 'react';
import { Popup } from '..';
import { Button, Loader } from '../../core';
import styles from './confirmation-popup.module.scss';
import { useSelector } from 'react-redux';
import { getLang } from 'shared/store/selectors/lang.selector';

const ConfirmationPopupComponent = React.memo(function ConfirmationPopupComponent({
  title,
  message,
  isLoading = false,
  onConfirm,
  onDismiss,
  confirmMessage,
  dismissMessage,
  confirmButtonStyle = 'danger',
  dismissButtonStyle = null,
}) {
  const lang = useSelector(getLang('CONFIRMATION_MODAL_DEFAULT'));
  const confirm = confirmMessage ?? lang.CONFIRM;
  const dismiss = dismissMessage ?? lang.DISMISS;

  return (
    <Popup title={title} isOpen={true} className={styles.root}>
      <p className={styles.content}>{message}</p>

      {!isLoading && (
        <div className={styles.buttons}>
          <Button
            className={styles.button}
            size="medium"
            onClick={onConfirm}
            buttonStyle={confirmButtonStyle}
          >
            {confirm}
          </Button>
          <Button
            className={styles.button}
            onClick={onDismiss}
            size="medium"
            buttonStyle={dismissButtonStyle}
          >
            {dismiss}
          </Button>
        </div>
      )}
      {isLoading && <Loader />}
    </Popup>
  );
});

export { ConfirmationPopupComponent };
