// @ts-check

import { useSelector as _useSelector } from "react-redux";
import { StoreState } from "./store";

function useSelector<TSelected>(
  selector: ((state: StoreState) => TSelected),
  equalityFn?: ((left: TSelected, right: TSelected) => boolean)
) {
  return _useSelector(selector, equalityFn)
}

export { useSelector }