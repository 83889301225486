import API from './API';
import { GET, PUT } from '../utils/Executer';
import { WORKSPACE_SERVICE_URL as servicePath } from '../constants/APIconstants';

class WorkspaceServiceAPI {
  async getWorkspaces() {
    return await API.client.execute(GET, `${servicePath}`);
  }

  async selectWorkspace({ workspaceId }) {
    return await API.client.execute(PUT, `${servicePath}select`, { workspaceId });
  }
}

export default new WorkspaceServiceAPI();
