import { connect } from 'react-redux';
import {
  createKnowledgeItem,
  updateKnowledgeItem,
  uploadFile,
} from 'shared/store/actions/knowledge.items.actions';
import { KnowledgeContentEditorComponent } from './knowledge-content-editor.component';
import { getSelectedWorkspaceId } from 'shared/store/selectors/workspace.selector';

const mapStateToProps = ({ lang, labels, workspace }) => ({
  lang: lang.KNOWLEDGE_EDITOR,
  langTags: lang.TAGS_FORM,
  labelsViability: Object.keys(labels.list).length > 0,
  currentWorkspaceId: getSelectedWorkspaceId({ workspace }),
});

export const KnowledgeContentEditor = connect(mapStateToProps, {
  createKnowledgeItem,
  updateKnowledgeItem,
  uploadFile,
})(KnowledgeContentEditorComponent);
