import React from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { getLang } from 'shared/store/selectors/lang.selector';
import styles from './hub-coming-soon.module.scss';
import { HubBackground, PandaWithGlobe } from 'src/images';

export function HubComingSoonPage() {
  const lang = useSelector(getLang('KNOWLEDGE_HUB'));

  return (
    <div className={classnames([styles.root, 'new-design-system'])}>
      <div className={styles.header}>
        <h2 className={styles.subtitle}>{lang.COMING_SOON_SUBTITLE}</h2>
        <h1 className={styles.title}>{lang.COMING_SOON_TITLE}</h1>
        <p className={styles.message}>{lang.COMING_SOON_MESSAGE}</p>
      </div>
      <div className={styles.imageContainer}>
        <div style={{ backgroundImage: `url(${HubBackground})` }} className={styles.background} />
        <img src={PandaWithGlobe} alt="panda" className={styles.panda} />
      </div>
    </div>
  );
}
