import { connect } from 'react-redux';
import { deleteCheck } from 'shared/store/actions/admission.actions';
import { ChecklistItemDeleteComponent } from './checklist-item-delete.component';

const mapStateToProps = ({ lang }) => ({
  lang: lang.DELETE_CHECKLIST_ITEM_CONFIRMATION,
});

export const ChecklistItemDelete = connect(mapStateToProps, { deleteCheck })(
  ChecklistItemDeleteComponent,
);
