import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import { ROUTES } from 'shared/constants';
import styles from './Breadcrumbs.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';

const BreadcrumbsComponent = React.memo(
  ({
    path,
    title,
    categoryPath,
    rootPath,
    rootTitle,
    wrapperStyle,
    hideRoot,
    onCurrentClick,
    workspaceName = '',
  }) => {
    const onCurrentClickAction = e => {
      onCurrentClick();
    };

    return (
      <div className={classNames(styles.root, wrapperStyle)}>
        {!hideRoot && (
          <>
            <Link to={rootPath}>{rootTitle}</Link>
            <FontAwesomeIcon icon={faChevronRight} className={styles.arrow} />
          </>
        )}
        {workspaceName && (
          <div className={styles.path}>
            {workspaceName}
            <FontAwesomeIcon icon={faChevronRight} className={styles.arrow} />
          </div>
        )}
        {path.map(({ id, title }, i) => (
          <div key={`breadcrumbs-${id}`} className={styles.path}>
            {i % 2 !== 0 ? <Link to={categoryPath.replace(':id', id)}>{title}</Link> : title}
            <FontAwesomeIcon icon={faChevronRight} className={styles.arrow} />
          </div>
        ))}
        {onCurrentClick ? (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a onClick={onCurrentClickAction}>{title}</a>
        ) : (
          <span>{title}</span>
        )}
      </div>
    );
  },
);

BreadcrumbsComponent.defaultProps = {
  path: [],
  wrapperStyle: null,
  hideRoot: false,
  title: '',
  categoryPath: ROUTES.CONTENT_ITEM,
  rootPath: ROUTES.KNOWLEDGE,
  rootTitle: 'Knowledge Base',
};

export { BreadcrumbsComponent };
