import React from 'react';
import styles from './calendar-widget.module.scss';
import { useSelector } from 'react-redux';
import { getLang } from 'shared/store/selectors/lang.selector';
import { QgendaLogo } from 'src/images/ui';

export function CalendarListWidget({
  title = '',
  list,
  trackAnalytics = () => {},
  defaultIcon = QgendaLogo,
}) {
  const lang = useSelector(getLang('PROFILE_OPTIONS'));

  if (!list || list.length === 0) {
    return null;
  }

  const renderList = item => {
    const title =
      item.title ||
      [...item?.roles, ...item?.professions].map(i => lang[i]).join(', ') ||
      'Default';

    return (
      <div key={item.url} className={styles.calendarItem}>
        <a
          href={item.url}
          target="_blank"
          rel="noreferrer"
          onClick={() => trackAnalytics(item.url)}
        >
          <img src={item?.iconUrl || defaultIcon} alt={title} />
          <span>{title}</span>
        </a>
      </div>
    );
  };

  return (
    <>
      <label className={styles.title}>{title}</label>
      <div className={styles.listMap}>{list.map(renderList)}</div>
    </>
  );
}
