import React from 'react';
import { ENTITY_TYPES, EVENTS, Lang } from 'shared/constants';
import { Popup, UploadDocument } from 'src/components/common';
import styles from './checklist-item-editor.module.scss';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Loader, MrkdwnEditor, TextArea } from 'src/components/core';
import { TagsSelector, TAG_SELECTOR_TYPE } from 'src/components/tags/tagSelector';
import { PopupFormButtons } from 'src/components/common/popup-form-buttons';
import analyticsService from 'src/helpers/analytics.service';

const CHECKLIST_ITEM_SCHEMA = Yup.object().shape({
  title: Yup.string().min(2, 'Too Short!').required('Required!'),
  link: Yup.string().url(),
  info: Yup.string(),
  specialty: Yup.string().nullable(),
  subspecialty: Yup.string().nullable(),
  contentType: Yup.string().nullable(),
  targetAudience: Yup.array().of(Yup.string()),
});

const ChecklistItemEditorComponent = React.memo(function ChecklistItemEditorComponent({
  lang,
  value,
  categoryId,
  createItem,
  updateItem,
  onFinish,
}) {
  const isNew = !value.id;
  const [isLoading, setLoadingState] = React.useState(false);

  const updateOrGenerateValue = React.useCallback(
    async item => {
      setLoadingState(true);

      const { specialty, subspecialty, contentType, targetAudience, ...valuesData } = item;

      const itemData = {
        item: {
          ...valuesData,
          tags: { specialty, subspecialty, contentType, targetAudience },
        },
      };

      if (categoryId) {
        itemData.categoryId = categoryId;
      }

      // TODO: analytics
      if (isNew) {
        analyticsService.track(EVENTS.CONTENT.UPLOAD, {
          ...itemData,
          entityType: ENTITY_TYPES.admission,
        });
        await createItem(itemData);
      } else {
        itemData.checkId = value.id;
        analyticsService.track(EVENTS.CONTENT.UPDATE, {
          ...itemData,
          entityType: ENTITY_TYPES.admission,
        });

        await updateItem(itemData);
      }

      onFinish(true);
      setLoadingState(false);
    },
    [categoryId, createItem, isNew, onFinish, updateItem, value.id],
  );

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    resetForm,
    values,
    errors,
    touched,
    dirty,
  } = useFormik({
    validationSchema: CHECKLIST_ITEM_SCHEMA,
    initialValues: {
      ...value.tags,
      ...value,
    },
    onSubmit: updateOrGenerateValue,
  });

  const dismiss = React.useCallback(() => {
    // TODO: analytics

    onFinish();
  }, [onFinish]);

  const resetField = React.useCallback(
    field => {
      const newValues = { values };
      delete newValues.values[field];
      resetForm(newValues);
    },
    [values, resetForm],
  );

  return (
    <Popup title={isNew ? lang.CREATE_NEW_TITLE : lang.UPDATE_TITLE} isOpen>
      <form onSubmit={handleSubmit}>
        <label>{lang.ITEM_TITLE}</label>
        <MrkdwnEditor
          name="title"
          value={values.title}
          error={errors.title}
          touched={touched.title}
          onChange={setFieldValue}
          placeholder={lang.ITEM_TITLE_PLACEHOLDER}
        />

        <label>{lang.ITEM_INFO}</label>
        <TextArea
          inputStyle="editor"
          name="info"
          value={values.info}
          error={errors.info}
          touched={touched.info}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={lang.ITEM_INFO_PLACEHOLDER}
        />

        <TagsSelector
          selectedValues={values}
          errors={errors}
          onChange={setFieldValue}
          resetField={resetField}
          type={TAG_SELECTOR_TYPE.CHECKLIST}
        />

        <label>{lang.ITEM_REFERENCE}</label>
        <UploadDocument
          setFieldValue={setFieldValue}
          name="link"
          value={values.link}
          error={errors.link}
          touched={touched.link}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={lang.ITEM_REFERENCE_PLACEHOLDER}
        />

        {!isLoading && (
          <PopupFormButtons
            dismiss={dismiss}
            language={{
              DISMISS: lang.DISMISS,
              CREATE_OR_UPDATE: isNew ? lang.CREATE : lang.UPDATE,
            }}
            disableUpdate={!dirty}
          />
        )}
        {isLoading && <Loader />}
        {!isNew && <span className={styles.itemId}>Id: {value?.id}</span>}
      </form>
    </Popup>
  );
});

ChecklistItemEditorComponent.defaultProps = {
  lang: Lang.CHECKLIST_ITEM_EDITOR,
  value: {},
  categoryId: null,
  createItem: () => {},
  uploadDocument: () => {},
  updateItem: () => {},
  onFinish: () => {},
};

export { ChecklistItemEditorComponent };
