import { useState, useEffect, createContext, useContext, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getLang } from 'shared/store/selectors/lang.selector';
import knowledgeCommentsServiceApi from 'shared/api/knowledge-comments.service.api';
import { addLevelToComments, removeCommentById, countComments } from 'shared/utils/comments.helper';
import { notification } from 'antd';
import { isKnowledgeCommentsEnabled } from 'shared/store/selectors/features.selector';

const CommentsContext = createContext();

/**
 * Get comments context
 * @returns {{
 *  lang: object,
 *  comments: import('shared/api/knowledge-comments.service.api').Comment[],
 *  addComment: (comment: {text: string}) => Promise<void>,
 *  addSubComment: (commentId: string, comment: {text: string}) => Promise<void>,
 *  deleteSubComment: (commentId: string) => Promise<void>,
 *  loading: boolean,
 *  count: number
 *  fetching: boolean
 * }}
 */
export function useKnowledgeComments() {
  return useContext(CommentsContext);
}

function notifyError() {
  notification.error({
    message: 'Error',
    description: 'Can not perform the action. See the updated comments below.',
  });
}

export function KnowledgeCommentsProvider({ knowledgeId, children }) {
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(false);
  const lang = useSelector(getLang('KNOWLEDGE_COMMENTS'));
  const isEnabled = useSelector(isKnowledgeCommentsEnabled);

  const fetchComments = useCallback(async () => {
    if (!knowledgeId) {
      return;
    }
    setFetching(true);
    const res = await knowledgeCommentsServiceApi.getComments({ knowledgeId });
    if (!res.success) {
      setFetching(false);
      return;
    }

    const { data } = res;
    if (!data.comments) {
      setFetching(false);
      return;
    }

    const commentsWithLevel = addLevelToComments(data.comments.comments);
    setComments(commentsWithLevel.reverse());

    setFetching(false);
  }, [knowledgeId]);

  useEffect(() => {
    if (!isEnabled) {
      return;
    }

    fetchComments();
  }, [knowledgeId, fetchComments, isEnabled]);

  /**
   * Add comment
   * @param {{text: string}} comment
   */
  const addComment = async comment => {
    setLoading(true);

    const res = await knowledgeCommentsServiceApi.createComment({ knowledgeId, comment });

    if (res.success) {
      const { data } = res;
      const commentsWithLevel = addLevelToComments(data.comments?.comments || []);
      setComments(commentsWithLevel.reverse());
    } else {
      notifyError();
      await fetchComments();
    }

    setLoading(false);
  };

  /**
   * Add sub comment
   * @param {string} commentId
   * @param {{text: string}} comment
   */
  const addSubComment = async (commentId, comment) => {
    setLoading(true);

    const res = await knowledgeCommentsServiceApi.addSubComment({
      knowledgeId,
      commentId,
      comment,
    });
    if (res.success) {
      const { data } = res;
      const commentsWithLevel = addLevelToComments(data.comments?.comments || []);
      setComments(commentsWithLevel.reverse());
    } else {
      notifyError();
      await fetchComments();
    }

    setLoading(false);
  };

  /**
   * Delete sub comment
   * @param {string} commentId
   */
  const deleteSubComment = async commentId => {
    setLoading(true);

    const res = await knowledgeCommentsServiceApi.deleteSubComment({ knowledgeId, commentId });
    if (res.success) {
      setComments(prevComments => removeCommentById(prevComments, commentId));
    } else {
      notifyError();
      await fetchComments();
    }

    setLoading(false);
  };

  const count = useMemo(() => countComments(comments), [comments]);

  const value = {
    knowledgeId,
    lang,
    comments,
    addComment,
    addSubComment,
    deleteSubComment,
    loading,
    count,
    fetching,
    isEnabled,
  };

  return <CommentsContext.Provider value={value}>{children}</CommentsContext.Provider>;
}
