import styles from './render-chat-messages.module.scss';
import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { useChatConversation } from '../../hooks/use-chat-conversation';
import { faPenToSquare } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TextAreaInput } from 'src/components/design-system/forms';
import { Button } from 'src/components/design-system/buttons';
import { useFormik } from 'formik';
import { CHAT_SCHEMA, MAX_MESSAGE_LENGTH } from '../chat-input-schema';

export function RenderQuestion({ message, mainQuestionId, renderAvatar, renderHistoryToggle }) {
  const { lang, editMessage, loading } = useChatConversation();
  const [isEditMode, setIsEditMode] = useState(false);

  const onEditMode = () => {
    setIsEditMode(true);
  };

  const onSubmit = async (values, { resetForm }) => {
    if (loading) return;

    setIsEditMode(false);
    await editMessage(mainQuestionId, values);

    resetForm();
  };

  const { handleChange, handleBlur, handleSubmit, values, touched, dirty } = useFormik({
    validationSchema: CHAT_SCHEMA,
    initialValues: {
      message: message.content,
    },
    enableReinitialize: true,
    onSubmit,
  });

  const renderMessageState = () => {
    if (isEditMode) {
      return (
        <form onSubmit={handleSubmit}>
          <TextAreaInput
            name="message"
            value={values.message}
            touched={touched.message}
            onChange={handleChange}
            onBlur={handleBlur}
            maxLength={MAX_MESSAGE_LENGTH}
          />

          <div className={styles.sendCancelButtons}>
            <Button htmlType="button" onClick={() => setIsEditMode(false)}>
              {lang.CANCEL}
            </Button>
            <Button htmlType="submit" type="primary" disabled={!dirty}>
              {lang.SEND}
            </Button>
          </div>
        </form>
      );
    }

    return (
      <ReactMarkdown className={styles.messageContent}>
        {loading ? values.message : message.content}
      </ReactMarkdown>
    );
  };

  return (
    <div className={styles.message}>
      <div className={styles.avatar}>{renderAvatar(true)}</div>

      <div className={styles.content}>
        <h4>{lang.USER_NAME}</h4>

        <div className={styles.question}>
          <div className={styles.historyMessageWrapper}>
            {renderMessageState()}
            {!isEditMode && !loading && renderHistoryToggle()}
          </div>

          {!isEditMode && (
            <button
              disabled={loading}
              onClick={onEditMode}
              type="button"
              className={styles.editButton}
            >
              <FontAwesomeIcon icon={faPenToSquare} />
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
