import React from 'react';
import { faEllipsis } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './actions-dropdown.module.scss';
import { Button } from 'src/components/design-system';
import { Tooltip } from 'src/components/core';

/**
 * ActionsDropdown component
 *
 * @param {{
 * actions: {
 *  label: string;
 *  onClick: () => void;
 *  props?: React.HTMLProps<HTMLDivElement>;
 * }[]
 * }} param
 *
 */
export function ActionsDropdown({ actions }) {
  const [showDropdown, setShowDropdown] = React.useState(false);

  const toggleDropdown = e => {
    e.stopPropagation();
    setShowDropdown(prev => !prev);
  };

  return (
    <div className={styles.icon}>
      <FontAwesomeIcon icon={faEllipsis} className={styles.actionsIcon} onClick={toggleDropdown} />
      {showDropdown && (
        <div className={styles.dropdownMenu} onMouseLeave={toggleDropdown}>
          {actions.map((action, index) => {
            const onActionClick = e => {
              action.onClick(e);
            };
            return (
              <Tooltip key={index} title={action.tooltip} placement="top">
                <Button
                  type="button"
                  key={index}
                  onClick={onActionClick}
                  className={styles.item}
                  disabled={action.disabled}
                  {...action.props}
                >
                  {action.label}
                </Button>
              </Tooltip>
            );
          })}
        </div>
      )}
    </div>
  );
}
