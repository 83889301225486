import { connect } from 'react-redux';
import { VerificationPageComponent } from './verification.page.component';

const mapStateToProps = ({ lang, auth }) => ({
  lang: lang.OTP_SCREEN,
  langAuth: lang.WELCOME_SCREEN,
  email: auth.user.email,
});

export const VerificationPage = connect(mapStateToProps)(VerificationPageComponent);
