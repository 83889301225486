import { connect } from 'react-redux';
import { labSetItemDetails } from 'src/store/actions/labViewer.actions';
import { LabValueComponent } from './lab-value.component';
import { updateLabItem } from 'shared/store/actions/lab.values.actions';

const mapStateToProps = ({ lang }) => ({
  lang: lang.LAB_VALUE_EDITOR,
  langTooltip: lang.ACTION_BUTTON_TOOLTIPS,
});

const mapDispatchToProps = {
  labSetItemDetails,
  updateLabItem,
};

export const LabValue = connect(mapStateToProps, mapDispatchToProps)(LabValueComponent);
