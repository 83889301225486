import { RightOutlined } from '@ant-design/icons';
import { Collapse, List, Typography, theme } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { EVENTS, ROUTES } from 'shared/constants';
import { useDateFormatter } from 'shared/hooks/dates.hooks';
import { getLang } from 'shared/store/selectors/lang.selector';
import { Button } from 'src/components/design-system';
import analyticsService from 'src/helpers/analytics.service';
import { useHistory } from 'react-router';

/**
 * @param {{
 * textPrefix?: string,
 * timeslot: import('shared/api/rotations.service.api').Rotation,
 * style?: React.CSSProperties,
 * }} props
 */
function Timeslot(props) {
  const { token } = theme.useToken();
  const { formatRangeDate } = useDateFormatter();
  const history = useHistory();

  const styles = {
    box: {
      width: '100%',
      padding: token.padding,
      paddingTop: token.paddingSM,
      paddingBottom: token.paddingSM,
      backgroundColor: token.colorBgBase,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: '2.5rem',
    },
  };

  const content = (
    <>
      <Typography style={{ color: token.colorTextHeading }}>
        {props.textPrefix ? <strong>{props.textPrefix}: </strong> : null}
        {props.timeslot.rotation.name}
        {props.timeslot.end
          ? ', '.concat(formatRangeDate(props.timeslot.start, props.timeslot.end))
          : ''}
      </Typography>
    </>
  );

  const redirectToRotationPage = () => {
    analyticsService.track(EVENTS.ROTATIONS.OPEN_LINK, {
      rotationName: props.timeslot.rotation.name,
      rotationCode: props.timeslot.rotation.code,
      rotationOrganizationId: props.timeslot.rotation.organizationId,
      rotationOwnerId: props.timeslot.rotation.ownerId,
    });

    history.push(`${ROUTES.ROTATIONS}?id=${props.timeslot.rotation.id}`);
  };

  return (
    <Button
      type="link"
      onClick={redirectToRotationPage}
      style={{
        ...styles.box,
        width: '100%',
        color: 'inherit',
        ...props.style,
      }}
    >
      {content}
      <RightOutlined />
    </Button>
  );
}

/**
 * @param {{
 * rotationTimeslots: import('shared/api/rotations.service.api').RotationTimeslot[]
 * }} props
 */
export function RotationsWidgetCollapsible(props) {
  const { token } = theme.useToken();
  const lang = useSelector(getLang('DASHBOARD'));

  const now = new Date().getTime();

  let current;
  let next;
  const rest = [];

  const timeslots = props.rotationTimeslots
    .map(t => {
      return {
        ...t,
        start: new Date(t.start),
        end: t.end ? new Date(t.end) : null,
      };
    })
    .sort((a, b) => a.start.getTime() - b.start.getTime());

  for (const timeslot of timeslots) {
    const hasStarted = timeslot.start.getTime() <= now;
    const hasEnded = timeslot.end ? now > timeslot.end.getTime() : false;

    const isCurrent = hasStarted && !hasEnded;
    const isFuture = timeslot.start.getTime() > now;
    const isNext = isFuture && (!next || timeslot.start.getTime() < next.start.getTime());

    if (isCurrent) {
      current = timeslot;
      continue;
    }

    if (isNext) {
      next = timeslot;
      continue;
    }

    if (isFuture) {
      rest.push(timeslot);
    }
  }

  return (
    <>
      {current ? (
        <div>
          <Timeslot
            style={{
              marginBottom: token.marginSM,
              borderRadius: token.borderRadius,
            }}
            textPrefix={lang.ROTATIONS_CURRENT}
            timeslot={current}
          />
        </div>
      ) : null}
      {next ? (
        <div>
          <Timeslot
            style={{
              marginBottom: token.marginSM,
              borderRadius: token.borderRadius,
            }}
            textPrefix={lang.ROTATIONS_UPCOMING}
            timeslot={next}
          />
        </div>
      ) : null}
      {rest.length > 0 && (
        <Collapse accordion style={{ border: 'none' }}>
          <Collapse.Panel header={lang.ROTATIONS_ALL_ROTATIONS} key="1">
            <List
              style={{
                marginLeft: -token.padding,
                marginRight: -token.padding,
                marginTop: -token.padding,
                marginBottom: -token.padding,
              }}
              itemLayout="horizontal"
              dataSource={
                // Do not display more than 12 rotations
                rest.slice(0, 12)
              }
              renderItem={item => (
                <List.Item style={{ padding: 0 }}>
                  <Timeslot timeslot={item} />
                </List.Item>
              )}
            />
          </Collapse.Panel>
        </Collapse>
      )}
    </>
  );
}
