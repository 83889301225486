import React from 'react';
import styles from './admission-checklist-info.module.scss';
import { Lang } from 'shared/constants';
import { Button } from 'src/components/core';
import { Popup } from 'src/components/common';
import { AdmissionBreadcrumbs } from 'src/components/breadcrumbs';
import { TagsIcons } from 'src/components/tags/tagsIcons';
import { TextTag } from 'src/components/tags/text-tags';

const DetailInfo = ({ label, value, link }) => {
  if (!value) return false;

  return (
    <div className={styles.detail}>
      <h6>{label}</h6>
      <div className={styles.detailContent}>
        {!link && value}
        {link && (
          <a href={link} target="_blank" rel="noreferrer">
            {value}
          </a>
        )}
      </div>
    </div>
  );
};

const AdmissionChecklistInfoComponent = React.memo(function AdmissionChecklistInfoComponent({
  id,
  item,
  lang,
  admissionDetails,
  parents,
  admissionSelectChecklist,
}) {
  const close = React.useCallback(() => {
    admissionDetails(null);
  }, [admissionDetails]);

  const onNavigateToCategory = id => {
    close();
    admissionSelectChecklist(id);
  };

  if (!id) {
    return null;
  }

  let { info, tags } = item;

  const renderTags = () => (
    <div className={styles.tags}>
      <TextTag tag={tags?.contentType} />
      <TagsIcons
        tags={tags}
        hideTags={{ targetAudience: true, contentType: true }}
        className={styles.tagIcons}
      />
    </div>
  );

  return (
    <Popup isOpen={true} title={lang.HEADER}>
      {renderTags()}
      <div className={styles.data}>
        <DetailInfo label={lang.INFO} value={info} />
      </div>

      <div className={styles.detail}>
        <h6>{lang.PARENTS_PATH}</h6>
        <div className={styles.detailContent}>
          {parents.map(parent => (
            <AdmissionBreadcrumbs
              wrapperStyle={styles.breadcrumbs}
              pathParts={parent.pathParts}
              title={parent.title}
              key={parent.id}
              onCurrentClick={() => onNavigateToCategory(parent.id)}
              hideRoot
              initialWorkspaceId={parent.workspaceId}
            />
          ))}
        </div>
      </div>

      <Button className={styles.button} onClick={close}>
        {lang.DISMISS}
      </Button>
    </Popup>
  );
});

AdmissionChecklistInfoComponent.defaultProps = {
  itemId: null,
  lang: Lang.ADMISSION_INFORMATION_MODAL,
  item: {},
  admissionDetails: () => {},
  parents: [],
  admissionSelectChecklist: () => {},
};

export { AdmissionChecklistInfoComponent };
