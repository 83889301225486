import { REMOTE_CONFIG_KEYS } from 'shared/constants';
import { MAINTENANCE_STATUS_UPDATE } from 'shared/store/actions';
import remoteConfigService from '../../helpers/remote-config.service';

export const initMaintenanceStatus = () => dispatch => {
  const maintenance = remoteConfigService.getJsonValue(REMOTE_CONFIG_KEYS.MAINTENANCE);

  dispatch({
    type: MAINTENANCE_STATUS_UPDATE,
    payload: maintenance,
  });
};
