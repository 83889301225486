import React from 'react';
import { Button, FileUpload, FileWithPreview } from '../../../core';
import styles from '../knowledge-upload-file.module.scss';
import { Alert, Switch } from 'antd';
import { theme } from 'antd';
import { SUPPORTED_FILES_HELPDESK_LINK } from 'shared/constants/assets.constants';
import { useSelector } from 'src/store/use-selector';
import { getLang } from 'shared/store/selectors/lang.selector';
import { FileRejection } from 'react-dropzone';

const UploadFile = function UploadFile({
  isMov,
  smartFormat,
  toggleSmartFormat,
  onUploadFile,
  onSubmit,
  onCancel,
  disabled = false,
  handleBulkUpload = undefined,
}: {
  isMov: boolean | undefined;
  smartFormat: boolean;
  toggleSmartFormat: (checked: boolean, event: React.MouseEvent<HTMLButtonElement>) => void;
  onUploadFile: (name?: string, file?: FileWithPreview) => void;
  onSubmit: () => void;
  onCancel: () => void;
  disabled: boolean;
  handleBulkUpload?: (files: File[], fileRejections: FileRejection[]) => void | undefined;
}) {
  const { token } = theme.useToken();
  const lang = useSelector(getLang('KNOWLEDGE_UPLOAD_POPUP'));

  const linkPlaceholder = lang.MOV_FILE_WARNING.match(/\{supportedFormatsLink:.*?\}/)![0];
  const linkText = linkPlaceholder.slice(22, -1); // Extract display text between "{supportedFormatsLink:" and "}"

  const parts = lang.MOV_FILE_WARNING.split(linkPlaceholder);

  const uploadIcon = () => (
    <div className={styles.uploadBox}>
      <h4>{lang.UPLOAD}</h4>
      <p>{lang.SUBTITLE}</p>
    </div>
  );
  return (
    <>
      <h4 className={styles.title}>{lang.TITLE}</h4>
      <FileUpload
        wrapperClass={styles.wrapper}
        onChange={onUploadFile}
        renderIcon={uploadIcon}
        maxFiles={50}
        handleBulkUpload={handleBulkUpload}
        disableDragReject={!!handleBulkUpload}
      />
      {isMov ? (
        <Alert
          type="warning"
          style={{ marginTop: token.marginMD, marginBottom: token.marginMD }}
          message={
            <>
              {parts[0]}
              <a href={SUPPORTED_FILES_HELPDESK_LINK} target="_blank" rel="noopener noreferrer">
                {linkText}
              </a>
              {parts[1]}
            </>
          }
          showIcon
        />
      ) : null}
      <div className={styles.checkbox} data-key="smart-format-conversion-toggle">
        <Switch
          className="ant-switch-secondary"
          defaultChecked={smartFormat}
          onChange={toggleSmartFormat}
        />
        <h6>{lang.SMART_FORMAT}</h6>
      </div>
      <div className={styles.buttons}>
        <Button className={styles.button} size="medium" onClick={onCancel}>
          {lang.DISMISS}
        </Button>
        <Button
          className={styles.button}
          size="medium"
          buttonStyle="primary"
          onClick={onSubmit}
          disabled={disabled}
        >
          {lang.SUBMIT}
        </Button>
      </div>
    </>
  );
};

export { UploadFile };
