import { connect } from 'react-redux';
import {
  deleteCategory,
  updateCategorySubcategories,
  resetChecklist,
} from 'shared/store/actions/admission.actions';
import { AdmissionSectionListComponent } from './admission-section-list.component';

const mapStateToProps = ({ application, admission, lang }) => ({
  lang: lang.CONTENT_LIST,
  adminMode: application.adminMode,
  isHaveActiveChecklist: Object.keys(admission.activeChecklist).length,
});

const mapDispatchToProps = {
  deleteCategory,
  updateCategorySubcategories,
  resetChecklist,
};

export const AdmissionSectionList = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdmissionSectionListComponent);
