import React from 'react';
import styles from './share-box.module.scss';
import { appURL } from 'shared/constants/APIconstants';
import { UploadOutlined, CopyOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import { useSelector } from 'react-redux';
import { getLang } from 'shared/store/selectors/lang.selector';
import analyticsService from '../../../helpers/analytics.service';
import { EVENTS } from 'shared/constants/analytics';
import { CopyToClipboard } from 'src/components/common/copy-to-clipboard';

const ShareBoxComponent = React.memo(function ShareBoxComponent({
  path,
  iconClassName = styles.icon,
  id,
  title,
  type,
  tags,
  entityType,
}) {
  const lang = useSelector(getLang('SHARE'));
  const LINK = `${appURL.slice(0, appURL.length - 1)}${path}`;

  const onCopy = () => {
    analyticsService.track(`${EVENTS.SHARE.COPY_LINK} - ${entityType}`, {
      link: LINK,
      id,
      title,
      type,
      ...tags,
      entityType,
    });
  };

  const menu = (
    <Menu>
      <CopyToClipboard text={LINK} onCopy={onCopy}>
        <Menu.Item className={styles.option} key={lang.COPY_LINK}>
          <CopyOutlined className={styles.optionIcon} />
          {lang.COPY_LINK}
        </Menu.Item>
      </CopyToClipboard>
    </Menu>
  );

  return (
    <Dropdown
      overlay={menu}
      placement="bottomLeft"
      trigger="click"
      overlayClassName={styles.dropdown}
    >
      <UploadOutlined onClick={e => e.stopPropagation()} className={iconClassName} />
    </Dropdown>
  );
});

export { ShareBoxComponent };
