import { RenderSection } from '../../components';
import { useKeywords } from 'shared/hooks/knowledge-content.hooks';
import { getLang } from 'shared/store/selectors/lang.selector';
import { useSelector } from 'react-redux';
import { TagSelector } from 'src/components/design-system/forms/tag-selector.component';

export function RenderKeywordsSelector({
  setFieldValue = () => {},
  knowledgeKeywords = [],
  keywords = [],
}) {
  const lang = useSelector(getLang('KNOWLEDGE_EDITOR'));
  const { suggestedKeywords } = useKeywords({ keywords: knowledgeKeywords });

  return (
    <RenderSection header={lang.KEYWORDS}>
      <TagSelector
        value={keywords}
        options={suggestedKeywords}
        name="keywords"
        placeholder={lang.KEYWORDS_PLACEHOLDER}
        onChange={setFieldValue}
      />
    </RenderSection>
  );
}
