import React, { useCallback, useMemo } from 'react';
import {
  TAGS_SPECIALTY,
  TAGS_KNOWLEDGE_CONTENT_TYPE,
  TAGS_TARGET_AUDIENCE,
  TAGS_LAB_CONTENT_TYPE,
  TAGS_CHECKLIST_CONTENT_TYPE,
} from 'shared/constants';
import { getLang } from 'shared/store/selectors/lang.selector';
import { useSelector } from 'react-redux';
import { RenderSection } from '../../components';
import { Select } from 'src/components/design-system/forms/select.component';
import { sortByAlphabet } from 'shared/utils';

const TAG_SELECTOR_TYPE = {
  KNOWLEDGE: 'KNOWLEDGE',
  LAB: 'LAB',
  CHECKLIST: 'CHECKLIST',
};

const names = {
  specialty: 'specialty',
  subspecialty: 'subspecialty',
  targetAudience: 'targetAudience',
  contentType: 'contentType',
};

export function RenderTags({ selectedValues, errors, onChange, resetField }) {
  const LANG_TAGS = useSelector(getLang('TAGS'));
  const LANG_TAGS_FORM = useSelector(getLang('TAGS_FORM'));
  const type = TAG_SELECTOR_TYPE.KNOWLEDGE;

  const placeholders = {
    specialty: LANG_TAGS_FORM.SPECIALTY_PLACEHOLDER,
    subspecialty: LANG_TAGS_FORM.SUBSPECIALTY_PLACEHOLDER,
    targetAudience: LANG_TAGS_FORM.TARGET_AUDIENCE_PLACEHOLDER,
    contentType: LANG_TAGS_FORM.CONTENT_TYPE_PLACEHOLDER,
  };

  const specialtyOptions = useMemo(
    () =>
      Object.values(TAGS_SPECIALTY)
        .map(el => ({
          value: el,
          label: LANG_TAGS[el],
        }))
        .sort((a, b) => sortByAlphabet(a?.label, b?.label)),
    [LANG_TAGS],
  );

  const subspecialtyOptions = useMemo(
    () =>
      Object.values(TAGS_SPECIALTY)
        .filter(el => el !== selectedValues.specialty)
        .map(el => ({
          value: el,
          label: LANG_TAGS[el],
        }))
        .sort((a, b) => sortByAlphabet(a?.label, b?.label)),

    [LANG_TAGS, selectedValues.specialty],
  );

  const contentTypeOptions = useMemo(() => {
    let contentTypes = {};
    if (type === TAG_SELECTOR_TYPE.KNOWLEDGE) {
      contentTypes = TAGS_KNOWLEDGE_CONTENT_TYPE;
    }
    if (type === TAG_SELECTOR_TYPE.LAB) {
      contentTypes = TAGS_LAB_CONTENT_TYPE;
    }

    if (type === TAG_SELECTOR_TYPE.CHECKLIST) {
      contentTypes = TAGS_CHECKLIST_CONTENT_TYPE;
    }

    return Object.values(contentTypes)
      .map(el => ({
        value: el,
        label: LANG_TAGS[el],
      }))
      .sort((a, b) => sortByAlphabet(a?.label, b?.label));
  }, [LANG_TAGS, type]);

  const targetAudienceOptions = useMemo(
    () =>
      Object.values(TAGS_TARGET_AUDIENCE)
        .map(el => ({
          value: el,
          label: LANG_TAGS[el],
        }))
        .sort((a, b) => sortByAlphabet(a?.label, b?.label)),
    [LANG_TAGS],
  );

  const handleOnChange = useCallback(
    (field, value) => {
      if (!value) {
        resetField('subspecialty');
      }
      onChange(field, value);
    },
    [onChange, resetField],
  );

  return (
    <>
      <RenderSection>
        <h6 data-key="tags-specialty-label">{placeholders.specialty}</h6>
        <Select
          value={selectedValues?.specialty}
          options={specialtyOptions}
          onChange={handleOnChange}
          name={names.specialty}
          placeholder={placeholders.specialty}
          error={errors?.specialty}
        />
        <h6 data-key="tags-subspecialty-label">{placeholders.subspecialty}</h6>
        <Select
          value={selectedValues?.subspecialty}
          options={subspecialtyOptions}
          onChange={onChange}
          name={names.subspecialty}
          placeholder={placeholders.subspecialty}
          error={errors?.subspecialty}
          disabled={!selectedValues.specialty}
        />
      </RenderSection>
      <RenderSection>
        <h6 data-key="tags-content-type-label">{placeholders.contentType}</h6>
        <Select
          value={selectedValues?.contentType}
          options={contentTypeOptions}
          onChange={onChange}
          name={names.contentType}
          placeholder={placeholders.contentType}
          error={errors?.contentType}
        />
        <h6 data-key="tags-target-audience-label">{placeholders.targetAudience}</h6>
        <Select
          mode="multiple"
          value={selectedValues?.targetAudience ?? []}
          options={targetAudienceOptions}
          onChange={onChange}
          name={names.targetAudience}
          placeholder={placeholders.targetAudience}
          error={errors?.targetAudience}
          optionsRender
        />
      </RenderSection>
    </>
  );
}
