import { LAB_SELECT_ITEM, LAB_SET_ITEM_DETAILS } from 'shared/store/actions';
import { routeWithQueryParam } from '../../helpers/routing.helper';
import { INTERNAL_ROUTING } from 'shared/constants';

export const labSelectValues = id => dispatch => {
  routeWithQueryParam({
    key: INTERNAL_ROUTING.QUERY_PARAMS.COMPENDIUM,
    value: id,
  });

  dispatch({
    type: LAB_SELECT_ITEM,
    payload: { id },
  });
};

export const labSetItemDetails = id => dispatch => {
  dispatch({
    type: LAB_SET_ITEM_DETAILS,
    payload: { itemId: id },
  });
};
