import React from 'react';
import { ArrowBlack } from '../../../../images/ui';
import styles from './revision-log.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons/faArrowUpRightFromSquare';
import { useDateFormatter } from 'shared/hooks/dates.hooks';

const RevisionLog = React.memo(function RevisionLog({ closeLog, log = [], lang, link }) {
  const { formatLongDateTime } = useDateFormatter();
  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <button className={styles.buttonWrapper}>
          <img alt="back" src={ArrowBlack} className={styles.backArrow} onClick={closeLog} />
        </button>
        <h2 className={styles.title}>{lang.TITLE}</h2>
        {link && (
          <a href={link} target="_blank" className={styles.viewOriginal} rel="noreferrer">
            {lang.VIEW_ORIGINAL}
            <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
          </a>
        )}
      </div>
      <table className={styles.logsTable}>
        <thead>
          <tr>
            <td>{lang.DATE}</td>
            <td>{lang.USER}</td>
          </tr>
        </thead>
        <tbody>
          {log.map(entry => (
            <tr key={`${entry.email}-${entry.updatedAt}`}>
              <td>{formatLongDateTime(entry.updatedAt)}</td>
              <td>{entry.email}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
});

export { RevisionLog };
