import React from 'react';
import styles from '../knowledge-hub.module.scss';
import { useSelector } from 'react-redux';
import { getLang } from 'shared/store/selectors/lang.selector';
import { useKnowledgeHub } from '../hooks/use-knowledge-hub.hooks';
import { ScreenTitle } from 'src/components/design-system';
import { SearchBox } from 'src/components/design-system/forms/search-box.component';
import { colorPalette, ROUTES } from 'shared/constants';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom-v5-compat';
import { useBreadcrumbs } from '../hooks/use-breadcrumbs.hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faHome } from '@fortawesome/pro-solid-svg-icons';
import { faGlobe } from '@fortawesome/pro-regular-svg-icons';
import { debounce } from 'lodash';
import { useHistory } from 'react-router';
import ReactMarkdown from 'react-markdown';

export function RenderHeader({ title, subtitle, upperTitle }) {
  const lang = useSelector(getLang('KNOWLEDGE_HUB'));
  const { sectionTitle, organizationId } = useKnowledgeHub();
  const { breadcrumbItems } = useBreadcrumbs();
  const history = useHistory();

  // get search query param
  const search = new URLSearchParams(history.location.search).get('q');

  const rootItem = [
    {
      title: <FontAwesomeIcon icon={faHome} />,
      path: ROUTES.KNOWLEDGE_HUB,
    },
  ];
  const showRoot = !!organizationId;

  // start the search session with debounce
  const startSearch = debounce(value => {
    history.push(`${ROUTES.KNOWLEDGE_HUB_SEARCH}?q=${value}`);
  }, 500);

  const focusSearch = () => {
    if (!history.location.pathname.includes(ROUTES.KNOWLEDGE_HUB_SEARCH)) {
      history.push(`${ROUTES.KNOWLEDGE_HUB_SEARCH}?q=`);
    }
  };

  return (
    <div className={styles.header}>
      <div className={styles.headerTitle}>
        <Breadcrumb
          separator={<FontAwesomeIcon icon={faChevronRight} className={styles.separator} />}
          className={styles.breadcrumbs}
        >
          {showRoot &&
            [...rootItem, ...breadcrumbItems].map(
              (item, index) =>
                item?.path && (
                  <Breadcrumb.Item key={index}>
                    <Link to={item?.path}>{item?.title}</Link>
                  </Breadcrumb.Item>
                ),
            )}
        </Breadcrumb>

        {upperTitle && <span className={styles.upperTitle}>{upperTitle}</span>}

        <ScreenTitle title={title || sectionTitle || lang.TITLE} subtitle={subtitle} />
      </div>
      <div className={styles.searchWrapper}>
        <ReactMarkdown className={styles.searchHeader}>{lang.SEARCH_KNOWLEDGE_HUB}</ReactMarkdown>
        <SearchBox
          className={styles.search}
          placeholder={lang.SEARCH_PLACEHOLDER}
          onChange={e => startSearch(e.target.value)}
          defaultValue={search}
          autoFocus={history.location.pathname.includes(ROUTES.KNOWLEDGE_HUB_SEARCH) || search}
          allowClear
          suffix={<FontAwesomeIcon icon={faGlobe} style={{ color: colorPalette.primaryMain6 }} />}
          onFocus={focusSearch}
        />
      </div>
    </div>
  );
}
