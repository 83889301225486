import API from './API';
import { POST } from '../utils/Executer';
import { ASSIGNMENT_SERVICE_URL as servicePath } from '../constants/APIconstants';

class AssignmentServiceAPI {
  async suggestContent({ data }) {
    return await API.client.execute(POST, servicePath, data);
  }
}

export default new AssignmentServiceAPI();
