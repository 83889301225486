import { getSamlUrl } from 'shared/constants';

/**
 * Redirect to the SSO login
 * @param {{authenticationMethodId: string, organizationId: string}} { authenticationMethodId, organizationId }
 */
export function redirectToLoginWithSSO({ authenticationMethodId, organizationId }) {
  const url = getSamlUrl({ authenticationMethodId, organizationId });
  window.location = url;
}
