import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal } from 'antd';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getLang } from 'shared/store/selectors/lang.selector';
import { Button } from 'src/components/design-system';
import styles from './render-binder-selector.module.scss';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { RenderBinderSelector } from './render-binder-selector';
import { Divider } from 'src/components/design-system';

export function RenderPublishButton({ onSelectBinder, onSubmit, disabled }) {
  const lang = useSelector(getLang('BINDER_SELECTOR'));
  const [openBinderSelector, setOpenBinderSelector] = useState(false);
  const [binderId, setBinderId] = useState(null);

  const onClose = () => {
    setOpenBinderSelector(false);
    setBinderId(null);
  };

  const selectBinder = binderAndWorskpace => {
    setBinderId(binderAndWorskpace?.binderId);
    onSelectBinder(binderAndWorskpace);
  };

  const renderFooter = () => (
    <>
      <Divider />
      <div className={styles.footer}>
        <Button type="default" onClick={onClose}>
          {lang.CANCEL}
        </Button>
        <Button
          type="primary"
          onClick={() => {
            onSubmit();
            onClose();
          }}
          disabled={!binderId}
        >
          {lang.PUBLISH}
        </Button>
      </div>
    </>
  );

  return (
    <>
      <Button
        type="primary"
        className={styles.headerActionsButton}
        icon={<FontAwesomeIcon icon={faCheck} />}
        onClick={() => setOpenBinderSelector(true)}
        disabled={disabled}
      >
        {lang.PUBLISH_TO_BINDER}
      </Button>
      <Modal
        className="new-design-system"
        open={openBinderSelector}
        title={<h2 className={styles.title}>{lang.TITLE}</h2>}
        onCancel={onClose}
        footer={renderFooter()}
        destroyOnClose
        width={800}
      >
        <RenderBinderSelector binderId={binderId} selectBinder={selectBinder} />
      </Modal>
    </>
  );
}
