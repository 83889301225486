import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Cross, WebsiteLinkIcon, FromScratchIcon, LinkIcon, DocumentIcon } from '../../images';
import styles from './knowledge-add-popup.module.scss';
import { getLang } from 'shared/store/selectors/lang.selector';
import analyticsService from '../../helpers/analytics.service';
import { EVENTS, DATA_KEYS } from 'shared/constants';
import { getOwnersByIDs } from 'shared/store/selectors/owners.selector';
import { getUser } from 'shared/store/selectors/user.selector';
const { KNOWLEDGE_BASE } = DATA_KEYS;

const KnowledgeAddPopupComponent = React.memo(function KnowledgeAddPopupComponent({
  close,
  onCreate,
  onLink,
  onUpload,
  onExternalLink,
  title = '',
  ownerIds = [],
}) {
  const lang = useSelector(getLang('KNOWLEDGE_ADD_POPUP'));
  const owners = useSelector(getOwnersByIDs(ownerIds));
  const { email: userEmail } = useSelector(getUser);
  const ownersEmails = useMemo(() => owners?.map(owner => owner.email) || [], [owners]);

  const handleCreate = useCallback(() => {
    analyticsService.track(EVENTS.KNOWLEDGE_ITEMS.CREATE_NEW_ITEM_BUTTON, {
      type: 'newItem',
      title,
      ownersEmails,
      userEmail,
    });
    onCreate();
    close();
  }, [close, onCreate, title, ownersEmails, userEmail]);

  const handleLink = useCallback(() => {
    analyticsService.track(EVENTS.KNOWLEDGE_ITEMS.CREATE_NEW_ITEM_BUTTON, {
      type: 'linkExistingItem',
      title,
      ownersEmails,
      userEmail,
    });
    onLink();
    close();
  }, [close, onLink, title, ownersEmails, userEmail]);

  const handleUpload = useCallback(() => {
    analyticsService.track(EVENTS.KNOWLEDGE_ITEMS.CREATE_NEW_ITEM_BUTTON, {
      type: 'uploadFile',
      title,
      ownersEmails,
      userEmail,
    });
    onUpload();
    close();
  }, [close, onUpload, title, ownersEmails, userEmail]);

  const handleExternalLink = useCallback(() => {
    analyticsService.track(EVENTS.KNOWLEDGE_ITEMS.CREATE_NEW_ITEM_BUTTON, {
      type: 'link',
      title,
      ownersEmails,
      userEmail,
    });
    onExternalLink();
    close();
  }, [close, onExternalLink, title, ownersEmails, userEmail]);

  return (
    <div className={styles.addPopup}>
      <img className={styles.close} src={Cross} alt="close" onClick={close} />
      <div
        className={styles.section}
        onClick={handleCreate}
        data-key={KNOWLEDGE_BASE.ADD.FROM_SCRATCH}
      >
        <img className={styles.icon} src={FromScratchIcon} alt="" />
        <div>
          <h5>{lang.NEW_TITLE}</h5>
          <h6>{lang.NEW_DESCRIPTION}</h6>
        </div>
      </div>
      <div className={styles.section} onClick={handleLink} data-key={KNOWLEDGE_BASE.ADD.LINK}>
        <img className={styles.smallerIcon} src={LinkIcon} alt="" />
        <div>
          <h5>{lang.LINK_TITLE}</h5>
          <h6>{lang.LINK_DESCRIPTION}</h6>
        </div>
      </div>
      <div className={styles.section} onClick={handleUpload} data-key={KNOWLEDGE_BASE.ADD.CONTENT}>
        <img className={styles.icon} src={DocumentIcon} alt="" />
        <div>
          <h5>{lang.UPLOAD_TITLE}</h5>
          <h6>{lang.UPLOAD_DESCRIPTION}</h6>
        </div>
      </div>
      <div
        className={styles.section}
        onClick={handleExternalLink}
        data-key={KNOWLEDGE_BASE.ADD.EXTERNAL_LINK}
      >
        <img className={styles.smallerIcon} src={WebsiteLinkIcon} alt="" />
        <div>
          <h5>{lang.EXTERNAL_TITLE}</h5>
          <h6>{lang.EXTERNAL_DESCRIPTION}</h6>
        </div>
      </div>
    </div>
  );
});

KnowledgeAddPopupComponent.defaultProps = {
  close: () => {},
  onCreate: () => {},
  onLink: () => {},
  onUpload: () => {},
  onExternalLink: () => {},
};

export { KnowledgeAddPopupComponent };
