import { EXTERNAL_SYNC_PROVIDERS } from 'shared/constants';
import { SharepointBinderIcon, SharepointIcon } from '../images/items';

const externalSyncToIconsMapper = {
  [EXTERNAL_SYNC_PROVIDERS.SHAREPOINT]: SharepointIcon,
};

const externalSyncToBinderIconsMapper = {
  [EXTERNAL_SYNC_PROVIDERS.SHAREPOINT]: SharepointBinderIcon,
};

export const getExternalSyncIcon = provider => externalSyncToIconsMapper[provider];
export const getExternalSyncBinderIcon = provider => externalSyncToBinderIconsMapper[provider];
