import { connect } from 'react-redux';
import { updateChecklistComment } from 'shared/store/actions/admission.actions';
import { getComment } from 'shared/store/selectors/admission.selector';
import { AdmissionChecklistCommentComponent } from './admission-checklist-comment.component';

const mapStateToProps = ({ admission, lang }, { categoryId, item = {} }) => {
  let { id } = item;

  let comment = '';

  if (id) {
    comment = getComment(admission, { categoryId, id });
  }

  return {
    lang: lang.ADMISSION_COMMENT,
    comment,
  };
};

export const AdmissionChecklistComment = connect(mapStateToProps, {
  updateComment: updateChecklistComment,
})(AdmissionChecklistCommentComponent);
