import React from 'react';
import { connect } from 'react-redux';
import { Router } from './App.router';
import { ConnectedRouter } from 'connected-react-router';
import { appInit } from 'shared/store/actions/application.actions';
import { MobilePopover } from '../mobilePopover';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { deepLinkTrigger } from 'shared/store/actions/deep-linking.actions';
import analyticsService from '../../helpers/analytics.service';
import remoteConfigService from '../../helpers/remote-config.service';
import { MaintenancePage } from '../../pages/maintenance';
import { getMaintenanceStatus } from 'shared/store/selectors/maintenance.selector';
import { ConfigProvider } from 'antd';
import { colors, antdTheme as theme, colorPalette } from 'shared/constants';
import userService from 'shared/services/user.service';
import { CompatRouter } from 'react-router-dom-v5-compat';
import { AntDesignStylesOverrides } from './AntDesignStylesOverrides';
import { useQuery } from 'src/utils/router.hooks';

// Set global
global.platform = 'web';

const App = React.memo(({ history, appInit, isReady, deepLinkTrigger, inMaintenance }) => {
  let query = useQuery();

  React.useEffect(() => {
    const init = async () => {
      if (!isReady) {
        // Check if user have open session - built for widget shared session
        const { widgetRedirect, checkAuthContext } = query;

        if (widgetRedirect || checkAuthContext) {
          userService.hasToCheckAuthContext = true;
        }

        await remoteConfigService.init();

        await analyticsService.init();

        console.log('App - Init start');
        appInit();

        // Check if there is a deep link
        if (query.action) {
          deepLinkTrigger(query);
        }
      }
    };

    init();
  });

  const renderContent = () => {
    if (!isReady) return null;

    if (inMaintenance) return <MaintenancePage />;

    return <Router />;
  };

  return (
    <ConnectedRouter history={history}>
      <style>
        {`
      :root {
        ${Object.keys(theme)
          .map(key => `--${key}: ${theme[key]};`)
          .join('\n')}

        ${Object.keys(colorPalette)
          .map(key => `--${key.replace(/([A-Z])/g, '-$1').toLowerCase()}: ${colorPalette[key]};`)
          .join('\n')}
      }
      `}
      </style>
      <CompatRouter>
        <MobilePopover>
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: theme.colorPrimary,
                colorError: theme.colorError,
                fontFamily: theme.fontFamily,
                fontSizeHeading3: 22,
                colorTextHeading: colors.darkSlateBlue,
                fontWeightStrong: 700,
              },
            }}
          >
            <AntDesignStylesOverrides>
              <DndProvider backend={HTML5Backend}>{renderContent()}</DndProvider>
            </AntDesignStylesOverrides>
          </ConfigProvider>
        </MobilePopover>
      </CompatRouter>
    </ConnectedRouter>
  );
});

App.defaultProps = {
  appInit: () => {},
  deepLinkTrigger: () => {},
  isReady: false,
  inMaintenance: false,
};

const mapStateToProps = ({ application, maintenance }) => ({
  isReady: application.isReady,
  inMaintenance: getMaintenanceStatus({ maintenance }),
});

export default connect(mapStateToProps, { appInit, deepLinkTrigger })(App);
