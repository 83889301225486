import { useState, useEffect, useRef } from 'react';
import { TOCTitle } from './table-of-contents.component';

const useHeadingsData = ({ content = null }) => {
  const [headings, setHeadings] = useState([]);

  useEffect(() => {
    const container = document.querySelector('#content-container');

    if (!container) {
      return;
    }
    const headingElements = Array.from(container.querySelectorAll('h1, h2, h3'));

    const headingResult = headingElements
      .map((el, index) => {
        const level = Number(el.nodeName.charAt(1));
        el.setAttribute('id', `${el.nodeName}-${index}`);

        return {
          key: el.id,
          title: <TOCTitle level={level} title={el.innerText} />,
          href: `#${el.id}`,
          level,
        };
      })
      .reduce((acc, item) => {
        if (item.level === 1) {
          // Push the item as a new parent object
          acc.push({ ...item, children: [] });
        } else if (item.level === 2) {
          // Find the last parent item (level 1) and add this item to its children
          let last = acc[acc.length - 1];
          if (last && last.level === 1) {
            last.children.push({ ...item, children: [] });
          }
        } else if (item.level === 3) {
          // Find the last level 2 item inside the last level 1 item
          let lastLevel1 = acc[acc.length - 1];
          if (lastLevel1 && lastLevel1.children) {
            let lastLevel2 = lastLevel1.children[lastLevel1.children.length - 1];
            if (lastLevel2 && lastLevel2.level === 2) {
              lastLevel2.children.push(item);
            }
          }
        }
        return acc;
      }, [])
      .map(parentItem => {
        // Clean up: remove 'children' key if it's empty from level 1 and level 2 items
        if (parentItem.children && parentItem.children.length === 0) {
          delete parentItem.children;
        } else {
          parentItem.children = parentItem.children.map(childItem => {
            if (childItem.children && childItem.children.length === 0) {
              delete childItem.children;
            }
            return childItem;
          });
        }
        return parentItem;
      });

    setHeadings(headingResult);
  }, [content]);

  return { headings };
};

const useHeadingsObserver = () => {
  const observer = useRef();
  const [activeId, setActiveId] = useState('');

  useEffect(() => {
    const handleObsever = entries => {
      entries.forEach(entry => {
        if (entry?.isIntersecting) {
          setActiveId(entry.target.id);
        }
      });
    };

    observer.current = new IntersectionObserver(handleObsever, {
      rootMargin: '0% 0px -50% 0px',
    });

    const elements = document.querySelector('#content-container')?.querySelectorAll('h1, h2, h3');

    if (!elements) {
      return;
    }

    elements.forEach(el => observer.current.observe(el));

    return () => observer.current?.disconnect();
  }, []);

  return { activeId };
};

export { useHeadingsData, useHeadingsObserver };
