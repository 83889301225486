import { connect } from 'react-redux';
import { ChecklistItemEditorComponent } from './checklist-item-editor.component';
import { createCheck, updateCheck } from 'shared/store/actions/admission.actions';

const mapStateToProps = ({ lang }) => ({
  lang: lang.CHECKLIST_ITEM_EDITOR,
});

const mapDispatchToState = {
  createItem: createCheck,
  updateItem: updateCheck,
};

export const ChecklistItemEditor = connect(
  mapStateToProps,
  mapDispatchToState,
)(ChecklistItemEditorComponent);
