import { EVENTS_BY_ACTIONS_MAP, SESSION_PROPERTIES, EVENTS } from 'shared/constants';
import {
  AUTH_UPDATE_USER,
  AUTH_UPDATE_AUTH_CONTEXT,
  ORGANIZATION_UPDATE,
  AUTH_LOGIN,
  FIRST_TIME,
} from 'shared/store/actions';
import { getUser } from 'shared/store/selectors/user.selector';
import AnalyticsService from '../../helpers/analytics.service';

export const analyticsByAction = () => next => async action => {
  next(action);

  if (EVENTS_BY_ACTIONS_MAP[action.type]) {
    AnalyticsService.track(EVENTS_BY_ACTIONS_MAP[action.type]);
  }
};

export const analyticsEnricher = () => next => async action => {
  next(action);

  if (action.type === FIRST_TIME) {
    AnalyticsService.setSessionProperty(SESSION_PROPERTIES.IS_FIRST_TIME, true);
  }
};

export const analyticsAddUserId =
  ({ getState }) =>
  next =>
  async action => {
    next(action);

    if (action.type === AUTH_UPDATE_USER || action.type === AUTH_LOGIN) {
      let user = getUser(getState());

      const userData = { ...user, ...user?.professional };
      const propertiesToIgnore = [
        'professional',
        'organizationId',
        'snooze',
        'createdAt',
        'updatedAt',
        'profileCompletedAt',
        'type',
      ];
      AnalyticsService.setSessionProperty(SESSION_PROPERTIES.USER_TYPE, user?.type);

      propertiesToIgnore.forEach(property => {
        delete userData[property];
      });

      AnalyticsService.setUserId(userData?.id);
      AnalyticsService.registerUserData(userData);

      AnalyticsService.userReady();

      if (window.StonlyWidget) {
        window.StonlyWidget('identify', action.payload?.id, {
          permissions: user?.permissions,
          organizationId: user?.organizationId,
          createdAt: user?.createdAt,
        });
      }
    }
  };

export const analyticsAddOrgId = () => next => async action => {
  next(action);

  if (action.type === ORGANIZATION_UPDATE) {
    AnalyticsService.setGroup({
      key: 'organization',
      value: action.payload?.name,
    });
    AnalyticsService.setGroup({
      key: 'organizationId',
      value: action.payload?.id,
    });
    AnalyticsService.setGroup({
      key: 'organizationClassification',
      value: action.payload?.classification,
    });
  }
};

export const analyticsAuthContext = () => next => async action => {
  next(action);

  if (action.type === AUTH_UPDATE_AUTH_CONTEXT) {
    AnalyticsService.track(EVENTS.AUTH.MAGIC_LINK_TRIGGERED);
  }
};

export const analyticsMiddleware = [
  analyticsByAction,
  analyticsEnricher,
  analyticsAddUserId,
  analyticsAddOrgId,
  analyticsAuthContext,
];
