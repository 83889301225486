import { connect } from 'react-redux';
import { AdmissionCategoryItemComponent } from './admission-category-item.component';
import { admissionSelectChecklist } from 'src/store/actions/admission-viewer.actions';
import { updateCategory } from 'shared/store/actions/admission.actions';

const mapStateToProps = ({ application, lang }) => {
  return {
    adminMode: application.adminMode,
    lang: lang.ACTION_BUTTON_TOOLTIPS,
  };
};

const mapDispatchToProps = { admissionSelectChecklist, updateCategory };

export const AdmissionCategoryItem = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdmissionCategoryItemComponent);
