import { connect } from 'react-redux';
import { IssueReportPopupComponent } from './issue-report-popup.component';
import { reportIssue } from 'shared/store/actions/communication-center.actions';
import { ISSUE_REPORT_PROBLEMS, ISSUE_TYPES } from 'shared/constants';

const mapStateToProps = ({ lang }, { itemId, origin }) => {
  return {
    lang: lang.REPORT_PROBLEM,
    serviceMethod: reportIssue,
    serviceMethodProps: { type: ISSUE_TYPES.BROKEN_FORMAT, itemId, origin },
    defaultOptions: Object.values(ISSUE_REPORT_PROBLEMS),
  };
};

export const KnowledgeIssueReport = connect(mapStateToProps)(IssueReportPopupComponent);
