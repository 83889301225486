import { useSelector } from 'react-redux';
import styles from '../Dashboard.module.scss';
import { isAppUrl } from 'shared/utils';
import { getDashboardBanner } from 'shared/store/selectors/dashboard.selector';

export function DashboardBanner() {
  const { webImage, imageLink = '' } = useSelector(getDashboardBanner);

  if (!webImage) {
    return null;
  }
  return (
    <a
      href={imageLink}
      rel="noreferrer"
      className={styles.banner}
      target={isAppUrl(imageLink) ? '_self' : '_blank'}
    >
      <img src={webImage} alt="banner" />
    </a>
  );
}
