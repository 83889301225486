import { KNOWLEDGE_SELECT_ITEM, KNOWLEDGE_SET_ITEM_DETAILS } from 'shared/store/actions';
import { routeWithQueryParam } from '../../helpers/routing.helper';
import { INTERNAL_ROUTING } from 'shared/constants/routes';

export const knowledgeSelectItem = id => dispatch => {
  routeWithQueryParam({
    key: INTERNAL_ROUTING.QUERY_PARAMS.KNOWLEDGE,
    value: id,
  });

  dispatch({
    type: KNOWLEDGE_SELECT_ITEM,
    payload: { id },
  });
};

export const knowledgeSetItemDetails =
  (id, ownerId = null, categoryId) =>
  dispatch => {
    dispatch({
      type: KNOWLEDGE_SET_ITEM_DETAILS,
      payload: { itemId: id, ownerId, categoryId },
    });
  };
