import React from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { ROUTES } from 'shared/constants';
import { Search } from 'src/images';
import styles from './search.module.scss';

const SearchComponent = ({ updateSearch }) => {
  let history = useHistory();
  let isOnSearch = useRouteMatch(ROUTES.SEARCH);

  const onFocus = () => {
    if (!isOnSearch) {
      history.push(ROUTES.SEARCH);
    }
  };

  const onSearch = React.useCallback(
    ({ target }) => {
      updateSearch(target.value);
    },
    [updateSearch],
  );

  return (
    <div className={styles.root}>
      <Search className={styles.icon} color="white" />
      <input onFocus={onFocus} onChange={onSearch} className={styles.input} />
    </div>
  );
};

SearchComponent.defaultProps = {
  updateSearch: () => {},
};

export { SearchComponent };
