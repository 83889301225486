import { connect } from 'react-redux';
import { AdmissionPageComponent } from './admission.page.component';
import {
  getAdmissionRoot,
  getAdmissionCategoryById,
  getAdmissionCategoriesById,
} from 'shared/store/selectors/admission.selector';

const mapStateToProps = ({ lang, admission }, { match }) => {
  let category;
  let { id } = match.params;
  let isRoot = false;

  if (id) {
    category = id ? getAdmissionCategoryById(admission, { id }) : {};
  } else {
    category = getAdmissionRoot(admission);
    id = category?.id;
    isRoot = true;
  }

  return {
    id,
    category,
    showBreadcrumbs: !isRoot,
    children: getAdmissionCategoriesById(admission, id) || [],
    lastUpdated: admission.lastUpdated,
    isReady: admission.isReady,
  };
};

export const AdmissionPage = connect(mapStateToProps, {})(AdmissionPageComponent);
