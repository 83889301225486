import { combineReducers, createStore } from 'redux';
import { baseReducersMap } from 'shared/store/reducers';
import { createBrowserHistory } from 'history';
import { middleware } from 'shared/store/middlewares';
import { storageMiddleware } from './middleware/storage.middleware';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import knowledgeViewerReducer from './reducers/knowledgeViewer.reducer';
import labViewerReducer from './reducers/labViewer.reducer';
import admissionViewerReducer from './reducers/admission-viewer.reducer';
import { analyticsMiddleware } from './middleware/analytics.middleware';
import { langMiddleware } from './middleware/lang-local.middleware';
import { remoteConfigMiddleware } from './middleware/remoteConfig.middleware';

export const history = createBrowserHistory();

/**
 * @typedef {import('redux').ActionFromReducersMapObject<typeof reducersMap>} StoreAction
 * @typedef {import('redux').StateFromReducersMapObject<typeof reducersMap>} StoreState
 */

const reducersMap = {
  ...baseReducersMap,
  router: connectRouter(history),
  knowledgeViewer: knowledgeViewerReducer,
  labViewer: labViewerReducer,
  admissionViewer: admissionViewerReducer,
};

export const createStoreAction = () =>
  createStore(
    combineReducers(reducersMap),
    {},
    middleware([
      ...remoteConfigMiddleware,
      ...storageMiddleware,
      ...analyticsMiddleware,
      ...langMiddleware,
      routerMiddleware(history),
    ]),
  );

export const store = createStoreAction();

global.store = store;
