import { Collapse } from 'antd';
import React, { useState } from 'react';
import { Binder } from 'src/components/common/binder';
import { Button } from 'src/components/design-system';
import styles from './upload-file-binder.module.scss';
import { FileWithErrorComponent } from './file-with-error.component';
import { FileComponent } from './file.component';
import { useSelector } from 'react-redux';
import { getLang } from 'shared/store/selectors/lang.selector';

const UploadFileBinderComponent = function UploadFileBinderComponent({
  files: receivedFiles,
  onClose,
  props,
}) {
  const lang = useSelector(getLang('KNOWLEDGE_UPLOAD_BINDER'));
  const [files, setFiles] = useState(receivedFiles || []);
  const [startUpload, setStartUpload] = useState(false);
  const filesWithError = files.filter(f => f.error);
  const filesToUpload = files.filter(f => !f.error);
  const isUploadCompleted =
    files.filter(file => file.status === 'complete' && !file.error).length === filesToUpload.length;

  const removeFile = file => {
    setFiles(prevFiles => prevFiles.filter(f => f.name !== file.name));
  };

  const handleErrors = ({ file, error }) => {
    setFiles(prev =>
      prev.map(f => {
        if (f.name === file.name) {
          return { ...file, name: file.name, error: error };
        }
        return f;
      }),
    );
  };

  const onUploadCompleted = file => {
    setFiles(prev =>
      prev.map(f => {
        if (f.name === file.name) {
          return { ...file, name: file.name, status: 'complete' };
        }
        return f;
      }),
    );
  };

  const uploadFiles = async () => {
    setStartUpload(true);
  };

  const uploadLabelText = (() => {
    if (isUploadCompleted) {
      return lang.FILES_UPLOADED.replace('{number}', filesToUpload.length);
    }

    if (startUpload) {
      return lang.UPLOADING.replace('{number}', filesToUpload.length);
    }

    return lang.FILES_TO_UPLOAD.replace('{number}', filesToUpload.length);
  })();

  return (
    <Binder>
      <div className={styles.root}>
        <Collapse
          className={styles.collapseWrapper}
          ghost
          size="large"
          defaultActiveKey={'upload'}
          items={[
            {
              key: 'upload',
              label: uploadLabelText,
              children: filesToUpload.map(file => (
                <FileComponent
                  key={`${file.name}-uploading`}
                  file={file}
                  onRemove={removeFile}
                  close={onClose}
                  startUpload={startUpload}
                  handleErrors={handleErrors}
                  handleUploadCompleted={onUploadCompleted}
                  props={props}
                />
              )),
            },
          ]}
        />
        {!!filesWithError.length && (
          <Collapse
            ghost
            size="large"
            items={[
              {
                key: 'validation-errors',
                label: lang.FILES_WITH_ERRORS.replace('{number}', filesWithError.length),
                children: filesWithError.map(file => (
                  <FileWithErrorComponent
                    key={`${file.name}-error`}
                    file={file}
                    error={file.error}
                  />
                )),
              },
            ]}
          />
        )}
        <div className={styles.footer}>
          <Button
            type="default"
            size="medium"
            onClick={onClose}
            disabled={startUpload && !isUploadCompleted}
          >
            {startUpload ? lang.CLOSE : lang.DISMISS}
          </Button>
          {!isUploadCompleted && (
            <Button
              type="primary"
              size="medium"
              onClick={uploadFiles}
              disabled={!filesToUpload.length || startUpload}
            >
              {lang.UPLOAD_BUTTON.replace(
                '{number}',
                !!filesToUpload.length ? filesToUpload.length : '',
              )}
            </Button>
          )}
        </div>
      </div>
    </Binder>
  );
};

export { UploadFileBinderComponent };
