import React from 'react';
import classnames from 'classnames';
import { ArrowIcon, DeleteIcon, Pencil } from '../../../images';
import styles from './section-actions.module.scss';
import { Lang } from 'shared/constants/lang';
import { Tooltip } from '../../core';
import { haveEditPermissions } from 'shared/store/selectors/knowledge.selector';
import { useSelector } from 'react-redux';
import { getLang } from 'shared/store/selectors/lang.selector';

const DIRECTION_UP = 'up';
const DIRECTION_DOWN = 'down';

const SectionActions = React.memo(function SectionActions({
  adminMode,
  deleteAction,
  editAction,
  changeOrder,
  lastItem,
  className,
  index,
  lang,
  ownerIds,
}) {
  const hasEditPermission = useSelector(state => haveEditPermissions(state, { ownerIds }));

  const actionButtonLang = useSelector(getLang('ACTION_BUTTON_TOOLTIPS'));

  const changeSectionOrder = React.useCallback(
    ({ target }) => {
      let { dataset } = target;

      let toIndex = dataset.direction === DIRECTION_UP ? index - 1 : index + 1;
      changeOrder(toIndex);
    },
    [changeOrder, index],
  );

  const deleteSection = React.useCallback(() => {
    if (!hasEditPermission) return;

    deleteAction();
  }, [hasEditPermission, deleteAction]);

  const editSection = React.useCallback(() => {
    if (!hasEditPermission) return;

    editAction();
  }, [hasEditPermission, editAction]);

  if (!adminMode) return null;

  return (
    <div className={className}>
      {index !== 0 && (
        <Tooltip title={lang.MOVE_UP}>
          <ArrowIcon
            data-direction={DIRECTION_UP}
            onClick={changeSectionOrder}
            className={classnames(styles.changeOrderIcon, styles.changeOrderUp)}
          />
        </Tooltip>
      )}
      {!lastItem && (
        <Tooltip title={lang.MOVE_DOWN}>
          <ArrowIcon
            data-direction={DIRECTION_DOWN}
            onClick={changeSectionOrder}
            className={classnames(styles.changeOrderIcon, styles.changeOrderDown)}
          />
        </Tooltip>
      )}
      <Tooltip title={hasEditPermission ? lang.DELETE : actionButtonLang.NOT_ALLOWED_TO_EDIT}>
        <DeleteIcon className={styles.deleteIcon} onClick={deleteSection} />
      </Tooltip>
      <Tooltip title={hasEditPermission ? lang.EDIT : actionButtonLang.NOT_ALLOWED_TO_EDIT}>
        <Pencil className={styles.editIcon} onClick={editSection} />
      </Tooltip>
    </div>
  );
});

SectionActions.defaultProps = {
  deleteAction: () => {},
  changeOrder: () => {},
  editAction: () => {},
  lastItem: true,
  adminMode: false,
  lang: Lang.ACTION_BUTTON_TOOLTIPS,
};

export { SectionActions };
