import React from 'react';
import { NewsFeed, NotificationLink, GreyArrowIcon, DeleteIcon } from '../../../images';
import { Tooltip } from '../../core';
import { Lang } from 'shared/constants/lang';
import styles from './news-feed-item.module.scss';
import { isToday } from 'shared/utils/date.utils';
import { useDateFormatter } from 'shared/hooks/dates.hooks';

const NewsFeedItemComponent = React.memo(
  ({ id, title, content, url, createdAt, adminMode, removeItem, lang }) => {
    const { formatShortDateTime, formatShortTime } = useDateFormatter();
    const renderContent = () => {
      return (
        <>
          <img src={url ? NotificationLink : NewsFeed} alt="" className={styles.icon} />
          <div className={styles.main}>
            <h4>{title}</h4>
            <span className={styles.subtitle}>{content}</span>
          </div>
          <span className={styles.hour}>
            {isToday(createdAt) ? formatShortTime(createdAt) : formatShortDateTime(createdAt)}
          </span>
        </>
      );
    };

    const removeItemAction = e => {
      e.preventDefault();
      removeItem(id);
    };

    const renderDeleteButton = () => {
      if (!adminMode) return null;
      return (
        <div className={styles.removeButton}>
          <Tooltip title={lang.DELETE}>
            <DeleteIcon className={styles.removeButton} onClick={removeItemAction} />
          </Tooltip>
        </div>
      );
    };

    return (
      <>
        {url ? (
          <a href={url} target="_blank" rel="noreferrer" className={styles.content}>
            {renderContent()}
            {!adminMode && <img src={GreyArrowIcon} alt={title} className={styles.linkArrow} />}
            {renderDeleteButton()}
          </a>
        ) : (
          <div className={styles.content}>
            {renderContent()}
            {renderDeleteButton()}
          </div>
        )}
      </>
    );
  },
);

NewsFeedItemComponent.defaultProps = {
  title: '',
  content: '',
  url: '',
  createdAt: '',
  adminMode: false,
  removeItem: () => {},
  lang: Lang.NEWS_FEED,
};

export { NewsFeedItemComponent };
