import React, { useCallback } from 'react';
import rotationsServiceApi from 'shared/api/rotations.service.api';
import styles from './rotation-content.module.scss';
import { ConfigProvider, Modal, notification } from 'antd';
import { useKnowledgeParser } from 'shared/utils/rotations';
import { CategoryRowComponent } from 'src/components/common/categories/category-row';
import { useHistory } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faXmark } from '@fortawesome/pro-solid-svg-icons';
import { Button } from 'src/components/design-system';
import { NotFound } from 'src/components/common';
import { colorPalette, EVENTS, KNOWLEDGE_RESOURCES_TYPES, theme } from 'shared/constants';
import { useRotationsContext } from '../rotations-context';
import { useSelector } from 'react-redux';
import { SelectContent } from 'src/components/core/select-content';
import classNames from 'classnames';
import analyticsService from 'src/helpers/analytics.service';

const KnowledgeTab = () => {
  const {
    lang,
    selectedRotation: rotation,
    canEdit,
    removeResourceInRotation,
    addResourceInRotation,
  } = useRotationsContext();
  const knowledge = useSelector(state => state.knowledge);
  const admission = useSelector(state => state.admission);
  const lab = useSelector(state => state.lab);
  const [showAddNew, setShowAddNew] = React.useState(false);
  const [loadingAdd, setLoadingAdd] = React.useState(false);
  const [loadingRemoveId, setLoadingRemoveId] = React.useState(null);
  const [api, contextHolder] = notification.useNotification();
  const { parse, compose } = useKnowledgeParser({ knowledge, admission, lab });

  const rotationResourceIds = React.useMemo(() => {
    return rotation?.knowledge?.map(k => k.id) || [];
  }, [rotation]);

  const handleAddNew = async item => {
    setLoadingAdd(true);
    try {
      const payload = compose(item);
      const res = await rotationsServiceApi.addKnowledge({
        rotationId: rotation.id,
        payload,
      });
      if (res.error) {
        api.error({
          message: res.data.message || lang.ERROR_OCCURRED,
          placement: 'bottomRight',
        });
      } else {
        api.success({
          message: lang.RESOURCE_ADDED,
          placement: 'bottomRight',
        });
        addResourceInRotation(payload);
      }
    } catch (e) {
      api.error({
        message: lang.ERROR_OCCURRED,
        placement: 'bottomRight',
      });
    } finally {
      setLoadingAdd(false);
    }
  };

  const handleRemove = async (e, id) => {
    if (loadingRemoveId) return;
    e.stopPropagation();
    e.preventDefault();

    setLoadingRemoveId(id);
    try {
      await rotationsServiceApi.removeKnowledge({ rotationId: rotation.id, id });
      removeResourceInRotation(id);
    } finally {
      setLoadingRemoveId(null);
    }
  };

  const history = useHistory();

  const onClick = useCallback(
    (navigateTo, item) => {
      history.push(navigateTo);

      analyticsService.track(EVENTS.ROTATIONS.RESOURCE_OPEN, {
        rotationId: rotation.id,
        rotationName: rotation.name,
        resourceId: item.id,
        resourceName: item.title,
        type: item?.type,
      });
    },
    [history, rotation],
  );

  const onOpenExternalUrl = useCallback(
    (navigateTo, item) => {
      analyticsService.track(EVENTS.ROTATIONS.RESOURCE_OPEN, {
        rotationId: rotation.id,
        rotationName: rotation.name,
        resourceId: item.id,
        resourceName: item.title,
        type: item?.type,
      });
    },
    [rotation],
  );

  return (
    <div className={styles.content}>
      {contextHolder}
      <ConfigProvider
        theme={{
          components: {
            Modal: {
              contentBg: colorPalette.neutral2,
              headerBg: colorPalette.neutral2,
              fontFamily: theme.newFontFamily,
            },
          },
        }}
      >
        <Modal
          open={showAddNew}
          footer={null}
          width={1000}
          centered
          maskClosable={false}
          destroyOnClose
          closable={false}
          title={<h2 className={styles.modalTitle}>{lang.ADD_RESOURCE}</h2>}
        >
          <SelectContent
            disabledIds={rotationResourceIds}
            onCancel={() => setShowAddNew(false)}
            onSubmit={handleAddNew}
            loading={loadingAdd}
          />
        </Modal>
      </ConfigProvider>

      {canEdit && (
        <Button type="primary" onClick={() => setShowAddNew(true)} className={styles.addNew}>
          {lang.ADD_RESOURCE}
        </Button>
      )}
      {rotation?.knowledge?.length > 0 ? (
        rotation?.knowledge?.map((knowledgeItem, i) => {
          const parsedKnowledge = parse(knowledgeItem);
          if (!parsedKnowledge) return null;

          if (parsedKnowledge?.type === KNOWLEDGE_RESOURCES_TYPES.EXTERNAL_URL) {
            return (
              <a
                href={parsedKnowledge.navigateTo}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.externalUrl}
                key={i}
                onClick={() => onOpenExternalUrl(parsedKnowledge.navigateTo, parsedKnowledge)}
              >
                <CategoryRowComponent
                  {...parsedKnowledge}
                  actions={
                    canEdit ? (
                      <Button
                        type="link"
                        onClick={e => handleRemove(e, parsedKnowledge.id)}
                        className={styles.buttonIcon}
                      >
                        <FontAwesomeIcon
                          icon={loadingRemoveId === parsedKnowledge.id ? faSpinner : faXmark}
                          spin={loadingRemoveId === parsedKnowledge.id}
                          className={classNames([styles.icon, styles.removeIcon])}
                        />
                      </Button>
                    ) : null
                  }
                />
              </a>
            );
          }
          return (
            <CategoryRowComponent
              {...parsedKnowledge}
              key={i}
              onClick={() => onClick(parsedKnowledge.navigateTo, parsedKnowledge)}
              actions={
                canEdit ? (
                  <Button
                    type="link"
                    onClick={e => handleRemove(e, parsedKnowledge.id)}
                    className={styles.buttonIcon}
                  >
                    <FontAwesomeIcon
                      icon={loadingRemoveId === parsedKnowledge.id ? faSpinner : faXmark}
                      spin={loadingRemoveId === parsedKnowledge.id}
                      className={classNames([styles.icon, styles.removeIcon])}
                    />
                  </Button>
                ) : null
              }
            />
          );
        })
      ) : (
        <NotFound text={lang.NO_RESOURCES_FOUND} />
      )}
    </div>
  );
};

export { KnowledgeTab };
