export function addLevelToComments(comments, level = 0) {
  return comments.map(comment => ({
    ...comment,
    level,
    comments: addLevelToComments(comment.comments, level + 1),
  }));
}

export function removeCommentById(comments, commentId) {
  return comments.reduce((acc, comment) => {
    if (comment.id !== commentId) {
      acc.push({
        ...comment,
        comments: removeCommentById(comment.comments, commentId),
      });
    }
    return acc;
  }, []);
}

export function countComments(comments) {
  let count = 0;
  function recursiveCount(comments) {
    comments.forEach(comment => {
      count++;
      if (comment.comments && comment.comments.length > 0) {
        recursiveCount(comment.comments);
      }
    });
  }
  recursiveCount(comments);
  return count;
}
