import React from 'react';
import { Lang } from 'shared/constants/lang';
import { Tooltip } from '../core';
import { FAVORITES_ENTITIES, EVENTS } from 'shared/constants';
import styles from './favorites-button.module.scss';
import classNames from 'classnames';
import analyticsService from '../../helpers/analytics.service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark as faBookmarkFilled } from '@fortawesome/free-solid-svg-icons/faBookmark';
import { faBookmark } from '@fortawesome/free-regular-svg-icons/faBookmark';

const FavoritesButtonComponent = ({
  id,
  wrapper,
  className,
  toggleFavorites,
  isFavorite,
  lang,
  background,
  entity = FAVORITES_ENTITIES.KNOWLEDGE,
}) => {
  const toggleFavoritesItem = e => {
    analyticsService.track(
      `${isFavorite ? EVENTS.FAVORITES.REMOVE : EVENTS.FAVORITES.ADD} - ${entity}`,
      {
        id,
      },
    );

    e.stopPropagation();
    toggleFavorites({ id, entity });
  };

  return (
    <Tooltip title={lang.FAVORITES}>
      <button
        type="button"
        onClick={toggleFavoritesItem}
        className={classNames([wrapper, background])}
      >
        <FontAwesomeIcon className={className} icon={isFavorite ? faBookmarkFilled : faBookmark} />
      </button>
    </Tooltip>
  );
};

FavoritesButtonComponent.defaultProps = {
  id: '',
  oldId: '',
  wrapper: styles.buttonWrapper,
  className: styles.favoritesIcon,
  toggleFavorites: () => {},
  isFavorite: false,
  lang: Lang.ACTION_BUTTON_TOOLTIPS,
  background: null,
};

export { FavoritesButtonComponent };
