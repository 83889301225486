import { ADMISSION_SELECT_CHECKLIST, ADMISSION_CHECK_DETAILS } from 'shared/store/actions';
import { routeWithQueryParam } from '../../helpers/routing.helper';
import { INTERNAL_ROUTING } from 'shared/constants/routes';

export const admissionSelectChecklist = id => dispatch => {
  routeWithQueryParam({
    key: INTERNAL_ROUTING.QUERY_PARAMS.CHECKLIST,
    value: id,
  });

  dispatch({
    type: ADMISSION_SELECT_CHECKLIST,
    payload: { id },
  });
};

export const admissionDetails = item => dispatch => {
  dispatch({
    type: ADMISSION_CHECK_DETAILS,
    payload: { item },
  });
};
