import React, { useEffect, useState } from 'react';
import {
  CalculatorIcon,
  DeleteIcon,
  DragIcon,
  InformationIcon,
  CompendiumIcon,
  Pencil,
  ReferenceIcon,
} from 'src/images';
import styles from './lab-value.module.scss';
import classnames from 'classnames';
import { useDrag, useDrop } from 'react-dnd';
import analyticsService from 'src/helpers/analytics.service';
import { EVENTS, Lang } from 'shared/constants';
import { DrugClassIcon } from 'src/components/drugClass/drugClassIcon/DrugClassIcon.component';
import { SingleTagIcon } from 'src/components/tags/tagsIcons';
import { Input } from 'antd';
import { Button, MrkdwnEditor, Tooltip } from 'src/components/core';
import { useSelector } from 'react-redux';
import { haveEditPermissions } from 'shared/store/selectors/knowledge.selector';
const { TextArea } = Input;

const LabValueComponent = React.memo(function LabValueComponent({
  item,
  light,
  labSetItemDetails,
  adminMode,
  deleteValue,
  editValue,
  dragItem,
  findItem,
  dndGroup,
  draggable,
  finishDrag,
  showSpecialty,
  showContentType,
  updateLabItem,
  lang,
  langTooltip,
}) {
  let { id, title, value, url, tags, drugClass, ownerIds, reference } = item;
  const hasEditPermission = useSelector(state => haveEditPermissions(state, { ownerIds }));
  let boxRef = React.useRef(null);

  const [titleInput, setTitleInput] = useState(title);
  const [valueInput, setValueInput] = useState(value);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (titleInput !== title) {
      setTitleInput(title);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title, setTitleInput]);

  useEffect(() => {
    if (valueInput !== value) {
      setValueInput(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, setValueInput]);

  const originalIndex = findItem(id)?.index;

  const [{ isDragging }, drag] = useDrag({
    item: { type: dndGroup, id, originalIndex },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: () => adminMode && draggable,
    end: (dropResult, monitor) => {
      const { id: droppedId, originalIndex } = monitor.getItem();
      const didDrop = monitor.didDrop();
      if (!didDrop) {
        dragItem(droppedId, originalIndex);
      }

      finishDrag(droppedId);
    },
  });
  const [, drop] = useDrop({
    accept: dndGroup,
    canDrop: () => false,
    hover({ id: draggedId }) {
      if (draggedId !== id) {
        const { index: overIndex } = findItem(id);
        dragItem(draggedId, overIndex);
      }
    },
  });

  if (draggable) {
    boxRef = drag(drop(boxRef));
  }

  const onClick = () => {
    if (adminMode) {
      // TODO: analytics
      editValue({ item });
      return false;
    }
  };

  const openInformationInner = React.useCallback(
    e => {
      e.stopPropagation();

      analyticsService.track(EVENTS.LAB_ITEMS.OPEN_INFORMATION, {
        id: id.toString(),
        title,
        ownerIds,
        ...tags,
      });

      labSetItemDetails(id);
    },
    [id, labSetItemDetails, title, tags, ownerIds],
  );

  const openCalculator = React.useCallback(
    e => {
      e.stopPropagation();

      // TODO: analytics
      window.open(url);
    },
    [url],
  );

  const openReference = React.useCallback(
    e => {
      e.stopPropagation();

      // TODO: analytics
      window.open(reference);
    },
    [reference],
  );

  const deleteValueItem = React.useCallback(
    e => {
      e.stopPropagation();

      deleteValue({ id, title });
    },
    [deleteValue, id, title],
  );

  const onFocus = React.useCallback(() => {
    setDisabled(true);
  }, [setDisabled]);

  const onBlur = React.useCallback(
    async e => {
      const { value, name: field } = e.target;
      // TODO: add analytics

      if (value === item[field]) {
        setDisabled(false);
        return false;
      }
      await updateLabItem({ id, item: { ...item, [field]: value } });
      setDisabled(false);
    },
    [id, item, updateLabItem],
  );

  const onChange = React.useCallback(
    e => {
      if (e.target.name === 'title') {
        setTitleInput(e.target.value);
      }
      if (e.target.name === 'value') {
        setValueInput(e.target.value);
      }
    },
    [setTitleInput, setValueInput],
  );

  const renderActions = () => {
    return (
      <div className={styles.buttons}>
        {showSpecialty && tags.specialty && <SingleTagIcon tag={tags.specialty} />}
        {showContentType && tags.contentType && <SingleTagIcon tag={tags.contentType} />}
        {!!drugClass && <DrugClassIcon drugClass={drugClass} />}
        {!adminMode && (
          <>
            <Tooltip title={langTooltip.INFO}>
              <button type="button" onClick={openInformationInner} className={styles.buttonWrapper}>
                <InformationIcon className={styles.informationIcon} />
              </button>
            </Tooltip>

            {reference && (
              <Tooltip title={langTooltip.OPEN_REFERENCE}>
                <button type="button" onClick={openReference} className={styles.buttonWrapper}>
                  <ReferenceIcon className={styles.referenceIcon} />
                </button>
              </Tooltip>
            )}

            {url && (
              <Tooltip title={langTooltip.OPEN_CALCULATOR}>
                <button type="button" onClick={openCalculator} className={styles.buttonWrapper}>
                  <CalculatorIcon className={styles.informationIcon} />
                </button>
              </Tooltip>
            )}
          </>
        )}

        {adminMode && (
          <>
            <Tooltip
              title={hasEditPermission ? langTooltip.DELETE : langTooltip.NOT_ALLOWED_TO_EDIT}
            >
              <Button
                type="button"
                onClick={deleteValueItem}
                className={styles.buttonWrapper}
                disabled={!hasEditPermission}
              >
                <DeleteIcon className={styles.deleteIcon} />
              </Button>
            </Tooltip>
            <Tooltip title={hasEditPermission ? langTooltip.EDIT : langTooltip.NOT_ALLOWED_TO_EDIT}>
              <Button
                onClick={onClick}
                className={styles.buttonWrapper}
                disabled={disabled || !hasEditPermission}
              >
                <Pencil className={styles.editIcon} />
              </Button>
            </Tooltip>
            {draggable && (
              <Tooltip title={langTooltip.DRAG}>
                <button
                  type="button"
                  className={classnames(styles.buttonWrapper, styles.dragIconWrapper)}
                >
                  <DragIcon className={styles.dragIcon} />
                </button>
              </Tooltip>
            )}
          </>
        )}
      </div>
    );
  };

  const onChangeValue = async (name, value) => {
    setValueInput(value);

    if (value === item[name]) {
      setDisabled(false);
      return false;
    }
    await updateLabItem({ id, item: { ...item, [name]: value } });
    setDisabled(false);
  };

  const renderThumbnail = () => (
    <div className={styles.thumbnailWrapper}>
      <img src={CompendiumIcon} alt={titleInput} className={styles.itemIcon} />
    </div>
  );

  let opacity = isDragging ? 0 : 1;

  return (
    <div
      ref={boxRef}
      style={{ opacity }}
      className={classnames(styles.root, light ? styles.light : {})}
    >
      <div className={styles.content}>
        {renderThumbnail()}
        <div className={styles.textFields}>
          <TextArea
            onBlur={onBlur}
            onFocus={onFocus}
            className={styles.title}
            autoSize={{ minRows: 1 }}
            disabled={!adminMode || !hasEditPermission}
            value={titleInput}
            onChange={onChange}
            placeholder={lang.ITEM_TITLE_PLACEHOLDER}
            name={'title'}
          />
          {(adminMode || value) && (
            <MrkdwnEditor
              className={styles.valueWrapper}
              wrapperClass={styles.value}
              readOnly={!adminMode || !hasEditPermission}
              value={valueInput}
              onChange={onChangeValue}
              placeholder={lang.ITEM_VALUES_PLACEHOLDER}
              hideControllers={!disabled}
              name="value"
              inputStyle="inline"
              onFocus={onFocus}
            />
          )}
        </div>
      </div>

      {renderActions()}
    </div>
  );
});

LabValueComponent.defaultProps = {
  id: null,
  item: {
    title: '',
    value: '',
    ownerIds: [],
  },
  light: null,
  adminMode: false,
  draggable: true,
  labSetItemDetails: () => {},
  deleteValue: () => {},
  editValue: () => {},
  dragItem: () => {},
  findItem: () => {},
  dndGroup: '',
  finishDrag: () => {},
  showSpecialty: false,
  showContentType: false,
  lang: Lang.LAB_VALUE_EDITOR,
  langTooltip: Lang.ACTION_BUTTON_TOOLTIPS,
};

export { LabValueComponent };
