import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';
import { AUTHENTICATION_TYPE, ROUTES } from 'shared/constants';
import userService from 'shared/services/user.service';
import { useQuery } from 'src/utils/router.hooks';
import { AuthPage } from '../auth-page-wrapper';
import { Loader } from 'src/components/core';
import { useSelector } from 'react-redux';
import { needToUpdateProfile } from 'shared/store/selectors/user.selector';

export function OauthCallback({ match }) {
  const isAuthenticated = userService.isAuthenticated;
  const query = useQuery();
  const navigator = useNavigate();
  const { provider } = match.params;

  const isSaml = provider === AUTHENTICATION_TYPE.SAML.toLowerCase();

  const verifyCodeAfterSSOLogin = async () => {
    if (query.authCode && query.userId && isSaml) {
      await userService.verifyCode({ code: query.authCode, userId: query.userId });
    }
  };

  // this use effect is to handle SSO login flow
  useEffect(() => {
    verifyCodeAfterSSOLogin();
  });

  // this use effect is to handle oauth login flow
  useEffect(() => {
    // ignore on SSO login
    if (isSaml) {
      return;
    }

    // check if there is code in the query
    if (!query.code) {
      return navigator(ROUTES.WELCOME);
    }

    const redirectRoute = isAuthenticated ? ROUTES.ROOT : ROUTES.WELCOME;

    // if there is code in the query, redirect to the welcome page
    return navigator(redirectRoute, {
      state: { query, provider },
    });
  }, [query, provider, navigator, isAuthenticated, isSaml]);

  const isNeedToUpdateProfile = useSelector(needToUpdateProfile);

  // if user is authenticated and need to update profile, redirect to the welcome page to consist with the normal flow
  if (isNeedToUpdateProfile) {
    return navigator(ROUTES.WELCOME);
  }

  return (
    <AuthPage title="Processing...">
      <Loader />
    </AuthPage>
  );
}
