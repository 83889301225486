import React, { useEffect, useState } from 'react';
import styles from './category-box.module.scss';
import gridStyles from './grid-components.module.scss';
import { Input } from 'antd';
import {
  RenderCategoryIcon,
  RenderContentType,
  RenderTags,
  RenderMetadata,
  RenderLabels,
  RenderIndicationRibbon,
} from '../components';
import { CATEGORY_TYPES } from 'shared/constants';
const { TextArea } = Input;

export function CategoryBoxComponent({
  title = '',
  type,
  tags,
  labels,
  metadata,
  options,
  lastUpdatedAt,
  categoryType,
  adminMode,
  onBlur,
  onClick,
  actions,
  hasEditPermission,
}) {
  const isCategory = type === CATEGORY_TYPES.CATEGORY;
  const [titleInput, setTitleInput] = useState(title);
  const showUpdatedIndication = !adminMode && lastUpdatedAt;

  useEffect(() => {
    if (titleInput !== title) {
      setTitleInput(title);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title]);

  const onInputFocus = e => {
    e.stopPropagation();
  };

  const onHeaderPress = e => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.target.blur();
    }
  };

  const onChange = e => {
    const { value } = e.target;
    setTitleInput(value);
  };

  return (
    <div className={styles.root} onClick={onClick}>
      <div className={styles.iconWrapper}>
        <RenderCategoryIcon
          title={title}
          type={type}
          isCategory={isCategory}
          categoryType={categoryType}
          adminMode={adminMode}
          options={options}
          styles={gridStyles}
        />
        {showUpdatedIndication && <RenderIndicationRibbon lastUpdate={lastUpdatedAt} />}
      </div>
      <div className={styles.content}>
        <div className={styles.header}>
          <TextArea
            onClick={onInputFocus}
            onKeyPress={onHeaderPress}
            onBlur={onBlur}
            className={styles.text}
            autoSize={{ minRows: 1, maxRows: 1 }}
            disabled={!adminMode || !hasEditPermission}
            onChange={onChange}
            value={titleInput}
          />
          <div>{actions}</div>
        </div>
        <div className={styles.tags}>
          <RenderTags tags={tags} styles={gridStyles} />
          <RenderLabels labels={labels} styles={gridStyles} />
        </div>
        <div className={styles.metadata}>
          <RenderMetadata
            metadata={metadata}
            adminMode={adminMode}
            isCategory={isCategory}
            styles={gridStyles}
          />
          <RenderContentType tags={tags} styles={gridStyles} />
        </div>
      </div>
    </div>
  );
}
