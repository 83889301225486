import { createContext, useState, useCallback, useContext } from 'react';
import { useSelector } from 'react-redux';
import { isAIEnabled } from 'shared/store/selectors/features.selector';
import { getLang } from 'shared/store/selectors/lang.selector';
import zeusServiceApi from 'shared/api/zeus.service.api';

const ChatContext = createContext();

/**
 * Get the chat conversation context
 * @returns {{
 * conversationId: string,
 * loading: boolean,
 * messages: object[],
 * lang: object,
 * isOpen: boolean,
 * isChatEnabled: boolean,
 * onMessageSend: (values: { message: string }) => Promise<void>,
 * editMessage: (userMessageId: string, values: { message: string }) => Promise<void>,
 * regenerate: (answerId: string) => Promise<void>,
 * clearChat: () => void,
 * isMessageLoading: (index: number) => boolean
 * }}
 */
export function useChatConversation() {
  return useContext(ChatContext);
}

/**
 *
 * @param {{question: object, answer: object}} chat
 * @param {boolean} error
 */
function constructMessages(chat, error = false) {
  return {
    question: {
      ...chat.question,
      error,
      id: chat.question._id,
    },
    answer: {
      ...chat.answer,
      error,
      id: chat.answer._id,
    },
    id: `${chat.question._id}-${chat.answer._id}`,
  };
}

/**
 * Chat provider
 * @export
 * @param {{
 *  children: React.ReactNode,
 *  analyticsTriggers: {
 *   onMessage: (payload: object) => void
 *   onErrorMessage: (payload: object) => void
 *   onEditMessage: (payload: object) => void
 *   onClear: (payload: object) => void
 * }} { children, analyticsTriggers = {} }
 */
export function ChatProvider({ children, analyticsTriggers = {} }) {
  const [conversationId, setConversationId] = useState(undefined);
  const [loading, setLoadingState] = useState(false);
  const [messages, setMessages] = useState([]);
  const [loadedQA, setLoadedQA] = useState({
    index: null,
    loading: false,
  });

  const isMessageLoading = index => {
    return loadedQA.index === index && loadedQA.loading;
  };

  const setLoadedQAState = useCallback(
    (id, state) => {
      const index = messages.findIndex(msg => {
        const msgId = msg.id.split('-');
        return msgId[0] === id || msgId[1] === id;
      });
      setLoadedQA({
        index,
        loading: state,
      });
    },
    [messages],
  );

  const lang = useSelector(getLang('AI_CHAT'));

  const isOpen = useSelector(state => state.ai.isChatOpen);

  const isChatEnabled = useSelector(isAIEnabled);

  const updatedMessage = ({ id = '', message = {}, transformFn = null }) => {
    const isNew = id === '';
    setMessages(prevMessages => {
      const messageIndex = isNew
        ? prevMessages.length - 1
        : prevMessages.findIndex(msg => {
            const msgId = msg.id.split('-');
            return msgId[0] === id || msgId[1] === id;
          });
      if (messageIndex === -1) return prevMessages;

      const updatedMessages = [...prevMessages];
      updatedMessages[messageIndex] = transformFn
        ? transformFn(updatedMessages[messageIndex])
        : message;
      return updatedMessages;
    });
  };

  const onMessageSend = useCallback(
    async values => {
      setLoadingState(true);

      // push the user message to the chat temporarily
      const tempQA = {
        question: {
          content: values.message,
        },
        answer: {
          content: '',
        },
      };
      setMessages(prevMessages => [...prevMessages, tempQA]);
      setLoadedQA({ index: messages.length, loading: true });

      const res = await zeusServiceApi.chatMessage({ term: values.message, conversationId });

      if (res.success) {
        const { conversationId, chat } = res.data;
        const qAndA = constructMessages(chat);
        updatedMessage({ message: qAndA });
        setConversationId(conversationId);
        analyticsTriggers?.onMessage({
          conversationId,
          depth: messages.length,
          references: chat?.answer?.references.length,
          knowledgeItemId: chat?.answer?.references[0]?.knowledgeItemId,
          resources: chat?.answer?.resources.length,
          state: chat?.answer?.state,
          historyState: chat?.answer?.historyState,
        });
      } else {
        const randomId = Math.random();
        updatedMessage({
          message: {
            question: {
              id: randomId,
              content: values.message,
              error: true,
            },
            answer: {
              id: randomId,
              content: lang.ERROR_MESSAGE,
              error: true,
            },
            id: `${randomId}-${randomId}`,
          },
        });

        analyticsTriggers?.onErrorMessage({
          conversationId,
          depth: messages.length,
        });
      }

      setLoadedQA({ index: messages.length, loading: false });
      setLoadingState(false);
    },
    [conversationId, lang.ERROR_MESSAGE, messages.length, analyticsTriggers],
  );

  const onHistoryResponse = useCallback(
    async (res, oldMessage, messageId) => {
      if (res.success) {
        const { chat } = res.data;
        const qAndA = constructMessages(chat, false);
        updatedMessage({ id: messageId, message: qAndA });
      } else {
        const randomId = Math.random();
        updatedMessage({
          id: messageId,
          transformFn: prevQA => ({
            question: {
              id: randomId,
              content: oldMessage ? oldMessage : prevQA.question.content,
              error: true,
              history: [...(prevQA.question.history || []), prevQA.question],
            },
            answer: {
              id: randomId,
              content: lang.ERROR_MESSAGE,
              error: true,
              history: [...(prevQA.answer.history || []), prevQA.answer],
            },
            id: `${randomId}-${randomId}`,
          }),
        });
      }
    },
    [lang.ERROR_MESSAGE],
  );

  const editMessage = useCallback(
    async (questionId, values) => {
      setLoadingState(true);
      setLoadedQAState(questionId, true);

      // remove all the following messages on edit
      setMessages(prevMessages => {
        const messageIndex = prevMessages.findIndex(msg => {
          const msgId = msg.id.split('-');
          return msgId[0] === questionId;
        });
        if (messageIndex === -1) return prevMessages;

        return prevMessages.slice(0, messageIndex + 1);
      });
      const res = await zeusServiceApi.editMessage(conversationId, questionId, {
        term: values.message,
      });

      onHistoryResponse(res, values.message, questionId);

      let payload;

      if (res.success) {
        const { chat } = res.data;
        payload = {
          references: chat?.answer?.references.length,
          knowledgeItemId: chat?.answer?.references[0]?.knowledgeItemId,
          resources: chat?.answer?.resources.length,
          state: chat?.answer?.state,
          historyState: chat?.answer?.historyState,
        };
      } else {
        payload = {
          error: true,
        };
      }

      analyticsTriggers?.onEditMessage({
        conversationId,
        depth: messages.length,
        ...payload,
      });

      setLoadedQAState(questionId, false);
      setLoadingState(false);
    },
    [conversationId, onHistoryResponse, setLoadedQAState, analyticsTriggers, messages.length],
  );

  const regenerate = useCallback(
    async answerId => {
      setLoadingState(true);

      setLoadedQAState(answerId, true);
      const res = await zeusServiceApi.regenerate(conversationId, answerId);

      onHistoryResponse(res, '', answerId);

      setLoadedQAState(answerId, false);
      setLoadingState(false);
    },
    [conversationId, onHistoryResponse, setLoadedQAState],
  );

  const clearChat = useCallback(() => {
    analyticsTriggers?.onClear({ conversationId, depth: messages.length });

    setMessages([]);
    setConversationId(undefined);
  }, [analyticsTriggers, conversationId, messages.length]);

  const value = {
    conversationId,
    loading,
    messages,
    lang,
    isOpen,
    isChatEnabled,
    onMessageSend,
    editMessage,
    regenerate,
    clearChat,
    isMessageLoading,
  };

  return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
}
