import React from 'react';
import styles from './labels-list.module.scss';
import { Label } from '../../core/label';
import { useSelector } from 'react-redux';
import { getLabelsListByCode } from 'shared/store/selectors/labels.selector';
import classNames from 'classnames';
import { theme } from 'shared/constants';
import { Tooltip } from 'antd';

const LabelsList = React.memo(({ labels = [], className }) => {
  const labelsItems = useSelector(
    state => getLabelsListByCode(state, labels),
    (a, b) => {
      return a?.length === b?.length;
    },
  );

  if (!labelsItems?.length) return null;

  return labelsItems.map(label => (
    <Tooltip title={label.name} placement="topLeft" key={label.code} color={theme.tooltipColor}>
      <Label color={label.color} value={label.code} className={classNames(styles.tag, className)} />
    </Tooltip>
  ));
});

export { LabelsList };
