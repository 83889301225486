import { useSelector } from 'react-redux';
import { getLang } from 'shared/store/selectors/lang.selector';
import { TagsIcons } from 'src/components/tags/tagsIcons';
import styles from './shared-styles.module.scss';
import { RenderSection } from '../../components';
import {
  getWorkspaceById,
  isThereOnlyOneWorkspace,
} from 'shared/store/selectors/workspace.selector';

export function KnowledgeTags({ tags, workspaceId }) {
  const lang = useSelector(getLang('TAGS_FORM'));
  const langTags = useSelector(getLang('TAGS'));
  const workspace = useSelector(getWorkspaceById(workspaceId));
  const isOnlyOneWorkspace = useSelector(isThereOnlyOneWorkspace);

  if (
    !tags?.specialty &&
    !tags?.subSpecialty &&
    !tags?.contentType &&
    tags?.targetAudience?.length === 0 &&
    !workspace &&
    !isOnlyOneWorkspace
  )
    return;

  return (
    <>
      {tags?.contentType && (
        <RenderSection header={lang.CONTENT_TYPE_PLACEHOLDER}>
          <p className={styles.content}>{langTags[tags?.contentType]}</p>
        </RenderSection>
      )}
      {workspace && !isOnlyOneWorkspace && (
        <RenderSection header={lang.WORKSPACE}>
          <p className={styles.content}>{workspace.name}</p>
        </RenderSection>
      )}
      {(tags?.specialty || tags?.subSpecialty) && (
        <RenderSection header={lang.SPECIALTY_SUBSPECIALTY}>
          <TagsIcons tags={tags} hideTags={{ contentType: true, targetAudience: true }} />
        </RenderSection>
      )}

      {tags?.targetAudience?.length > 0 && (
        <RenderSection header={lang.TARGET_AUDIENCE_PLACEHOLDER}>
          <p className={styles.content}>
            {tags?.targetAudience.map(audience => langTags[audience]).join(', ')}
          </p>
        </RenderSection>
      )}
    </>
  );
}
