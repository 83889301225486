import { connect } from 'react-redux';
import { LabBinderPageComponent } from './lab-binder.page.component';
import { getLabCategoryById, getItemsByCategoryId } from 'shared/store/selectors/lab.selector';
import { updateValueOrder, createQuickLabItem } from 'shared/store/actions/lab.values.actions';
import { labSelectValues } from '../../../store/actions/labViewer.actions';
import { getQueryParam } from '../../../helpers/routing.helper';
import { INTERNAL_ROUTING } from 'shared/constants';

const mapStateToProps = ({ labViewer, lab, application, lang }) => {
  const id = getQueryParam({ key: INTERNAL_ROUTING.QUERY_PARAMS.COMPENDIUM });
  const items = id ? getItemsByCategoryId(lab, { id }) : [];
  const category = id ? getLabCategoryById(lab, { id }) : {};

  return {
    id,
    category,
    items,
    adminMode: application.adminMode,
    lastUpdated: lab.lastUpdated,
    lang: lang.COMPENDIUM_BUTTONS,
    langTooltip: lang.ACTION_BUTTON_TOOLTIPS,
  };
};

const mapDispatchToProps = {
  labSelectValues,
  updateValueOrder,
  createQuickLabItem,
};

export const LabBinderPage = connect(mapStateToProps, mapDispatchToProps)(LabBinderPageComponent);
