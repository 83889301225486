import { connect } from 'react-redux';
import { KnowledgeItemsWidgetComponent } from './knowledge-items-widget.component';
import { getItemById } from 'shared/store/selectors/knowledge.selector';

const mapStateToProps = ({ knowledge, application }, { location }) => {
  const { id: itemId } = location?.state || {};
  let knowledgeItem = null;

  if (!!itemId) {
    knowledgeItem = getItemById(knowledge, { id: itemId });
  }

  return {
    adminMode: application.adminMode,
    knowledgeItem,
  };
};

export const KnowledgeItemsWidget = connect(mapStateToProps)(KnowledgeItemsWidgetComponent);
