import React from 'react';
import styles from './TagsIcons.module.scss';
import { TagIcon } from './TagIcon.component';
import { AudienceIcon } from './AudienceIcon.component';

const TagsIcons = React.memo(function TagIcons({
  tags = {},
  large = false,
  center = false,
  hideTags = {},
  className = '',
}) {
  const { specialty, subspecialty, targetAudience, contentType } = tags;

  return (
    <div
      className={
        center ? styles.centerContainer : large ? styles.largeContainer : styles.tagContainer
      }
    >
      {!hideTags?.specialty && specialty && (
        <TagIcon tag={specialty} large={large} className={className} />
      )}
      {!hideTags?.subspecialty && subspecialty && (
        <TagIcon tag={subspecialty} large={large} className={className} />
      )}
      {!hideTags?.contentType && contentType && (
        <TagIcon tag={contentType} large={large} className={className} />
      )}
      {!hideTags?.targetAudience && !!targetAudience?.length && (
        <AudienceIcon tags={targetAudience} large={large} className={className} />
      )}
    </div>
  );
});

export { TagsIcons };
