import { connect } from 'react-redux';
import { getOwnersList } from 'shared/store/selectors/owners.selector';
import { getUser } from 'shared/store/selectors/user.selector';
import { ExpiredKnowledgeWidgetComponent } from './expired-knowledge-widget.component';

const mapStateToProps = state => ({
  owners: getOwnersList(state.owners),
  userId: getUser(state)?.id,
  knowledgeItemsCount: Object.keys(state?.knowledge?.items || {})?.length,
  lang: state.lang.EXPIRED_CONTENT,
});

export const ExpiredKnowledgeWidget = connect(mapStateToProps)(ExpiredKnowledgeWidgetComponent);
