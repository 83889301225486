import { Switch } from 'antd';
import { useSelector } from 'react-redux';
import { getLang } from 'shared/store/selectors/lang.selector';
import styles from './shared-styles.module.scss';

export function SmartFormatToggle({ smartFormatMode, toggleSmartFormat, link }) {
  const lang = useSelector(getLang('KNOWLEDGE_EDITOR'));

  return (
    <div className={styles.smartFormatToggle}>
      <Switch defaultChecked={smartFormatMode} onChange={toggleSmartFormat} size="small" />
      {lang.SMART_FORMAT}
    </div>
  );
}
