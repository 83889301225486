import assignmentServiceApi from '../../api/assignment.service.api';

export const suggestContent =
  ({ data }) =>
  async () => {
    const res = await assignmentServiceApi.suggestContent({ data });

    if (res.error) {
      return { error: res.error };
    }

    return {};
  };
