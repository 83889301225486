import React, { useCallback, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Editor } from '@tinymce/tinymce-react';
import { uploadDocument } from 'shared/store/actions/knowledge.items.actions';
import { config } from './tiny-mce-editor.config';
import { TINY_MCE_KEY } from '../../../constants/tiny-mce.constants';
import analyticsService from '../../../helpers/analytics.service';
import { EVENTS } from 'shared/constants';
import { Loader } from '../';
import styles from './tiny-mce-editor.module.scss';

/**
 * @type {React.FC<any>}
 */
const TinyEditor = React.memo(
  ({
    id,
    initialValue,
    onChange,
    disabled,
    height,
    width,
    setLoadingContent,
    setEditorFocused,
  }) => {
    const editorRef = useRef(null);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);

    const onInit = useCallback(
      (evt, editor) => {
        editorRef.current = editor;
        analyticsService.track(EVENTS.MARKDOWN_EDITOR.OPEN, { id: id.toString() });
        setLoading(false);
      },
      [editorRef, id],
    );

    const handleFilePicker = cb => {
      const input = document.createElement('input');
      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'application/*,audio/*,image/*,video/*');

      input.addEventListener('change', async e => {
        setLoadingContent(true);
        const file = e.target.files[0];
        const uploadedFile = await dispatch(uploadDocument(file));

        cb(uploadedFile.url, { title: file.name });
        setLoadingContent(false);
      });

      input.click();
    };

    const handleImageUpload = async blobInfo => {
      setLoadingContent(true);
      const file = blobInfo.blob();
      const uploadedFile = await dispatch(uploadDocument(file));
      setLoadingContent(false);
      return uploadedFile.url;
    };

    return (
      <div className={styles.root}>
        {loading && (
          <div className={styles.loader}>
            <Loader />
          </div>
        )}
        <Editor
          disabled={disabled}
          apiKey={TINY_MCE_KEY}
          value={initialValue}
          onInit={onInit}
          onEditorChange={onChange}
          init={{
            ...config,
            setup: editor => {
              editor.on('focus', () => setEditorFocused(true));
            },
            height,
            min_height: height,
            width,
            file_picker_callback: handleFilePicker,
            images_upload_handler: handleImageUpload,
          }}
        />
      </div>
    );
  },
);

TinyEditor.defaultProps = {
  id: '',
  initialValue: '',
  error: '',
  onChange: () => {},
  height: '80vh',
  width: '100%',
};

export { TinyEditor };
