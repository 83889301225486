import { useState, useRef, useEffect } from 'react';
import { useKnowledgeComments } from '../hooks';
import { useDateFormatter } from 'shared/hooks/dates.hooks';
import styles from '../knowledge-comments.module.scss';
import ReactMarkdown from 'react-markdown';
import { CommentInput } from './comment-input';
import { isAccountAdmin } from 'shared/store/selectors/auth.selector';
import { getUser } from 'shared/store/selectors/user.selector';
import { useSelector } from 'react-redux';
import { faReply, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import analyticsService from 'src/helpers/analytics.service';
import { EVENTS, AUTH_USER_TYPE_NORMAL } from 'shared/constants';

const { KNOWLEDGE_COMMENTS } = EVENTS;

const COMMENT_INDENT = 24;
const MAX_LEVEL = 2;

/**
 * Comment component
 * @param {object} props
 * @param {import('shared/api/knowledge-comments.service.api').Comment} props.comment
 * @param {string} props.knowledgeId
 */
export function Comment({ comment }) {
  const { lang, deleteSubComment, knowledgeId } = useKnowledgeComments();
  const [showReplyInput, setShowReplyInput] = useState(false);
  const { formatLongDateTime } = useDateFormatter();
  const replyInputRef = useRef(null);

  const isAdmin = useSelector(isAccountAdmin);
  const currentUser = useSelector(getUser);

  const handleDeleteSubComment = id => async () => {
    analyticsService.track(KNOWLEDGE_COMMENTS.DELETE_CLICKED, {
      commentId: id,
      isOwner: comment.user.id === currentUser.id,
    });

    await deleteSubComment(id);
  };

  const openReplyInput = () => {
    analyticsService.track(KNOWLEDGE_COMMENTS.REPLY_CLICKED, {
      commentId: comment.id,
      knowledgeId,
    });

    setShowReplyInput(true);
  };

  const scrollAfterReplyInput = () => {
    replyInputRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
  };

  useEffect(() => {
    if (showReplyInput) {
      setTimeout(scrollAfterReplyInput, 100);
    }
  }, [showReplyInput]);

  return (
    <>
      <div id={`comment-${comment.id}`} style={{ marginLeft: comment.level * COMMENT_INDENT }}>
        <div className={styles.comment}>
          <div className={styles.author}>
            <h4>{comment.user.displayName}</h4>
            <span>{formatLongDateTime(comment.createdAt)}</span>
          </div>

          <ReactMarkdown className={styles.text}>{comment.text}</ReactMarkdown>

          <div className={styles.actions}>
            {comment.level < MAX_LEVEL && currentUser.type === AUTH_USER_TYPE_NORMAL && (
              <button className={styles.reply} onClick={openReplyInput}>
                <FontAwesomeIcon icon={faReply} />
                {lang.REPLY}
              </button>
            )}

            {(comment.user.id === currentUser.id || isAdmin) && (
              <button className={styles.delete} onClick={handleDeleteSubComment(comment.id)}>
                <FontAwesomeIcon icon={faTrash} />
                {lang.DELETE}
              </button>
            )}
          </div>
        </div>
        {showReplyInput && (
          <div ref={replyInputRef} className={styles.scrollWithMargin}>
            <CommentInput
              commentLevel={comment.level}
              commentId={comment.id}
              closeInput={() => setShowReplyInput(false)}
            />
          </div>
        )}
      </div>
      {comment.comments.map(subComment => (
        <Comment key={subComment.id} comment={subComment} />
      ))}
    </>
  );
}
