import { connect } from 'react-redux';
import { KnowledgeSectionListComponent } from './knowledge-section-list.component';
import {
  deleteCategory,
  createCategory,
  updateCategorySubcategories,
} from 'shared/store/actions/knowledge.categories.actions';
import { knowledgeSelectItem } from 'src/store/actions/knowledgeViewer.actions';

const mapStateToProps = ({ application }) => ({
  adminMode: application.adminMode,
});

export const KnowledgeSectionList = connect(mapStateToProps, {
  deleteCategory,
  createCategory,
  updateCategorySubcategories,
  knowledgeSelectItem,
})(KnowledgeSectionListComponent);
