import React from 'react';
import styles from './contact-item.module.scss';
import { ContactsEntityIcon, PagerIcon } from '../../images';
import { useSelector } from 'react-redux';
import { getLang } from 'shared/store/selectors/lang.selector';
import { FavoritesButton } from '../favorites-button/favorites-button';
import { FAVORITES_ENTITIES, ROUTES, INTERNAL_ROUTING, CATEGORY_TYPES } from 'shared/constants';
import { ShareBoxComponent } from '../../components/share';
import { MailFilled, PhoneFilled } from '@ant-design/icons';
import { useHistory } from 'react-router';

const ContactItemComponent = React.memo(function ContactItemComponent({
  contact,
  onClickEvent = () => {},
  hideFavorites = false,
}) {
  const departmentsLang = useSelector(getLang('TAGS'));
  const history = useHistory();

  const navigateToContact = () => {
    const event = {
      id: contact.id,
      title: contact?.firstName,
    };

    // Trigger event
    onClickEvent(event);

    history.push(`${ROUTES.CONTACTS}?${INTERNAL_ROUTING.QUERY_PARAMS.CONTACTS}=${contact.id}`);
  };

  const renderSecondaryDetail = () => {
    if (contact?.pager) {
      return (
        <div className={styles.iconWrapper}>
          <PagerIcon className={styles.pagerIcon} />
          <div className={styles.pager}>{contact?.pager}</div>
        </div>
      );
    }

    if (contact?.email) {
      return (
        <div className={styles.iconWrapper}>
          <MailFilled className={styles.icon} />
          <div className={styles.email}>
            {contact?.email && <a href={`mailto:${contact?.email}`}>{contact?.email}</a>}
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <div className={styles.root} onClick={navigateToContact}>
      <div className={styles.thumbnailWrapper}>
        <img src={ContactsEntityIcon} alt={'contact'} />
      </div>
      <div className={styles.content}>
        <div className={styles.wrapper}>
          <div className={styles.name}>
            {`${contact?.firstName} ${contact?.lastName ? contact?.lastName : ''}`}
          </div>
          <div className={styles.department}>
            {departmentsLang[contact?.professional?.department] || ''}
          </div>
        </div>
        <div className={styles.contacts}>
          {contact?.phone && (
            <div className={styles.iconWrapper}>
              <PhoneFilled className={styles.icon} />
              <div className={styles.phone}>{contact?.phone}</div>
            </div>
          )}
          {renderSecondaryDetail()}
        </div>
        <div className={styles.actionIcons}>
          <button type="button" className={styles.buttonWrapper}>
            <ShareBoxComponent
              path={`${ROUTES.CONTACTS}?${INTERNAL_ROUTING.QUERY_PARAMS.CONTACTS}=${contact.id}`}
              id={contact.id}
              title={contact?.firstName}
              entityType={CATEGORY_TYPES.KNOWLEDGE}
              iconClassName={styles.actionIcon}
            />
          </button>
          {!hideFavorites && (
            <FavoritesButton
              id={contact.id}
              entity={contact.isUser ? FAVORITES_ENTITIES.USER : FAVORITES_ENTITIES.CONTACT}
              background={styles.greyBackground}
              className={styles.actionIcon}
            />
          )}
        </div>
      </div>
    </div>
  );
});

export { ContactItemComponent };
