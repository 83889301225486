import React from 'react';
import { Editor, EditorState, RichUtils, convertToRaw, convertFromRaw } from 'draft-js';
import gkx from 'draft-js/lib/gkx';
import NestedRichTextEditorUtil from 'draft-js/lib/NestedRichTextEditorUtil';
import { draftToMarkdown, markdownToDraft } from 'markdown-draft-js';
import classNames from 'classnames';
import styles from './mrkdwn-editor.module.scss';

const RichTextUtils = gkx('draft_tree_data_support') ? NestedRichTextEditorUtil : RichUtils;

// Editorjs block and inline example
// https://github.com/facebook/draft-js/blob/main/examples/draft-0-10-0/rich/rich.html

const BLOCK_TYPES = [
  { label: 'UL', style: 'unordered-list-item' },
  { label: 'OL', style: 'ordered-list-item' },
];

const INLINE_STYLES = [
  { label: 'Bold', style: 'BOLD' },
  { label: 'Italic', style: 'ITALIC' },
  { label: 'Monospace', style: 'CODE' },
];

const ACCEPTABLE_STYLES = INLINE_STYLES.map(({ style }) => style.toLowerCase());

function StyleButton({ active, style, label, onToggle }) {
  return (
    <span
      className={classNames(styles.styleButton, active && styles.styleButtonActive)}
      onMouseDown={e => {
        e.preventDefault();
        onToggle(style);
      }}
    >
      {label}
    </span>
  );
}

function BlockStyleControls(props) {
  const selection = props.editorState.getSelection();
  const blockType = props.editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  return BLOCK_TYPES.map(type => (
    <StyleButton
      key={type.label}
      label={type.label}
      onToggle={props.onToggle}
      active={type.style === blockType}
      style={type.style}
    />
  ));
}

function InlineStyleControls(props) {
  const currentStyle = props.editorState.getCurrentInlineStyle();

  return INLINE_STYLES.map(type => (
    <StyleButton
      key={type.label}
      active={currentStyle.has(type.style)}
      label={type.label}
      onToggle={props.onToggle}
      style={type.style}
    />
  ));
}

const MrkdwnEditor = React.memo(
  ({
    name,
    error,
    className,
    wrapperClass,
    placeholder,
    value,
    onChange,
    hideControllers,
    inputStyle = 'user',
    ...props
  }) => {
    const [editorState, setEditorState] = React.useState(() => EditorState.createEmpty());

    React.useEffect(() => {
      const rawData = markdownToDraft(value);
      const contentState = convertFromRaw(rawData);
      setEditorState(EditorState.createWithContent(contentState));
    }, [value]);

    const handleKeyCommand = (command, editorState) => {
      if (!ACCEPTABLE_STYLES.includes(command)) return null;

      const newState = RichUtils.handleKeyCommand(editorState, command);

      if (newState) {
        setEditorState(newState);
        return 'handled';
      }

      return 'not-handled';
    };

    const toggleInlineStyle = inlineStyle => {
      setEditorState(RichTextUtils.toggleInlineStyle(editorState, inlineStyle));
    };

    const toggleBlockStyle = blockStyle => {
      setEditorState(RichTextUtils.toggleBlockType(editorState, blockStyle));
    };

    const onBlur = () => {
      const content = editorState.getCurrentContent();
      const rawObject = convertToRaw(content);
      const markdownString = draftToMarkdown(rawObject);
      onChange(name, markdownString);
    };

    return (
      <div className={classNames(styles.root, className)}>
        <div className={classNames(styles.wrapper, styles[inputStyle], wrapperClass)}>
          {!hideControllers && (
            <div className={styles.editorControls}>
              <InlineStyleControls editorState={editorState} onToggle={toggleInlineStyle} />
              <BlockStyleControls editorState={editorState} onToggle={toggleBlockStyle} />
            </div>
          )}
          <div className={styles.editor}>
            <Editor
              editorState={editorState}
              onChange={setEditorState}
              onBlur={onBlur}
              placeholder={placeholder}
              handleKeyCommand={handleKeyCommand}
              {...props}
            />
          </div>
        </div>
        <span className={styles.error}>{error}</span>
      </div>
    );
  },
);

export { MrkdwnEditor };
