import React from 'react';
import { Lang } from 'shared/constants/lang';
import { NoContent } from '../../common';
import { Loader } from '../../core';
import { NewsFeedDelete } from '../news-feed-delete';
import { NewsFeedItem } from '../news-feed-item';
import styles from './news-feed-list.module.scss';

const NewsFeedListComponent = React.memo(({ items, lang }) => {
  const [entityDeletionId, setEntityDeletionId] = React.useState(null);

  const renderItems = () => {
    if (items === null) {
      return <Loader />;
    }

    if (items?.length === 0) {
      return <NoContent text={lang.NO_EXISTING_NEWS} className={styles.noContent} />;
    }

    return items.map(item => (
      <NewsFeedItem removeItem={setEntityDeletionId} key={item.id} {...item} />
    ));
  };

  return (
    <>
      <label className={styles.title}>{lang.TITLE}</label>
      <div className={styles.root}>{renderItems()}</div>
      <NewsFeedDelete onFinish={() => setEntityDeletionId(null)} id={entityDeletionId} />
    </>
  );
});

NewsFeedListComponent.defaultProps = {
  items: null,
  lang: Lang.NEWS_FEED,
};

export { NewsFeedListComponent };
