import { ConfigProvider, theme } from 'antd';
import { colorPalette } from 'shared/constants';

export function AntDesignStylesOverrides(props) {
  const { token } = theme.useToken();

  return (
    <ConfigProvider
      theme={{
        ...token,
        components: {
          ...token.components,
          Card: {
            boxShadow: '0px 9px 20px 0px rgba(0, 0, 0, 0.10)',
          },
          Collapse: {
            headerBg: token.colorBgBase,
            colorBorder: token.colorBorderSecondary,
          },
          List: {
            lineWidth: 2,
          },
          Anchor: {
            linkPaddingInlineStart: 12,
          },
          Switch: {
            colorPrimary: colorPalette.primaryMain5,
            colorPrimaryHover: colorPalette.primaryMain6,
            colorTextQuaternary: colorPalette.neutral5,
            colorTextTertiary: colorPalette.neutral5,
          },
        },
      }}
    >
      {/* 
        Since we don't have LESS to override ant design,
        we do it in the Javascript runtime, where it can access the design token 
      */}
      <style>{`
      .ant-collapse-header-text {
        font-weight: ${token.fontWeightStrong};
      }

      .ant-anchor-link-title {
        text-overflow: clip !important;
        white-space: normal !important;
      }

      .ant-anchor-ink{
        left: 100% !important;
      }

      .ant-anchor-link-title{
        padding-right: var(--margin-1)
      }
      `}</style>
      {props.children}
    </ConfigProvider>
  );
}
