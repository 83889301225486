import React from 'react';
import styles from './switch-organization.module.scss';
import { Lang, ROUTES, DATA_KEYS } from 'shared/constants';
import { OrganizationBoxComponent } from '../../../components/switchOrganization/organizationBox';
import { Redirect, useHistory } from 'react-router';
import { Popup } from '../../../components/common';
import _ from 'lodash';
const { HOME_PAGE } = DATA_KEYS;

const SwitchOrganizationPageComponent = React.memo(function SwitchOrganizationPageComponent({
  organizations,
  lang,
  switchOrganization,
  activeOrganizationId,
}) {
  let history = useHistory();

  const switchOrganizationAction = async ({ id }) => {
    if (id === activeOrganizationId) {
      history.push(ROUTES.HOME);
      return false;
    }

    await switchOrganization({ id });
    history.push(ROUTES.HOME);
  };

  if (!organizations || _.isEmpty(organizations)) {
    return null;
  }

  const allOrganizations = organizations.filter(({ activated }) => activated);

  if (allOrganizations.length <= 1) {
    return <Redirect to={ROUTES.HOME} />;
  }

  return (
    <Popup title={lang.HEADER} isOpen>
      <div className={styles.organizations} data-key={HOME_PAGE.SWITCH_ORGANIZATION_POPUP}>
        {allOrganizations.map(org => (
          <OrganizationBoxComponent
            key={org.id}
            isActive={org.id === activeOrganizationId}
            {...org}
            onClick={switchOrganizationAction}
          />
        ))}
      </div>
    </Popup>
  );
});

SwitchOrganizationPageComponent.defaultProps = {
  organizations: [],
  activeOrganizationId: '',
  lang: Lang.SWITCH_ORGANIZATION,
  switchOrganization: () => {},
};

export { SwitchOrganizationPageComponent };
