import * as React from 'react';

function SvgComponent({ color = '#2c5789', ...props }) {
  return (
    <svg id="prefix__Extra_Checkmarks" data-name="Extra Checkmarks" viewBox="0 0 20 20" {...props}>
      <path
        id="prefix__Path_285"
        data-name="Path 285"
        className="prefix__cls-1"
        d="M14.37 8.13L10.56 12V2H9.44v10L5.63 8.13l-.79.79L10 14.06l5.17-5.14z"
        fill={color}
      />
      <path
        id="prefix__Path_286"
        data-name="Path 286"
        className="prefix__cls-1"
        d="M17.73 13.07v3.82H2.27v-3.82H1.16V18h17.68v-4.93z"
        fill={color}
      />
    </svg>
  );
}

export default SvgComponent;
