import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import hubServiceApi from 'shared/api/hub.service.api';

const ContentPreviewContext = createContext({
  isHub: false,
  itemId: null,
  setItemId: () => {},
  item: {},
  error: null,
  loading: false,
  analyticsTriggers: {},
});

export function useContentPreview() {
  return useContext(ContentPreviewContext);
}

/**
 * Content Preview Provider
 * @export
 * @param {{
 * children: React.ReactNode;
 * isHub?: boolean;
 * analyticsTriggers?: {
 *  onImport: (payload) => void;
 *  onImportDismiss: (payload) => void;
 *  onImportEditContent: (payload) => void;
 *  onPublish: (payload) => void;
 *  onShare: (payload) => void;
 * }
 * }} { children, isHub, analyticsTriggers }
 * @returns
 */
export function ContentPreviewProvider({
  children,
  isHub: initialIsHub = false,
  analyticsTriggers = {},
}) {
  const [itemId, setItemId] = useState(null);
  const [item, setItem] = useState({});
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isHub] = useState(initialIsHub);

  const getItem = useCallback(async id => {
    setError(null);
    setLoading(true);

    const { data, error } = await hubServiceApi.getItem({ id });

    if (error || !data) {
      setError(error);
      setLoading(false);
      return;
    }

    setItem(data);
    setLoading(false);
  }, []);

  useEffect(() => {
    if (!isHub || !itemId) {
      return;
    }

    getItem(itemId);
  }, [itemId, getItem, isHub]);

  const value = { isHub, itemId, setItemId, item, error, loading, analyticsTriggers };

  return <ContentPreviewContext.Provider value={value}>{children}</ContentPreviewContext.Provider>;
}
