import * as React from 'react';

function SvgComponent({ color = '#fff', ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" {...props}>
      <path
        fill={color}
        d="M25 2C12.31 2 2 12.31 2 25s10.31 23 23 23 23-10.31 23-23a1 1 0 10-2 0c0 11.61-9.39 21-21 21S4 36.61 4 25 13.39 4 25 4c6.693 0 12.635 3.13 16.48 8H35a1 1 0 100 2h10V4a1 1 0 00-1.016-1.014A1 1 0 0043 4v6.7C38.785 5.401 32.288 2 25 2z"
      />
    </svg>
  );
}

export default SvgComponent;
