import * as Yup from 'yup';
import { USER_TAGS_KEYS } from 'shared/constants';
import { currentEnvironment } from 'shared/services/Environment';
import { ENVIRONMENT_KEYS } from 'shared/constants/environment';

export const KNOWLEDGE_ITEM_SCHEMA = Yup.object().shape({
  title: Yup.string().min(2, 'Too Short!').max(200, 'Too Long!').required('Required!'),
  subtitle: Yup.string().max(150, 'Too Long!'),
  /*
    Localhost urls are not considered valid by yup,
    and this issue locks the save button when running the app in localhost
    https://github.com/jquense/yup/issues/224
  */
  link: currentEnvironment() === ENVIRONMENT_KEYS.DEVELOPMENT ? Yup.string() : Yup.string().url(),
  keywords: Yup.array().of(Yup.string()),
  expiresAt: Yup.date().nullable(true),
  fileUpdatedAt: Yup.date().required('Required'),
  ownerIds: Yup.array()
    .of(Yup.string())
    .min(1, 'Please assign at least one owner')
    .required('Required!'),
  specialty: Yup.string().nullable(),
  subspecialty: Yup.string().nullable(),
  contentType: Yup.string().nullable(),
  targetAudience: Yup.array().of(Yup.string()),
  labels: Yup.array().of(Yup.string()),
  type: Yup.string(),
  recipientsTags: Yup.object().shape({
    [USER_TAGS_KEYS.DEPARTMENT]: Yup.array().of(Yup.string()),
    [USER_TAGS_KEYS.ROTATION]: Yup.array().of(Yup.string()),
    [USER_TAGS_KEYS.ROLE]: Yup.array().of(Yup.string()),
    [USER_TAGS_KEYS.LEVEL_OF_TRAINING]: Yup.array().of(Yup.string()),
    [USER_TAGS_KEYS.PROFESSION]: Yup.array().of(Yup.string()),
    [USER_TAGS_KEYS.USERS]: Yup.array().of(Yup.string()),
  }),
  contributors: Yup.array().of(
    Yup.object().shape({
      userId: Yup.string(),
      contributorId: Yup.string(),
      displayName: Yup.string().max(50).required('Required!'),
    }),
  ),
  shareToHub: Yup.boolean(),
});
