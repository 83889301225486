import { LOAD_STORAGE, UPDATE_STORAGE } from 'shared/store/actions';
import { applyInitialState } from 'shared/store/actions/storage.actions';
import { finishStep } from 'shared/store/actions/application.actions';
import { STORAGE_KEYS, APPLICATION_STEPS } from 'shared/constants';
import IndexedDBHelper from '../../helpers/indexeddb.helper';

export const loadState =
  ({ dispatch }) =>
  next =>
  async action => {
    next(action);

    if (action.type === LOAD_STORAGE) {
      let state = (await IndexedDBHelper.get(STORAGE_KEYS.APPLICATION_DATA)) || {};

      dispatch(applyInitialState(state));

      dispatch(finishStep({ step: APPLICATION_STEPS.PERSIST_STORAGE }));
    }
  };

export const updatePersistData = () => next => async action => {
  next(action);

  if (action.type === UPDATE_STORAGE) {
    IndexedDBHelper.set(STORAGE_KEYS.APPLICATION_DATA, action.payload);
  }
};

export const storageMiddleware = [loadState, updatePersistData];
