import React, { useCallback, useMemo } from 'react';
import rotationsServiceApi from 'shared/api/rotations.service.api';
import styles from './rotation-content.module.scss';
import { notification } from 'antd';
import { formatFullName } from 'shared/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/pro-solid-svg-icons';
import { Button } from 'src/components/design-system';
import { useRotationsContext } from '../rotations-context';
import { useSelector } from 'react-redux';
import { Select } from 'src/components/design-system/forms';

const RotationOwners = () => {
  const { selectedRotation: rotation, lang, canEdit, updateRotationOwners } = useRotationsContext();
  const owners = useSelector(state => state.owners.items);
  const [editing, setEditing] = React.useState(false);
  const ownersToDisplay = rotation?.ownerIds?.map(id => owners[id]).filter(o => o !== undefined);
  const [managedOwners, setManagedOwners] = React.useState(rotation?.ownerIds);
  const [api, contextHolder] = notification.useNotification();

  const options = useMemo(
    () =>
      Object.values(owners)
        .map(owner => ({
          label: formatFullName(owner),
          value: owner.id,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [owners],
  );

  const showToast = useCallback(
    message => {
      api.error({
        message: message || lang.ERROR_OCCURRED,
        placement: 'bottomRight',
      });
    },
    [api, lang.ERROR_OCCURRED],
  );

  const handleOwnerChange = useCallback(value => {
    setManagedOwners(value || []);
  }, []);

  const handleCancel = useCallback(() => {
    setEditing(false);
    setManagedOwners(rotation?.ownerIds);
  }, [rotation]);

  const handleSubmit = async () => {
    setEditing(false);
    try {
      const res = await rotationsServiceApi.updateRotation({
        rotationId: rotation?.id,
        payload: { ownerIds: managedOwners },
      });
      if (res.error) {
        showToast(res.data.message);
      } else {
        updateRotationOwners(managedOwners);
      }
    } catch (e) {
      showToast();
    } finally {
      setEditing(false);
    }
  };

  return editing ? (
    <div className={styles.editOwners}>
      {contextHolder}
      <label className={styles.editOwnersTitle}>{lang.ROTATION_DIRECTOR}</label>
      <Select
        multiple
        size="large"
        onChange={(f, v) => handleOwnerChange(v)}
        options={options}
        value={managedOwners}
        style={{ maxWidth: '70%' }}
      />
      <div className={styles.actionButtons}>
        <Button type="primary" onClick={handleSubmit}>
          {lang.SUBMIT}
        </Button>
        <Button onClick={handleCancel}>{lang.CANCEL}</Button>
      </div>
    </div>
  ) : (
    <div className={styles.owners}>
      {lang.ROTATION_DIRECTOR}
      {': '}
      {ownersToDisplay?.length > 0 ? (
        ownersToDisplay?.map((owner, i, arr) => (
          <span>
            <a href={`mailto:${owner?.email}`} target="_blank" rel="noreferrer">
              {formatFullName(owner)}
            </a>
            {i !== arr.length - 1 ? ', ' : ''}
          </span>
        ))
      ) : (
        <span className={styles.noOwner}>{lang.UNASSIGNED}</span>
      )}
      {canEdit && (
        <FontAwesomeIcon
          icon={faPencil}
          className={styles.ownersIcon}
          onClick={() => setEditing(true)}
        />
      )}
    </div>
  );
};

export default RotationOwners;
