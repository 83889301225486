import { connect } from 'react-redux';
import { fetchUsers, deleteUser } from 'shared/store/actions/users-management.actions';
import { UserManagementPageComponent } from './users-management.component';
import { RotationOptionsProvider } from 'shared/hooks/profile.context';

const mapStateToProps = ({ lang, usersManagement }) => {
  return {
    lang: lang.USERS_MANAGEMENT,
    users: usersManagement.list,
  };
};

const UserManagementPageWrapper = ({ ...props }) => {
  return (
    <RotationOptionsProvider>
      <UserManagementPageComponent {...props} />
    </RotationOptionsProvider>
  );
};

export const UserManagementPage = connect(mapStateToProps, {
  fetchUsers,
  deleteUser,
})(UserManagementPageWrapper);
