import ReactMarkdown from 'react-markdown';
import styles from './render-chat-messages.module.scss';
import React from 'react';
import classNames from 'classnames';
import { useChatConversation } from '../../hooks/use-chat-conversation';
import { SourcesCollapse } from '../sources-collapse';
import { ANSWER_STATE, EVENTS } from 'shared/constants';
import analyticsService from 'src/helpers/analytics.service';

export function MessageReferences({ message }) {
  const { lang, conversationId } = useChatConversation();

  if (message.state === ANSWER_STATE.ONLY_RESOURCES_FOUND || !message.references?.length) {
    return <></>;
  }

  return (
    <SourcesCollapse
      onOpen={() => {
        analyticsService.track(EVENTS.AI_CHAT.OPEN_REFERENCES, { conversationId });
      }}
      header={<p className={styles.collapseHeader}>{lang.SOURCE}</p>}
    >
      <div className={styles.referencesPanel}>
        {message.references.map((reference, index) => (
          <RenderReference key={index} reference={reference} />
        ))}
      </div>
    </SourcesCollapse>
  );
}

export function RenderReference({ reference }) {
  const [expanded, setExpanded] = React.useState(false);
  const { lang, conversationId } = useChatConversation();

  const handleExpand = () => {
    const newState = !expanded;
    setExpanded(newState);

    analyticsService.track(EVENTS.AI_CHAT.TOGGLE_REFERENCE, {
      conversationId,
      expended: newState,
    });
  };

  return (
    <div className={styles.reference}>
      <div className={classNames(styles.referenceContent, expanded && styles.expanded)}>
        <ReactMarkdown>{reference.content}</ReactMarkdown>
      </div>
      <button className={styles.expandedBtn} onClick={handleExpand}>
        {expanded ? lang.READ_LESS : lang.READ_MORE}
      </button>
    </div>
  );
}
