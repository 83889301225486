import { REMOTE_CONFIG_KEYS } from 'shared/constants';
import { FEATURE_FLAGS_UPDATE } from 'shared/store/actions';
import remoteConfigService from '../../helpers/remote-config.service';

export const initFeatureFlagStatus = () => dispatch => {
  const featureFlags = remoteConfigService.getJsonValue(REMOTE_CONFIG_KEYS.FEATURE_FLAGS);

  dispatch({
    type: FEATURE_FLAGS_UPDATE,
    payload: featureFlags,
  });
};
