import React, { useCallback, useEffect, useState } from 'react';
import styles from './category-row.module.scss';
import listStyles from './list-components.module.scss';
import {
  RenderCategoryIcon,
  RenderContentType,
  RenderTags,
  RenderMetadata,
  RenderLabels,
  RenderIndicationTag,
} from '../components';
import { CATEGORY_TYPES } from 'shared/constants';
import classnames from 'classnames';

export function CategoryRowComponent({
  title = '',
  type,
  tags,
  labels,
  metadata,
  options,
  lastUpdatedAt,
  categoryType,
  adminMode,
  onBlur,
  onClick,
  hasEditPermission,
  actions,
  wrapperClass,
}) {
  const isCategory = type === CATEGORY_TYPES.CATEGORY;
  const [titleInput, setTitleInput] = useState(title);
  const showUpdatedIndication = !adminMode && lastUpdatedAt;

  useEffect(() => {
    if (titleInput !== title) {
      setTitleInput(title);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title, setTitleInput]);

  const onInputFocus = useCallback(
    e => {
      if (!adminMode) return;
      e.stopPropagation();
    },
    [adminMode],
  );

  const onHeaderPress = useCallback(e => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.target.blur();
    }
  }, []);

  const onChange = useCallback(
    e => {
      const { value } = e.target;
      setTitleInput(value);
    },
    [setTitleInput],
  );

  return (
    <div className={classnames([styles.root, wrapperClass])} onClick={onClick}>
      <div className={styles.titleContainer}>
        <RenderCategoryIcon
          title={title}
          type={type}
          isCategory={isCategory}
          categoryType={categoryType}
          adminMode={adminMode}
          options={options}
          styles={listStyles}
        />
        <div>
          <h5
            className={styles.text}
            contentEditable={adminMode && hasEditPermission}
            suppressContentEditableWarning={true}
            onBlur={onBlur}
            onKeyPress={onHeaderPress}
            onChange={onChange}
            onClick={onInputFocus}
          >
            {titleInput}
          </h5>
        </div>
        <RenderContentType tags={tags} styles={listStyles} />
        {showUpdatedIndication && <RenderIndicationTag lastUpdate={lastUpdatedAt} />}
      </div>
      <div className={styles.tags}>
        {labels?.length > 0 && <RenderLabels labels={labels} styles={listStyles} />}
        <RenderMetadata
          metadata={metadata}
          adminMode={adminMode}
          isCategory={isCategory}
          styles={listStyles}
        />
        <RenderTags tags={tags} styles={listStyles} />
        <div className={styles.actions}>{actions}</div>
      </div>
    </div>
  );
}
