import { connect } from 'react-redux';
import {
  deleteLabCategory,
  createLabCategory,
  updateLabCategorySubcategories,
} from 'shared/store/actions/lab.categories.actions';
import { getLabCategoryById } from 'shared/store/selectors/lab.selector';
import { LabSectionListComponent } from './lab-section-list.component';

const mapStateToProps = ({ application, lab, lang }, { id }) => ({
  adminMode: application.adminMode,
  category: getLabCategoryById(lab, { id }),
  lang: lang.CONTENT_LIST,
});

const mapDispatchToProps = {
  deleteCategory: deleteLabCategory,
  createCategory: createLabCategory,
  updateCategorySubcategories: updateLabCategorySubcategories,
};

export const LabSectionList = connect(mapStateToProps, mapDispatchToProps)(LabSectionListComponent);
