import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EVENTS } from 'shared/constants';
import { updateKnowledgeItem } from 'shared/store/actions/knowledge.items.actions';
import { getLang } from 'shared/store/selectors/lang.selector';
import analyticsService from 'src/helpers/analytics.service';

export const useUpdateKnowledgeItem = ({ item, openNotification }) => {
  const lang = useSelector(getLang('KNOWLEDGE_EDITOR'));
  const [itemData] = useState(item);
  const dispatch = useDispatch();

  const submitUpdate = useCallback(
    async itemData => {
      try {
        await dispatch(updateKnowledgeItem(itemData));

        const EVENT_DATA = {
          id: itemData?.id,
          title: itemData?.title,
          ownerIds: itemData?.ownerIds,
          type: itemData?.type,
          specialty: itemData?.tags?.specialty,
          subspecialty: itemData?.tags?.subspecialty,
          contentType: itemData?.tags?.contentType,
          targetAudience: itemData?.tags?.targetAudience,
        };
        if (itemData?.shareToHub !== undefined) {
          const event = itemData.shareToHub
            ? EVENTS.KNOWLEDGE_HUB.SHARE_ITEM
            : EVENTS.KNOWLEDGE_HUB.UNSHARE_ITEM;
          analyticsService.track(event, EVENT_DATA);
        }

        openNotification({ message: lang.SHARE_SUCCESS_MESSAGE });
      } catch (error) {
        openNotification({
          message: lang.SHARE_ERROR_MESSAGE,
          type: 'error',
        });
      }
    },
    [dispatch, openNotification, lang],
  );

  const setFieldValue = useCallback(
    (name, value) => {
      submitUpdate({ ...itemData, [name]: value });
    },
    [itemData, submitUpdate],
  );

  return { setFieldValue };
};
