import * as React from 'react';

function SvgComponent({ color = '#2c5789', ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.43 31.14" {...props}>
      <path
        data-name="Path 136"
        d="M8.37 2.42a1.92 1.92 0 013.84 0"
        fill="none"
        stroke={color}
        strokeLinecap="square"
        strokeMiterlimit={10}
      />
      <path
        data-name="Path 137"
        d="M15.35 7.31H5.22V3.84h10.13z"
        fill="none"
        stroke={color}
        strokeLinecap="square"
        strokeMiterlimit={10}
      />
      <path
        data-name="Path 138"
        d="M17.15 4.96h2.78v25.68H.5V4.96h2.58"
        fill="none"
        stroke={color}
        strokeLinecap="square"
        strokeMiterlimit={10}
      />
      <path
        data-name="Line 33"
        fill="none"
        stroke={color}
        strokeLinecap="square"
        strokeMiterlimit={10}
        d="M4.016 13.1H16.65"
      />
      <path
        data-name="Line 34"
        fill="none"
        stroke={color}
        strokeLinecap="square"
        strokeMiterlimit={10}
        d="M4.016 18.74H16.65"
      />
      <path
        data-name="Line 35"
        fill="none"
        stroke={color}
        strokeLinecap="square"
        strokeMiterlimit={10}
        d="M4.016 24.37H16.65"
      />
    </svg>
  );
}

export default SvgComponent;
