import React from 'react';
import _ from 'lodash';
import { USER_TAGS_KEYS } from 'shared/constants';
import { useProfileTagsOption } from 'shared/hooks/profile.hooks';
import styles from './recipients-selector.module.scss';
import classNames from 'classnames';
import { Select } from 'src/components/design-system/forms/select.component';
import { formatFullName, sortByAlphabet } from 'shared/utils';

export const RecipientsSelectorComponent = ({
  lang,
  users,
  fetchUsers,
  usersStats,
  fetchUserStats,
  usersLastUpdated,
  onChange,
  error,
  wrapperStyle,
  wrapperClass,
  ...props
}) => {
  // Fetch users on mount if not fetched yet
  React.useEffect(() => {
    if (_.isNull(users)) fetchUsers();
  }, [users, fetchUsers]);

  // Fetch users stats on mount if not fetched yet
  React.useEffect(() => {
    if (_.isNull(usersStats)) fetchUserStats();
  }, [usersStats, fetchUserStats]);

  const {
    departmentOptions,
    rotationOptions,
    professionOptions,
    roleOptions,
    levelOfTrainingOptions,
  } = useProfileTagsOption({ stats: usersStats });

  const usersList = React.useMemo(() => {
    if (_.isNull(users)) return [];
    return users
      .map(({ id, email, firstName, lastName }) => ({
        value: `${USER_TAGS_KEYS.USERS}.${id}`,
        label: formatFullName({ firstName, lastName, email }, true),
      }))
      .sort((a, b) => sortByAlphabet(a?.label, b?.label));
  }, [users]);

  const options = React.useMemo(
    () => [
      {
        label: lang.DEPARTMENT,
        options: departmentOptions,
      },
      {
        label: lang.ROTATION,
        options: rotationOptions,
      },
      {
        label: lang.ROLE,
        options: roleOptions,
      },
      {
        label: lang.PROFESSION,
        options: professionOptions,
      },
      {
        label: lang.LEVEL_OF_TRAINING,
        options: levelOfTrainingOptions,
      },
      {
        label: lang.USERS,
        options: usersList,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [usersStats, usersList, usersLastUpdated, rotationOptions],
  );

  const onChangeInternal = React.useCallback(
    (values, options) => {
      const selected = {
        [USER_TAGS_KEYS.DEPARTMENT]: [],
        [USER_TAGS_KEYS.ROTATION]: [],
        [USER_TAGS_KEYS.ROLE]: [],
        [USER_TAGS_KEYS.LEVEL_OF_TRAINING]: [],
        [USER_TAGS_KEYS.PROFESSION]: [],
        [USER_TAGS_KEYS.USERS]: [],
      };

      options.forEach(value => {
        const [key, val] = value.split('.');
        selected[key].push(val);
      });
      onChange(selected);
    },
    [onChange],
  );

  return (
    <div className={styles.root}>
      <div className={classNames(styles[wrapperStyle], wrapperClass)}>
        <Select
          mode="multiple"
          error={error}
          onChange={onChangeInternal}
          options={options}
          {...props}
        />
      </div>
      {error && <span className={styles.error}>{error}</span>}
    </div>
  );
};
