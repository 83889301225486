import React from 'react';
import styles from './admission-binder.module.scss';
import classnames from 'classnames';
import { Cross } from '../../../images';
import { Button, Tooltip } from '../../../components/core';
import update from 'immutability-helper';
import { useDrop } from 'react-dnd';
import { ENTITY_TYPES, EVENTS, Lang } from 'shared/constants';
import {
  AdmissionMovePopup,
  ChecklistItem,
  ChecklistItemDelete,
  ChecklistItemEditor,
} from '../admission/components';
import { AdmissionChecklistComment } from '../admission/components';
import { TagsIcons } from 'src/components/tags/tagsIcons';
import { TextTag } from 'src/components/tags/text-tags';
import { useLocation } from 'react-router-dom';
import { INTERNAL_ROUTING, ROUTES } from 'shared/constants';
import { ShareBoxComponent } from 'src/components/share';
import { CATEGORY_TYPES } from 'shared/constants';
import analyticsService from 'src/helpers/analytics.service';
import { useSelector } from 'react-redux';
import { haveEditPermissions } from 'shared/store/selectors/knowledge.selector';
import { formatFullName } from 'shared/utils';
import { useVettedOrProvidedLang } from 'shared/hooks/lang.hooks';

const AdmissionBinderComponent = React.memo(function AdmissionBinderComponent({
  id,
  category,
  admissionSelectChecklist,
  initialOpenState,
  adminMode,
  updateOrder,
  owners,
  langPage,
  langButtons,
  langTooltip,
  createQuickCheckListItem,
}) {
  let { title, ownerIds, tags } = category;

  const hasEditPermission = useSelector(state => haveEditPermissions(state, { ownerIds }));

  const { search } = useLocation();

  const [isOpen, setOpenState] = React.useState(initialOpenState);
  const [data, setData] = React.useState(category.checks);

  const [deleteEntity, setDeleteEntity] = React.useState(null);
  const [itemEntity, setItemEntity] = React.useState(null);
  const [moveEntity, setMoveEntity] = React.useState(null);
  const [commentEntity, setCommentEntity] = React.useState(null);

  const validatedByLang = useVettedOrProvidedLang(langPage.VALIDATED_BY);

  const EVENT_DATA = {
    id,
    title,
    ...tags,
  };

  React.useEffect(() => {
    if (!id) return;

    analyticsService.track(EVENTS.CONTENT.OPEN, {
      ...EVENT_DATA,
      entityType: ENTITY_TYPES.admission,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // Drag and Drop
  const dndGroup = `values`;
  const [, drop] = useDrop({ accept: dndGroup });

  const dragItem = (id, atIndex) => {
    const { item, index } = findItem(id);
    setData(
      update(data, {
        $splice: [
          [index, 1],
          [atIndex, 0, item],
        ],
      }),
    );
  };

  const findItem = id => {
    const item = data.find(i => `${i.id}` === id);
    return {
      item,
      index: data.indexOf(item),
    };
  };

  const finishDrag = itemId => {
    updateOrder({
      id,
      itemId,
      index: findItem(itemId).index,
    });
  };

  // Comment
  const addCommentCheck = React.useCallback(({ id, title }) => {
    // TODO: analytics
    setCommentEntity({ id, title });
  }, []);

  const onFinishComment = React.useCallback(() => {
    setCommentEntity(null);
  }, []);

  // Delete value items
  const deleteCheck = React.useCallback(({ id, title }) => {
    // TODO: analytics
    setDeleteEntity({ id, title });
  }, []);

  const onFinishDelete = React.useCallback(() => {
    setDeleteEntity(null);
  }, []);

  // Editor
  const onFinishEditor = React.useCallback(() => {
    setItemEntity(null);
  }, []);

  const editOrCreateLabValue = React.useCallback(({ item = {} }) => {
    setItemEntity({ ...item });
  }, []);

  const moveAction = React.useCallback(({ id, title }) => {
    // TODO: analytics
    setMoveEntity({ id, title });
  }, []);

  const onFinishMove = React.useCallback(() => {
    setMoveEntity(null);
  }, []);

  const create = React.useCallback(
    ({ item = {} }) => {
      setItemEntity({ ownerIds, tags, ...item });
    },
    [ownerIds, tags],
  );

  const createQuickValue = React.useCallback(async () => {
    await createQuickCheckListItem({ categoryId: category.id, tags });
  }, [createQuickCheckListItem, category, tags]);

  const close = React.useCallback(() => {
    setOpenState(false);
    admissionSelectChecklist(null);
  }, [admissionSelectChecklist]);

  React.useEffect(() => {
    if (id) {
      setOpenState(true);
      setData(category.checks);
    } else {
      setOpenState(false);
    }
  }, [id, category]);

  React.useEffect(() => {
    const searchId = new URLSearchParams(search).get(INTERNAL_ROUTING.QUERY_PARAMS.CHECKLIST);

    if (!searchId && isOpen) {
      setOpenState(false);
    }
  }, [search, isOpen]);

  const renderTags = () => {
    if (!tags?.contentType && !tags?.specialty) return null;

    return (
      <div className={styles.tagsContainer}>
        <TagsIcons tags={tags} hideTags={{ targetAudience: true, contentType: true }} large />
        <TextTag tag={tags?.contentType} large />
      </div>
    );
  };

  const renderList = () => {
    return data?.map(item => (
      <ChecklistItem
        key={item.id}
        item={item}
        deleteValue={deleteCheck}
        editValue={editOrCreateLabValue}
        moveAction={moveAction}
        addCommentCheck={addCommentCheck}
        adminMode={adminMode}
        categoryId={id}
        canEdit={hasEditPermission}
        {...{
          dragItem,
          findItem,
          dndGroup,
          finishDrag,
        }}
      />
    ));
  };

  return (
    <>
      <div className={classnames(styles.root, isOpen ? styles.open : undefined)}>
        <Tooltip title={langTooltip.CLOSE} placement="bottom">
          <div onClick={close} className={styles.close}>
            <img src={Cross} alt="Close" />
          </div>
        </Tooltip>

        <div className={styles.tagIconsWrapper}>
          <div className={styles.header}>
            {title && <h2>{title}</h2>}
            <ShareBoxComponent
              path={`${ROUTES.ADMISSION}?${INTERNAL_ROUTING.QUERY_PARAMS.CHECKLIST}=${id}`}
              id={id}
              title={title}
              tags={tags}
              entityType={CATEGORY_TYPES.CHECKS}
            />
          </div>
          {renderTags()}
        </div>

        {owners && owners.length > 0 && (
          <>
            <h5>{validatedByLang}</h5>
            <div className={styles.ownersWrapper}>
              {owners.map((owner, index) => (
                <p key={owner.id}>
                  {index > 0 && ', '}
                  <a href={`mailto:${owner.email}`} target="_blank" rel="noreferrer">
                    {formatFullName(owner)}
                  </a>
                </p>
              ))}
            </div>
          </>
        )}

        {adminMode && (
          <div className={styles.buttons}>
            <Tooltip
              title={hasEditPermission ? null : langTooltip.NOT_ALLOWED_TO_EDIT}
              placement="top"
            >
              <Button
                size="small"
                onClick={create}
                buttonStyle="primary"
                className={styles.buttonWithMargin}
                disabled={!hasEditPermission}
              >
                {langButtons.ADD}
              </Button>
            </Tooltip>
            <Tooltip
              title={hasEditPermission ? null : langTooltip.NOT_ALLOWED_TO_EDIT}
              placement="top"
            >
              <Button
                size="small"
                onClick={createQuickValue}
                buttonStyle="primary"
                className={styles.buttonWithMargin}
                disabled={!hasEditPermission}
              >
                {langButtons.QUICK_ADD}
              </Button>
            </Tooltip>
          </div>
        )}
        <div key={id} ref={drop}>
          {renderList()}
        </div>
      </div>

      {deleteEntity && (
        <ChecklistItemDelete categoryId={id} entity={deleteEntity} onFinish={onFinishDelete} />
      )}

      {itemEntity && (
        <ChecklistItemEditor categoryId={id} value={itemEntity} onFinish={onFinishEditor} />
      )}

      {moveEntity && (
        <AdmissionMovePopup categoryId={id} item={moveEntity} onFinish={onFinishMove} />
      )}

      {commentEntity && (
        <AdmissionChecklistComment
          categoryId={id}
          item={commentEntity}
          onFinish={onFinishComment}
        />
      )}
    </>
  );
});

AdmissionBinderComponent.defaultProps = {
  category: {
    title: '',
    checks: [],
  },
  owners: [],
  admissionSelectChecklist: () => {},
  initialOpenState: false,
  adminMode: false,
  updateOrder: () => {},
  langPage: Lang.ADMISSION_CHECKLIST_PAGE,
  langButtons: Lang.CHECKLIST_BUTTONS,
  langTooltip: Lang.ACTION_BUTTON_TOOLTIPS,
  createQuickCheckListItem: () => {},
};

export { AdmissionBinderComponent };
