import { useFormik } from 'formik';
import React from 'react';
import { Popup } from '../../../../components/common';
import styles from './delete-users-confirm.module.scss';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { getLang } from 'shared/store/selectors/lang.selector';
import { Button } from 'src/components/design-system';
import { COLLABORATOR_PERMISSIONS_GROUP } from 'shared/constants';
import { OwnerSelector } from 'src/components/ownerSelector';

export function DeleteUserConfirm({ onConfirm, onDismiss, user }) {
  const [isLoading, setLoadingState] = React.useState(false);
  const [error, setError] = React.useState(null);

  const lang = useSelector(getLang('USERS_MANAGEMENT'));

  const onSubmit = async ({ ownerId }) => {
    setLoadingState(true);
    setError(null);

    // Call the delete user action
    let res = await onConfirm({ ownerId });

    if (res?.error) {
      setError(res.error);
    } else {
      onDismiss();
    }

    setLoadingState(false);
  };

  // Check if the user is a collaborator or account admin
  const isCollaborator = COLLABORATOR_PERMISSIONS_GROUP.includes(user.permissions);

  // Schema
  const DELETE_USER_SCHEMA = Yup.object().shape({
    ownerId: isCollaborator ? Yup.string().required('Required') : Yup.string().nullable(),
  });

  const { handleSubmit, setFieldValue, errors, isValid } = useFormik({
    validationSchema: DELETE_USER_SCHEMA,
    initialValues: {
      ownerId: null,
    },
    onSubmit,
  });

  const messageText = isCollaborator
    ? lang.DELETE_USER_POPUP_TEXT_REPLACE_OWNER
    : lang.DELETE_USER_POPUP_TEXT;

  const message = messageText.replace('{name}', `${user?.firstName} ${user?.lastName}`);

  return (
    <Popup title={lang.DELETE_USER_POPUP_TITLE} isOpen={true}>
      <form onSubmit={handleSubmit}>
        <p className={styles.content}>{message}</p>

        {isCollaborator && (
          <OwnerSelector
            currentOwnerId={user.id}
            error={errors.ownerId}
            placeholder={lang.DELETE_USER_POPUP_OWNER_PLACEHOLDER}
            name="ownerId"
            onChange={setFieldValue}
          />
        )}

        {error && <span className={styles.errorMessage}>{error}</span>}
        <div className={styles.buttons}>
          <Button
            loading={isLoading}
            className={styles.button}
            disabled={!isValid}
            type="primary"
            danger
            htmlType="submit"
            size="medium"
            block
          >
            {lang.DELETE_USER_POPUP_CONFIRM}
          </Button>
          <Button
            disabled={isLoading}
            className={styles.button}
            size="medium"
            htmlType="button"
            block
            onClick={onDismiss}
          >
            {lang.DELETE_USER_POPUP_DISMISS}
          </Button>
        </div>
      </form>
    </Popup>
  );
}
