import { useState, useEffect, useCallback } from 'react';

const PROGRESS_STATUS = {
  UPLOADING: 'Uploading',
  PDF_CONVERSION: 'Converting to PDF',
  SMART_CONVERSION: 'Converting to Smart Format',
  DONE: 'Done',
};

const officeDocumentRegex = /officedocument/;

const useUploadProgress = ({ smartFormat = false, isDirectConversion = false, file }) => {
  const [progress, setProgress] = useState(0);
  const [status, setStatus] = useState(PROGRESS_STATUS.UPLOADING);
  const progressFactor = isDirectConversion ? 3 : 1;
  const needsPdfConversion = officeDocumentRegex.test(file?.type);

  let uploadSteps = 1;
  if (needsPdfConversion) {
    uploadSteps++;
  }
  if (smartFormat) {
    uploadSteps++;
  }

  const onUploadProgress = useCallback(
    e => {
      const totalPercent = Math.floor(100 / uploadSteps);
      const percentCompleted = Math.round((e.loaded * totalPercent) / e.total);

      if (needsPdfConversion && percentCompleted >= totalPercent) {
        setStatus(PROGRESS_STATUS.PDF_CONVERSION);
      }
      if (smartFormat && percentCompleted >= totalPercent) {
        setStatus(PROGRESS_STATUS.SMART_CONVERSION);
      }

      // if uploadSteps is 1, make sure progress will be 100% only after calling completeProgress()
      if (uploadSteps === 1) {
        setProgress(percentCompleted - 1);
      } else {
        setProgress(percentCompleted);
      }
    },
    [smartFormat, needsPdfConversion, uploadSteps],
  );

  const completeProgress = useCallback(() => {
    setProgress(100);
    setStatus(PROGRESS_STATUS.DONE);
  }, []);

  useEffect(() => {
    if (isDirectConversion) {
      setStatus(PROGRESS_STATUS.SMART_CONVERSION);
    }
  }, [isDirectConversion]);

  useEffect(() => {
    if (status === PROGRESS_STATUS.UPLOADING) {
      return;
    }

    const intervalId = setInterval(() => {
      if (status === PROGRESS_STATUS.DONE) {
        clearInterval(intervalId);
        return;
      }
      const randomProgress = Math.round(Math.random() * (9 - uploadSteps) * progressFactor);
      setProgress(progress => Math.min(progress + randomProgress, 99));
    }, 1000);
    return () => clearInterval(intervalId);
  }, [status, progressFactor, uploadSteps]);

  return {
    status,
    progress,
    onUploadProgress,
    completeProgress,
  };
};

export { useUploadProgress };
