import React from 'react';
import { ScreenTitle } from '../../../components/design-system/titles';
import styles from './user-profile.module.scss';
import { UserProfile } from '../../../components/user-profile';
import { useState } from 'react';
import { useCallback } from 'react';
import { Lang } from 'shared/constants';

const UserProfilePopupComponent = ({ lang = Lang.USER_PROFILE, showPopup: show }) => {
  const [showPopup, setShowPopup] = useState(show);

  const onClose = useCallback(() => {
    setShowPopup(false);
  }, []);

  return showPopup ? (
    <div className={styles.popup}>
      <div className={styles.popupContainer}>
        <div className={styles.root}>
          <ScreenTitle title={lang.POPUP_TITLE} subtitle={lang.POPUP_SUBTITLE} />
          <UserProfile isPopup onClose={onClose} />
        </div>
      </div>
    </div>
  ) : null;
};

export { UserProfilePopupComponent };
