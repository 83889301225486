import React from 'react';
import styles from './oauth-footer.module.scss';
import { useSelector } from 'react-redux';
import { getLang } from 'shared/store/selectors/lang.selector';
import classNames from 'classnames';
import { useOauthConfig } from '../hooks';

const OAuthFooter = ({ loading }) => {
  const lang = useSelector(getLang('WELCOME_SCREEN'));
  const { providerConfig, generateAuthenticationUrl } = useOauthConfig();

  const loginWithOauth = config => {
    window.location.href = generateAuthenticationUrl(config);
  };
  return (
    <>
      <div className={styles.hr} />
      <h6>{lang.SOCIAL_LOGIN_CONTENT}</h6>
      {Object.keys(providerConfig).map(provider => {
        const config = providerConfig[provider];
        return !config ? null : (
          <button
            key={provider}
            disabled={loading}
            className={classNames([styles.providerButton, loading && styles.disabled])}
            onClick={() => loginWithOauth(config)}
          >
            <img
              src={config.logo}
              alt={provider}
              className={classNames([styles.providerLogo, loading && styles.disabled])}
            />
          </button>
        );
      })}
    </>
  );
};

export { OAuthFooter };
