import React from 'react';
import { Popup } from '../common';
import { Button, Input, Loader } from '../core';
import styles from './owner-editor.module.scss';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Lang } from 'shared/constants';

const OWNER_SCHEMA = Yup.object().shape({
  firstName: Yup.string().min(2, 'Too Short!').max(200, 'Too Long!').required('Required!'),
  lastName: Yup.string().min(2, 'Too Short!').max(200, 'Too Long!').required('Required!'),
  email: Yup.string().email().required('Required!'),
  phone: Yup.string(),
});

const OwnerEditorComponent = React.memo(function OwnerEditorComponent({
  lang,
  isOpen,
  entity,
  createOwner,
  updateOwner,
  onFinish,
  selectOwnerId,
  fieldName = 'ownerId',
}) {
  let isNew = !entity?.id;

  const [isLoading, setLoadingState] = React.useState(false);
  const [error, setError] = React.useState(null);

  const onSubmit = React.useCallback(
    async owner => {
      setLoadingState(true);
      setError(null);

      // TODO: analytics,
      let res;
      if (isNew) {
        res = await createOwner(owner);
      } else {
        res = await updateOwner({ id: entity.id, ...owner });
      }

      if (res.error) {
        setLoadingState(false);
        setError(res.error);
        return;
      }

      if (isNew) {
        selectOwnerId(fieldName, res?.owner?.id);
      }

      setLoadingState(false);
      onFinish();
    },
    [createOwner, entity?.id, isNew, onFinish, updateOwner, selectOwnerId, fieldName],
  );

  const { handleChange, handleBlur, handleSubmit, values, errors, touched, handleReset } =
    useFormik({
      validationSchema: OWNER_SCHEMA,
      initialValues: {
        ...entity,
      },
      onSubmit: data => {
        onSubmit(data);
        handleReset();
      },
    });

  const dismiss = React.useCallback(() => {
    // TODO: analytics,
    onFinish();
    handleReset();
  }, [onFinish, handleReset]);

  return (
    <Popup
      isOpen={isOpen}
      wrapperClassName={styles.popup}
      title={isNew ? lang.HEADER_CREATE : lang.HEADER_UPDATE}
    >
      <label>{lang.FIRST_NAME}</label>
      <Input
        inputStyle="editor"
        name="firstName"
        value={values.firstName}
        error={errors.firstName}
        touched={touched.firstName}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={lang.FIRST_NAME_PLACEHOLDER}
      />

      <label>{lang.LAST_NAME}</label>
      <Input
        inputStyle="editor"
        name="lastName"
        value={values.lastName}
        error={errors.lastName}
        touched={touched.lastName}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={lang.LAST_NAME_PLACEHOLDER}
      />

      <label>{lang.EMAIL}</label>
      <Input
        inputStyle="editor"
        name="email"
        value={values.email}
        error={errors.email}
        touched={touched.email}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={lang.EMAIL_PLACEHOLDER}
      />

      <label>{lang.PHONE}</label>
      <Input
        inputStyle="editor"
        name="phone"
        value={values.phone}
        error={errors.phone}
        touched={touched.phone}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={lang.PHONE_PLACEHOLDER}
      />

      {!isLoading && (
        <div className={styles.buttons}>
          <Button className={styles.button} onClick={dismiss} size="medium">
            {lang.DISMISS}
          </Button>
          <Button
            className={styles.button}
            type="submit"
            onClick={handleSubmit}
            size="medium"
            buttonStyle="primary"
          >
            {isNew ? lang.CREATE : lang.UPDATE}
          </Button>
        </div>
      )}
      {isLoading && <Loader />}
      {error && <div className={styles.error}>{error}</div>}
    </Popup>
  );
});

OwnerEditorComponent.defaultProps = {
  lang: Lang.OWNER_EDITOR,
  isOpen: false,
  createOwner: () => {},
  updateOwner: () => {},
  entity: {
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  },
  selectOwnerId: () => {},
};

export { OwnerEditorComponent };
