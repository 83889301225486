import React, { useRef, useState } from 'react';
import { LogoSymbolWhite } from '../../../../images';
import { Search } from './search';
import styles from './header.module.scss';
import { ConfigProvider, Dropdown, Switch, notification } from 'antd';
import { ROUTES } from 'shared/constants/routes';
import { Lang, DATA_KEYS } from 'shared/constants';
import { MobileQRCode } from '../../../mobile-qr-code';
import { NavLink } from 'react-router-dom';
import { GiveFeedbackReport } from 'src/components/issue-report/give-feedback-report';
import { useHistory, useLocation } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/pro-solid-svg-icons/faAngleDown';
import { AskPandaButton } from 'src/components/ai';
import { SwitchOrganizationDropdown } from 'src/pages/application/switchOrganization/switch-organization-dropdown';
import { sortByAlphabet } from 'shared/utils';
import classnames from 'classnames';

const { HOME_PAGE } = DATA_KEYS;

const HeaderComponent = React.memo(function HeaderComponent({
  lang,
  logout,
  adminMode,
  setAdminMode,
  isCollaborator,
  isAccountAdmin,
  isRoot,
  isSwitchOrganizationAvailable,
  workspaces,
  selectedWorkspaceId,
  selectWorkspace,
  isAIEnabled,
  isCanEditProfile,
}) {
  const [showIssueReportPopup, setShowIssueReportPopup] = useState(false);
  const [notificationApi, contextHolder] = notification.useNotification();
  const history = useHistory();
  const location = useLocation();

  const openIssueReportPopup = () => {
    setShowIssueReportPopup(true);
  };

  const closeIssueReportPopup = () => {
    setShowIssueReportPopup(false);
  };

  const mobileQRCodeRef = useRef(null);

  const handleHoverOut = () => {
    // Close the dialog when not hovering on the dropdown menu
    mobileQRCodeRef.current?.closeDialog();
  };

  const renderWorkspaceSelector = () => {
    if (workspaces.length <= 1) return;
    const currentWorkspace = workspaces.find(ws => ws.id === selectedWorkspaceId);
    const maxCharacters = 14;
    return (
      <div className={classnames(['new-design-system', styles.workspaceWrapper])}>
        <h5>{lang.WORKSPACE}</h5>
        <ConfigProvider
          theme={{
            components: {
              Dropdown: {
                colorText: '#2A48B0',
                fontSize: 14,
                fontWeight: 600,
                lineHeight: 2,
                controlHeight: 40,
              },
            },
          }}
        >
          <Dropdown
            menu={{
              items: workspaces
                .sort((a, b) => sortByAlphabet(a.name, b.name))
                .map(workspace => ({
                  key: workspace?.id,
                  label: <p className={styles.workspaceItem}>{workspace?.name}</p>,
                })),
              onClick: ({ key }) => {
                selectWorkspace(key);

                if (location.pathname === ROUTES.KNOWLEDGE_HUB) {
                  return;
                }

                history.replace({
                  search: '',
                  pathname: `/${location.pathname.split('/')[1]}`,
                });
              },
            }}
            trigger={['click']}
          >
            <div className={styles.workspaceDropdown}>
              {currentWorkspace?.name.length > maxCharacters
                ? `${currentWorkspace?.name.slice(0, maxCharacters)}...`
                : currentWorkspace?.name}
              <FontAwesomeIcon icon={faAngleDown} className={styles.icon} />
            </div>
          </Dropdown>
        </ConfigProvider>
      </div>
    );
  };

  return (
    <div className={styles.root}>
      <SwitchOrganizationDropdown successMessage={lang.SUCCESSFUL_SWITCH_ORG} />
      <Search />
      {showIssueReportPopup && (
        <GiveFeedbackReport close={closeIssueReportPopup} notificationApi={notificationApi} />
      )}
      {contextHolder}
      <div className={styles.alignToRight}>
        <div className={styles.askPandaWorkspacesWrapper}>
          {renderWorkspaceSelector()}
          {isAIEnabled && <AskPandaButton />}
        </div>

        <div className={styles.actions}>
          <div className={styles.actionMenu}>
            <img src={LogoSymbolWhite} alt="menu" />

            <div
              className={styles.dropdown}
              data-key={HOME_PAGE.HEADER_ACTIONS}
              onMouseLeave={handleHoverOut}
            >
              {isCanEditProfile && (
                <NavLink className={styles.link} to={ROUTES.USER_PROFILE}>
                  {lang.PROFILE}
                </NavLink>
              )}

              {isRoot && (
                <NavLink className={styles.link} to={ROUTES.SETTINGS}>
                  {lang.SETTINGS}
                </NavLink>
              )}

              {isAccountAdmin && (
                <>
                  <NavLink className={styles.link} to={ROUTES.USERS_MANAGEMENT}>
                    {lang.USERS_MANAGEMENT}
                  </NavLink>
                </>
              )}

              {isCollaborator && (
                <div className={styles.adminMode}>
                  {lang.ADMIN_MODE_TOGGLER}
                  <Switch
                    data-key={HOME_PAGE.ADMIN_MODE}
                    size="small"
                    checked={adminMode}
                    onChange={setAdminMode}
                    className={styles.toggleAdmin}
                  />
                </div>
              )}

              <button className={styles.buttonLink} onClick={openIssueReportPopup}>
                {lang.GIVE_FEEDBACK}
              </button>

              {isCollaborator && (
                <a
                  href="https://help.c8health.com/"
                  target="_blank"
                  rel="noreferrer"
                  className={styles.link}
                >
                  {lang.TUTORIALS}
                </a>
              )}

              <button className={styles.buttonLink} onClick={logout} data-key={HOME_PAGE.LOGOUT}>
                {lang.LOGOUT}
              </button>

              <div className={styles.mobileQRCode}>
                <MobileQRCode ref={mobileQRCodeRef} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

HeaderComponent.defaultProps = {
  organization: {},
  logout: () => {},
  user: {},
  adminMode: false,
  isCollaborator: false,
  isAccountAdmin: false,
  isSwitchOrganizationAvailable: false,
  setAdminMode: () => {},
  lang: Lang.HEADER,
  feedbackForm: 'mailto:contact@c8health.com',
};

export { HeaderComponent };
