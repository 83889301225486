import { useEffect } from 'react';
import { useUploadLogic } from '../hooks/upload.hooks';
import { Button } from 'src/components/design-system';
import { SimpleProgressBar } from '../progress-bar/simple-progress-bar.component';
import styles from './upload-file-binder.module.scss';
import { DeleteFilled } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { createKnowledgeItem } from 'shared/store/actions/knowledge.items.actions';
import { getLang } from 'shared/store/selectors/lang.selector';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { getUser } from 'shared/store/selectors/user.selector';
import { isCurrentUserValidOwner } from 'shared/store/selectors/owners.selector';

const FileComponent = function FileComponent({
  file: fileToUpload,
  onRemove,
  close,
  startUpload,
  handleErrors,
  handleUploadCompleted,
  props,
}) {
  const lang = useSelector(getLang('KNOWLEDGE_UPLOAD_BINDER'));
  const dispatch = useDispatch();
  const isUserValidOwner = useSelector(isCurrentUserValidOwner);
  const currentUser = useSelector(getUser);
  const ownerIds = isUserValidOwner ? [currentUser.id] : props.ownerIds;

  const {
    file,
    progress,
    cancelled,
    uploadFile,
    uploadFileHandler,
    cancelUpload,
    completeProgress,
  } = useUploadLogic({
    close,
    props,
  });

  useEffect(() => {
    uploadFileHandler('', fileToUpload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileToUpload]);

  useEffect(() => {
    (async () => {
      if (startUpload) {
        await handleUploadAndItemCreation();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startUpload]);

  async function handleUploadAndItemCreation() {
    const { asset, error, cancelled } = await uploadFile({
      skipOpenEditor: true,
    });

    if (!asset || error || cancelled) {
      handleErrors({ file, error });
      return;
    }

    const data = {
      ...props,
      link: asset?.url,
      type: asset?.type,
      fileName: file?.name,
      title: file?.name.slice(0, file?.name.lastIndexOf('.')),
      expiresAt: new Date(Date.now() + 3.154e10).toISOString(),
      ownerIds,
    };

    try {
      await dispatch(createKnowledgeItem(data));
    } catch (e) {
      handleErrors({ file, error: 'file-creation-error' });
      return;
    }

    handleUploadCompleted(file);
    completeProgress();
  }

  const renderButtons = () => {
    if (!startUpload) {
      return (
        <Button type="danger" size="small" icon={<DeleteFilled />} onClick={() => onRemove(file)}>
          {lang.REMOVE}
        </Button>
      );
    }

    if (startUpload && !cancelled && progress !== 100) {
      return (
        <Button type="default" size="small" onClick={cancelUpload}>
          {lang.CANCEL_UPLOAD}
        </Button>
      );
    }
    if (progress === 100 && !cancelled) {
      return (
        <div className={classnames([styles.tag, styles.complete])}>
          <FontAwesomeIcon icon={faCheck} className={styles.icon} />
          {lang.UPLOAD_COMPLETE}
        </div>
      );
    }
  };

  return (
    <>
      <div className={styles.fileRow}>
        <div className={styles.fileNameWrapper}>
          <p>{file?.name}</p>
          {renderButtons()}
        </div>
        {startUpload && !cancelled && <SimpleProgressBar progress={progress} />}
        {cancelled && <p className={styles.error}>{lang.CANCELLATION_TITLE}</p>}
      </div>
    </>
  );
};

export { FileComponent };
