import { connect } from 'react-redux';
import { getLabelsList } from 'shared/store/selectors/labels.selector';
import { LabelsSelectorComponent } from './labels-selector.component';

const mapStateToProps = ({ labels }) => ({
  list: getLabelsList({ labels }),
  labelsByIds: labels.list,
});

export const LabelsSelector = connect(mapStateToProps, {})(LabelsSelectorComponent);
