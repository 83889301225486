import {
  ACCOUNT_ADMIN_PERMISSIONS_GROUP,
  COLLABORATOR_PERMISSIONS_GROUP,
  DEFAULT_USER_PERMISSION,
  VIEWER_PERMISSIONS_GROUP,
} from '../../constants';

export const getPermission = ({ auth }) => {
  let { tokenDetails } = auth;

  return tokenDetails.permissions || DEFAULT_USER_PERMISSION;
};

export const isCollaborator = ({ auth }) => {
  let { tokenDetails } = auth;

  return COLLABORATOR_PERMISSIONS_GROUP.includes(
    tokenDetails.permissions || DEFAULT_USER_PERMISSION,
  );
};
//TODO: refactor
export const isAccountAdmin = ({ auth }) => {
  let { tokenDetails } = auth;

  return ACCOUNT_ADMIN_PERMISSIONS_GROUP.includes(
    tokenDetails.permissions || DEFAULT_USER_PERMISSION,
  );
};

export const isHavePermission = ({ auth, permissions = VIEWER_PERMISSIONS_GROUP }) => {
  let { tokenDetails } = auth;

  return permissions.includes(tokenDetails.permissions || DEFAULT_USER_PERMISSION);
};
