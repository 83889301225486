import API from './API';
import { DELETE, GET, POST } from '../utils/Executer';
import { KNOWLEDGE_SERVICE_URL as servicePath } from '../constants/APIconstants';

const commentsServicePath = `${servicePath}items/:id/comments/`;

/**
 * @typedef Comment
 * @property {string} id
 * @property {string} text
 * @property {{
 *  id: string,
 *  displayName: string,
 * }} user
 * @property {Comment[]} comments
 * @property {Date | string} createdAt
 * @property {Date | string} updatedAt
 */

/**
 * @typedef CommentsResponse
 * @property {boolean} success
 * @property {{
 * comments: {
 *  knowledgeId: string;
 *  comments: Comment[];
 *  id: string;
 * }
 * }} data
 */

class KnowledgeCommentsServiceAPI {
  /**
   * Get comments for a knowledge
   * @param {{knowledgeId: string}} { knowledgeId }
   * @returns {Promise<CommentsResponse>}
   */
  async getComments({ knowledgeId }) {
    return await API.client.execute(GET, commentsServicePath.replace(':id', knowledgeId));
  }

  /**
   * Create a comment for a knowledge
   * @param {{knowledgeId: string, comment: {text: string}}} param0
   * @returns {Promise<CommentsResponse>}
   */
  async createComment({ knowledgeId, comment }) {
    return await API.client.execute(POST, commentsServicePath.replace(':id', knowledgeId), comment);
  }

  /**
   * Create a comment for a knowledge
   * @param {{knowledgeId: string, commentId: string, comment: {text: string}}} param0
   * @returns {Promise<CommentsResponse>}
   */
  async addSubComment({ knowledgeId, commentId, comment }) {
    return await API.client.execute(
      POST,
      `${commentsServicePath.replace(':id', knowledgeId)}sub/${commentId}`,
      comment,
    );
  }

  /**
   * Delete a sub comment
   * @param {{knowledgeId: string, commentId: string}} param0
   */
  async deleteSubComment({ knowledgeId, commentId }) {
    return await API.client.execute(
      DELETE,
      `${commentsServicePath.replace(':id', knowledgeId)}sub/${commentId}`,
    );
  }
  /**
   * Delete root comment
   * @param {{knowledgeId: string, commentId: string}} param0
   */
  async deleteComment({ knowledgeId, commentId }) {
    return await API.client.execute(
      DELETE,
      `${commentsServicePath.replace(':id', knowledgeId)}${commentId}`,
    );
  }
}

export default new KnowledgeCommentsServiceAPI();
