import { connect } from 'react-redux';
// import { createKnowledgeItem, uploadDocument, updateKnowledgeItem } from 'shared/store/actions/knowledge.items.actions';
import { getOwnersListForEditor } from 'shared/store/selectors/owners.selector';
import { OwnerSelectorComponent } from './owner-selector.component';
import { isAccountAdmin } from 'shared/store/selectors/auth.selector';

const mapStateToProps = ({ auth, owners, lang }) => ({
  ownersList: getOwnersListForEditor(owners),
  lang: lang.OWNER_SELECTOR,
  canAddOwner: isAccountAdmin({ auth }),
});

export const OwnerSelector = connect(mapStateToProps, {})(OwnerSelectorComponent);
