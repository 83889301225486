import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { ROUTES, CATEGORY_TYPES } from 'shared/constants';
import { ChecklistIcon, DeleteIcon, Pencil } from 'src/images';
import styles from './admission-category-item.module.scss';
import classnames from 'classnames';
import { Input } from 'antd';
import { Lang } from 'shared/constants/lang';
import { Tooltip } from 'src/components/core';
import { SingleTagIcon } from 'src/components/tags/tagsIcons';
import { scrollTo } from 'src/utils';
import { getParentIdByPath } from 'shared/utils/Utils';
const { TextArea } = Input;

const AdmissionCategoryItemComponent = React.memo(function AdmissionCategoryItem({
  item,
  admissionSelectChecklist,
  adminMode,
  deleteCategory,
  updateCategory,
  editCategory,
  onClickEvent = () => {},
  lang,
  light,
}) {
  const { id, title, type, ownerIds, path, tags, keywords } = item;
  const isChecklistsBox = type === CATEGORY_TYPES.CHECKS;
  const [titleInput, setTitleInput] = useState(title);
  const history = useHistory();

  useEffect(() => {
    if (titleInput !== title) {
      setTitleInput(title);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title, setTitleInput]);

  const onClick = React.useCallback(() => {
    const event = {
      id,
      title,
      ownerIds,
      ...tags,
    };

    // Trigger event
    onClickEvent(event);

    if (isChecklistsBox) {
      const parent = getParentIdByPath(path);
      setTimeout(() => scrollTo(parent), 100);
      admissionSelectChecklist(id);
    } else {
      history.push(ROUTES.ADMISSION_CATEGORIES.replace(':id', id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [admissionSelectChecklist, history, id, isChecklistsBox, path]);

  const editCategoryAction = React.useCallback(() => {
    editCategory({ id, title, ownerIds, path, tags, keywords, type });
  }, [editCategory, id, title, ownerIds, path, tags, keywords, type]);

  const deleteCategoryAction = React.useCallback(() => {
    deleteCategory({ id, title });
  }, [deleteCategory, id, title]);

  const onInputFocus = React.useCallback(e => {
    e.stopPropagation();
  }, []);

  const onPress = React.useCallback(e => {
    if (e.key === 'Enter') {
      e.preventDefault();

      e.target.blur();
    }
  }, []);

  const onBlur = React.useCallback(
    e => {
      let { value } = e.target;
      if (value === title) return false;
      updateCategory({ id, title: value, tags, keywords, type, ownerIds, path });
    },
    [id, title, updateCategory, tags, keywords, type, ownerIds, path],
  );

  const onChange = React.useCallback(
    e => {
      setTitleInput(e.target.value);
    },
    [setTitleInput],
  );

  const renderThumbnail = () => (
    <div className={styles.thumbnailWrapper}>
      <img src={ChecklistIcon} alt={titleInput} />
    </div>
  );

  const renderActions = () => {
    if (!adminMode) return null;

    return (
      <div className={styles.buttons}>
        <Tooltip title={lang.DELETE}>
          <button type="button" onClick={deleteCategoryAction} className={styles.buttonWrapper}>
            <DeleteIcon className={styles.deleteIcon} />
          </button>
        </Tooltip>
        <Tooltip title={lang.EDIT}>
          <button type="button" onClick={editCategoryAction} className={styles.buttonWrapper}>
            <Pencil className={styles.editIcon} />
          </button>
        </Tooltip>
      </div>
    );
  };

  return (
    <div className={classnames(styles.root, light ? styles.light : {})}>
      <div className={styles.content} onClick={onClick}>
        {renderThumbnail()}
        <TextArea
          onClick={onInputFocus}
          onKeyPress={onPress}
          onBlur={onBlur}
          className={styles.title}
          autoSize={{ minRows: 1, maxRows: 2 }}
          disabled={!adminMode}
          placeholder={lang.ITEM_TITLE_PLACEHOLDER}
          value={titleInput}
          onChange={onChange}
        />
      </div>

      {!adminMode && (
        <div className={styles.tag}>
          <SingleTagIcon tag={tags?.specialty} />
        </div>
      )}
      {renderActions()}
    </div>
  );
});

AdmissionCategoryItemComponent.defaultProps = {
  light: false,
  item: {},
  admissionSelectChecklist: () => {},
  adminMode: false,
  deleteCategory: () => {},
  updateCategory: () => {},
  editCategory: () => {},
  lang: Lang.ACTION_BUTTON_TOOLTIPS,
};

export { AdmissionCategoryItemComponent };
