import { connect } from 'react-redux';
import { getFavoritesItems } from 'shared/store/selectors/favorites.selector';
import { FavoritesPageComponent } from './Favorites.page.component';

const mapStateToProps = ({ lang, favorites, knowledge, contacts, usersManagement }) => ({
  lang: lang.FAVORITES_SCREEN,
  list: getFavoritesItems({ favorites, knowledge, contacts, usersManagement }),
  lastUpdated: favorites.lastUpdated,
});

export const FavoritesPage = connect(mapStateToProps)(FavoritesPageComponent);
