import { connect } from 'react-redux';
import { IssueReportPopupComponent } from './issue-report-popup.component';
import { giveFeedback } from 'shared/store/actions/communication-center.actions';
import { ISSUE_TYPES, USER_FEEDBACK } from 'shared/constants';

const mapStateToProps = ({ lang }) => ({
  lang: lang.REPORT_PROBLEM,
  serviceMethod: giveFeedback,
  serviceMethodProps: { type: ISSUE_TYPES.GIVE_FEEDBACK },
  defaultOptions: Object.values(USER_FEEDBACK),
});

export const GiveFeedbackReport = connect(mapStateToProps)(IssueReportPopupComponent);
