import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { parseYoutubeUrl, isVideoUrl, isGoogleUrl } from 'shared/utils';
import { ExternalLinkPreviewIcon, OpenNewWindow } from '../../../../images/ui';
import { Button, Loader } from '../../../core';
import { getLang } from 'shared/store/selectors/lang.selector';
import { EVENTS } from 'shared/constants';
import styles from './external-link-viewer.module.scss';
import classNames from 'classnames';
import knowledgeServiceApi from 'shared/api/knowledge.service.api';
import analyticsService from 'src/helpers/analytics.service';

/**
 * @type {React.FC<any>}
 */
const ExternalLinkViewer = React.memo(({ id = '', title, link: initialLink }) => {
  const [linkIsLoading, setLinkIsLoading] = React.useState();
  const [iframeIsLoading, setIframeIsLoading] = React.useState(true);
  const lang = useSelector(getLang('EXTERNAL_LINK_VIEWER'));

  const [link, setLink] = useState();

  useEffect(() => {
    (async () => {
      if (!initialLink) {
        setLinkIsLoading(true);
        const res = await knowledgeServiceApi.getItemContentAsPDF({ id });
        setLink(res.data.url);
        setLinkIsLoading(false);
      } else {
        setLink(initialLink);
      }
    })();
  }, [id, link, initialLink]);

  const openInNewTab = React.useCallback(() => {
    analyticsService.track(EVENTS.KNOWLEDGE_ITEMS.OPEN_IN_NEW_TAB, {
      id,
      link,
      title,
    });
    window.open(link);
  }, [id, title, link]);

  const allowIframeDomain =
    useSelector(({ generalConfig }) => generalConfig.ALLOW_IFRAME_DOMAINS) || [];
  const allowIframeRegex = new RegExp(allowIframeDomain.join('|'));

  if (linkIsLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Loader />
      </div>
    );
  }

  if (!link) {
    return;
  }

  if (isVideoUrl(link)) {
    const parsedLink = parseYoutubeUrl(link);

    return (
      <>
        <iframe
          className={styles.iframe}
          src={parsedLink}
          title={title}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        />
        <div className={styles.openExternal} onClick={openInNewTab}>
          {lang.OPEN_MEDIA}
          <img src={OpenNewWindow} alt={title} className={styles.openExternalIcon} />
        </div>
      </>
    );
  }

  if (allowIframeRegex.test(link) || isGoogleUrl(link)) {
    const parsedLink = allowIframeRegex.test(link) ? `${link}#toolbar=0` : link;

    return (
      <>
        <iframe
          className={classNames(styles.iframe, iframeIsLoading ? styles.hideIframe : null)}
          title={title}
          src={parsedLink}
          onLoad={e => setIframeIsLoading(false)}
        />
        {iframeIsLoading && (
          <div className={styles.loaderWrapper}>
            <Loader />
          </div>
        )}
        <div className={styles.openExternal} onClick={openInNewTab}>
          {lang.OPEN_KNOWLEDGE}
          <img src={OpenNewWindow} alt={title} className={styles.openExternalIcon} />
        </div>
      </>
    );
  }

  return (
    <div className={styles.notAvilableLink}>
      <img src={ExternalLinkPreviewIcon} alt={''} className={styles.externalIcon} />
      <span className={styles.externalHeader}>{lang.LINK_NOT_AVAILABLE_HEADER}</span>
      <span className={styles.externalSubheader}>{lang.LINK_NOT_AVAILABLE_SUBHEADER}</span>
      <div>
        <Button size="small" buttonStyle="primary" onClick={openInNewTab} className={styles.button}>
          {link}
        </Button>
      </div>
    </div>
  );
});

export { ExternalLinkViewer };
