import React, { useCallback, useState, useEffect } from 'react';
import styles from './tag-filter.module.scss';
import { TAGS_KEYS } from 'shared/constants';
import { getLang } from 'shared/store/selectors/lang.selector';
import { useSelector } from 'react-redux';
import { Checkbox } from 'antd';
import { SingleTagIcon } from '../../tags/tagsIcons/SingleTagIcon.component';
import { Lang } from 'shared/constants/lang';
import _ from 'lodash';
import { FilterIcon } from '../../../images';
import { Label } from '../../core/label';

const MAX_PER_SEARCH = 3;
const FILTERS_INITIAL_STATE = {
  [TAGS_KEYS.SPECIALTY]: new Set(),
  [TAGS_KEYS.SUBSPECIALTY]: new Set(),
  [TAGS_KEYS.CONTENT_TYPE]: new Set(),
  [TAGS_KEYS.TARGET_AUDIENCE]: new Set(),
  LABELS: new Set(),
};

const RenderTags = ({
  title = '',
  tagKey,
  tags = {},
  hideTag = false,
  renderLabel,
  addFilter,
  filters,
}) => {
  const LANG_TAGS = useSelector(getLang('TAGS')) ?? Lang.TAGS;
  const LANG_FILTERS = useSelector(getLang('FILTERS')) ?? Lang.FILTERS;
  const keyFilters = Object.keys(tags);
  const [showMore, setShowMore] = useState(false);

  if (keyFilters.length === 0) return null;

  return (
    <div className={styles.tagContainer} key={tagKey}>
      <h4>{title}</h4>
      {keyFilters
        .sort((a, b) => tags[b] - tags[a])
        .filter((v, i) => (!showMore ? i < MAX_PER_SEARCH : true))
        .map(key => {
          return (
            <div className={styles.item} key={`${tagKey}-${key}`}>
              <div className={styles.checkbox}>
                <Checkbox
                  tagKey={tagKey}
                  tag={key}
                  onChange={addFilter}
                  defaultChecked={filters[tagKey].has(key)}
                />
              </div>
              {!hideTag && <SingleTagIcon tag={key} darkBackground />}
              <span className={styles.tagKey}>
                {renderLabel ? renderLabel(key) : LANG_TAGS[key] || key}
              </span>
              ({tags[key]})
            </div>
          );
        })}
      {keyFilters.length > MAX_PER_SEARCH && (
        <h5 className={styles.showButton} onClick={() => setShowMore(prev => !prev)}>
          {!showMore ? `${LANG_FILTERS.SHOW_MORE}` : `${LANG_FILTERS.SHOW_LESS}`}
        </h5>
      )}
    </div>
  );
};

const useTagFilters = ({ results, term }) => {
  const LANG = useSelector(getLang('TAGS_FORM')) ?? Lang.TAGS_FORM;
  const LANG_FILTERS = useSelector(getLang('FILTERS')) ?? Lang.FILTERS;
  const [filters, setFilters] = useState(_.cloneDeep(FILTERS_INITIAL_STATE));

  useEffect(() => {
    setFilters(_.cloneDeep(FILTERS_INITIAL_STATE));
  }, [term]);

  const clearFilters = useCallback(() => {
    setFilters(_.cloneDeep(FILTERS_INITIAL_STATE));
  }, [setFilters]);

  const labelsItems = useSelector(state => state.labels.list);

  const addFilter = useCallback(
    e => {
      const { tag, tagKey, checked } = e.target;

      if (checked) {
        setFilters(prevFilters => {
          return {
            ...prevFilters,
            [tagKey]: new Set([tag]),
          };
        });
      } else {
        setFilters(prevFilters => {
          prevFilters[tagKey].delete(tag);
          return { ...prevFilters, [tagKey]: new Set(prevFilters[tagKey]) };
        });
      }
    },
    [setFilters],
  );

  const FilterComponent = () => {
    // ignore EVERYONE tag in filters
    if (results?.tagsMap?.targetAudience?.EVERYONE) {
      delete results.tagsMap.targetAudience.EVERYONE;
    }

    return (
      <div>
        <div className={styles.header}>
          <div className={styles.container}>
            <img className={styles.icon} src={FilterIcon} alt={LANG_FILTERS.TITLE} />
            <h2 className={styles.title}>{LANG_FILTERS.TITLE}</h2>
          </div>
          <h5 onClick={clearFilters}>{LANG_FILTERS.CLEAR}</h5>
        </div>

        <RenderTags
          key="LABELS"
          addFilter={addFilter}
          filters={filters}
          title={LANG.LABELS}
          tagKey="LABELS"
          tags={results?.tagsMap?.labels}
          hideTag={true}
          renderLabel={key => (
            <>
              <Label key={key} color={labelsItems[key]?.color} value={key} />
              {labelsItems[key]?.name}
            </>
          )}
        />

        <RenderTags
          key={TAGS_KEYS.SPECIALTY}
          addFilter={addFilter}
          filters={filters}
          title={LANG.SPECIALTY_PLACEHOLDER}
          tagKey={TAGS_KEYS.SPECIALTY}
          tags={results?.tagsMap?.specialty}
        />

        <RenderTags
          key={TAGS_KEYS.SUBSPECIALTY}
          addFilter={addFilter}
          filters={filters}
          title={LANG.SUBSPECIALTY_PLACEHOLDER}
          tagKey={TAGS_KEYS.SUBSPECIALTY}
          tags={results?.tagsMap?.subspecialty}
        />

        <RenderTags
          key={TAGS_KEYS.CONTENT_TYPE}
          addFilter={addFilter}
          filters={filters}
          title={LANG.CONTENT_TYPE_PLACEHOLDER}
          tagKey={TAGS_KEYS.CONTENT_TYPE}
          tags={results?.tagsMap?.contentType}
        />

        <RenderTags
          key={TAGS_KEYS.TARGET_AUDIENCE}
          addFilter={addFilter}
          filters={filters}
          title={LANG.TARGET_AUDIENCE_PLACEHOLDER}
          tagKey={TAGS_KEYS.TARGET_AUDIENCE}
          tags={results?.tagsMap?.targetAudience}
          hideTag
        />
      </div>
    );
  };

  return {
    FilterComponent,
    filters,
  };
};

export { useTagFilters };
