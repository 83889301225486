import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import styles from './suggest-content.module.scss';
import { notification } from 'antd';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import analyticsService from 'src/helpers/analytics.service';
import { EVENTS } from 'shared/constants';
import { FileUpload } from 'src/components/core';
import { Button, ScreenTitle } from 'src/components/design-system';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { SelectBox, TextInput, TextAreaInput } from 'src/components/design-system/forms';

const SUGGESTION_SCHEMA = Yup.object().shape({
  title: Yup.string().max(200, 'Too Long!').required('Required!'),
  link: Yup.string().url().required('Required!'),
  comment: Yup.string().max(2000, 'Too Long!'),
  fileName: Yup.string(),
});

const SuggestContentComponent = ({ lang, suggestContent, uploadFile }) => {
  const OPTION_MAPPER = {
    URL: {
      id: lang.FORM_URL,
      value: lang.FORM_URL,
    },
    IMAGE_OR_FILE: {
      id: lang.FORM_IMAGE_OR_FILE,
      value: lang.FORM_IMAGE_OR_FILE,
    },
  };

  const history = useHistory();
  const [api, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(OPTION_MAPPER.IMAGE_OR_FILE.value);
  const close = useCallback(() => history.goBack(), [history]);

  const openNotification = useCallback(
    error => {
      if (error) {
        api.error({
          message: lang.NOTIFICATION_ERROR,
          placement: 'bottomRight',
          onClose: close,
          onClick: close,
        });
        return;
      }
      api.success({
        message: lang.NOTIFICATION_SUCCESS,
        placement: 'bottomRight',
        onClose: close,
        onClick: close,
      });
    },

    [api, lang, close],
  );

  const submitSuggestion = useCallback(
    async data => {
      setLoading(true);

      analyticsService.track(EVENTS.SUGGEST_CONTENT.SUBMIT, {
        title: data?.title,
        link: data?.link,
        comment: data?.comment,
      });
      const res = await suggestContent({ data });

      setLoading(false);
      openNotification(res?.error);
    },
    [suggestContent, openNotification],
  );

  const { handleSubmit, handleChange, setFieldValue, values, errors, isValid } = useFormik({
    validationSchema: SUGGESTION_SCHEMA,
    initialValues: {
      title: '',
      link: '',
      comment: '',
      fileName: '',
    },
    onSubmit: submitSuggestion,
  });

  useEffect(() => {
    setFieldValue('link', '');
    setFieldValue('fileName', '');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption]);

  const doUpload = useCallback(
    async (fieldName, file) => {
      if (!file) {
        setFieldValue(fieldName, '');
        setFieldValue('link', '');
        return;
      }
      setLoading(true);
      const res = await uploadFile({ file });
      setLoading(false);

      if (res?.error) {
        openNotification(res?.error);
        return;
      }

      setFieldValue(fieldName, file.name);
      setFieldValue('link', res?.url);
    },
    [openNotification, setFieldValue, uploadFile],
  );

  const renderInput = () => {
    switch (selectedOption) {
      case OPTION_MAPPER.URL.value:
        return (
          <TextInput
            name="link"
            value={values.link}
            error={errors.link}
            onChange={handleChange}
            allowClear
            label={lang.URL}
          />
        );
      case OPTION_MAPPER.IMAGE_OR_FILE.value:
        return (
          <div className={styles.inputContainer}>
            <div className={styles.labelContainer}>
              <label className={styles.inputLabel}>{lang.UPLOAD_FILE}</label>
              {loading && <FontAwesomeIcon icon={faSpinner} spin className={styles.icon} />}
            </div>
            <FileUpload
              name="fileName"
              value={values.fileName}
              error={errors.fileName}
              onChange={doUpload}
              allowClear
              maxFiles={1}
              deleteButtonType="button"
            />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className={styles.root}>
      {contextHolder}
      <ScreenTitle title={lang.TITLE} />
      <form onSubmit={handleSubmit} className={styles.form}>
        <SelectBox
          className={styles.select}
          value={selectedOption}
          onChange={(field, value) => setSelectedOption(value)}
          options={Object.values(OPTION_MAPPER)}
          label={lang.SELECT_FORMAT}
        />
        {renderInput()}

        <TextInput
          label={lang.FORM_TITLE}
          name="title"
          value={values.title}
          error={errors.title}
          onChange={handleChange}
          allowClear
        />

        <TextAreaInput
          name="comment"
          value={values.comment}
          error={errors.comment}
          onChange={handleChange}
          label={lang.FORM_COMMENTS}
          rows={11}
          placeholder={lang.FORM_COMMENTS_PLACEHOLDER}
          className={styles.textArea}
        />

        <Button disabled={!isValid || loading} htmlType="submit" className={styles.submitButton}>
          {loading ? (
            <FontAwesomeIcon icon={faSpinner} spin className={styles.icon} />
          ) : (
            lang.FORM_SUBMIT
          )}
        </Button>
      </form>
    </div>
  );
};

export { SuggestContentComponent };
