import React from 'react';
import { Tooltip as AntdTooltip } from 'antd';
import { theme } from 'shared/constants/theme';

/**
 * @typedef {import('antd').TooltipProps} TooltipProps
 */

const Tooltip = React.memo(
  /**
   * @param {TooltipProps} param0
   */
  function TooltipComponent({ title, placement, color, children, ...props }) {
    return (
      <AntdTooltip
        title={title}
        placement={placement}
        arrow={{ pointAtCenter: true }}
        mouseEnterDelay={0.8}
        color={color}
        key={color}
        {...props}
      >
        {children}
      </AntdTooltip>
    );
  },
);

Tooltip.defaultProps = {
  placement: 'topLeft',
  color: theme.tooltipColor,
};

export { Tooltip };
