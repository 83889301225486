import { Col, Row } from 'antd';
import styles from './knowledge-view-layout.module.scss';
import { ApproveBanner } from '../components/approve-banner/';
import { ResizableLayout } from './resizable-layout';

export function KnowledgeViewLayout({
  hideLeftPanel,
  renderLeftPanel = () => {},
  renderHeader = () => {},
  renderRightPanel = () => {},
  renderFooter = () => {},
  children,
}) {
  const renderLeftPanelInner = () => {
    if (hideLeftPanel) {
      return null;
    }

    return <div className={styles.leftPanel}>{renderLeftPanel()}</div>;
  };

  const renderRightPanelInner = () => {
    return (
      <div className={styles.fullWidth} id="full-width">
        <Row>
          <Col span={18} md={15} lg={18}>
            {renderHeader()}

            <ApproveBanner />

            {children}

            <Row align="bottom">
              <Col span={24} className={styles.footer}>
                {renderFooter()}
              </Col>
            </Row>
          </Col>

          <Col span={5} md={8} lg={5} offset={1} className={styles.rightContainer}>
            {renderRightPanel()}
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <div className={`${styles.root} new-design-system`}>
      <ResizableLayout
        hideLeftPanel={hideLeftPanel}
        leftPanel={renderLeftPanelInner()}
        rightPanel={renderRightPanelInner()}
      />
    </div>
  );
}
