import React from 'react';
import { CircleXMark } from '../../../images/ui';
import { Tag as TagComp } from 'antd';
import styles from './tag.module.scss';
import classNames from 'classnames';

const renderCloseIcon = props => (
  <img src={CircleXMark} className={styles.closeIcon} {...props} alt="x" />
);

export const Tag = ({ value, label, closable, onClose, className = null, ...props }) => {
  return (
    <TagComp
      closable={closable}
      onClose={onClose}
      className={classNames(styles.tag, className)}
      closeIcon={renderCloseIcon()}
      {...props}
    >
      {label || value}
    </TagComp>
  );
};
