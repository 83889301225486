import React, { useEffect, useState } from 'react';
import rotationsServiceApi from 'shared/api/rotations.service.api';
import { DateTime, Duration } from 'luxon';
import { useSelector } from 'react-redux';
import { getLang } from 'shared/store/selectors/lang.selector';
import { Typography, theme } from 'antd';
import { RotationsWidgetCollapsible } from './rotations-widget-collapsible.component';
import { isRotationsEnabled as isRotationsEnabledSelector } from 'shared/store/selectors/features.selector';
import { colorPalette } from 'shared/constants';
import _ from 'lodash';

/**
 * @typedef {{
 * loading: boolean,
 * data?: import('shared/api/rotations.service.api').RotationTimeslot[],
 * error?: boolean
 * }} RotationsState
 */
export function RotationsWidget() {
  const { token } = theme.useToken();
  const lang = useSelector(getLang('DASHBOARD'));
  const isRotationsEnabled = useSelector(isRotationsEnabledSelector);

  /**
   * @type {[RotationsState, (state: RotationsState) => void]}
   */
  const [rotationsState, setRotationsState] = useState({ loading: false });

  const user = useSelector(state => {
    return state.auth.user;
  });

  useEffect(() => {
    if (!isRotationsEnabled) {
      return;
    }

    if (rotationsState.loading || rotationsState.data || rotationsState.error) {
      return;
    }

    (async () => {
      setRotationsState({ loading: true });

      const res = await rotationsServiceApi.getTimeslots({
        userIds: [user.id],
        start: DateTime.now().toJSDate(),
        end: DateTime.now()
          .plus(Duration.fromObject({ month: 12 }))
          .toJSDate(),
      });

      // If no rotations are found, try to get the rotation based on user's profile
      if (!res.success || !res.data || res.data.length === 0) {
        const profileRotation = user.professional?.rotation;

        if (!profileRotation) {
          return null;
        }

        const rotationRes = await rotationsServiceApi.getRotationFromUser();

        if (!rotationRes || !rotationRes.data || rotationRes.error || _.isEmpty(rotationRes.data)) {
          setRotationsState({ loading: false, error: true });
          return;
        }

        setRotationsState({
          loading: false,
          data: [
            {
              rotation: rotationRes.data,
              start: Date.now(),
            },
          ],
        });
        return;
      }

      setRotationsState({ loading: false, data: res.data });
    })();
  }, [rotationsState, user, isRotationsEnabled]);

  // Hide widget if rotations failed to load
  if (rotationsState.error) {
    return null;
  }

  if (rotationsState.loading) {
    return null;
  }

  if (!rotationsState.data || rotationsState.data.length === 0) {
    return null;
  }

  return (
    <>
      <div style={{ marginTop: token.marginXL }}>
        <Typography.Title
          level={3}
          style={{ marginBottom: token.marginLG, color: colorPalette.primaryMain8 }}
        >
          {lang.TITLE_ROTATIONS}
        </Typography.Title>
        <RotationsWidgetCollapsible rotationTimeslots={rotationsState.data} />
      </div>
    </>
  );
}
