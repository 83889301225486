import React from 'react';
import { useHistory } from 'react-router';
import { ROUTES, CATEGORY_TYPES } from 'shared/constants';
import styles from './admission-category.module.scss';
import classnames from 'classnames';
import _ from 'lodash';
import { useDrag, useDrop } from 'react-dnd';
import { CategoryBoxComponent } from 'src/components/common/categories/category-box';
import analyticsService from 'src/helpers/analytics.service';
import { EVENTS } from 'shared/constants/analytics';
import { CategoryRowComponent } from 'src/components/common/categories/category-row';
import { useViewSelector } from 'src/components/common/view-selector';
import { useSelector } from 'react-redux';
import { haveEditPermissions } from 'shared/store/selectors/knowledge.selector';
import { getLang } from 'shared/store/selectors/lang.selector';
import { ActionsDropdown } from 'src/components/common/actions-dropdown/actions-dropdown.component';

const AdmissionCategory = React.memo(function AdmissionCategory({
  item,
  admissionSelectChecklist,
  activeId,
  adminMode,
  deleteCategory,
  updateCategory,
  dragItem,
  findItem,
  finishDrag,
  dndGroup,
  editCategory,
  draggable,
}) {
  let { id, title, type, ownerIds, path, tags, keywords } = item;

  let history = useHistory();
  const isChecklistsCategory = type === CATEGORY_TYPES.CHECKS;
  let focusStyle = !_.isNull(activeId) ? (activeId === id ? styles.active : styles.inActive) : null;

  const lang = useSelector(getLang('ACTION_BUTTON_TOOLTIPS'));

  const hasEditPermission = useSelector(state => haveEditPermissions(state, { ownerIds }));

  const originalIndex = findItem(id)?.index;

  const [{ isDragging }, drag] = useDrag({
    item: { type: dndGroup, id, originalIndex },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: () => adminMode && draggable,
    end: (dropResult, monitor) => {
      const { id: droppedId, originalIndex } = monitor.getItem();
      const didDrop = monitor.didDrop();
      if (!didDrop) {
        dragItem(droppedId, originalIndex);
      }

      finishDrag(droppedId);
    },
  });
  const [, drop] = useDrop({
    accept: dndGroup,
    canDrop: () => false,
    hover({ id: draggedId }) {
      if (draggedId !== id) {
        const { index: overIndex } = findItem(id);
        dragItem(draggedId, overIndex);
      }
    },
  });

  const onClick = React.useCallback(() => {
    if (isChecklistsCategory) {
      analyticsService.track(EVENTS.CHECKLISTS.OPEN, {
        id,
        title,
        ...tags,
      });
      admissionSelectChecklist(id);
    } else {
      history.push(ROUTES.ADMISSION_CATEGORIES.replace(':id', id));
    }
  }, [admissionSelectChecklist, history, id, isChecklistsCategory, title, tags]);

  const editCategoryAction = React.useCallback(
    e => {
      e.stopPropagation();
      // TODO: analytics
      if (!hasEditPermission) {
        return;
      }
      editCategory({ id, title, ownerIds, path, tags, keywords, type });
    },
    [hasEditPermission, editCategory, id, title, ownerIds, path, tags, keywords, type],
  );

  const onBlur = React.useCallback(
    e => {
      const { textContent: value } = e.target;
      // TODO: analytics
      if (value === title) return false;
      updateCategory({ id, title: value, tags, ownerIds });
    },
    [id, title, updateCategory, tags, ownerIds],
  );

  const deleteCategoryAction = React.useCallback(
    e => {
      e.stopPropagation();
      // TODO: analytics
      if (!hasEditPermission) {
        return;
      }
      deleteCategory({ id, title, path, type });
    },
    [hasEditPermission, deleteCategory, id, title, path, type],
  );

  const adminActions = [
    {
      label: lang.EDIT,
      tooltip: !hasEditPermission && lang.NOT_ALLOWED_TO_EDIT,
      onClick: editCategoryAction,
      disabled: !hasEditPermission,
    },
    {
      label: lang.DELETE,
      tooltip: !hasEditPermission && lang.NOT_ALLOWED_TO_EDIT,
      onClick: deleteCategoryAction,
      disabled: !hasEditPermission,
    },
  ];

  const opacity = isDragging ? 0 : 1;

  const { isGridView } = useViewSelector();
  const CategoryComponent = isGridView ? CategoryBoxComponent : CategoryRowComponent;

  return (
    <div
      ref={node => drag(drop(node))}
      style={{ opacity }}
      className={classnames(
        isGridView ? styles.box : {},
        focusStyle,
        adminMode ? styles.admin : {},
      )}
    >
      <CategoryComponent
        title={title}
        tags={tags}
        type={type}
        adminMode={adminMode}
        onClick={onClick}
        onBlur={onBlur}
        actions={adminMode && <ActionsDropdown actions={adminActions} />}
        categoryType={CATEGORY_TYPES.CHECKS}
        hasEditPermission={hasEditPermission}
      />
    </div>
  );
});

AdmissionCategory.defaultProps = {
  item: {
    title: '',
    id: '',
  },
  admissionSelectChecklist: () => {},
  activeId: null,
  adminMode: false,
  deleteCategory: () => {},
  updateCategory: () => {},
  editCategory: () => {},
  findItem: () => {},
  finishDrag: () => {},
  dndGroup: '',
  draggable: true,
};

export { AdmissionCategory };
