import React from 'react';
import styles from './forms.module.scss';
import { ConfigProvider, Input } from 'antd';
import { antdTheme as theme } from 'shared/constants';
import classNames from 'classnames';

const { TextArea } = Input;

/**
 * @typedef {import('antd').InputProps} TextAreaInputProps
 */

const TextAreaInput = React.memo(
  /**
   * @param {TextAreaInputProps & {marginBottom: boolean}} props
   */
  function TextInput({
    marginBottom = true,
    label = null,
    error = null,
    size = 'large',
    ...props
  }) {
    return (
      <ConfigProvider
        theme={{
          components: {
            TextArea: {
              borderRadius: 8,
              colorBorder: theme.colorBorder,
              colorTextPlaceholder: theme.colorTextPlaceholder,
            },
          },
        }}
      >
        <div className={classNames(styles.root, { [styles.marginBottom]: marginBottom })}>
          {label && <label>{label}</label>}
          <TextArea status={error && 'error'} size={size} {...props} />
          <span className={styles.error}>{error}</span>
        </div>
      </ConfigProvider>
    );
  },
);

export { TextAreaInput };
