import React, { createContext, useContext, useState } from 'react';

export const VIEW_TYPES = {
  GRID: 'grid',
  LIST: 'list',
};

const ViewSelectorContext = createContext();

export function useViewSelector() {
  return useContext(ViewSelectorContext);
}

export function ViewSelectorProvider({ children }) {
  const [viewType, setViewType] = useState(VIEW_TYPES.GRID);

  const onSelectViewType = e => {
    setViewType(e.target.value);
  };

  const value = {
    viewType,
    onSelectViewType,
    VIEW_TYPES,
    isGridView: viewType === VIEW_TYPES.GRID,
  };

  return <ViewSelectorContext.Provider value={value}>{children}</ViewSelectorContext.Provider>;
}
