export const getSelectedWorkspace = ({ workspace }) => {
  return workspace?.list?.find(w => w.id === workspace.selected);
};

export const getOperationalData = ({ workspace, organization }) => {
  // workspace config takes precedence over organization config
  const workspaceConfig = getSelectedWorkspace({ workspace })?.operational;

  const calendarUrl = workspaceConfig?.calendarUrl || organization?.calendarUrl;
  const scheduleUrls = workspaceConfig?.scheduleUrls.length
    ? workspaceConfig.scheduleUrls
    : organization?.scheduleUrls;
  const quickLinks = workspaceConfig?.quickLinks.length
    ? workspaceConfig.quickLinks
    : organization?.configuration?.quickLinks;

  return {
    calendarUrl,
    scheduleUrls,
    quickLinks,
  };
};

export const getSelectedWorkspaceId = ({ workspace }) => {
  return workspace?.selected ?? getWorkspaces({ workspace })?.[0]?.id;
};

export const getWorkspaces = ({ workspace }) => {
  return workspace.list;
};

export const getWorkspaceById =
  id =>
  ({ workspace }) => {
    return id ? workspace?.list?.find(w => w.id === id) : null;
  };

export const isThereOnlyOneWorkspace = ({ workspace }) => {
  return workspace?.list?.length === 1;
};
