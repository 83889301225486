import { useMemo } from 'react';
import { EVENTS } from 'shared/constants';
import uploadService from 'shared/services/upload.service';
import analyticsService from 'src/helpers/analytics.service';

type Asset = {
  assetId: string;
};

type OcrConversionData =
  | {
      status: 'IN_PROGRESS' | 'FAILED' | 'TIMEOUT';
      results?: undefined;
    }
  | { status: 'DONE'; results: unknown };

export function useSmartFormat() {
  return useMemo(() => {
    const convertToSmartFormat = async ({
      knowledgeId,
      knowledgeTitle,
      asset,
    }: {
      knowledgeId: string | undefined;
      knowledgeTitle: string | undefined;
      asset: Asset;
    }) => {
      analyticsService.track(EVENTS.SMART_FORMAT_CONVERSION.START, {
        conversionTime: EVENTS.SMART_FORMAT_CONVERSION_TIME.IMMEDIATE,
      });

      await uploadService.triggerOcr({ assetId: asset.assetId });

      while (true) {
        await new Promise(resolve => setTimeout(resolve, 300));
        try {
          const data = (await uploadService.fetchOcrData({
            assetId: asset.assetId,
          })) as unknown as OcrConversionData;

          if (data.status === 'FAILED' || data.status === 'TIMEOUT') {
            analyticsService.track(EVENTS.SMART_FORMAT_CONVERSION.FAILED, {
              id: knowledgeId,
              title: knowledgeTitle,
              conversionTime: EVENTS.SMART_FORMAT_CONVERSION_TIME.IMMEDIATE,
            });

            return { status: 'FAILED' } as const;
          } else if (data.status === 'DONE') {
            return { status: 'DONE', data: data.results } as const;
          }
        } catch (e) {
          return { status: 'FAILED' } as const;
        }
      }
    };

    return {
      convertToSmartFormat,
    };
  }, []);
}
