import * as React from 'react';

function SvgComponent({ color = '#2c5789', ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11.258 14.959" {...props}>
      <g id="prefix__calculator" transform="translate(-317.067 -256.927)">
        <path
          id="prefix__Path_1096"
          d="M327.507 256.927h-9.621a.819.819 0 00-.818.818v13.322a.819.819 0 00.818.818h9.621a.819.819 0 00.818-.818v-13.322a.819.819 0 00-.818-.818zm-9.788.818a.167.167 0 01.167-.167h9.621a.167.167 0 01.167.167v13.322a.167.167 0 01-.167.167h-9.621a.167.167 0 01-.167-.167z"
          fill={color}
        />
        <path
          id="prefix__Path_1097"
          d="M368.7 296.422h-8.551V300h8.551zm-7.9 2.928v-2.276h7.248v2.276z"
          fill={color}
          transform="translate(-41.761 -38.28)"
        />
        <path
          id="prefix__Path_1098"
          d="M360.8 434.757a1.246 1.246 0 10-1.246-1.246 1.248 1.248 0 001.246 1.246zm0-1.841a.595.595 0 11-.595.595.6.6 0 01.595-.596z"
          fill={color}
          transform="translate(-41.182 -169.942)"
        />
        <path
          id="prefix__Path_1099"
          d="M460.738 432.264a1.246 1.246 0 101.246 1.246 1.248 1.248 0 00-1.246-1.246zm0 1.841a.595.595 0 11.595-.595.6.6 0 01-.595.595z"
          fill={color}
          transform="translate(-138.043 -169.942)"
        />
        <path
          id="prefix__Path_1100"
          d="M558.281 432.264a1.246 1.246 0 101.246 1.246 1.248 1.248 0 00-1.246-1.246zm.595 1.246a.595.595 0 11-.595-.595.6.6 0 01.595.595z"
          fill={color}
          transform="translate(-232.584 -169.942)"
        />
        <path
          id="prefix__Path_1101"
          d="M360.8 527.886a1.246 1.246 0 10-1.246-1.246 1.248 1.248 0 001.246 1.246zm0-1.841a.595.595 0 11-.595.595.6.6 0 01.595-.596z"
          fill={color}
          transform="translate(-41.182 -260.205)"
        />
        <path
          id="prefix__Path_1102"
          d="M460.738 525.393a1.246 1.246 0 101.246 1.246 1.248 1.248 0 00-1.246-1.246zm0 1.841a.595.595 0 11.595-.595.6.6 0 01-.595.595z"
          fill={color}
          transform="translate(-138.043 -260.205)"
        />
        <path
          id="prefix__Path_1103"
          d="M558.281 525.393a1.246 1.246 0 101.246 1.246 1.248 1.248 0 00-1.246-1.246zm.595 1.246a.595.595 0 11-.595-.595.6.6 0 01.595.595z"
          fill={color}
          transform="translate(-232.584 -260.205)"
        />
        <path
          id="prefix__Path_1104"
          d="M360.8 621.014a1.246 1.246 0 10-1.246-1.246 1.248 1.248 0 001.246 1.246zm0-1.841a.595.595 0 11-.595.595.6.6 0 01.595-.596z"
          fill={color}
          transform="translate(-41.182 -350.468)"
        />
        <path
          id="prefix__Path_1105"
          d="M460.738 618.521a1.246 1.246 0 101.246 1.246 1.248 1.248 0 00-1.246-1.246zm0 1.841a.595.595 0 11.595-.595.6.6 0 01-.595.595z"
          fill={color}
          transform="translate(-138.043 -350.468)"
        />
        <path
          id="prefix__Path_1106"
          d="M558.281 618.521a1.246 1.246 0 101.246 1.246 1.248 1.248 0 00-1.246-1.246zm.595 1.246a.595.595 0 11-.595-.595.6.6 0 01.595.595z"
          fill={color}
          transform="translate(-232.584 -350.468)"
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
