import { connect } from 'react-redux';
import { FavoritesButtonComponent } from './favorites-button.component';
import { toggleFavorites } from 'shared/store/actions/favorites.actions';
import { isItemFavorite } from 'shared/store/selectors/favorites.selector';

const mapStateToProps = ({ favorites, lang }, { id }) => ({
  isFavorite: isItemFavorite(favorites, { id }),
  lang: lang.ACTION_BUTTON_TOOLTIPS,
});

export const FavoritesButton = connect(mapStateToProps, { toggleFavorites })(
  FavoritesButtonComponent,
);
