import { connect } from 'react-redux';
import { SuggestContentComponent } from './suggest-content.component';
import { suggestContent } from 'shared/store/actions/assignment.actions';
import { uploadFile } from 'shared/store/actions/knowledge.items.actions';

const mapStateToProps = ({ lang }) => ({
  lang: lang.SUGGEST_CONTENT,
});

export const SuggestContentPage = connect(mapStateToProps, { suggestContent, uploadFile })(
  SuggestContentComponent,
);
