import { connect } from 'react-redux';
import { UserProfileComponent } from './user-profile.component';
import { updateUserData, snoozeProfile } from 'shared/store/actions/auth.actions';
import { isProfileMandatory } from 'shared/store/selectors/user.selector';
import { isTermsAndConditionsEnabled } from 'shared/store/selectors/features.selector';
import { RotationOptionsProvider } from 'shared/hooks/profile.context';

const mapStateToProps = ({ lang, auth, deviceFlags, generalConfig, organization }) => {
  return {
    lang: lang.USER_PROFILE,
    user: auth?.user,
    selectedLang: deviceFlags?.LANGUAGE?.toUpperCase() ?? 'EN',
    isMandatory: isProfileMandatory({ auth, generalConfig }),
    showTermsAndConditions: isTermsAndConditionsEnabled({ organization, auth }),
  };
};

const UserProfileComponentWrapper = ({ ...props }) => {
  return (
    <RotationOptionsProvider>
      <UserProfileComponent {...props} />
    </RotationOptionsProvider>
  );
};

export const UserProfile = connect(mapStateToProps, { updateUserData, snoozeProfile })(
  UserProfileComponentWrapper,
);
