import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom-v5-compat';
import { useHistory } from 'react-router';
import hubServiceApi from 'shared/api/hub.service.api';
import { INTERNAL_ROUTING } from 'shared/constants';

const KnowledgeHubContext = createContext();

export function useKnowledgeHub() {
  return useContext(KnowledgeHubContext);
}

/**
 * Knowledge Hub Provider
 * @export
 * @param {{
 * children: React.ReactNode;
 * analyticsTriggers: {
 *  onPageLoad: (payload) => void;
 *  onSelectOrganization: (payload) => void;
 * }
 * }} { children, analyticsTriggers }
 * @returns
 */
export function KnowledgeHubProvider({ children, analyticsTriggers }) {
  const history = useHistory();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const [organizationId, setOrganizationId] = useState(searchParams.get('oid') ?? '');
  const [organizationsList, setOrganizationsList] = useState([]);
  const [workspaceId, setWorkspaceId] = useState(searchParams.get('wid') ?? '');
  const [workspacesList, setWorkspacesList] = useState([]);
  const [knowledgeCategories, setKnowledgeCategories] = useState([]);
  const [previousParentCategories, setPreviousParentCategories] = useState([]);
  const [parentCategory, setParentCategory] = useState(null);
  const [categoryId, setCategoryId] = useState(searchParams.get('cid') ?? '');
  const [binderId, setBinderId] = useState(
    searchParams.get(INTERNAL_ROUTING.QUERY_PARAMS.HUB) ?? '',
  );
  const [binder, setBinder] = useState(null);
  const [sectionTitle, setSectionTitle] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingBinder, setLoadingBinder] = useState(false);
  const [error, setError] = useState(null);

  const addParamToUrl = (key, value) => {
    searchParams.set(key, value);
    history.push({ search: searchParams.toString() }, { shallow: true });
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    const orgId = searchParams.get('oid');
    const workspaceId = searchParams.get('wid');
    const categoryId = searchParams.get('cid');
    const binderId = searchParams.get(INTERNAL_ROUTING.QUERY_PARAMS.HUB);

    setOrganizationId(orgId ?? '');
    setWorkspaceId(workspaceId ?? '');
    setCategoryId(categoryId ?? '');
    setBinderId(binderId ?? '');
  }, [search]);

  /**
   * set section title depending on the selected category, workspace or organization
   */
  useEffect(() => {
    let sectionTitle = null;

    if (categoryId) {
      sectionTitle = parentCategory?.title;
    } else if (workspaceId) {
      sectionTitle = workspacesList?.find(ws => ws.id === workspaceId)?.name;
    } else if (organizationId) {
      sectionTitle = organizationsList?.find(org => org.id === organizationId)?.name;
    }

    setSectionTitle(sectionTitle);
  }, [organizationId, workspaceId, categoryId, organizationsList, workspacesList, parentCategory]);

  /**
   * fetch functions
   */

  const getOrganizations = useCallback(async () => {
    setLoading(true);
    setError(null);
    setParentCategory(null);
    setCategoryId(null);

    const { data, error } = await hubServiceApi.getOrganizations();

    if (error || !data) {
      setError(error);
      setLoading(false);
      return;
    }

    setOrganizationsList(data);
    setLoading(false);
    return data;
  }, []);

  const getWorkspaces = useCallback(async ({ organizationId }) => {
    setLoading(true);
    setError(null);

    const { data, error } = await hubServiceApi.getWorkspaces({ organizationId });

    if (error || !data) {
      setError(error);
      setLoading(false);
      return;
    }

    setWorkspacesList(data);
    setLoading(false);
    return data;
  }, []);

  const getKnowledgeCategories = useCallback(
    async ({ organizationId, workspaceId, parentId = null }) => {
      setLoading(true);
      setError(null);

      const { data, error } = await hubServiceApi.getKnowledgeCategories({
        organizationId,
        workspaceId,
        parentId,
      });

      if (error || !data) {
        setError(error);
        setLoading(false);
        return;
      }

      setPreviousParentCategories(prev => [...prev, parentCategory]);
      setParentCategory(data);
      setSectionTitle(data.title);
      setKnowledgeCategories(data.subcategories);
      setLoading(false);
    },
    [parentCategory],
  );

  const getCategory = useCallback(async ({ organizationId, workspaceId, categoryId }) => {
    setLoading(true);
    setError(null);

    const { data, error } = await hubServiceApi.getCategory({
      organizationId,
      workspaceId,
      categoryId,
    });

    if (error || !data) {
      setError(error);
      setLoading(false);
      return;
    }

    setLoading(false);
    return data;
  }, []);

  const getBinder = useCallback(async ({ organizationId, workspaceId, binderId }) => {
    setLoadingBinder(true);
    setError(null);

    const { data, error } = await hubServiceApi.getKnowledgeBinder({
      organizationId,
      workspaceId,
      categoryId: binderId,
    });

    if (error || !data) {
      setError(error);
      setLoading(false);
      return;
    }

    setBinder(data);
    setLoadingBinder(false);
  }, []);

  /**
   * fetch on demand effects
   */

  useEffect(() => {
    if (!organizationId) {
      getOrganizations();
    }
  }, [organizationId, getOrganizations]);

  useEffect(() => {
    if (organizationId && !workspaceId) {
      getWorkspaces({ organizationId });
    }
  }, [organizationId, workspaceId, getWorkspaces]);

  useEffect(() => {
    if (organizationId && workspaceId) {
      getKnowledgeCategories({ organizationId, workspaceId, parentId: categoryId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId, workspaceId, categoryId]);

  useEffect(() => {
    if (binderId) {
      getBinder({ organizationId, workspaceId, binderId });
    }
  }, [binderId, organizationId, workspaceId, getBinder]);

  /**
   * event handlers
   */

  const onSelectOrganization = e => {
    const orgId = e.target.id;
    addParamToUrl('oid', orgId);

    const organization = organizationsList?.find(org => org.id === orgId);

    analyticsTriggers?.onSelectOrganization({
      hubOrganizationId: orgId,
      hubOrganizationName: organization?.name,
      hubOrganizationHealthSystem: organization?.healthSystem,
      hubOrganizationDepartment: organization?.department,
    });
  };

  const onSelectWorkspace = e => {
    addParamToUrl('wid', e.target.id);
  };

  const onSelectCategory = e => {
    //close binder if open
    searchParams.delete(INTERNAL_ROUTING.QUERY_PARAMS.HUB);

    addParamToUrl('cid', e.target.id);
  };

  const onSelectBinder = e => {
    addParamToUrl(INTERNAL_ROUTING.QUERY_PARAMS.HUB, e.target.id);
  };

  const value = {
    organizationsList,
    organizationId,
    workspacesList,
    workspaceId,
    knowledgeCategories,
    categoryId,
    sectionTitle,
    parentCategory,
    previousParentCategories,
    loading,
    loadingBinder,
    error,
    binder,
    getOrganizations,
    getWorkspaces,
    getCategory,
    onSelectOrganization,
    onSelectWorkspace,
    onSelectCategory,
    onSelectBinder,
    analyticsTriggers,
  };

  return <KnowledgeHubContext.Provider value={value}>{children}</KnowledgeHubContext.Provider>;
}
