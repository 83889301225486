import React from 'react';
import { Lang } from 'shared/constants';
import { Popup } from 'src/components/common';
import { Button, Input, Loader, TagInput } from 'src/components/core';
import styles from './admission-generate-report.module.scss';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Checkbox } from 'antd';

const REPORT_SCHEMA = Yup.object().shape({
  signature: Yup.object().shape({
    name: Yup.string().max(200, 'Too Long!'),
    email: Yup.string().email('Email must be a valid email').required('Email is required'),
  }),
  recipients: Yup.array().of(Yup.string().email('Email must be a valid email. ')),
  sendByEmail: Yup.boolean(),
});

const AdmissionGenerateReportComponent = React.memo(function AdmissionGenerateReportComponent({
  lang,
  generateChecklistReport,
  onFinish,
}) {
  const [isLoading, setLoadingState] = React.useState(false);
  const [reportUrl, setReportUrl] = React.useState('');

  const createOrUpdateCategoryAction = React.useCallback(
    async values => {
      setLoadingState(true);

      let res = await generateChecklistReport(values);

      if (res.url) {
        setReportUrl(res.url);
        window.open(res.url);
      }
      setLoadingState(false);
    },
    [generateChecklistReport],
  );

  const dismiss = React.useCallback(() => {
    // TODO: analytics

    onFinish({});
  }, [onFinish]);

  const close = React.useCallback(() => {
    // TODO: analytics

    onFinish({});
  }, [onFinish]);

  const { handleChange, handleSubmit, setFieldValue, values, errors, touched, isValid } = useFormik(
    {
      validationSchema: REPORT_SCHEMA,
      initialValues: { sendByEmail: false, signature: {} },
      onSubmit: createOrUpdateCategoryAction,
    },
  );

  const onCheckChange = e => {
    let { checked } = e.target;
    setFieldValue('sendByEmail', checked);
  };

  const renderContent = () => {
    if (!reportUrl) {
      return (
        <form onSubmit={handleSubmit}>
          <label>{lang.SIGNATURE_NAME}</label>
          <Input
            name="signature.name"
            value={values.signature.name}
            error={errors.signature?.name}
            onChange={handleChange}
            inputStyle="editor"
            placeholder={lang.SIGNATURE_NAME_PLACEHOLDER}
          />

          <label>{lang.SIGNATURE_EMAIL}</label>
          <Input
            name="signature.email"
            value={values.signature.email}
            error={errors.signature?.email}
            onChange={handleChange}
            inputStyle="editor"
            placeholder={lang.SIGNATURE_EMAIL_PLACEHOLDER}
          />

          <div className={styles.checkbox}>
            <Checkbox defaultChecked={values.sendByEmail} onChange={onCheckChange} />
            <span className={styles.checkLabel}>{lang.SEND_COPY}</span>
          </div>

          {values.sendByEmail && (
            <>
              <label>{lang.ADDITIONAL_RECIPIENTS}</label>
              <TagInput
                showSearch={false}
                defaultActiveFirstOption={false}
                showArrow={false}
                value={values.recipients}
                tokenSeparators={[',']}
                open={false}
                name="recipients"
                error={errors.recipients}
                touched={touched.recipients}
                onChange={setFieldValue}
              />
            </>
          )}

          {!isLoading && (
            <div className={styles.buttons}>
              <Button className={styles.button} onClick={dismiss} size="medium">
                {lang.DISMISS}
              </Button>
              <Button
                className={styles.button}
                size="medium"
                type="submit"
                buttonStyle="primary"
                disabled={!isValid}
              >
                {lang.GENERATE}
              </Button>
            </div>
          )}
          {isLoading && <Loader />}
        </form>
      );
    }

    return (
      <div className={styles.success}>
        <h3>{lang.GENERATED_SUCCSECUFULY}</h3>
        <a target="_blank" href={reportUrl} rel="noreferrer">
          {lang.GENERATED_FALLBACK}
        </a>
        <br />
        {lang.DISABLE_FALLBACK}
        <div className={styles.buttons}>
          <Button className={styles.button} onClick={close} size="medium">
            {lang.CLOSE}
          </Button>
        </div>
      </div>
    );
  };

  return (
    <Popup title={lang.TITLE} isOpen>
      {renderContent()}
    </Popup>
  );
});

AdmissionGenerateReportComponent.defaultProps = {
  lang: Lang.ADMISSION_GENERATE_REPORT,
  generateChecklistReport: () => {},
  onFinish: () => {},
};

export { AdmissionGenerateReportComponent };
