import { connect } from 'react-redux';
import { NewsFeedListComponent } from './news-feed-list.component';

const mapStateToProps = ({ application, communication, lang, workspace }) => ({
  // if workspaceIds is empty, it belongs to all workspaces
  items: communication?.newsFeedItems?.filter(
    item => !item.workspaceIds?.length || item.workspaceIds?.includes(workspace.selected),
  ),
  adminMode: application.adminMode,
  lang: lang.NEWS_FEED,
});

export const NewsFeedList = connect(mapStateToProps)(NewsFeedListComponent);
