import { Button } from '../../design-system/buttons';
import styles from './popup-form-buttons.module.scss';

/**
 * @typedef {object} TextLang
 * @property {string} DISMISS - dismiss button text
 * @property {string} CREATE_OR_UPDATE - create or update button text
 */

/**
 * @typedef {object} PopupFormButtonsProps
 * @property {TextLang} language - text language
 * @property {() => void} dismiss - dismiss function on click
 * @property {boolean} disableUpdate - disable update button
 */

/**
 * @type {React.FC<PopupFormButtonsProps>}
 * @param {PopupFormButtonsProps} props
 * @returns {JSX.Element}
 */
export function PopupFormButtons({ language, dismiss = () => {}, disableUpdate = false }) {
  return (
    <div className={styles.buttons}>
      <Button type="default" onClick={dismiss} size="medium">
        {language.DISMISS}
      </Button>
      <Button type="primary" htmlType="submit" disabled={disableUpdate} size="medium">
        {language.CREATE_OR_UPDATE}
      </Button>
    </div>
  );
}
