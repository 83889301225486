import React, { useCallback, useMemo } from 'react';
import {
  TAGS_SPECIALTY,
  TAGS_KNOWLEDGE_CONTENT_TYPE,
  TAGS_TARGET_AUDIENCE,
  TAGS_LAB_CONTENT_TYPE,
  TAGS_CHECKLIST_CONTENT_TYPE,
} from 'shared/constants';
import { TagSelectorComponent } from './tagSelector.component';
import styles from './tagSelector.module.scss';
import { getLang } from 'shared/store/selectors/lang.selector';
import { useSelector } from 'react-redux';
import { TagInput } from '../core';

const TAG_SELECTOR_TYPE = {
  KNOWLEDGE: 'KNOWLEDGE',
  LAB: 'LAB',
  CHECKLIST: 'CHECKLIST',
};

const names = {
  specialty: 'specialty',
  subspecialty: 'subspecialty',
  targetAudience: 'targetAudience',
  contentType: 'contentType',
};

const TagsSelector = React.memo(function TagsSelector({
  selectedValues,
  errors,
  onChange,
  resetField,
  type,
  wrapperClass,
  labelsClass,
  wrapperStyle = "greyWrapper",
}) {
  const LANG_TAGS = useSelector(getLang('TAGS'));
  const LANG_TAGS_FORM = useSelector(getLang('TAGS_FORM'));

  const placeholders = {
    specialty: LANG_TAGS_FORM.SPECIALTY_PLACEHOLDER,
    subspecialty: LANG_TAGS_FORM.SUBSPECIALTY_PLACEHOLDER,
    targetAudience: LANG_TAGS_FORM.TARGET_AUDIENCE_PLACEHOLDER,
    contentType: LANG_TAGS_FORM.CONTENT_TYPE_PLACEHOLDER,
  };

  const specialtyOptions = useMemo(
    () =>
      Object.values(TAGS_SPECIALTY).map(el => ({
        id: el,
        value: LANG_TAGS[el],
      })),
    [LANG_TAGS],
  );

  const subspecialtyOptions = useMemo(
    () =>
      Object.values(TAGS_SPECIALTY)
        .filter(el => el !== selectedValues.specialty)
        .map(el => ({
          id: el,
          value: LANG_TAGS[el],
        })),
    [LANG_TAGS, selectedValues.specialty],
  );

  const contentTypeOptions = useMemo(() => {
    let contentTypes = {};
    if (type === TAG_SELECTOR_TYPE.KNOWLEDGE) {
      contentTypes = TAGS_KNOWLEDGE_CONTENT_TYPE;
    }
    if (type === TAG_SELECTOR_TYPE.LAB) {
      contentTypes = TAGS_LAB_CONTENT_TYPE;
    }

    if (type === TAG_SELECTOR_TYPE.CHECKLIST) {
      contentTypes = TAGS_CHECKLIST_CONTENT_TYPE;
    }

    return Object.values(contentTypes).map(el => ({
      id: el,
      value: LANG_TAGS[el],
    }));
  }, [LANG_TAGS, type]);

  const targetAudienceOptions = useMemo(
    () =>
      Object.values(TAGS_TARGET_AUDIENCE).map(el => ({
        id: el,
        value: LANG_TAGS[el],
      })),
    [LANG_TAGS],
  );

  const handleOnChange = useCallback(
    (field, value) => {
      if (!value) {
        resetField('subspecialty');
      }
      onChange(field, value);
    },
    [onChange, resetField],
  );

  return (
    <div className={styles.selectorContainer}>
      <label data-key="tags-content-type-label" className={labelsClass}>
        {placeholders.contentType}
      </label>
      <TagSelectorComponent
        value={selectedValues?.contentType}
        options={contentTypeOptions}
        onChange={onChange}
        name={names.contentType}
        placeholder={placeholders.contentType}
        error={errors?.contentType}
        wrapperClass={wrapperClass}
      />

      <label data-key="tags-specialty-label" className={labelsClass}>
        {placeholders.specialty}
      </label>
      <TagSelectorComponent
        value={selectedValues?.specialty}
        options={specialtyOptions}
        onChange={handleOnChange}
        name={names.specialty}
        placeholder={placeholders.specialty}
        error={errors?.specialty}
        wrapperClass={wrapperClass}
      />
      <label data-key="tags-subspecialty-label" className={labelsClass}>
        {placeholders.subspecialty}
      </label>
      <TagSelectorComponent
        value={selectedValues?.subspecialty}
        options={subspecialtyOptions}
        onChange={onChange}
        name={names.subspecialty}
        placeholder={placeholders.subspecialty}
        error={errors?.subspecialty}
        disabled={!selectedValues.specialty}
        wrapperClass={wrapperClass}
      />

      <label data-key="tags-target-audience-label" className={labelsClass}>
        {placeholders.targetAudience}
      </label>
      <TagInput
        value={selectedValues?.targetAudience ?? []}
        options={targetAudienceOptions}
        onChange={onChange}
        name={names.targetAudience}
        placeholder={placeholders.targetAudience}
        error={errors?.targetAudience}
        optionsRender
        wrapperStyle={wrapperStyle}
        mode="multiple"
      />
    </div>
  );
});

export { TagsSelector, TAG_SELECTOR_TYPE };
