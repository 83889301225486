import React from 'react';
import { Lang, DEFAULT_CONTENT_TAGS } from 'shared/constants';
import { Popup, UploadDocument } from 'src/components/common';
import styles from './KnowledgeEditor.module.scss';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { DatePicker, Input, Loader, FileUpload, TagInput } from 'src/components/core';
import { MultipleOwnersSelector } from 'src/components/owners/owners-selector/multiple-owners-selector';
import { Checkbox } from 'antd';
import { TagsSelector, TAG_SELECTOR_TYPE } from 'src/components/tags/tagSelector';
import { DateTime } from 'luxon';
import moment from 'moment';
import { SUPPORTED_IMG_FORMATS } from 'shared/constants/assets.constants';
import { PopupFormButtons } from 'src/components/common/popup-form-buttons';

const KNOWLEDGE_ITEM_SCHEMA = Yup.object().shape({
  title: Yup.string().min(2, 'Too Short!').max(200, 'Too Long!').required('Required!'),
  subtitle: Yup.string().max(150, 'Too Long!'),
  link: Yup.string().url().required('Please upload a file or add an URL path.'),
  keywords: Yup.array().of(Yup.string()),
  expiresAt: Yup.date().nullable(true),
  fileUpdatedAt: Yup.date(),
  ownerIds: Yup.array()
    .of(Yup.string())
    .min(1, 'Please assign at least one owner')
    .required('Required!'),
  specialty: Yup.string().nullable(),
  subspecialty: Yup.string().nullable(),
  contentType: Yup.string().nullable(),
  targetAudience: Yup.array().of(Yup.string()),
});

const getExpirationIncrementOptions = lang => [
  { value: 0.5, label: lang.ADD_MONTHS ? lang.ADD_MONTHS.replace("'{number}'", '6') : '' },
  { value: 1, label: lang.ADD_YEAR ? lang.ADD_YEAR.replace("'{number}'", '1') : '' },
  { value: 2, label: lang.ADD_YEARS ? lang.ADD_YEARS.replace("'{number}'", '2') : '' },
];

/**
 * Old component, to be deleted
 * @deprecated
 */
const KnowledgeEditorPopupComponent = React.memo(function KnowledgeEditorPopupComponent({
  lang,
  knowledge,
  categoryId,
  createKnowledgeItem,
  updateKnowledgeItem,
  onFinish,
  locations,
  langNotification,
}) {
  const isNew = !knowledge.id;
  const [isLoading, setLoadingState] = React.useState(false);
  const [parentId] = React.useState(categoryId);

  const updateOrGenerateKnowledge = React.useCallback(
    async data => {
      setLoadingState(true);
      const { specialty, subspecialty, contentType, targetAudience, ...restData } = data;

      const itemData = {
        ...restData,
        tags: { specialty, subspecialty, contentType, targetAudience },
      };

      if (itemData.expiresAt === null) {
        delete itemData.expiresAt;
      }
      // TODO: analytics
      if (isNew) {
        itemData.categoryId = parentId;
        await createKnowledgeItem(itemData);
      } else {
        //Update knowledge by id
        itemData.id = knowledge.id;

        if (parentId && parentId !== categoryId) {
          itemData.categoryId = parentId;
        }

        await updateKnowledgeItem(itemData);
      }

      onFinish(true);
      setLoadingState(false);
    },
    [categoryId, createKnowledgeItem, isNew, knowledge.id, onFinish, parentId, updateKnowledgeItem],
  );

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    resetForm,
    values,
    errors,
    touched,
    isValid,
    dirty,
  } = useFormik({
    validationSchema: KNOWLEDGE_ITEM_SCHEMA,
    initialValues: {
      expiresAt: new Date(Date.now() + 3.154e10).toISOString(),
      fileUpdatedAt: new Date().toISOString(),
      ...knowledge,
      ...knowledge.tags,
      isSendNotification: false,
      notifyOnWorkingHours: false,
      ownerIds: isNew ? [] : knowledge.ownerIds || [],
    },
    onSubmit: updateOrGenerateKnowledge,
  });

  const dismiss = React.useCallback(() => {
    // TODO: analytics

    onFinish();
  }, [onFinish]);

  const resetField = React.useCallback(
    field => {
      const newValues = { values };
      delete newValues.values[field];
      resetForm(newValues);
    },
    [values, resetForm],
  );

  const onCheckChange = key => e => {
    let { checked } = e.target;
    setFieldValue(key, checked);
  };

  const onIncreaseExpirationDate = e => {
    const { value } = e.target;
    let previousDate = values.expiresAt;

    // antd date picker handles dates with moment and returns moment object
    if (moment.isMoment(previousDate)) {
      previousDate = previousDate.toISOString();
    }

    const date = DateTime.fromISO(previousDate).plus({ years: value }).toISO();
    setFieldValue('expiresAt', date);
  };

  return (
    <Popup title={isNew ? lang.CREATE_NEW_TITLE : lang.UPDATE_TITLE} isOpen close={dismiss}>
      <form onSubmit={handleSubmit}>
        <label>{lang.ITEM_NAME}</label>
        <Input
          inputStyle="editor"
          name="title"
          value={values.title}
          error={errors.title}
          touched={touched.title}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={lang.ITEM_NAME_PLACEHOLDER}
        />

        <label>{lang.ITEM_DESCRIPTION}</label>
        <Input
          inputStyle="editor"
          name="subtitle"
          value={values.subtitle}
          error={errors.subtitle}
          touched={touched.subtitle}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={lang.ITEM_DESCRIPTION_PLACEHOLDER}
        />

        <label>{lang.OWNER}</label>
        <MultipleOwnersSelector
          value={values.ownerIds}
          error={errors.ownerIds}
          placeholder={lang.OWNER_PLACEHOLDER}
          name="ownerIds"
          onChange={setFieldValue}
          bordered
          borderRadius={4}
        />

        <label>{lang.KEYWORDS}</label>
        <TagInput
          value={values.keywords}
          options={DEFAULT_CONTENT_TAGS}
          name="keywords"
          placeholder={lang.KEYWORDS_PLACEHOLDER}
          onChange={setFieldValue}
        />

        <TagsSelector
          selectedValues={values}
          errors={errors}
          onChange={setFieldValue}
          resetField={resetField}
          type={TAG_SELECTOR_TYPE.KNOWLEDGE}
        />

        <label>{lang.LABELS}</label>

        <div className={styles.parts}>
          <div className={styles.part}>
            <label>{lang.EXPIRES_AT}</label>
            <DatePicker
              name="expiresAt"
              value={values.expiresAt}
              error={errors.expiresAt}
              touched={touched.expiresAt}
              onChange={setFieldValue}
              allowClear={true}
              inputStyle="editor"
            />

            <div className={styles.expirationButtons}>
              {getExpirationIncrementOptions(lang).map(({ value, label }) => (
                <button key={value} type="button" onClick={onIncreaseExpirationDate} value={value}>
                  {label}
                </button>
              ))}
            </div>
          </div>

          <div className={styles.part}>
            <label>{lang.VALIDATED_AT}</label>
            <DatePicker
              name="fileUpdatedAt"
              value={values.fileUpdatedAt}
              error={errors.fileUpdatedAt}
              touched={touched.fileUpdatedAt}
              onChange={setFieldValue}
              inputStyle="editor"
              preventFutureDate
            />
          </div>
        </div>

        <label>Thumbnail</label>
        <FileUpload
          name="thumbnail"
          onChange={setFieldValue}
          inputStyle="editor"
          accept={SUPPORTED_IMG_FORMATS}
        />

        <label>{lang.LINK}</label>
        <UploadDocument
          setFieldValue={setFieldValue}
          name="link"
          value={values.link}
          error={errors.link}
          touched={touched.link}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={lang.LINK_PLACEHOLDER}
        />

        <div className={styles.checkbox}>
          <Checkbox
            defaultChecked={values.isSendNotification}
            onChange={onCheckChange('isSendNotification')}
          />
          <span className={styles.checkLabel}>{lang.SEND_NOTIFICATION}</span>
        </div>
        {values.isSendNotification && (
          <div className={styles.checkbox}>
            <Checkbox
              defaultChecked={values.notifyOnWorkingHours}
              onChange={onCheckChange('notifyOnWorkingHours')}
            />
            <span className={styles.checkLabel}>{langNotification.ON_WORKING_HOURS}</span>
          </div>
        )}

        {!isLoading && (
          <PopupFormButtons
            dismiss={dismiss}
            language={{
              CREATE_OR_UPDATE: isNew ? lang.CREATE : lang.UPDATE,
              DISMISS: lang.DISMISS,
            }}
            disableUpdate={!isValid || !dirty}
          />
        )}
        {isLoading && <Loader />}
        {!isNew && <span className={styles.itemId}>Id: {knowledge?.id}</span>}
      </form>
    </Popup>
  );
});

KnowledgeEditorPopupComponent.defaultProps = {
  lang: Lang.KNOWLEDGE_EDITOR,
  knowledge: {},
  categoryId: null,
  createKnowledgeItem: () => {},
  updateKnowledgeItem: () => {},
  onFinish: () => {},
  locations: [],
  langNotification: Lang.COMMUNICATION_CENTER,
};

export { KnowledgeEditorPopupComponent };
