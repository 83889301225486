import { connect } from 'react-redux';
import { BreadcrumbsComponent } from './Breadcrumbs.component';
import { generateBreadcrumbs } from 'shared/store/selectors/lab.selector';
import { ROUTES } from 'shared/constants';

const mapStateToProps = ({ lab }, { pathParts, initialWorkspaceId = null }) => {
  return {
    path: generateBreadcrumbs(lab, { pathParts, initialWorkspaceId }),
    categoryPath: ROUTES.LABS_CATEGORIES,
    rootPath: ROUTES.LABS,
    rootTitle: 'Compendium',
  };
};

export const LabBreadcrumbs = connect(mapStateToProps)(BreadcrumbsComponent);
