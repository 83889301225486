import React from 'react';
import { useHistory } from 'react-router';
import styles from './rotations-list.module.scss';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom-v5-compat';
import { useDateFormatter } from 'shared/hooks/dates.hooks';
import analyticsService from 'src/helpers/analytics.service';
import { EVENTS } from 'shared/constants';

const RotationItem = ({ item }) => {
  const { name, id, start, end } = item;
  const { formatRangeDate } = useDateFormatter();
  const history = useHistory();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const selected = searchParams.get('id') === id;

  const handleClick = () => {
    searchParams.set('id', id);
    history.push({ search: searchParams.toString() }, { shallow: true });

    analyticsService.track(EVENTS.ROTATIONS.OPEN, { rotationId: id, name });
  };

  return (
    <div
      className={classNames(styles.item, selected ? styles.itemSelected : '')}
      onClick={handleClick}
    >
      {start && end && <p className={styles.dates}>{formatRangeDate(start, end)}</p>}
      <p className={classNames(styles.itemTitle, selected ? styles.itemSelectedText : '')}>
        {name}
      </p>
    </div>
  );
};

export { RotationItem };
