import { useSelector } from 'react-redux';
import { RenderSection } from '../../components/render-section';
import { getLang } from 'shared/store/selectors/lang.selector';
import styles from './shared-styles.module.scss';

export function RenderKeywords({ keywords = [] }) {
  const lang = useSelector(getLang('KNOWLEDGE_EDITOR'));

  if (keywords?.length === 0) return;

  return (
    <RenderSection header={lang.KEYWORDS}>
      <p className={styles.content}>{keywords.join(', ')}</p>
    </RenderSection>
  );
}
