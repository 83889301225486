import { connect } from 'react-redux';
import { deleteCategory } from 'shared/store/actions/admission.actions';
import { AdmissionCategoryDeleteComponent } from './admission-category-delete.component';

const mapDispatchToProps = {
  deleteCategory,
};

export const AdmissionCategoryDelete = connect(
  null,
  mapDispatchToProps,
)(AdmissionCategoryDeleteComponent);
