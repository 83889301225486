import React, { useCallback } from 'react';
import classnames from 'classnames';
import styles from './knowledge-hub-search.module.scss';
import { KnowledgeHubProvider } from './hooks/use-knowledge-hub.hooks';
import { RenderHeader } from './components/render-header';
import { Loader } from 'src/components/core';
import hubServiceApi from 'shared/api/hub.service.api';
import { KnowledgeWrapperComponent } from '../knowledge/components/knowledge-row';
import { ROUTES } from 'shared/constants';
import { SectionComponent } from 'src/components/common/section/section.component';
import { NotFound } from 'src/components/common';
import { TAGS_KEYS_MAP_TO_SEARCH, useTagFilters } from './components/tag-filter';
import { Panda } from 'shared/images/characters';
import { useSelector } from 'react-redux';
import { getLang } from 'shared/store/selectors/lang.selector';

export function KnowledgeHubSearchPage() {
  const lang = useSelector(getLang('KNOWLEDGE_HUB_SEARCH'));
  const [loading, setLoading] = React.useState(false);
  const [searchResults, setSearchResults] = React.useState(null);
  const [query, setQuery] = React.useState('');

  const { FilterComponent, filters } = useTagFilters({
    results: searchResults,
  });

  const searchQuery = new URLSearchParams(window.location.search).get('q');

  const triggerSearch = useCallback(async () => {
    setQuery(searchQuery);
    setSearchResults(null);

    if (searchQuery === '') return;

    setLoading(true);

    // map all filters to arrays from sets
    const filtersMap = Object.fromEntries(
      Object.entries(filters).map(([key, value]) => [
        TAGS_KEYS_MAP_TO_SEARCH[key],
        Array.from(value),
      ]),
    );

    const res = await hubServiceApi.search({ query: searchQuery, filters: filtersMap });

    if (res.error) {
      setSearchResults({
        results: [],
      });
    } else {
      setSearchResults(res.data);
    }

    setLoading(false);
  }, [searchQuery, filters]);

  React.useEffect(() => {
    triggerSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  React.useEffect(() => {
    if (searchQuery !== query) {
      triggerSearch();
    }
  }, [query, searchQuery, triggerSearch]);

  const renderResults = () => {
    if (loading) return <Loader />;

    if (searchResults === null) {
      return (
        <div className={styles.emptyRoot}>
          <span className={styles.emptyHeader}>{lang.CALL_TO_ACTION_SEARCH}</span>
          <img src={Panda} className={styles.emptyImage} alt={lang.CALL_TO_ACTION_SEARCH} />
        </div>
      );
    }

    if (!searchResults.results || searchResults.results.length === 0) {
      return <NotFound className={styles.notFound} />;
    }

    return (
      <div className={styles.main}>
        <div className={styles.results}>
          {searchResults.results.map((orgResults, index) => (
            <SectionComponent
              isOpen={searchResults.results === 1 || index === 0}
              title={`${orgResults.healthSystem} (${orgResults.results.length} results)`}
              key={orgResults.healthSystem}
            >
              {orgResults.results.map(item => (
                <KnowledgeWrapperComponent
                  key={item.id}
                  item={item}
                  linkOwnerIds={item.ownerIds}
                  adminMode={false}
                  linkedItem
                  hideFavorites
                  hideInfo
                  basePath={ROUTES.KNOWLEDGE_HUB_ITEMS}
                  light
                  showSpecialty
                />
              ))}
            </SectionComponent>
          ))}
        </div>
        <div className={styles.filters}>
          <FilterComponent />
        </div>
      </div>
    );
  };

  const title = searchResults?.count
    ? lang.SEARCH_RESULTS.replace('{count}', searchResults?.count).replace(
        '{searchTerm}',
        searchQuery,
      )
    : lang.SEARCH_HEADER;

  return (
    <KnowledgeHubProvider>
      <div className={classnames([styles.root, 'new-design-system'])}>
        <RenderHeader title={title} upperTitle={lang.SEARCH_UPPER_HEADER} />
        {renderResults()}
      </div>
    </KnowledgeHubProvider>
  );
}
