import React from 'react';
import styles from './forms.module.scss';
import { ConfigProvider, DatePicker as AntdDatePicker } from 'antd';
import { antdTheme as theme } from 'shared/constants';
import { DateTime } from 'luxon';

import luxonGenerateConfig from 'rc-picker/lib/generate/luxon';

/*
  We use luxon instead of the default antd date library (dayjs)
  To do so we created a custom DatePicker component
  https://ant.design/docs/react/use-custom-date-library#datepicker
*/
const CustomDatePicker = AntdDatePicker.generatePicker(luxonGenerateConfig);

const dateFormat = 'MMM D, YYYY';

/**
 * @typedef {import('antd').DatePickerProps} AntdDatePickerProps
 */

/**
 * @param {AntdDatePickerProps & { label?: string, error?: string }} props
 * @returns {JSX.Element}
 */
function DatePicker({ label = null, error = null, value, ...props }) {
  return (
    <ConfigProvider
      theme={{
        components: {
          DatePicker: {
            borderRadius: 8,
            colorBorder: theme.colorBorder,
            colorTextPlaceholder: theme.colorTextPlaceholder,
          },
        },
      }}
    >
      <div className={styles.root}>
        {label && <label>{label}</label>}
        <CustomDatePicker
          format={dateFormat}
          status={error && 'error'}
          value={value ? DateTime.fromISO(value) : ''}
          {...props}
        />
        <span className={styles.error}>{error}</span>
      </div>
    </ConfigProvider>
  );
}

export { DatePicker };
