import { connect } from 'react-redux';
import { OwnersManagementPageComponent } from './owners-management.component';

const mapStateToProps = ({ lang }) => {
  return {
    lang: lang.OWNERS_MANAGEMENT,
  };
};

export const OwnersManagementPage = connect(mapStateToProps)(OwnersManagementPageComponent);
