import { connect } from 'react-redux';
import { HeaderComponent } from './header.component';
import { logout } from 'shared/store/actions/auth.actions';
import { setAdminMode } from 'shared/store/actions/application.actions';
import { isHavePermission } from 'shared/store/selectors/auth.selector';
import { isSwitchOrganizationAvailable } from 'shared/store/selectors/available-organizations.selector';
import {
  ACCOUNT_ADMIN_PERMISSIONS_GROUP,
  COLLABORATOR_PERMISSIONS_GROUP,
  ROOT_PERMISSIONS_GROUP,
} from 'shared/constants';
import { selectWorkspace } from 'shared/store/actions/workspace.actions';
import { getSelectedWorkspaceId, getWorkspaces } from 'shared/store/selectors/workspace.selector';
import { isAIEnabled } from 'shared/store/selectors/features.selector';
import { isNormalUser } from 'shared/store/selectors/user.selector';

const mapStateToProps = ({
  organization,
  auth,
  application,
  availableOrganizations,
  lang,
  generalConfig,
  workspace,
}) => ({
  lang: lang.HEADER,
  organization,
  user: auth.user,
  adminMode: application.adminMode,
  isCollaborator: isHavePermission({
    auth,
    permissions: COLLABORATOR_PERMISSIONS_GROUP,
  }),
  isAccountAdmin: isHavePermission({
    auth,
    permissions: ACCOUNT_ADMIN_PERMISSIONS_GROUP,
  }),
  isRoot: isHavePermission({
    auth,
    permissions: ROOT_PERMISSIONS_GROUP,
  }),
  isSwitchOrganizationAvailable: isSwitchOrganizationAvailable({
    availableOrganizations,
  }),
  feedbackForm: generalConfig.FEEDBACK_FORM,
  workspaces: getWorkspaces({ workspace }),
  selectedWorkspaceId: getSelectedWorkspaceId({ workspace }),
  isAIEnabled: isAIEnabled({ organization, auth }),
  isCanEditProfile: isNormalUser({ auth }),
});

export const Header = connect(mapStateToProps, {
  logout,
  setAdminMode,
  selectWorkspace,
})(HeaderComponent);
