import { REMOTE_CONFIG_KEYS } from 'shared/constants';
import { OAUTH_CONFIGURATIONS_UPDATE } from 'shared/store/actions';
import remoteConfigService from '../../helpers/remote-config.service';

export const initOauthConfigStatus = () => dispatch => {
  const oauthConfig = remoteConfigService.getJsonValue(REMOTE_CONFIG_KEYS.OAUTH_CONFIGURATIONS);

  dispatch({
    type: OAUTH_CONFIGURATIONS_UPDATE,
    payload: oauthConfig,
  });
};
