import * as React from 'react';

function SvgComponent({ color = '#2c5789', ...props }) {
  return (
    <svg {...props} viewBox="0 0 16.008 15.993">
      <g id="edit" transform="translate(0.004 -0.029)">
        <path
          id="Path_539"
          d="M14.964,1.064a3.529,3.529,0,0,0-4.994,0L.813,10.217a.458.458,0,0,0-.13.263L0,15.5a.457.457,0,0,0,.13.385.466.466,0,0,0,.324.136.371.371,0,0,0,.061,0l3.027-.409a.461.461,0,0,0-.123-.914L1,15.027l.474-3.5,3.689,3.689a.466.466,0,0,0,.324.136.451.451,0,0,0,.324-.136l9.157-9.154a3.527,3.527,0,0,0,0-4.994ZM10.147,2.189l1.538,1.538L3.329,12.082,1.791,10.544ZM5.487,14.236l-1.5-1.5L12.343,4.38l1.5,1.5Zm9-9.014L10.8,1.544a2.613,2.613,0,0,1,3.678,3.678Z"
          fill={color}
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
