import React from 'react';
import { Popup } from 'src/components/common';
import { Button, Loader } from 'src/components/core';
import styles from './lab-category-delete.module.scss';
import { useSelector } from 'react-redux';
import { getLang } from 'shared/store/selectors/lang.selector';
import { Alert } from 'antd';
import { isCompendiumOwner } from 'shared/store/selectors/lab.selector';
import analyticsService from 'src/helpers/analytics.service';
import { EVENTS, ANALYTICS_CONTENT_CATEGORIES_TYPES } from 'shared/constants';
import { getCategoryType } from 'shared/utils';

const LabCategoryDelete = React.memo(function LabCategoryDelete({
  entity,
  deleteCategory,
  onFinish,
}) {
  const [isLoading, setLoadingState] = React.useState(false);

  const canRemoveCategory = useSelector(state => isCompendiumOwner(state, { id: entity?.id }));

  const lang = useSelector(getLang('DELETE_LAB_CATEGORY_CONFIRMATION'));

  const deleteCategoryAction = async () => {
    setLoadingState(true);

    // TODO: remove the category

    await deleteCategory({ id: entity?.id });

    if (entity) {
      analyticsService.track(EVENTS.CATEGORIES.REMOVE, {
        categoryType: getCategoryType({ type: entity.type, path: entity.path }),
        contentType: ANALYTICS_CONTENT_CATEGORIES_TYPES.LAB,
        categoryId: entity.id,
        title: entity.title,
        ownerIds: entity.ownerIds,
      });
    }
    onFinish();
    setLoadingState(false);
  };

  const dismiss = React.useCallback(() => {
    // TODO: analytics

    onFinish();
  }, [onFinish]);

  if (!entity) return null;

  return (
    <Popup title={lang.TITLE} isOpen={true}>
      <p className={styles.content}>{lang.TEXT.replace('{name}', entity?.title)}</p>

      {!canRemoveCategory && (
        <Alert className={styles.alert} message={lang.OWNERSHIP_ERROR} type="error" />
      )}

      {!isLoading && (
        <div className={styles.buttons}>
          <Button
            className={styles.button}
            size="medium"
            onClick={deleteCategoryAction}
            buttonStyle="danger"
            disabled={!canRemoveCategory}
          >
            {lang.CONFIRM}
          </Button>
          <Button className={styles.button} onClick={dismiss} size="medium">
            {lang.DISMISS}
          </Button>
        </div>
      )}
      {isLoading && <Loader />}
    </Popup>
  );
});

LabCategoryDelete.defaultProps = {
  entity: null,
  deleteCategory: () => {},
  onFinish: () => {},
};

export { LabCategoryDelete };
