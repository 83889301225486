import React from 'react';
import styles from './render-expiration-date.module.scss';
import { DateTime } from 'luxon';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { getLang } from 'shared/store/selectors/lang.selector';
import { RenderSection } from '../../components';
import { DatePicker } from 'src/components/design-system/forms';

const getExpirationIncrementOptions = lang => [
  { value: 0.5, label: lang.ADD_MONTHS ? lang.ADD_MONTHS.replace('{number}', '6') : '' },
  { value: 1, label: lang.ADD_YEAR ? lang.ADD_YEAR.replace('{number}', '1') : '' },
  { value: 2, label: lang.ADD_YEARS ? lang.ADD_YEARS.replace('{number}', '2') : '' },
];

export function RenderExpirationDate({ setFieldValue, name, value, error, touched }) {
  const lang = useSelector(getLang('KNOWLEDGE_EDITOR'));

  const extendAction = e => {
    const newValue = parseFloat(e.target.value);
    let previousValue = value;
    // antd date picker handles dates with moment and returns moment object
    if (moment.isMoment(previousValue)) {
      previousValue = previousValue.toISOString();
    }

    setFieldValue(name, DateTime.fromISO(previousValue).plus({ years: newValue }).toISO());
  };

  return (
    <RenderSection header={lang.EXPIRES_AT}>
      <DatePicker
        name="expiresAt"
        value={value}
        error={error}
        touched={touched}
        onChange={v => setFieldValue(name, v)}
        allowClear={true}
      />
      <h6>{lang.EXTEND_BY}</h6>
      <div className={styles.root}>
        {getExpirationIncrementOptions(lang).map(({ value, label }) => (
          <button
            className={styles.extendTime}
            key={value}
            type="button"
            onClick={extendAction}
            value={value}
          >
            {label}
          </button>
        ))}
      </div>
    </RenderSection>
  );
}
