import React from 'react';
import { Lang } from 'shared/constants';
import { Popup } from 'src/components/common';
import styles from './KnowledgeMove.module.scss';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button, Loader, SelectInput } from 'src/components/core';
import { normalizedContains } from 'src/utils';
import { Checkbox } from 'antd';

const KNOWLEDGE_ITEM_SCHEMA = Yup.object().shape({
  categoryId: Yup.string().required('Required'),
});

const KnowledgeMovePopupComponent = React.memo(function KnowledgeMovePopupComponent({
  lang,
  item,
  categoryId,
  moveKnowledgeItem,
  createKnowledgeLink,
  onFinish,
  locations,
  enableCreateLink = true,
}) {
  const [isLoading, setLoadingState] = React.useState(false);
  const [createLink, setCreateLink] = React.useState(false);

  const moveKnowledgeItemAction = React.useCallback(
    async data => {
      setLoadingState(true);

      if (createLink) {
        await createKnowledgeLink({ ...data, items: [item], linkOwnerIds: item.ownerIds });
      } else {
        await moveKnowledgeItem({ ...data, item, sourceId: categoryId ?? item.categoryId });
      }

      onFinish(true);
      setLoadingState(false);
    },
    [categoryId, moveKnowledgeItem, createKnowledgeLink, onFinish, item, createLink],
  );

  const { handleSubmit, setFieldValue, values, errors } = useFormik({
    validationSchema: KNOWLEDGE_ITEM_SCHEMA,
    initialValues: {
      id: item.id,
      categoryId,
    },
    onSubmit: moveKnowledgeItemAction,
  });

  const dismiss = React.useCallback(() => {
    // TODO: analytics
    onFinish();
  }, [onFinish]);

  const toggleCreateLink = () => {
    setCreateLink(!createLink);
  };

  return (
    <Popup title={lang.TITLE} isOpen>
      <p className={styles.content}>{item.title}</p>
      <form onSubmit={handleSubmit}>
        <label>{lang.LOCATION}</label>
        <SelectInput
          value={values.categoryId}
          error={errors.categoryId}
          placeholder={lang.LOCATION_PLACEHOLDER}
          options={locations}
          name="categoryId"
          onChange={setFieldValue}
          filterOption={(inputValue, option) => normalizedContains(option?.children, inputValue)}
        />
        {enableCreateLink && (
          <div className={styles.checkbox}>
            <Checkbox onChange={toggleCreateLink} />
            <label className={styles.checkboxLabel}>{lang.CHECKBOX}</label>
          </div>
        )}

        {!isLoading && (
          <div className={styles.buttons}>
            <Button className={styles.button} onClick={dismiss} size="medium">
              {lang.DISMISS}
            </Button>
            <Button
              className={styles.button}
              size="medium"
              type="submit"
              disabled={categoryId === values.categoryId}
              buttonStyle="primary"
            >
              {createLink ? lang.LINK : lang.MOVE}
            </Button>
          </div>
        )}
        {isLoading && <Loader />}
      </form>
    </Popup>
  );
});

KnowledgeMovePopupComponent.defaultProps = {
  lang: Lang.KNOWLEDGE_MOVE,
  knowledge: {},
  categoryId: null,
  moveKnowledgeItem: () => {},
  createKnowledgeLink: () => {},
  onFinish: () => {},
  locations: [],
};

export { KnowledgeMovePopupComponent };
