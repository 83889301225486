import React from 'react';
import styles from './textarea.module.scss';
import classnames from 'classnames';

const TextArea = React.memo(({ error, touched, wrapperClass, inputStyle, className, ...props }) => {
  return (
    <div className={classnames(styles.root, wrapperClass)}>
      <textarea className={classnames(styles.input, styles[inputStyle], className)} {...props} />
      <span className={styles.error}>{error}</span>
    </div>
  );
});

TextArea.defaultProps = {
  error: null,
  wrapperClass: null,
  className: {},
  inputStyle: 'user',
};

export { TextArea };
