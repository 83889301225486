import { useSelector } from 'react-redux';
import { getLang } from 'shared/store/selectors/lang.selector';
import styles from './render-binder-selector.module.scss';
import { Divider, SelectBox } from 'src/components/design-system';
import { getSelectedWorkspaceId, getWorkspaces } from 'shared/store/selectors/workspace.selector';
import { useEffect, useMemo, useState } from 'react';
import { ConfigProvider } from 'antd';
import { CATEGORY_TYPES, colorPalette } from 'shared/constants';
import {
  generateBreadcrumbs,
  getCategoriesById,
  getCategoryById,
  getRoot,
} from 'shared/store/selectors/knowledge.selector';
import { SectionComponent } from 'src/components/common/section/section.component';
import { CategoryRowComponent } from 'src/components/common/categories/category-row';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faHome } from '@fortawesome/pro-solid-svg-icons';

export function RenderBinderSelector({ binderId, selectBinder }) {
  const lang = useSelector(getLang('BINDER_SELECTOR'));

  /**
   * Workspaces
   */
  const workspaces = useSelector(getWorkspaces);
  const initialWorkspaceId = useSelector(getSelectedWorkspaceId);
  const [selectedWorkspaceId, setSelectedWorkspaceId] = useState(initialWorkspaceId);
  const onSelectWorkspace = workspaceId => setSelectedWorkspaceId(workspaceId);

  const workspacesOptions = useMemo(() => {
    return workspaces.map(({ id, name }) => ({ id, value: name }));
  }, [workspaces]);

  /**
   * Categories
   */
  const rootCategory = useSelector(({ knowledge }) => getRoot(knowledge, selectedWorkspaceId));
  const [categoryId, setCategoryId] = useState(rootCategory.id);

  useEffect(() => {
    setCategoryId(rootCategory.id);
  }, [rootCategory]);

  const category = useSelector(({ knowledge }) => getCategoryById(knowledge, { id: categoryId }));
  const sections = useSelector(({ knowledge }) =>
    getCategoriesById(knowledge, category.id, selectedWorkspaceId),
  );
  const subcategories = useSelector(({ knowledge }) =>
    sections?.reduce((acc, section) => {
      return {
        ...acc,
        [section.id]: section.subcategories?.map(id => getCategoryById(knowledge, { id })),
      };
    }, {}),
  );

  const onSelectCategory = e => {
    setCategoryId(e.target.id);
  };

  const onSelectBinder = e => {
    selectBinder({ binderId: e.target.id, targetWorkspaceId: selectedWorkspaceId });
  };

  const breadcrumbs = useSelector(({ knowledge }) =>
    generateBreadcrumbs(knowledge, { pathParts: category.pathParts }),
  );

  const showBreadcrumbs = categoryId !== rootCategory.id;

  const renderBreadcrumbs = () => (
    <div className={styles.breadcrumbs}>
      {breadcrumbs
        .filter((item, i) => i % 2 === 0)
        .map((item, index) => {
          if (!item) return null;

          if (index === 0) {
            return (
              <h3 key={index} onClick={() => setCategoryId(rootCategory.id)}>
                <FontAwesomeIcon icon={faHome} />
              </h3>
            );
          }

          return (
            <div className={styles.afterHomeBreadcrumbs}>
              <FontAwesomeIcon icon={faChevronRight} className={styles.separator} />
              <h3 key={index} onClick={() => setCategoryId(item.id)}>
                {item.title}
              </h3>
            </div>
          );
        })}
    </div>
  );

  const renderCategoriesList = () => (
    <div className={styles.categoriesContainer}>
      {sections?.map(section => (
        <SectionComponent title={section.title} isOpen={false}>
          {subcategories[section.id]?.map(category => {
            const isBinder = category?.type === CATEGORY_TYPES.KNOWLEDGE;
            const onClick = isBinder ? onSelectBinder : onSelectCategory;

            return (
              <div key={category.id} id={category.id} className={styles.row} onClick={onClick}>
                <CategoryRowComponent
                  {...category}
                  wrapperClass={binderId === category.id ? styles.selected : styles.categoryRow}
                />
              </div>
            );
          })}
        </SectionComponent>
      ))}
    </div>
  );

  return (
    <div className={styles.container}>
      <p>{lang.SUBTITLE}</p>
      <Divider className={styles.divider} />
      <div className={styles.workspace}>
        <p>{lang.WORKSPACE}</p>
        <ConfigProvider
          theme={{
            components: {
              Select: {
                colorText: colorPalette.primaryMain6,
              },
            },
          }}
        >
          <SelectBox
            placeholder={lang.WORKSPACE}
            options={workspacesOptions}
            value={selectedWorkspaceId}
            onChange={(name, value) => onSelectWorkspace(value)}
            bordered={false}
            size="small"
            showSearch={false}
          />
        </ConfigProvider>
      </div>
      <Divider className={styles.divider} />

      {showBreadcrumbs && (
        <div className={styles.titleContainer}>
          {renderBreadcrumbs()}
          <h2 className={styles.title}>{category.title}</h2>
        </div>
      )}
      {renderCategoriesList()}
    </div>
  );
}
