import { connect } from 'react-redux';
import { BreadcrumbsComponent } from './Breadcrumbs.component';
import { generateBreadcrumbs } from 'shared/store/selectors/knowledge.selector';

const mapStateToProps = ({ knowledge }, { pathParts, initialWorkspaceId = null }) => {
  return {
    path: generateBreadcrumbs(knowledge, { pathParts, initialWorkspaceId }),
  };
};

export const Breadcrumbs = connect(mapStateToProps)(BreadcrumbsComponent);
