import React from 'react';
import { Tag } from 'antd';

export const Label = ({ value, label, closable, onClose, className = null, ...props }) => {
  return (
    <Tag closable={closable} onClose={onClose} className={className} {...props}>
      {label || value}
    </Tag>
  );
};
