import { createContext, useContext, useEffect, useState } from 'react';
import rotationsServiceApi from 'shared/api/rotations.service.api';
import { TAGS_ROTATION, TAGS_SPECIALTY } from 'shared/constants';

const RotationOptionsContext = createContext();

export function useRotationOptions() {
  return useContext(RotationOptionsContext);
}

export function RotationOptionsProvider({ children }) {
  const [rotations, setRotations] = useState([]);

  const getRotationsOptions = async () => {
    const { data } = await rotationsServiceApi.getRotationsList();

    if (!data?.length) return Object.values({ ...TAGS_SPECIALTY, ...TAGS_ROTATION });

    setRotations(data.map(rotation => rotation.name));
  };

  useEffect(() => {
    if (!rotations.length) {
      getRotationsOptions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = {
    rotations,
    getRotationsOptions,
  };

  return (
    <RotationOptionsContext.Provider value={value}>{children}</RotationOptionsContext.Provider>
  );
}
