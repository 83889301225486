// @ts-check

import API from './API';
import { DELETE, GET, POST, PUT } from '../utils/Executer';
import { ROTATION_SERVICE_URL as servicePath } from '../constants/APIconstants';

/**
 * @typedef {{
 * id: string,
 * start: string,
 * end: string,
 * userIds: string[],
 * scheduleId: string,
 * rotation: {
 * organizationId: string,
 * ownerIds: string[],
 * code: string,
 * name: string,
 * knowledge: string[],
 * url?: string,
 * }
 * }} RotationTimeslot
 */

class RotationsService {
  /**
   *
   * @param {{
   * userIds?: string[],
   * start: Date,
   * end: Date
   * }} param0
   * @returns {Promise<{ success: false } | { success: true, data: Array<RotationTimeslot> }>}
   */
  getTimeslots({ userIds, start, end }) {
    const urlSearchParams = new URLSearchParams();

    if (userIds) {
      for (const userId of userIds) {
        urlSearchParams.append('userIds', userId);
      }
    }

    urlSearchParams.append('start', start.toJSON());
    urlSearchParams.append('end', end.toJSON());

    return API.client.execute(GET, `${servicePath}timeslot?${urlSearchParams}`);
  }

  getRotationsList() {
    return API.client.execute(GET, `${servicePath}list`);
  }

  getRotationFromUser() {
    return API.client.execute(GET, `${servicePath}user`);
  }

  getAllRotations() {
    return API.client.execute(GET, `${servicePath}`);
  }

  /**
   *
   * @param {*} id
   * @returns
   */
  getRotationById(id) {
    return API.client.execute(GET, `${servicePath}${id}`);
  }

  /**
   * @param {{
   * rotationId: string,
   * id: string
   * }} param0
   * @returns
   */
  removeKnowledge({ rotationId, id }) {
    return API.client.execute(DELETE, `${servicePath}${rotationId}/knowledge/${id}`);
  }

  /**
   * @param {{
   * rotationId: string,
   * payload: {
   * url: string,
   * type: string,
   * id: string
   * }
   * }} param0
   * @returns
   */
  addKnowledge({ rotationId, payload }) {
    return API.client.execute(POST, `${servicePath}${rotationId}/knowledge`, payload);
  }

  /**
   * @param {{
   * rotationId: string,
   * payload: {
   * name: string,
   * ownerIds: string[],
   * }
   * }} param0
   * @returns
   */
  updateRotation({ rotationId, payload }) {
    return API.client.execute(PUT, `${servicePath}${rotationId}`, payload);
  }

  /**
   * @param {{
   * rotationId: string,
   * payload: {
   *  subject: string,
   *  body: string,
   * }
   * }} param0
   * @returns
   */
  sendPreviewWelcomeLetter({ rotationId, payload }) {
    return API.client.execute(POST, `${servicePath}${rotationId}/welcome-letter/preview`, payload);
  }

  /**
   * @param {{
   * rotationId: string,
   * payload: {
   *  deliverDaysBefore: number,
   *  subject: string,
   *  body: string,
   * }
   * }} param0
   * @returns
   */
  updateWelcomeLetter({ rotationId, payload }) {
    return API.client.execute(PUT, `${servicePath}${rotationId}/welcome-letter`, payload);
  }

  /**
   * @param {*} rotationId
   * @returns
   */
  getTrainees(rotationId) {
    return API.client.execute(GET, `${servicePath}${rotationId}/trainees`);
  }

  /**
   * @param {{
   * rotationId: string,
   * group: string,
   * payload: [{
   * id: string,
   * type: string
   * }]
   * }} param0
   * @returns
   */
  addMembersToTeam({ rotationId, group, payload }) {
    return API.client.execute(POST, `${servicePath}${rotationId}/team/${group}`, payload);
  }

  /**
   * @param {{
   * rotationId: string,
   * group: string,
   * memberId: string
   * }} param0
   * @returns
   */
  removeMemberFromTeam({ rotationId, group, memberId }) {
    return API.client.execute(DELETE, `${servicePath}${rotationId}/team/${group}/${memberId}`);
  }
}

export default new RotationsService();
