import { useState } from 'react';
import styles from './resizable-layout.module.scss';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleChevronRight, faCircleChevronLeft } from '@fortawesome/pro-solid-svg-icons';
import {
  faCircleChevronRight as faCircleChevronRightReg,
  faCircleChevronLeft as faCircleChevronLeftReg,
} from '@fortawesome/pro-regular-svg-icons';

const MIN_WIDTH = 200;
const MAX_WIDTH = 500;
const INIT_WIDTH = 256;
const COLLAPSED_WIDTH = 13;

/**
 * Resizable layout with left and right panels
 * @param {{leftPanel: JSX.Element, rightPanel: JSX.Element, initialLeftSideWidth?: number }} param0
 */
export function ResizableLayout({
  leftPanel,
  rightPanel,
  initialLeftSideWidth = INIT_WIDTH,
  hideLeftPanel = false,
}) {
  const [isResizing, setIsResizing] = useState(false);
  const [sidebarWidth, setSidebarWidth] = useState(initialLeftSideWidth);

  const isLeftPanelCollapsed = sidebarWidth <= COLLAPSED_WIDTH;

  const collapseLeftPanel = () => {
    setSidebarWidth(isLeftPanelCollapsed ? initialLeftSideWidth : COLLAPSED_WIDTH);
  };

  const handleMouseDown = e => {
    e.preventDefault();
    e.stopPropagation();
    setIsResizing(true);
    document.body.style.cursor = 'col-resize'; // Set cursor style on body
  };

  const handleMouseUp = e => {
    e.preventDefault();
    e.stopPropagation();
    setIsResizing(false);
    document.body.style.cursor = ''; // Reset cursor style on body
    if (sidebarWidth < MIN_WIDTH) {
      setSidebarWidth(COLLAPSED_WIDTH); // Collapse the sidebar if it's less than the minimum width
    }
  };

  const handleMouseMove = e => {
    e.preventDefault();
    e.stopPropagation();
    if (isResizing) {
      const newWidth = e.clientX || 0;
      if (newWidth > COLLAPSED_WIDTH && newWidth < MAX_WIDTH) {
        setSidebarWidth(newWidth);
      }
    }
  };

  const handleMouseEnterSideBar = e => {
    e.preventDefault();
    e.stopPropagation();
    if (sidebarWidth <= COLLAPSED_WIDTH) {
      setSidebarWidth(initialLeftSideWidth); // Expand the sidebar when mouse enters
    }
  };

  return (
    <div className={styles.root} onMouseMove={handleMouseMove} onMouseUp={handleMouseUp}>
      {!hideLeftPanel && (
        <div
          onMouseEnter={handleMouseEnterSideBar}
          className={classNames(styles.sidebar, {
            [styles.resizing]: isResizing, // turn off the transition effect when resizing
          })}
          style={{
            width: sidebarWidth,
          }}
        >
          {leftPanel}
        </div>
      )}
      <div
        className={classNames(styles.resizer, {
          [styles.resizing]: isResizing,
          [styles.visible]: isLeftPanelCollapsed,
        })}
        onMouseDown={handleMouseDown}
      >
        <button
          style={{ cursor: isLeftPanelCollapsed ? 'e-resize' : 'w-resize' }}
          className={classNames(styles.collapseButton, {
            [styles.visible]: isLeftPanelCollapsed,
          })}
          onClick={collapseLeftPanel}
          type="button"
        >
          <>
            <FontAwesomeIcon
              className={classNames(styles.collapseIcon, styles.collapseIconSolid)}
              icon={isLeftPanelCollapsed ? faCircleChevronRight : faCircleChevronLeft}
            />
            <FontAwesomeIcon
              className={classNames(styles.collapseIcon, styles.collapseIconRegular)}
              icon={isLeftPanelCollapsed ? faCircleChevronRightReg : faCircleChevronLeftReg}
            />
          </>
        </button>
      </div>

      <div className={styles.mainContent}>{rightPanel}</div>
    </div>
  );
}
