import { connect } from 'react-redux';
import { generateChecklistReport } from 'shared/store/actions/admission.actions';
import { AdmissionGenerateReportComponent } from './admission-generate-report.component';

const mapStateToProps = ({ lang }) => ({
  lang: lang.ADMISSION_GENERATE_REPORT,
});

const mapDispatchToProps = {
  generateChecklistReport,
};

export const AdmissionGenerateReport = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdmissionGenerateReportComponent);
