// Application actions
export const APP_INIT = '[App] Init';
export const INITIAL_STATE = '[App] Set Initial state';
export const APP_FINISH_STEP = '[App] Finish step';
export const APP_IS_READY = '[App] App is ready';
export const FIRST_TIME = '[App] First time';
export const APPLICATION_SET_ADMIN_MODE = '[App] Set admin mode';
export const APPLICATION_WIPE_DATA = '[App] Wipe data';
export const APPLICATION_REFRESH_DATE = '[App] Refresh data';

// Storage actions
export const LOAD_STORAGE = '[Storage] Load storage';
export const UPDATE_STORAGE = '[Storage] Update storage';

// Auth actions
export const AUTH_LOGIN = '[Auth] Login';
export const AUTH_LOGOUT = '[Auth] Logout';
export const AUTH_UPDATE_USER = '[Auth] Update User';
export const AUTH_ATTACH_USER_ID = '[Auth] Attach user id';
export const AUTH_FINISH_ONBOARDING = '[Auth] Onboarding Finished';
export const AUTH_UPDATE_TOKEN = '[Auth] Update token';
export const AUTH_SET_REGION = '[Auth] Set Region';
export const AUTH_UPDATE_TOKEN_DETAILS = '[Auth] Update token details';
export const AUTH_UPDATE_AUTH_CONTEXT = '[Auth] Update auth context';
export const AUTH_ACTIVATE_ACCOUNT = '[Auth] Activate account';

// Onboarding actions
export const PROFILE_COMPLETED = '[User] Profile Completed';
export const NOTIFICATION_PROMPT_CLOSED = '[User] Notification Prompt closed';

// Knowledge content
export const KNOWLEDGE_UPDATE_CONTENT = '[Knowledge] Update content';
export const KNOWLEDGE_DASHBOARD_UPDATE_CONTENT = '[Knowledge] Update dashboard content';
export const KNOWLEDGE_USE_CACHE = '[Knowledge] Use cache';
export const KNOWLEDGE_SELECT_ITEM = '[Knowledge] Select item';
export const KNOWLEDGE_SET_ITEM_DETAILS = '[Knowledge] Set item details';
export const KNOWLEDGE_UPDATE_CATEGORY = '[Knowledge] Update category';
export const KNOWLEDGE_DELETE_CATEGORY = '[Knowledge] Delete category';
export const KNOWLEDGE_CREATE_CATEGORY = '[Knowledge] Create category';
export const KNOWLEDGE_UPDATE_SUBCATEGORIES = '[Knowledge] update subcategories';
export const KNOWLEDGE_CREATE_ITEM = '[Knowledge] Create item';
export const KNOWLEDGE_UPDATE_ITEM = '[Knowledge] Update item';
export const KNOWLEDGE_DELETE_ITEM = '[Knowledge] Delete item';
export const KNOWLEDGE_UPDATE_ITEMS_ORDER = '[Knowledge] Update items order';
export const KNOWLEDGE_UPDATE_ITEM_LOCATION = '[Knowledge] Update item location';
export const KNOWLEDGE_CREATE_LINK = '[Knowledge] Create link';
export const KNOWLEDGE_UPDATE_LINK = '[Knowledge] Update link';
export const KNOWLEDGE_DELETE_LINK = '[Knowledge] Delete link';
export const KNOWLEDGE_MOVE_LINK = '[Knowledge] Move link';
export const KNOWLEDGE_UPDATE_ITEM_CONTENT = '[Knowldege] Update item content';
export const KNOWLEDGE_UPDATE_KEYWORDS = '[Knowledge] Update keywords';

// Device Flags
export const UPDATE_DEVICE_FLAGS = '[Flags] Update flag';

// Search
export const SEARCH_START = '[Search] Change';
export const SEARCH_ON_CHANGE = '[Search] On Change';
export const SEARCH_RESULTS = '[Search] Results update';

// User actions
export const PREFERENCES_UPDATE = '[User] Preferences update';
export const PREFERENCES_READY = '[User] Preferences ready';

// Favorites
export const FAVORITES_INITIAL = '[Favorites] Initial';
export const FAVORITES_TOGGLE_ITEM = '[Favorites] Toggle item';
export const FAVORITES_ADD_ITEM = '[Favorites] Add item';
export const FAVORITES_REMOVE_ITEM = '[Favorites] Remove item';
export const FAVORITES_UPDATE_CONTENT = '[Favorites] Update content';
export const FAVORITES_USE_CACHE = '[Favorites] Use cache';
export const FAVORITES_MARK_FOR_REMOVAL = '[Favorites] Mark for removal';

// Network
export const NETWORK_CONNECTION_CHANGE_ONLINE = '[Network] Connection ONLINE';
export const NETWORK_CONNECTION_CHANGE_OFFLINE = '[Network] Connection OFFLINE';

// FS
export const FS_DOWNLOAD_FILE = '[FS] Download file';
export const FS_REMOVE_FILE = '[FS] Remove file';
export const FS_UPDATE_LOCAL_FILE = '[FS] Update local file';

// Notification
export const NOTIFICATION_CHECK_STATE = '[Notifications] Check state';
export const NOTIFICATION_UPDATE_STATE = '[Notifications] Update state';
export const NOTIFICATION_RESULTS = '[Notifications] Results';
export const NOTIFICATION_OPEN_PROMPTED = '[Notifications] Open prompted';
export const NOTIFICATION_ADD_TAG = '[Notifications] Add tag';

// Organization
export const ORGANIZATION_UPDATE = '[Organization] Update organization';
export const ORGANIZATION_ATTACH_ORG_ID = '[Organization] Attach organization id';
export const ORGANIZATION_SWITCH = '[Organization] Switch account';

// Available organizations
export const AVAILABLE_ORGANIZATIONS_UPDATE = '[Organization] Update available organizations';

// Owners
export const OWNERS_UPDATE = '[Owners] Update owners';
export const OWNERS_DELETE = '[Owners] Delete owner';

// Contacts
export const CONTACTS_UPDATE_LIST = '[Contacts] update contacts list';
export const CONTACTS_USE_CACHE = '[Contacts] Use cache';
export const CONTACTS_CREATE = '[Contacts] Create contact';
export const CONTACTS_UPDATE_CONTACT = '[Contacts] Update contact';
export const CONTACTS_DELETE = '[Contacts] Delete contact';

// Labs
export const LAB_UPDATE_CONTENT = '[LAB] Update content';
export const LAB_USE_CACHE = '[LAB] Use cache';
export const LAB_SELECT_ITEM = '[LAB] Select item';
export const LAB_SET_ITEM_DETAILS = '[LAB] Set item details';
export const LAB_UPDATE_CATEGORY = '[LAB] Update category';
export const LAB_DELETE_CATEGORY = '[LAB] Delete category';
export const LAB_CREATE_CATEGORY = '[LAB] Create category';
export const LAB_UPDATE_SUBCATEGORIES = '[LAB] update subcategories';
export const LAB_CREATE_ITEM = '[LAB] Create item';
export const LAB_UPDATE_ITEM = '[LAB] Update item';
export const LAB_DELETE_ITEM = '[LAB] Delete item';
export const LAB_UPDATE_ITEMS_ORDER = '[LAB] Update items order';

// Admission
export const ADMISSION_UPDATE_CONTENT = '[ADMISSION] Update content';
export const ADMISSION_UPDATE_CATEGORY = '[ADMISSION] Update category';
export const ADMISSION_USE_CACHE = '[ADMISSION] Use cache';
export const ADMISSION_CREATE_CATEGORY = '[ADMISSION] Create category';
export const ADMISSION_DELETE_CATEGORY = '[ADMISSION] Delete category';
export const ADMISSION_CATEGORY_ORDER = '[ADMISSION] Category order update';
export const ADMISSION_SELECT_CHECKLIST = '[ADMISSION] Select checklist';
export const ADMISSION_CHECK_CREATE = '[ADMISSION] Create item';
export const ADMISSION_CHECK_UPDATE = '[ADMISSION] Update item';
export const ADMISSION_CHECK_DELETE = '[ADMISSION] Delete item';
export const ADMISSION_CHECK_ORDER = '[ADMISSION] Change order';
export const ADMISSION_CHECK_DETAILS = '[ADMISSION] Set item details';
export const ADMISSION_CHECKLIST_LOCATION = '[ADMISSION] Update checklist location';
export const ADMISSION_CHECKLIST_UPDATE_COMMENT = '[ADMISSION] Update comment';
export const ADMISSION_CHECKLIST_UPDATE_CHECK = '[ADMISSION] Update check';
export const ADMISSION_CHECKLIST_RESET = '[ADMISSION] Reset checklist';

// Labels
export const LABELS_UPDATE = '[Labels] Update labels';
export const LABELS_DELETE = '[Labels] Delete label';
export const LABELS_CREATE = '[Labels] Create label';
export const LABELS_USE_CACHE = '[Labels] Use cache';

// Users management
export const USERS_MGMT_FETCH = '[Users Mgmt] fetch users';
export const USERS_MGMT_UPDATE_PERMISSIONS = '[Users Mgmt] update permissions';
export const USERS_MGMT_INVITE_USERS = '[Users Mgmt] invite users';
export const USERS_MGMT_CREATE_USER = '[Users Mgmt] create user';
export const USERS_MGMT_UPDATE_USER = '[Users Mgmt] update user';
export const USERS_MGMT_CREATE_USERS_IN_BULK = '[Users Mgmt] create users in bulk';
export const USERS_MGMT_UPDATE_USERS_IN_BULK = '[Users Mgmt] update users in bulk';
export const USERS_MGMT_DELETE_USER = '[Users Mgmt] delete user';
export const USERS_MGMT_FETCH_STATS = '[Users Mgmt] fetch stats';

// Deep linking
export const DPLNK_INIT = '[DPLINK] Init';
export const DPLNK_TRIGGER = '[DPLINK] Trigger';

// Communication center
export const COMMUNICATION_MESSAGE_SEND = '[Communication] message send';
export const COMMUNICATION_UPDATE_CONTENT = '[Communication] Update content';
export const COMMUNICATION_DELETE_ITEM = '[Communication] Delete item';
export const COMMUNICATION_USE_CACHE = '[Communication] Use cache';

// Update Manager
export const UPDATE_MANAGER_MODE_SET = '[Update Manager] Set mode';

// Lang manager
export const LANG_MANAGER_UPDATE_REPO = '[Lang Manager] Update repo';
export const LANG_MANAGER_UPDATE_LANG = '[Lang Manager] Update lang';

// Maintenance Status
export const MAINTENANCE_STATUS_UPDATE = '[Maintenance] Update status';

// General Config and feature flags
export const GENERAL_CONFIG_UPDATE = '[General config] Update configs';
export const FEATURE_FLAGS_UPDATE = '[Feature flags] Update configs';
export const OAUTH_CONFIGURATIONS_UPDATE = '[Oauth Config] Update configs';

// Workspaces
export const WORKSPACES_UPDATE_CONTENT = '[Workspaces] Update content';
export const WORKSPACES_SELECT_WORKSPACE = '[Workspaces] Select workspace';

// AI
export const AI_UPDATE_STATE = '[AI] Update state';
