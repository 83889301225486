import { useSelector } from 'react-redux';
import { isApprovalMarkEnabled } from 'shared/store/selectors/features.selector';
import { getLang } from 'shared/store/selectors/lang.selector';
import styles from './approve-banner.module.scss';
import { ORGANIZATIONS_LOGO_URL, colorPalette } from 'shared/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/pro-solid-svg-icons';
import { useState } from 'react';
import { useVettedOrProvidedLang } from 'shared/hooks/lang.hooks';

export function ApproveBanner() {
  const [isBannerVisible, setBannerVisibility] = useState(true);
  const lang = useSelector(getLang('KNOWLEDGE_BANNER'));
  const isApprovalMark = useSelector(isApprovalMarkEnabled);
  const { name, configuration, logo } = useSelector(state => state.organization);
  const bannerSubtitleLang = useVettedOrProvidedLang(lang.BANNER_SUBTITLE);

  if (!isApprovalMark) return null;

  if (!isBannerVisible) return null;

  const hideBanner = () => {
    setBannerVisibility(false);
  };

  const { approveBanner } = configuration;
  const { colors: orgColors, logoImage } = approveBanner || {
    colors: {
      background: colorPalette.neutral2,
      text: colorPalette.primaryMain9,
      border: colorPalette.neutral6,
      logoBackground: colorPalette.neutral6,
    },
    logoImage: `${ORGANIZATIONS_LOGO_URL}web/${logo}`,
  };

  return (
    <div
      className={styles.root}
      style={{ backgroundColor: orgColors?.background, borderColor: orgColors.border }}
    >
      <div className={styles.textWrapper} style={{ borderColor: orgColors?.border }}>
        <p style={{ color: orgColors?.text }} className={styles.title}>
          {lang.BANNER_TITLE.replace('{ORG_NAME}', name)}
        </p>
        <p style={{ color: orgColors?.text }}>{bannerSubtitleLang.replace('{ORG_NAME}', name)}</p>
      </div>
      <div style={{ backgroundColor: orgColors?.logoBackground }} className={styles.logoWrapper}>
        <img src={logoImage} className={styles.orgLogo} alt="org logo" />
      </div>
      <FontAwesomeIcon
        color={colorPalette.neutral6}
        className={styles.closeIcon}
        icon={faCircleXmark}
        size="lg"
        onClick={hideBanner}
      />
    </div>
  );
}
