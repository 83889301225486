import { INITIAL_STATE } from 'shared/store/actions';
import { initGeneralConfigStatus } from '../actions/general-config.actions';
import { initMaintenanceStatus } from '../actions/maintenance.actions';
import { initFeatureFlagStatus } from '../actions/feature-flag.actions';
import { initOauthConfigStatus } from '../actions/oauth-config.actions';

export const maintenanceFetchOnAppInit =
  ({ dispatch }) =>
  next =>
  async action => {
    next(action);

    if (action.type === INITIAL_STATE) {
      dispatch(initMaintenanceStatus());
    }
  };

export const generalConfigFetchOnAppInit =
  ({ dispatch }) =>
  next =>
  async action => {
    next(action);

    if (action.type === INITIAL_STATE) {
      dispatch(initGeneralConfigStatus());
    }
  };

export const featureFlagsFetchOnAppInit =
  ({ dispatch }) =>
  next =>
  async action => {
    next(action);

    if (action.type === INITIAL_STATE) {
      dispatch(initFeatureFlagStatus());
    }
  };

export const oauthConfigFetchOnAppInit =
  ({ dispatch }) =>
  next =>
  async action => {
    next(action);

    if (action.type === INITIAL_STATE) {
      dispatch(initOauthConfigStatus());
    }
  };

export const remoteConfigMiddleware = [
  maintenanceFetchOnAppInit,
  generalConfigFetchOnAppInit,
  featureFlagsFetchOnAppInit,
  oauthConfigFetchOnAppInit,
];
