import { useSelector } from 'react-redux';
import { OAUTH_PROVIDERS } from 'shared/constants';
import { GOOGLE_LOGO, SOAP_LOGO } from 'src/images/login-providers';
import userService from 'shared/services/user.service';
import { useMemo, useCallback } from 'react';

export function useOauthConfig() {
  const oauthConfig = useSelector(state => state.oauthConfig);

  const OAUTH_GOOGLE_CONFIG = useMemo(() => {
    const googleConfig = oauthConfig?.GOOGLE;
    return !googleConfig?.CLIENT_ID
      ? null
      : {
          url: googleConfig.WEB_OAUTH_URL,
          qs: {
            client_id: googleConfig.CLIENT_ID,
            redirect_uri: googleConfig?.REDIRECT_URI_WEB || googleConfig.REDIRECT_URI,
            scope: googleConfig.WEB_SCOPE,
            response_type: googleConfig.RESPONSE_TYPE,
            access_type: googleConfig.ACCESS_TYPE,
            prompt: googleConfig.PROMPT,
          },
          logo: GOOGLE_LOGO,
        };
  }, [oauthConfig]);

  const OAUTH_SOAP_CONFIG = useMemo(() => {
    const soapConfig = oauthConfig?.SOAP;
    return !soapConfig?.CLIENT_ID
      ? null
      : {
          url: soapConfig.WEB_OAUTH_URL,
          qs: {
            client_id: soapConfig.CLIENT_ID,
            redirect_uri: soapConfig?.REDIRECT_URI_WEB || soapConfig.REDIRECT_URI,
            scope: soapConfig.SCOPE,
            response_type: soapConfig.RESPONSE_TYPE,
          },
          logo: SOAP_LOGO,
        };
  }, [oauthConfig]);

  const providerConfig = useMemo(() => {
    return {
      [OAUTH_PROVIDERS.GOOGLE]: OAUTH_GOOGLE_CONFIG,
      [OAUTH_PROVIDERS.SOAP]: OAUTH_SOAP_CONFIG,
    };
  }, [OAUTH_GOOGLE_CONFIG, OAUTH_SOAP_CONFIG]);

  const getConfigByProvider = useCallback(provider => providerConfig[provider], [providerConfig]);

  const hasSocialLogin = useMemo(
    () => !!OAUTH_GOOGLE_CONFIG || !!OAUTH_SOAP_CONFIG,
    [OAUTH_GOOGLE_CONFIG, OAUTH_SOAP_CONFIG],
  );

  const generateAuthenticationUrl = useCallback(config => {
    const query = new URLSearchParams(config.qs).toString();
    return `${config.url}?${query}`;
  }, []);

  const doSignInWithOauth = useCallback(
    async ({ provider, query }) => {
      const config = providerConfig[provider]?.qs;

      return await userService.oauthLogin({
        provider,
        redirectUri: config.redirect_uri,
        clientId: config.client_id,
        code: query.code,
      });
    },
    [providerConfig],
  );

  const doActivateAccount = useCallback(
    async ({ provider, query }) => {
      const config = providerConfig[provider]?.qs;

      return await userService.activateOauth({
        provider,
        redirectUri: config.redirect_uri,
        clientId: config.client_id,
        code: query.code,
      });
    },
    [providerConfig],
  );

  return {
    providerConfig,
    getConfigByProvider,
    hasSocialLogin,
    generateAuthenticationUrl,
    doSignInWithOauth,
    doActivateAccount,
  };
}
