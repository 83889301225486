import React, { useCallback, useEffect, useState } from 'react';
import update from 'immutability-helper';
import { useFormik } from 'formik';
import { Popup } from 'src/components/common';
import { Button, Loader } from 'src/components/core';
import styles from './knowledge-link-editor.module.scss';
import { KnowledgeWrapperComponent } from '../knowledge-row';
import { Cactus } from 'src/images';
import { Lang } from 'shared/constants';
import { MultipleOwnersSelector } from 'src/components/owners/owners-selector/multiple-owners-selector';
import * as Yup from 'yup';

const formatError = (e, data, lang) => {
  const id = e.split(' ')[1].replace('(', '').replace(')', '');
  const item = data.find(item => item.id === id);

  return lang.ALREADY_BELONGS_ERROR.replace('{title}', item.title);
};

const KNOWLEDGE_LINK_SCHEMA = Yup.object().shape({
  linkOwnerIds: Yup.array()
    .of(Yup.string())
    .min(1, 'Please assign at least one owner')
    .required('Required!'),
});

const KnowledgeLinkEditorComponent = React.memo(function KnowledgeLinkEditorComponent({
  categoryId,
  onFinish,
  createKnowledgeLink,
  updateSearch,
  lang = Lang.KNOWLEDGE_LINK_EDITOR,
  knowledgeItems = [],
  parentOwnerIds = [],
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    return () => {
      updateSearch('');
    };
  }, [updateSearch]);

  useEffect(() => {
    setError(null);
  }, [selectedItems]);

  const onSubmit = useCallback(
    async data => {
      setIsLoading(true);

      try {
        await createKnowledgeLink({
          ...data,
          linkOwnerIds: data.linkOwnerIds,
          categoryId,
          items: selectedItems,
        });
        onFinish();
        setError(null);
      } catch (e) {
        setError(formatError(e.message, selectedItems, lang));
      } finally {
        setIsLoading(false);
      }
    },
    [onFinish, createKnowledgeLink, categoryId, selectedItems, lang],
  );

  const onSearch = useCallback(
    ({ target }) => {
      updateSearch(target.value);
    },
    [updateSearch],
  );

  const selectItem = useCallback(
    item => {
      setSelectedItems(
        update(selectedItems, {
          $push: [item],
        }),
      );
    },
    [selectedItems],
  );

  const removeItem = useCallback(
    item => {
      const index = selectedItems.findIndex(({ id }) => id === item.id);
      setSelectedItems(
        update(selectedItems, {
          $splice: [[index, 1]],
        }),
      );
    },
    [selectedItems],
  );

  const { handleSubmit, setFieldValue, values, errors, isValid } = useFormik({
    validationSchema: KNOWLEDGE_LINK_SCHEMA,
    initialValues: {
      linkOwnerIds: [],
    },
    onSubmit,
  });

  const dismiss = useCallback(() => {
    setSelectedItems([]);
    setError(null);
    updateSearch('');
    onFinish();
  }, [onFinish, updateSearch]);

  const renderSearchList = useCallback(() => {
    if (knowledgeItems.length === 0) return null;
    return (
      <>
        <label>{lang.RESULTS}</label>
        <div className={styles.searchList}>
          {knowledgeItems
            .sort(({ item: a }, { item: b }) => a.title.localeCompare(b.title))
            .filter(({ item }) => !selectedItems.includes(item))
            .map(({ item }) => (
              <KnowledgeWrapperComponent
                key={`${item.id}-link`}
                item={item}
                selectItem={selectItem}
                removeItem={removeItem}
                search
                editMode
                adminMode
                linkedItem
                preventOpen
              />
            ))}
        </div>
      </>
    );
  }, [knowledgeItems, selectedItems, selectItem, removeItem, lang]);

  const renderSelectedItems = useCallback(() => {
    if (selectedItems.length === 0) {
      return (
        <div className={styles.noItemsContainer}>
          <img src={Cactus} className={styles.cactus} alt={lang.NO_ITEMS} />
          <label>{lang.NO_ITEMS}</label>
        </div>
      );
    }
    return (
      <div className={styles.selectList}>
        {selectedItems.map(item => (
          <KnowledgeWrapperComponent
            key={`${item.id}-link`}
            item={item}
            selectItem={selectItem}
            removeItem={removeItem}
            selected
            editMode
            adminMode
            linkedItem
            preventOpen
          />
        ))}
      </div>
    );
  }, [selectedItems, selectItem, removeItem, lang]);

  return (
    <Popup title={lang.CREATE_TITLE} isOpen close={dismiss} className={styles.root}>
      <form onSubmit={handleSubmit}>
        <div className={styles.container}>
          <div className={styles.owner}>
            <label>{lang.OWNER}</label>
            <MultipleOwnersSelector
              value={values.linkOwnerIds}
              error={errors.linkOwnerIds}
              placeholder={lang.OWNER_PLACEHOLDER}
              name="linkOwnerIds"
              onChange={setFieldValue}
              bordered
              borderRadius={4}
              parentOwnerIds={parentOwnerIds}
            />
          </div>
        </div>
        <div className={styles.container}>
          <div className={styles.column}>
            <label>{lang.SEARCH}</label>
            <div className={styles.searchWrapper}>
              <input className={styles.input} onChange={onSearch} />
            </div>

            {renderSearchList()}
          </div>
          <div className={styles.column}>
            <label>{lang.SELECTED}</label>
            {renderSelectedItems()}
            {error && <span>{error}</span>}
          </div>
        </div>
        {!isLoading && (
          <div className={styles.buttons}>
            <Button className={styles.button} onClick={dismiss} size="medium">
              {lang.DISMISS}
            </Button>
            <Button
              className={styles.button}
              size="medium"
              type="submit"
              buttonStyle="primary"
              disabled={!isValid || error || selectedItems.length === 0}
            >
              {lang.SUBMIT}
            </Button>
          </div>
        )}
        {isLoading && <Loader />}
      </form>
    </Popup>
  );
});

export { KnowledgeLinkEditorComponent };
