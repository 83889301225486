import React from 'react';
import { LabBreadcrumbs } from '../../../components/breadcrumbs/LabBreadcrumbs';
import { LabSectionList } from './components';
import styles from './Labs.module.scss';
import { ScreenTitle } from 'src/components/design-system';
import { ViewSelector } from 'src/components/common/view-selector';
import classnames from 'classnames';

const LabsPageComponent = React.memo(
  ({ id, category, children, lastUpdated, isReady, showBreadcrumbs }) => {
    let { title, pathParts = [], fullPath, workspaceId } = category;

    return (
      <div className={classnames([styles.root, 'new-design-system'])}>
        <div className={styles.header}>
          <div className={styles.headerTitle}>
            {showBreadcrumbs && (
              <LabBreadcrumbs
                title={title}
                pathParts={pathParts}
                initialWorkspaceId={workspaceId}
              />
            )}
            <ScreenTitle title={title} />
          </div>
          <ViewSelector />
        </div>
        <LabSectionList
          list={children}
          key={lastUpdated}
          fullPath={fullPath}
          isReady={isReady}
          id={id}
        />
      </div>
    );
  },
);

LabsPageComponent.defaultProps = {
  isReady: false,
  lastUpdated: Date.now(),
  children: null,
  fullPath: '',
  showBreadcrumbs: false,
  category: {},
};

export { LabsPageComponent };
