import _ from 'lodash';
import React, { useCallback } from 'react';
import { Lang } from 'shared/constants';
import { notification } from 'antd';
import { useHistory } from 'react-router';
import { INTERNAL_ROUTING, ROUTES } from 'shared/constants/routes';
import { DeleteUserConfirm } from './delete-user-confirm';
import { TransferOwner } from './transfer-owner';
import { UserList } from 'src/components/user-list';
import { ENTITY, useColumns } from 'src/components/user-list/use-columns.hooks';

const UserManagementPageComponent = React.memo(function UserManagementPageComponent({
  lang,
  users,
  fetchUsers,
  deleteUser: deleteUserAction,
}) {
  const history = useHistory();
  const [deleteUserEntity, setDeleteUserEntity] = React.useState(null);
  const [transferOwnership, setTransferOwnership] = React.useState(null);
  const [api, contextHolder] = notification.useNotification();

  const columns = useColumns({ entity: ENTITY.USER });

  const closeDetails = useCallback(() => {
    history.push(ROUTES.USERS_MANAGEMENT);
  }, [history]);

  const openNotification = useCallback(
    ({ message, type = 'success' }) => {
      api[type]({
        message,
        placement: 'bottomRight',
        duration: 10,
      });
    },
    [api],
  );

  const onDeleteUser = useCallback(user => setDeleteUserEntity(user), []);
  const deleteUser = useCallback(
    async ({ ownerId }) => {
      const res = await deleteUserAction({ id: deleteUserEntity.id, ownerId });

      if (!res?.error) {
        openNotification({ message: lang.NOTIFICATION_DELETE_SUCCESS });
        setDeleteUserEntity(null);
        closeDetails();
      }
    },
    [deleteUserEntity, deleteUserAction, openNotification, lang, closeDetails],
  );

  const cancelDeleteUser = useCallback(() => setDeleteUserEntity(null), []);

  // Transfer ownership
  const cancelTransferOwnership = useCallback(() => setTransferOwnership(null), []);

  const onTransferOwnership = useCallback(
    ({ user, action, dismiss }) => {
      setTransferOwnership({
        user,
        action,
        dismiss: () => {
          dismiss();
          cancelTransferOwnership();
        },
      });
    },
    [cancelTransferOwnership],
  );

  React.useEffect(() => {
    if (_.isNull(users)) fetchUsers();
  }, [users, fetchUsers]);

  return (
    <>
      <UserList
        users={users}
        columns={columns}
        pageTitle={lang.HEADER}
        buttonText={lang.ADD_NEW_USER}
        onDelete={onDeleteUser}
        onTransferOwnership={onTransferOwnership}
        openNotification={openNotification}
        notificationContextHolder={contextHolder}
        baseRoute={ROUTES.USERS_MANAGEMENT}
        detailsRouteQueryParam={INTERNAL_ROUTING.QUERY_PARAMS.USERS}
        multiSelect
      />
      {deleteUserEntity && (
        <DeleteUserConfirm
          user={deleteUserEntity}
          onConfirm={deleteUser}
          onDismiss={cancelDeleteUser}
        />
      )}

      {transferOwnership && (
        <TransferOwner
          onConfirm={transferOwnership.action}
          user={transferOwnership.user}
          onDismiss={transferOwnership.dismiss}
        />
      )}
    </>
  );
});

UserManagementPageComponent.defaultProps = {
  lang: Lang.USERS_MANAGEMENT,
  users: null,
  fetchUsers: () => {},
};

export { UserManagementPageComponent };
