import { useSelector } from 'react-redux';
import { getLang } from 'shared/store/selectors/lang.selector';
import { message } from 'antd';
import React from 'react';

export type CopyToClipboardProps = {
  children: React.ReactNode;
  text: string;
  onCopy?: () => void;
  showCopiedFeedback?: boolean;
};

export function CopyToClipboard({
  children,
  text,
  onCopy = () => {},
  showCopiedFeedback = true,
}: CopyToClipboardProps) {
  const lang = useSelector(getLang('SHARE'));
  const [messageApi, contextHolder] = message.useMessage();

  const copiedFeedback = () => {
    if (showCopiedFeedback) {
      messageApi.success(lang.COPIED);
    }
  };

  const copyText = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
    navigator.clipboard.writeText(text).then(copiedFeedback).then(onCopy);
  };

  return (
    <>
      {contextHolder}
      <span onClick={copyText}>{children}</span>
    </>
  );
}
