import { connect } from 'react-redux';
import { createOwner, updateOwner } from 'shared/store/actions/owners.actions';
import { OwnerEditorComponent } from './owner-editor.component';

const mapStateToProps = ({ lang }) => ({
  lang: lang.OWNER_EDITOR,
});

const mapDispatchToProps = {
  createOwner,
  updateOwner,
};

export const OwnerEditor = connect(mapStateToProps, mapDispatchToProps)(OwnerEditorComponent);
