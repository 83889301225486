import { connect } from 'react-redux';
import { LabSectionComponent } from './lab-section.component';
import { getLabCategoriesById } from 'shared/store/selectors/lab.selector';
import {
  updateLabCategory,
  updateLabCategorySubcategories,
} from 'shared/store/actions/lab.categories.actions';
import { labSelectValues } from 'src/store/actions/labViewer.actions';

const mapStateToProps = ({ lab, application, labViewer, lang }, { section }) => ({
  children: getLabCategoriesById(lab, section.id),
  activeId: labViewer.id || null,
  adminMode: application.adminMode,
  lang: lang.ACTION_BUTTON_TOOLTIPS,
});

const mapDispatchToProps = {
  labSelectValues,
  updateCategory: updateLabCategory,
  updateCategorySubcategories: updateLabCategorySubcategories,
};

export const LabSection = connect(mapStateToProps, mapDispatchToProps)(LabSectionComponent);
