import { connect } from 'react-redux';
import { LabValueEditorComponent } from './lab-value-editor.component';
import { createLabItem, updateLabItem } from 'shared/store/actions/lab.values.actions';

const mapStateToProps = ({ lang }) => ({
  lang: lang.LAB_VALUE_EDITOR,
});

const mapDispatchToState = {
  createLabItem,
  updateLabItem,
};

export const LabValueEditor = connect(mapStateToProps, mapDispatchToState)(LabValueEditorComponent);
