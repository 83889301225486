import styles from './render-section.module.scss';

export function RenderSection({ header, children }) {
  return (
    <div className={styles.section}>
      {header && <h6>{header}</h6>}
      {children}
    </div>
  );
}
