import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * ScrollRestoration
 * @description
 * This component is used to restore the scroll position of the page when the user navigates back.
 * The scroll position is stored in the session storage and is indexed by the main path of the page.
 * @returns null
 */
function ScrollRestoration() {
  const { pathname } = useLocation();
  const mainPath = pathname.split('/').pop();

  useEffect(() => {
    const scrollPosition = sessionStorage.getItem(`scrollPosition-${mainPath}`);

    if (scrollPosition) {
      window.scrollTo(0, parseInt(scrollPosition, 10));
    } else {
      window.scrollTo(0, 0);
    }
  }, [mainPath, pathname]);

  useEffect(() => {
    const handleScroll = () => {
      sessionStorage.setItem(`scrollPosition-${mainPath}`, window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [mainPath]);
  return null;
}

export default ScrollRestoration;
