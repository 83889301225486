import * as React from 'react';

function SvgComponent({ color = '#2c5789', ...props }) {
  return (
    <svg id="delete_section" data-name="delete section" viewBox="0 0 22.125 27.671" {...props}>
      <g id="Group_238" data-name="Group 238" transform="translate(0 0)">
        <path
          id="Path_922"
          data-name="Path 922"
          d="M77.983,16.256a.89.89,0,0,0,.909-.954,4.544,4.544,0,0,0-3.209-4.254,6.212,6.212,0,0,0-1.418-.209c-.472-.025-.937-.019-1.43-.014-.21,0-.421,0-.633,0h-.139v-.5c0-.251,0-.491,0-.73a2.015,2.015,0,0,0-2.057-2.1c-1.359-.008-2.782-.008-4.231,0A2.125,2.125,0,0,0,63.6,9.7c0,.218,0,.436,0,.663v.471H61.409a4.576,4.576,0,0,0-4.64,4.4.883.883,0,0,0,.956,1.027c.161,0,.322,0,.493,0h.391v3.1c0,4.316,0,8.779,0,13.168a3.559,3.559,0,0,0,.062.744A2.388,2.388,0,0,0,61.1,35.164c1.6,0,3.233,0,4.81,0h3.923c1.546,0,3.145,0,4.717,0a2.381,2.381,0,0,0,2.383-1.71,3.732,3.732,0,0,0,.107-.98c.005-4.272,0-8.616,0-12.817v-3.4h.418C77.647,16.26,77.817,16.261,77.983,16.256ZM64.526,9.5a1.155,1.155,0,0,1,1.181-1.081c1.422-.008,2.893-.008,4.372,0A1.171,1.171,0,0,1,71.25,9.444a1.955,1.955,0,0,1,.021.3c0,.219,0,.437,0,.662v.408H64.519v-.139c0-.124,0-.248-.005-.368A7.439,7.439,0,0,1,64.526,9.5ZM76.2,21.119q0,5.734,0,11.468a1.487,1.487,0,0,1-1.668,1.657H61.186a1.469,1.469,0,0,1-1.568-1.167,1.678,1.678,0,0,1-.033-.362q0-7.259,0-14.518v-1.92H76.2ZM57.674,15.331l.008-.147a3.543,3.543,0,0,1,1.27-2.585,3.5,3.5,0,0,1,2.34-.858H63c3.727,0,7.581,0,11.372,0a3.581,3.581,0,0,1,3.575,3.084,1.993,1.993,0,0,1,.019.24c0,.037,0,.076.005.115l.009.148Z"
          transform="translate(-56.767 -7.495)"
          fill={color}
        />
        <path
          id="Path_923"
          data-name="Path 923"
          d="M171.062,228.774V217.546a.439.439,0,0,0-.439-.439h0a.439.439,0,0,0-.439.439v11.228a.441.441,0,0,0,.883,0Z"
          transform="translate(-163.862 -205.433)"
          fill={color}
        />
        <path
          id="Path_924"
          data-name="Path 924"
          d="M248.855,228.754V217.532a.439.439,0,0,0-.439-.439h-.005a.439.439,0,0,0-.439.439v11.222a.442.442,0,0,0,.883,0Z"
          transform="translate(-237.323 -205.419)"
          fill={color}
        />
        <path
          id="Path_925"
          data-name="Path 925"
          d="M326.3,216.972a.439.439,0,0,0-.439.439v11.236a.439.439,0,1,0,.878,0V217.411A.439.439,0,0,0,326.3,216.972Z"
          transform="translate(-310.875 -205.305)"
          fill={color}
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
