import styles from './render-back.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import classNames from 'classnames';

export const RenderBack = ({ close = null, className }) => (
  <div className={classNames(styles.closeButtonWrapper, className)}>
    {close && (
      <button type="button" onClick={close}>
        <FontAwesomeIcon icon={faCircleArrowLeft} className={styles.backArrow} />
      </button>
    )}
  </div>
);
