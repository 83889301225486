import API from './API';
import { ZEUS_SERVICE_URL as servicePath } from '../constants/APIconstants';
import { POST, PUT } from '../utils/Executer';

/**
 * @typedef {{
 * conversationId: string,
 * chat: {
 *  question: object,
 *  answer: object
 * }
 * }} UpsertChatResult
 */

class ZeusServiceAPI {
  /**
   * Send chat message
   * @param {{
   * term: string,
   * conversationId: string,
   * }} param0
   * @returns {Promise<{ success: false } | { success: true, data: UpsertChatResult }>}
   */
  async chatMessage(data) {
    return await API.client.execute(POST, `${servicePath}chat`, data);
  }

  /**
   * Send feedback for chat message
   * @param {string} conversationId
   * @param {string} messageId
   * @param {{
   *  type: 'GOOD' | 'BAD' | 'CANCELED',
   *  badResponseOption?: string,
   *  description?: string,
   * }} data
   * @returns {Promise<{ success: false } | { success: true, data: any }>}
   */
  async feedback(conversationId, messageId, data) {
    return await API.client.execute(
      PUT,
      `${servicePath}chat/${conversationId}/feedback/${messageId}`,
      data,
    );
  }

  /**
   * Edit chat message
   * @param {string} conversationId
   * @param {string} messageId
   * @param {{
   *  term: string
   * }} data
   * @returns {Promise<{ success: false } | { success: true, data: UpsertChatResult }>}
   */
  async editMessage(conversationId, messageId, data) {
    return await API.client.execute(
      PUT,
      `${servicePath}chat/${conversationId}/edit/${messageId}`,
      data,
    );
  }

  async regenerate(conversationId, messageId) {
    return await API.client.execute(
      PUT,
      `${servicePath}chat/${conversationId}/regenerate/${messageId}`,
      {},
    );
  }
}

export default new ZeusServiceAPI();
