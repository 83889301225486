import { connect } from 'react-redux';
import { getLangOptions } from 'shared/store/selectors/lang-manager.selector';
import { setLang } from 'shared/store/actions/lang.actions';
import { LangSelectorComponent } from './lang-selector.component';

const mapStateToProps = ({ lang, langManager }) => ({
  lang: lang.LANG_SELECTOR,
  options: getLangOptions(langManager),
  langTooltip: lang.ACTION_BUTTON_TOOLTIPS,
});

const mapDispatchToProps = {
  setLang,
};

export const LangSelector = connect(mapStateToProps, mapDispatchToProps)(LangSelectorComponent);
