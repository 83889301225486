import * as React from 'react';

function SvgComponent({ color = '#2c5789', ...props }) {
  return (
    <svg data-name="Layer 1" viewBox="0 0 97.98 293.3" {...props}>
      <path
        d="M37.14 50.29A25.76 25.76 0 0063 25.15 25.76 25.76 0 0037.14 0a25.15 25.15 0 000 50.29z"
        fill={color}
      />
      <path d="M63 265.28V81.14H0v28.02h34.98v156.12H0v28.02H97.98v-28.02H63z" fill={color} />
    </svg>
  );
}

export default SvgComponent;
