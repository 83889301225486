import React from 'react';
import classnames from 'classnames';
import styles from './Popup.module.scss';
import { Cross } from '../../../images';

/**
 * @type {React.FC<any>}
 */
const Popup = React.memo(function Popup({
  title,
  isOpen,
  children,
  wrapperClassName,
  close,
  className,
}) {
  if (!isOpen) {
    return false;
  }

  return (
    <div className={classnames(styles.root, wrapperClassName)}>
      <div className={classnames(styles.popup, className)}>
        {close && <img src={Cross} className={styles.close} onClick={close} alt="close" />}
        {title && <h5 className={styles.title}>{title}</h5>}
        <div className={title ? styles.body : null}>{children}</div>
      </div>
    </div>
  );
});

Popup.defaultProps = {
  title: '',
  wrapperClassName: null,
  className: null,
  isOpen: false,
  close: null,
};

export { Popup };
