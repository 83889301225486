import React from 'react';
import { useSelector } from 'react-redux';
import { getLang } from 'shared/store/selectors/lang.selector';
import styles from '../category-box/grid-components.module.scss';
import classnames from 'classnames';
import { IndicationRibbonIcon } from 'src/images';

const STATUS = {
  NEW: {
    style: styles.new,
  },
  UPDATED: {
    style: styles.updated,
  },
};

export function RenderIndicationRibbon({ lastUpdate = 0, status = 'UPDATED' }) {
  const label = useSelector(getLang('ITEM_STATUS'))[status]?.toUpperCase();
  const style = status ? STATUS[status].style : STATUS.UPDATED.style;

  const previousLastUpdated =
    useSelector(({ knowledge }) => knowledge.previousLastUpdated) ?? Date.now();

  if (new Date(lastUpdate).getTime() - new Date(previousLastUpdated).getTime() < 0) return null;

  return (
    <>
      <img src={IndicationRibbonIcon} className={classnames([styles.ribbon, style])} alt={label} />
      <h5 className={styles.text}>{label}</h5>
    </>
  );
}
