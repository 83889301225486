import { INITIAL_STATE } from 'shared/store/actions';
import { fetchLanguageFromRemoteConfig } from '../actions/lang-local.actions';

export const langFetchOnAppInit =
  ({ dispatch }) =>
  next =>
  async action => {
    next(action);

    if (action.type === INITIAL_STATE) {
      dispatch(fetchLanguageFromRemoteConfig());
    }
  };

export const langMiddleware = [langFetchOnAppInit];
