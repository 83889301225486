import React from 'react';
import styles from './binder.module.scss';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { getLang } from 'shared/store/selectors/lang.selector';
import { Cross } from 'src/images';
import { Tooltip } from 'src/components/core';

const Binder = ({ children, close = null }) => {
  const lang = useSelector(getLang('ACTION_BUTTON_TOOLTIPS'));
  const [isOpen] = React.useState(true);
  return (
    <>
      <div className={classnames(styles.root, isOpen ? styles.open : undefined)}>
        {close && (
          <Tooltip title={lang.CLOSE} placement={'bottom'}>
            <div onClick={close} className={styles.close}>
              <img src={Cross} alt="Close" />
            </div>
          </Tooltip>
        )}
        {children}
      </div>
    </>
  );
};

export { Binder };
