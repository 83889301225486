import { connect } from 'react-redux';
import { labSelectValues, labSetItemDetails } from 'src/store/actions/labViewer.actions';
import { LabValueInfoComponent } from './lab-value-info.component';
import { getItemById, getLabParentsByItemId } from 'shared/store/selectors/lab.selector';
import { getOwnersByIDs } from 'shared/store/selectors/owners.selector';

const mapStateToProps = ({ labViewer, lab, owners, lang }) => {
  let { itemId } = labViewer;

  if (!itemId) {
    return { lang: lang.LAB_INFORMATION_MODAL };
  }

  let item = getItemById(lab, { id: itemId });

  const { ownerIds } = item || {};
  const ownersList = getOwnersByIDs(ownerIds)({ owners });
  const parents = getLabParentsByItemId(lab, { id: itemId });

  return {
    id: itemId,
    owners: ownersList,
    item,
    parents,
    lang: lang.LAB_INFORMATION_MODAL,
  };
};

export const LabValueInfo = connect(mapStateToProps, {
  labSetItemDetails,
  labSelectValues,
})(LabValueInfoComponent);
