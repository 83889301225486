import { connect } from 'react-redux';
import { updateChecklistState, updateCheck } from 'shared/store/actions/admission.actions';
import { admissionDetails } from 'src/store/actions/admission-viewer.actions';
import { ChecklistItemComponent } from './checklist-item.component';
import { getCheckedState } from 'shared/store/selectors/admission.selector';

const mapStateToProps = ({ admission, lang }, { categoryId, item }) => {
  let { id } = item;
  return {
    checkedState: getCheckedState(admission, { categoryId, id }) || false,
    lastUpdated: admission.lastUpdated,
    lang: lang.CHECKLIST_ITEM_EDITOR,
    langTooltip: lang.ACTION_BUTTON_TOOLTIPS,
  };
};

const mapDispatchToProps = { admissionDetails, updateChecklistState, updateCheck };

export const ChecklistItem = connect(mapStateToProps, mapDispatchToProps)(ChecklistItemComponent);
