import React from 'react';
import classnames from 'classnames';
import styles from './Button.module.scss';

/**
 * @type {React.FC<any>}
 */
const Button = ({ className, children, buttonStyle, size, ...props }) => {
  let style = classnames(styles.root, styles[buttonStyle], styles[size], className);
  return (
    <button className={style} {...props}>
      {children}
    </button>
  );
};

Button.defaultProps = {
  children: null,
  className: null,
  buttonStyle: '',
  size: 'large',
};

export { Button };
