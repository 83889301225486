import { getContactItemById, getContacts } from 'shared/store/selectors/contact.selector';
import { TeamTabComponent } from './tab-team.component';
import { connect } from 'react-redux';

const mapStateToProps = ({ contacts, usersManagement, lang }) => ({
  userLang: lang.CONTACT_SCREEN,
  contacts: getContacts({ contacts, usersManagement }),
  getContactById: id => getContactItemById({ contacts, usersManagement }, id),
});

export const TeamTab = connect(mapStateToProps)(TeamTabComponent);
