import React from 'react';
import { Input, Loader, FileUpload } from '../../core';

const UploadDocumentComponent = React.memo(function UploadDocumentComponent({
  uploadDocument,
  setFieldValue,
  name,
  wrapperClass,
  ...props
}) {
  const [uploadDocumentInProcess, setUploadDocumentInProcess] = React.useState(false);

  const onFileSelected = async (inputName, file) => {
    setUploadDocumentInProcess(true);

    let res = await uploadDocument(file);

    setUploadDocumentInProcess(false);
    setFieldValue(name, res.url);
  };

  return (
    <>
      {!uploadDocumentInProcess && (
        <>
          <FileUpload
            name="documents"
            onChange={onFileSelected}
            inputStyle="editor"
            wrapperClass={wrapperClass}
          />

          <Input inputStyle="editor" name={name} {...props} />
        </>
      )}

      {uploadDocumentInProcess && <Loader />}
    </>
  );
});

UploadDocumentComponent.defaultProps = {
  uploadDocument: () => {},
  setFieldValue: () => {},
  name: '',
  wrapperClass: null,
};

export { UploadDocumentComponent };
