import React, { useState } from 'react';
import { Popup } from '../../common';
import { getLang } from 'shared/store/selectors/lang.selector';
import { ProgressBar } from './progress-bar/progress-bar.component';
import { FailUpload, DuplicatedFile, UploadFile } from './upload-file-popup';
import { useSelector } from 'src/store/use-selector';
import { MOV_FILE_EXTENSION } from 'shared/constants/assets.constants';
import { useUploadLogic } from './hooks/upload.hooks';
import { useFileValidation } from './hooks/validation.hooks';
import { UploadFileBinderComponent } from './upload-file-binder/upload-file-binder.component';
import { FileRejection } from 'react-dropzone';

type EditContentFn = (arg: {
  id: string;
  categoryId: string;
  ownerIds: string[];
  title: string;
  content: unknown;
  link: string;
  type: string;
  fileName: string;
}) => void;

function KnowledgeUploadFileComponent({
  close = () => {},
  editContent = () => {},
  editExternal = () => {},
  notificationApi,
  convertPastItemToSmartFormat = false,
  ...props
}: {
  id: string;
  title: string;
  close: () => void;
  editContent: EditContentFn;
  editExternal: (arg: {
    id: string;
    categoryId: string;
    ownerIds: string[];
    title: string;
    link: string;
    type: string;
    fileName: string;
  }) => void;
  notificationApi: {
    success: (args: { message: string; placement: string }) => void;
    error: (args: { message: string; placement: string }) => void;
  };
  convertPastItemToSmartFormat: boolean;
  categoryId: string;
  ownerIds: string[];
}) {
  const lang = useSelector(getLang('KNOWLEDGE_UPLOAD_POPUP'));
  const [bulkUploadFiles, setBulkUploadFiles] = useState(
    [] as {
      name: string;
      error?: string;
    }[],
  );
  const isBulkUpload = bulkUploadFiles.length > 0;

  const {
    loading,
    failed,
    duplication,
    file,
    asset,
    progress,
    status,
    isSmartFormat,
    toggleSmartFormat,
    uploadFile,
    convertToSmartFormat,
    cancelOnDuplication,
    cancelProgress,
    retryUpload,
    uploadFileHandler,
  } = useUploadLogic({
    convertPastItemToSmartFormat,
    editContent,
    editExternal,
    close,
    props,
  });

  const onSubmit = async () => {
    if (isSmartFormat) {
      convertToSmartFormat();
    } else {
      uploadFile();
    }
  };

  const handleBulkUpload = (files: File[], fileRejections: FileRejection[]) => {
    const filesWithErrors = fileRejections.map(({ file, errors }) => {
      const [error] = errors.map(error => error.code);

      return {
        name: file.name,
        error,
      };
    });

    const filesWithoutErrors = files.filter(f => !filesWithErrors.find(el => el.name === f.name));

    setBulkUploadFiles([...filesWithoutErrors, ...filesWithErrors]);
  };

  const { fileValidationError, fileExtension } = useFileValidation({ file, isSmartFormat });
  const isMov = fileExtension === MOV_FILE_EXTENSION;

  return (
    <>
      <Popup isOpen={!isBulkUpload}>
        {(() => {
          const error = failed ? 'upload-failed' : fileValidationError;

          if (error) {
            return (
              <FailUpload
                error={error as any}
                onCancel={close}
                onRetry={retryUpload}
                disableRetry={convertPastItemToSmartFormat}
              />
            );
          }

          // On duplicate recognized - show the duplicate knowledge item and allow to link or to continue
          if (duplication) {
            return (
              <DuplicatedFile
                duplicatedItemId={duplication?.knowledgeId}
                assetId={asset?.assetId}
                onCancel={cancelOnDuplication}
                onClose={close}
                notificationApi={notificationApi}
                props={props}
              />
            );
          }

          // Loading phase
          if (loading) {
            return (
              <ProgressBar
                fileName={file?.name}
                status={status}
                progress={progress}
                onCancel={cancelProgress}
                buttonLabel={lang.DISMISS}
                title={lang.TITLE}
                isDirectConversion={convertPastItemToSmartFormat}
              />
            );
          }

          /*
          This is so you don't get the FileUpload input flash before the useEffect has run,
          when you are just converting a previously uploaded file.
        */
          if (convertPastItemToSmartFormat) {
            return <></>;
          }

          // Initial phase
          return (
            <UploadFile
              isMov={isMov}
              smartFormat={isSmartFormat}
              toggleSmartFormat={toggleSmartFormat}
              onUploadFile={uploadFileHandler}
              onSubmit={onSubmit}
              onCancel={close}
              disabled={!!(!file || fileValidationError)}
              handleBulkUpload={handleBulkUpload}
            />
          );
        })()}
      </Popup>
      {isBulkUpload && (
        <UploadFileBinderComponent files={bulkUploadFiles} onClose={close} props={props} />
      )}
    </>
  );
}

export { KnowledgeUploadFileComponent };
