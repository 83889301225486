import { connect } from 'react-redux';
import { deleteCommunicationItem } from 'shared/store/actions/communication-center.actions';
import { NewsFeedDeleteComponent } from './news-feed-delete.component';

const mapStateToProps = ({ lang }) => ({
  lang: lang.NEWS_FEED_DELETION,
});

export const NewsFeedDelete = connect(mapStateToProps, { removeItem: deleteCommunicationItem })(
  NewsFeedDeleteComponent,
);
