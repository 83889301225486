import { connect } from 'react-redux';
import { LabsPageComponent } from './Labs.page.component';
import {
  getLabRoot,
  getLabCategoryById,
  getLabCategoriesById,
} from 'shared/store/selectors/lab.selector';

const mapStateToProps = ({ lang, lab }, { match }) => {
  let category;
  let { id } = match.params;
  let isRoot = false;

  if (id) {
    category = id ? getLabCategoryById(lab, { id }) : {};
  } else {
    category = getLabRoot(lab);
    id = category.id;
    isRoot = true;
  }

  return {
    id,
    category,
    showBreadcrumbs: !isRoot,
    children: getLabCategoriesById(lab, id) || [],
    lastUpdated: lab.lastUpdated,
    isReady: lab.isReady,
  };
};

export const LabsPage = connect(mapStateToProps, {})(LabsPageComponent);
