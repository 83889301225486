import React from 'react';
import styles from './DatePicker.module.scss';
import { DatePicker as AntdDatePicker } from 'antd';
import classnames from 'classnames';
import { DateTime } from 'luxon';
import luxonGenerateConfig from 'rc-picker/lib/generate/luxon';
import { isFutureDate } from 'shared/utils/date.utils';

/*
  We use luxon instead of the default antd date library (dayjs)
  To do so we created a custom DatePicker component
  https://ant.design/docs/react/use-custom-date-library#datepicker
*/
const CustomDatePicker = AntdDatePicker.generatePicker(luxonGenerateConfig);

const dateFormat = 'MMM D, YYYY';

const DatePicker = React.memo(function DatePicker({
  value,
  name,
  error,
  touched,
  wrapperClass,
  inputStyle,
  className,
  onChange,
  size,
  preventFutureDate,
  ...props
}) {
  const onChangeLocal = date => {
    onChange(name, date);
  };

  return (
    <div className={classnames(styles.root, wrapperClass)}>
      <CustomDatePicker
        className={classnames(styles.input, styles[inputStyle], className)}
        name={name}
        allowClear={false}
        disabledDate={preventFutureDate ? isFutureDate : null}
        format={dateFormat}
        value={value ? DateTime.fromISO(value) : ''}
        onChange={onChangeLocal}
        {...props}
      />

      {error && <span className={styles.error}>{error}</span>}
    </div>
  );
});

DatePicker.defaultProps = {
  error: null,
  wrapperClass: null,
  className: null,
  size: 'medium',
  inputStyle: 'user',
  preventFutureDate: false,
};

export { DatePicker };
