import React, { useCallback } from 'react';
import analyticsService from '../../../helpers/analytics.service';
import { DownloadDocIcon } from '../../../images';
import { Button } from '../../core';
import { EVENTS } from 'shared/constants/analytics';
import styles from './document-download.module.scss';

const DocumentDownload = React.memo(({ knowledge, lang }) => {
  const { id, title, link, type, ownerIds } = knowledge;
  const extension = link.split('.')[link.split('.').length - 1];

  React.useEffect(() => {
    analyticsService.track(EVENTS.KNOWLEDGE_ITEMS.DOWNLOAD_FILE_IMPRESSION, {
      id,
      title,
      ownerIds,
      type,
      link,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDownload = useCallback(() => {
    analyticsService.track(EVENTS.KNOWLEDGE_ITEMS.DOWNLOAD_FILE, {
      id,
      title,
      ownerIds,
      type,
      link,
    });

    window.open(link);
  }, [id, link, ownerIds, title, type]);

  return (
    <div className={styles.box}>
      <img src={DownloadDocIcon} alt={'download'} className={styles.icon} />
      <h2 className={styles.text}>{lang.COMING_SOON}</h2>
      <Button
        size="small"
        buttonStyle="primary-overlay"
        className={styles.button}
        onClick={onDownload}
      >
        {`${lang.DOWNLOAD} ${title}.${extension}`}
      </Button>
    </div>
  );
});

DocumentDownload.defaultProps = {
  knowledge: {},
};

export { DocumentDownload };
