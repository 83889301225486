import * as React from 'react';

function SvgComponent({ color = '#2c5789', ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" {...props}>
      <g id="surface47021875">
        <path
          stroke="none"
          fillRule="nonzero"
          fill={color}
          fillOpacity="1"
          d="M 22.113281 41.886719 C 23.15625 42.925781 24.84375 42.925781 25.886719 41.886719 L 41.886719 25.886719 C 42.894531 24.839844 42.882812 23.175781 41.851562 22.148438 C 40.824219 21.117188 39.160156 21.105469 38.113281 22.113281 L 22.113281 38.113281 C 21.074219 39.15625 21.074219 40.84375 22.113281 41.886719 Z M 22.113281 41.886719 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={color}
          fillOpacity="1"
          d="M 32.78125 42.382812 L 24.140625 51.023438 C 21.019531 54.011719 16.097656 54.011719 12.976562 51.023438 C 9.894531 47.945312 9.894531 42.949219 12.976562 39.871094 L 21.617188 31.226562 C 22.625 30.179688 22.613281 28.515625 21.582031 27.488281 C 20.554688 26.460938 18.890625 26.445312 17.84375 27.457031 L 9.207031 36.097656 C 4.039062 41.265625 4.039062 49.640625 9.207031 54.804688 C 14.371094 59.96875 22.746094 59.96875 27.910156 54.800781 L 36.550781 46.160156 C 37.59375 45.117188 37.597656 43.429688 36.554688 42.386719 C 35.515625 41.34375 33.824219 41.339844 32.78125 42.382812 Z M 32.78125 42.382812 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={color}
          fillOpacity="1"
          d="M 54.785156 9.207031 C 52.304688 6.726562 48.941406 5.332031 45.4375 5.332031 C 41.929688 5.332031 38.566406 6.726562 36.089844 9.207031 L 27.449219 17.847656 C 26.4375 18.894531 26.453125 20.558594 27.480469 21.585938 C 28.507812 22.613281 30.171875 22.628906 31.21875 21.617188 L 39.859375 12.976562 C 42.980469 9.988281 47.902344 9.988281 51.023438 12.976562 C 54.105469 16.054688 54.105469 21.050781 51.023438 24.128906 L 42.382812 32.773438 C 41.691406 33.441406 41.414062 34.433594 41.65625 35.367188 C 41.902344 36.300781 42.628906 37.027344 43.5625 37.269531 C 44.492188 37.515625 45.484375 37.238281 46.15625 36.542969 L 54.792969 27.902344 C 57.273438 25.421875 58.664062 22.058594 58.664062 18.550781 C 58.660156 15.042969 57.265625 11.683594 54.785156 9.207031 Z M 54.785156 9.207031 "
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
