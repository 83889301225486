import React, { useMemo } from 'react';
import styles from './rotation-content.module.scss';
import { ConfigProvider, Divider, Tabs } from 'antd';
import { useRotationsContext } from '../rotations-context';
import { KnowledgeTab } from './tab-resources-list.component';
import RotationOwners from './rotation-owners.component';
import RotationName from './rotation-name.component';
import { Loader } from 'src/components/core';
import { WelcomeLetterTab } from './components/tab-welcome-letter/tab-welcome-letter';
import { TeamTab } from './components';
import analyticsService from 'src/helpers/analytics.service';
import { EVENTS } from 'shared/constants';

const RotationContent = () => {
  const { loadingRotation } = useRotationsContext();

  if (loadingRotation) {
    return (
      <div className={styles.loading}>
        <Loader />
      </div>
    );
  }

  return (
    <div className={styles.rotationView}>
      <RotationName />
      <RotationOwners />
      <ContentTabs />
    </div>
  );
};

const ContentTabs = () => {
  const { lang } = useRotationsContext();
  const tabs = useMemo(
    () => [
      {
        key: lang.RESOURCES,
        label: lang.RESOURCES,
        children: <KnowledgeTab />,
      },
      {
        key: lang.TEAM,
        label: lang.TEAM,
        children: <TeamTab />,
      },
      {
        key: lang.INFO,
        label: lang.INFO,
        children: <WelcomeLetterTab />,
      },
    ],
    [lang],
  );

  const onChangeTab = key => {
    analyticsService.track(EVENTS.ROTATIONS.TAB_SELECTED, { tab: key });
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Tabs: {
            colorBorderSecondary: '#D9D9D9',
            titleFontSize: '16px',
            horizontalMargin: 0,
          },
        },
      }}
    >
      <Divider style={{ marginBottom: 0, marginTop: '18px' }} />
      <Tabs defaultActiveKey="1" items={tabs} onTabClick={onChangeTab} />
    </ConfigProvider>
  );
};

export { RotationContent };
