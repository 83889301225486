import React, { useCallback, useState } from 'react';
import { ConfirmationPopupComponent } from 'src/components/common/confirmation-popup';

const KnowledgeLinkDeletePopupComponent = ({
  entity,
  onDismiss,
  deleteKnowledgeLink,
  message,
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const messageText = message.replace('{name}', entity.title);
  const deleteLink = useCallback(
    async e => {
      e.stopPropagation();
      setIsLoading(true);

      await deleteKnowledgeLink(entity);

      setIsLoading(false);
      onDismiss();
    },
    [deleteKnowledgeLink, entity, onDismiss],
  );

  const dismiss = useCallback(
    e => {
      e.stopPropagation();
      onDismiss();
    },
    [onDismiss],
  );

  return (
    <ConfirmationPopupComponent
      onConfirm={deleteLink}
      onDismiss={dismiss}
      message={messageText}
      isLoading={isLoading}
      {...props}
    />
  );
};

export { KnowledgeLinkDeletePopupComponent };
