import React from 'react';
import { Lang } from 'shared/constants';
import { Popup } from '../../common';
import { Button, Loader } from '../../core';
import styles from './news-feed-delete.module.scss';

const NewsFeedDeleteComponent = React.memo(({ lang, removeItem, id, onFinish }) => {
  const [isLoading, setLoadingState] = React.useState(false);

  const deleteAction = React.useCallback(async () => {
    setLoadingState(true);

    // TODO: analytics
    await removeItem({ id });

    onFinish();
    setLoadingState(false);
  }, [removeItem, id, onFinish]);

  const dismiss = React.useCallback(() => {
    // TODO: analytics

    onFinish();
  }, [onFinish]);

  if (!id) return null;

  return (
    <Popup title={lang.TITLE} isOpen={true}>
      <p className={styles.content}>{lang.CONTENT}</p>

      {!isLoading && (
        <div className={styles.buttons}>
          <Button
            className={styles.button}
            size="medium"
            onClick={deleteAction}
            buttonStyle="danger"
          >
            {lang.CONFIRM}
          </Button>
          <Button className={styles.button} onClick={dismiss} size="medium">
            {lang.DISMISS}
          </Button>
        </div>
      )}
      {isLoading && <Loader />}
    </Popup>
  );
});

NewsFeedDeleteComponent.defaultProps = {
  lang: Lang.NEWS_FEED_DELETION,
  id: null,
  deleteKnowledgeItem: () => {},
  onFinish: () => {},
};

export { NewsFeedDeleteComponent };
