import React from 'react';
import { Loader } from 'src/components/core';
import styles from '../knowledge.module.scss';
import _ from 'lodash';
import { KnowledgeSection } from './knowledge-section';
import { ROUTES, CATEGORY_TYPES } from 'shared/constants';
import { KnowledgeCategoryDeletePopup } from '../knowledge-category/delete-popup/knowledge-category-delete-popup.component';
import { KnowledgeCategoryEditorPopup } from '../knowledge-category/editor-popup/knowledge-category-editor-popup';
import update from 'immutability-helper';
import { useHistory } from 'react-router';
import { scrollTo } from 'src/utils';
import { ActionButton, NotFound } from 'src/components/common';
import { AddSectionButton } from 'src/components/common/add-section';

const KnowledgeSectionListComponent = React.memo(function KnowledgeSectionListComponent({
  adminMode,
  list,
  isReady,
  fullPath,
  ownerIds,
  deleteCategory,
  updateCategorySubcategories,
  knowledgeSelectItem,
  id,
  tags,
  shared,
}) {
  const [categoryEntity, setCategory] = React.useState(null);
  const [deleteConfirmation, setDeleteConfirmation] = React.useState(null);
  const [sections, setSections] = React.useState(list);

  React.useEffect(() => {
    setSections(list);
  }, [list]);

  let history = useHistory();

  const changeSectionOrder = (id, atIndex) => {
    const { section, index } = findSection(id);
    setSections(
      update(sections, {
        $splice: [
          [index, 1],
          [atIndex, 0, section],
        ],
      }),
    );
    // Update the server with the new index
    finishDragSection(id, atIndex);
  };

  const findSection = id => {
    const section = sections.find(i => i.id === id);
    return {
      section,
      index: sections.indexOf(section),
    };
  };

  const finishDragSection = (categoryId, index) => {
    setTimeout(() => scrollTo(categoryId), 300);
    updateCategorySubcategories({
      id,
      categoryId,
      index,
    });
  };

  const createCategoryAction = React.useCallback(
    ({ path, ownerIds, isSectionCreation = false, tags, labels, shared }) => {
      // TODO: add analytics

      setCategory({ path, ownerIds, isSectionCreation, tags, labels, shared });
    },
    [],
  );

  const editCategoryAction = React.useCallback(
    ({
      id,
      title,
      subtitle,
      ownerIds,
      path,
      isSectionCreation = false,
      tags,
      type,
      options,
      labels,
      shared,
    }) => {
      // TODO: add analytics

      setCategory({
        id,
        title,
        subtitle,
        ownerIds,
        path,
        isSectionCreation,
        tags,
        type,
        options,
        labels,
        shared,
      });
    },
    [],
  );

  const deleteCategoryAction = React.useCallback(({ id, title }) => {
    // TODO: add analytics

    setDeleteConfirmation({ id, title });
  }, []);

  const createSection = () => {
    // TODO: analytics
    createCategoryAction({
      path: fullPath,
      isSectionCreation: true,
      ownerIds,
      tags,
      shared,
    });
  };

  const deleteFinish = React.useCallback(() => setDeleteConfirmation(null), []);

  const editorFinish = React.useCallback(
    ({ id, type, isSectionCreation = false }) => {
      setCategory(null);
      if (id && !isSectionCreation) {
        if (type === CATEGORY_TYPES.KNOWLEDGE) {
          knowledgeSelectItem(id);
          return;
        }

        history.push(ROUTES.CONTENT_ITEM.replace(':id', id));
      }
    },
    [history, knowledgeSelectItem],
  );

  if (_.isNull(sections) || !isReady) {
    return (
      <div className={styles.loader}>
        <Loader />
      </div>
    );
  }

  if (sections.length === 0 && !adminMode) {
    return <NotFound />;
  }

  const callToAction = sections.length === 0 && adminMode && !categoryEntity;

  return (
    <div className={styles.root}>
      {sections.map((item, index) => (
        <KnowledgeSection
          createCategory={createCategoryAction}
          deleteCategory={deleteCategoryAction}
          editCategory={editCategoryAction}
          key={`content-item-${item.id}`}
          firstItem={index === 0}
          lastItem={index === sections.length - 1}
          index={index}
          changeSectionOrder={changeSectionOrder}
          {...item}
        />
      ))}

      {callToAction && <AddSectionButton onClick={createSection} />}
      {adminMode && !callToAction && <ActionButton onClick={createSection} />}

      {categoryEntity && (
        <KnowledgeCategoryEditorPopup onFinish={editorFinish} entity={categoryEntity} />
      )}

      <KnowledgeCategoryDeletePopup
        entity={deleteConfirmation}
        deleteCategory={deleteCategory}
        onFinish={deleteFinish}
      />
    </div>
  );
});

KnowledgeSectionListComponent.defaultProps = {
  list: null,
  isReady: true,
  fullPath: null,
  updateCategorySubcategories: () => {},
  knowledgeSelectItem: () => {},
  id: null,
  ownerIds: [],
};

export { KnowledgeSectionListComponent };
