import * as React from 'react';

function SvgComponent({ color = '#2c5789', ...props }) {
  return (
    <svg viewBox="0 0 80 80" {...props}>
      <path
        fill={color}
        d="M5 8c-1.9 1.9-2 3.3-2 32s.1 30.1 2 32c1.9 1.9 3.3 2 35 2s33.1-.1 35-2c1.9-1.9 2-3.3 2-28.9 0-23.2-.2-27.2-1.6-28.5-1.3-1.4-4.7-1.6-22.2-1.6H32.6l-3.2-3.5L26.2 6h-9.6C8.3 6 6.7 6.3 5 8zm23 5l2.1 3 21.7.2 21.7.3.3 2.7.3 2.8H6v-5.3c0-3 .3-5.7.7-6 .3-.4 4.8-.7 9.9-.7 9.1 0 9.3 0 11.4 3zm45.5 35v21.5h-67L6.2 49c-.1-11.2 0-21 .3-21.7.3-1.1 7.3-1.3 33.7-1.1l33.3.3V48z"
      />
      <path
        fill={color}
        d="M34 33.1c0 .7.8 2.1 1.7 3.1 1.6 1.7 1.5 2-1.4 5.1-4.1 4.3-5.3 6.9-5.3 11.4 0 7 6.8 14.3 13.3 14.3 3.5 0 3.6-2.6.2-3.4-5.6-1.4-4.9-9.8 1-14.5l2.8-2.1 2.4 2.2c3.5 3.3 4.4 1.4 4.1-8.3l-.3-8.4-9.2-.3c-6.8-.2-9.3.1-9.3.9zm15.8 7.1c.3 4.7-.4 5.8-2.4 3.8-1.5-1.5-8.8 4.3-10.8 8.4-.9 1.9-1.6 4.5-1.6 5.8l-.1 2.3-1.4-2.5c-3.1-5.4-1.2-11.7 5-16.5 2.6-1.9 3.4-3 2.6-3.8-2.1-2.1-1.1-2.8 3.7-2.5l4.7.3.3 4.7z"
      />
    </svg>
  );
}

export default SvgComponent;
