export function getLabelByCode({ labels }, code) {
  if (!labels?.list) return null;

  return labels?.list[code];
}

export function getLabelsListByCode({ labels }, list = []) {
  if (!labels?.list || list.length === 0) return [];
  return list.reduce((acc, code) => {
    const label = labels?.list[code];
    if (label) {
      return [...acc, label];
    }
    return acc;
  }, []);
}

export function getLabelsList({ labels }) {
  if (!labels?.list) return null;

  return Object.values(labels?.list);
}
