import React, { useCallback } from 'react';
import rotationsServiceApi from 'shared/api/rotations.service.api';
import styles from './rotation-content.module.scss';
import { notification } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/pro-solid-svg-icons';
import { Button } from 'src/components/design-system';
import { TextInput } from 'src/components/design-system/forms';
import * as Yup from 'yup';
import { useRotationsContext } from '../rotations-context';

const name = Yup.string().min(2).required('Required!');

const RotationName = () => {
  const {
    lang,
    updateRotationName,
    selectedRotation: rotation,
    canEdit,
    loadingRotation,
  } = useRotationsContext();
  const [editing, setEditing] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [newName, setNewName] = React.useState(rotation?.name || '');
  const [api, contextHolder] = notification.useNotification();

  const handleValidateName = useCallback(async e => {
    setError(null);
    setNewName(e.target.value);

    try {
      await name.validate(e.target.value);
    } catch (e) {
      setError(e.message);
    }
  }, []);

  const showToast = useCallback(
    message => {
      api.error({
        message: message || lang.ERROR_OCCURRED,
        placement: 'bottomRight',
      });
    },
    [api, lang.ERROR_OCCURRED],
  );

  const handleCancel = useCallback(() => {
    setEditing(false);
    setNewName(rotation?.name || '');
  }, [rotation]);

  const handleNameChange = useCallback(async () => {
    try {
      const res = await rotationsServiceApi.updateRotation({
        rotationId: rotation.id,
        payload: { name: newName },
      });

      if (res.error) {
        showToast(res.data.message);
      } else {
        updateRotationName(rotation.id, newName);
      }
    } catch (e) {
      showToast();
    } finally {
      setEditing(false);
    }
  }, [newName, rotation, showToast, updateRotationName]);

  if (loadingRotation) {
    return null;
  }

  return editing ? (
    <div className={styles.editName}>
      {contextHolder}
      <label className={styles.editNameLabel}>{lang.ROTATION_NAME}</label>
      <TextInput
        value={newName}
        onChange={handleValidateName}
        size="large"
        style={{ maxWidth: '70%' }}
        error={error}
      />
      <div className={styles.actionButtons}>
        <Button type="primary" onClick={handleNameChange} disabled={!!error}>
          {lang.SUBMIT}
        </Button>
        <Button onClick={handleCancel}>{lang.CANCEL}</Button>
      </div>
    </div>
  ) : (
    <div className={styles.title}>
      <h1 className={styles.titleText}>{rotation?.name}</h1>

      {canEdit && (
        <FontAwesomeIcon
          icon={faPencil}
          className={styles.titleIcon}
          onClick={() => setEditing(true)}
        />
      )}
    </div>
  );
};

export default RotationName;
