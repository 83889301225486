import React from 'react';
import { Tooltip } from 'antd';
import styles from './DrugClassIcon.module.scss';
import { theme } from 'shared/constants/theme';
import { getLang } from 'shared/store/selectors/lang.selector';
import { useSelector } from 'react-redux';
import { getDrugClassIcon } from '../../../constants/drugClassIconsMapper';

const DrugClassIcon = React.memo(function Icon({ drugClass }) {
  const DrugClass = getDrugClassIcon(drugClass);

  const label = useSelector(getLang('DRUG_CLASSES'))[drugClass];

  return (
    <>
      <Tooltip title={label} color={theme.tooltipColor} key={theme.tooltipColor}>
        <img src={DrugClass} alt={label} className={styles.drugClassIcon} />
      </Tooltip>
    </>
  );
});

export { DrugClassIcon };
