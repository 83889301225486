import React from 'react';
import { useHistory } from 'react-router';
import styles from './suggest-knowledge-widget.module.scss';
import { ROUTES } from 'shared/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleArrowRight } from '@fortawesome/pro-solid-svg-icons';

const SuggestKnowledgeItemWidgetComponent = ({ lang }) => {
  const history = useHistory();

  const goToSuggest = () => history.push(ROUTES.SUGGEST_CONTENT);

  return (
    <div className={styles.root}>
      <h2 className={styles.title}>{lang.TITLE}</h2>
      <div className={styles.container}>
        <p className={styles.description}>{lang.DESCRIPTION}</p>
        <hr />
        <div className={styles.button} onClick={goToSuggest}>
          <p className={styles.buttonLabel}>{lang.BUTTON}</p>
          <FontAwesomeIcon icon={faCircleArrowRight} className={styles.icon} />
        </div>
      </div>
    </div>
  );
};

export { SuggestKnowledgeItemWidgetComponent };
