import React from 'react';
import classnames from 'classnames';
import styles from './knowledge-hub.module.scss';
import { KnowledgeHubProvider } from './hooks/use-knowledge-hub.hooks';
import { RenderOrganizationList } from './components/render-organization-list';
import { RenderWelcomeMessage } from './components/render-welcome-message';
import { RenderContent } from './components/render-content';
import { RenderHeader } from './components/render-header';
import analyticsService from 'src/helpers/analytics.service';
import { EVENTS } from 'shared/constants';

export function KnowledgeHubPage() {
  const analyticsTriggers = {
    onPageLoad: payload => analyticsService.track(EVENTS.KNOWLEDGE_HUB.OPEN, payload),
    onSelectOrganization: payload =>
      analyticsService.track(EVENTS.KNOWLEDGE_HUB.SELECT_ORGANIZATION, payload),
  };

  return (
    <KnowledgeHubProvider analyticsTriggers={analyticsTriggers}>
      <div className={classnames([styles.root, 'new-design-system'])}>
        <RenderHeader />
        <RenderWelcomeMessage />
        <RenderOrganizationList />
        <RenderContent />
      </div>
    </KnowledgeHubProvider>
  );
}
