import React from 'react';
import { SelectInput } from '../core';
import styles from './tagSelector.module.scss';
import { normalizedContains } from '../../utils';

const TagSelectorComponent = React.memo(function TagSelectorComponent({
  value,
  options,
  onChange,
  name = 'tag',
  placeholder,
  error = null,
  disabled = false,
  multiple = false,
  wrapperClass = null,
  ...props
}) {
  return (
    <SelectInput
      mode={multiple && 'multiple'}
      value={value ? value : null}
      options={options}
      error={error}
      placeholder={placeholder}
      name={name}
      onChange={onChange}
      wrapperClass={wrapperClass ?? styles.selector}
      allowClear
      disabled={disabled}
      filterOption={(inputValue, option) => normalizedContains(option?.children, inputValue)}
      {...props}
    />
  );
});

export { TagSelectorComponent };
