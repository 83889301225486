import React, { useCallback, useState } from 'react';
import { useFormik } from 'formik';
import { Popup } from 'src/components/common';
import { Button, Loader } from 'src/components/core';
import { updateKnowledgeLink } from 'shared/store/actions/knowledge.items.actions';
import styles from './knowledge-link-editor.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getLang } from 'shared/store/selectors/lang.selector';
import { MultipleOwnersSelector } from 'src/components/owners/owners-selector/multiple-owners-selector';

const KnowledgeLinkUpdatePopup = ({ entity, linkOwnerIds, onDismiss }) => {
  const [isLoading, setIsLoading] = useState(false);
  const lang = useSelector(getLang('KNOWLEDGE_LINK_EDITOR'));
  const dispatch = useDispatch();

  const onSubmit = useCallback(
    async ({ linkOwnerIds }) => {
      setIsLoading(true);

      await dispatch(updateKnowledgeLink({ ...entity, linkOwnerIds }));

      setIsLoading(false);
      onDismiss();
    },
    [onDismiss, entity, dispatch],
  );
  const { handleSubmit, setFieldValue, values, errors } = useFormik({
    initialValues: {
      linkOwnerIds,
    },
    onSubmit,
  });

  return (
    <Popup title={lang.UPDATE_TITLE} isOpen close={onDismiss}>
      <form onSubmit={handleSubmit}>
        <label>{lang.OWNER}</label>
        <MultipleOwnersSelector
          value={values.linkOwnerIds}
          error={errors.linkOwnerIds}
          placeholder={lang.OWNER_PLACEHOLDER}
          name="linkOwnerIds"
          onChange={setFieldValue}
          bordered
          borderRadius={4}
        />
        {!isLoading && (
          <div className={styles.buttons}>
            <Button className={styles.button} onClick={onDismiss} size="medium">
              {lang.DISMISS}
            </Button>
            <Button className={styles.button} size="medium" type="submit" buttonStyle="primary">
              {lang.UPDATE}
            </Button>
          </div>
        )}
        {isLoading && <Loader />}
      </form>
    </Popup>
  );
};

export { KnowledgeLinkUpdatePopup };
