import { Switch } from 'antd';
import { RenderSection } from '../../components';
import styles from './render-notify-team.module.scss';
import { RecipientsSelector } from 'src/components/communication';
import { useSelector } from 'react-redux';
import { getLang } from 'shared/store/selectors/lang.selector';
import { Select } from 'src/components/design-system/forms/select.component';
import React from 'react';
import { WorkspaceSelectorInput } from 'src/components/workspaces';

export function RenderNotifyTeam({ values, errors, setFieldValue }) {
  const lang = useSelector(getLang('KNOWLEDGE_EDITOR'));
  const [changeWorkspace, setChangeWorkspace] = React.useState(false);

  const onCheckChange = checked => {
    setFieldValue('isSendNotification', checked);
  };

  return (
    <RenderSection>
      <div className={styles.checkbox}>
        <Switch
          className="ant-switch-big"
          defaultChecked={values.isSendNotification}
          onChange={onCheckChange}
        />
        <span className={styles.checkLabel}>{lang.SEND_NOTIFICATION}</span>
      </div>
      {values.isSendNotification && (
        <div className={styles.notifyWhen}>
          <h6>{lang.NOTIFY_WHEN}</h6>
          <Select
            allowClear={false}
            value={values.notifyOnWorkingHours.toString()}
            error={errors.notifyOnWorkingHours}
            options={[
              { value: 'false', label: lang.NOTIFY_NOW },
              { value: 'true', label: lang.NOTIFY_WORKING_HOURS },
            ]}
            name="notifyOnWorkingHours"
            onChange={(n, v) => setFieldValue(n, Boolean(v === 'true'))}
          />
          <h6>{lang.RECIPIENTS_DESCRIPTION}</h6>
          <RecipientsSelector
            error={errors.recipientsTags}
            onChange={v => setFieldValue('recipientsTags', v)}
            placeholder={lang.RECIPIENTS_PLACEHOLDER}
          />

          {!changeWorkspace && (
            <span onClick={() => setChangeWorkspace(true)} className={styles.workspaceSelector}>
              {lang.WORKSPACE_SELECT}
            </span>
          )}

          {changeWorkspace && (
            <>
              <h6>{lang.WORKSPACE_HEADER}</h6>
              <WorkspaceSelectorInput
                value={values.workspaceIds}
                error={errors.workspaceIds}
                name="workspaceIds"
                onChange={setFieldValue}
                placeholder={lang.WORKSPACE_PLACEHOLDER}
              />
            </>
          )}
        </div>
      )}
    </RenderSection>
  );
}
