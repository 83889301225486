import React from 'react';
import styles from './UpdatedIndication.module.scss';

const UpdatedIndicationComponent = ({ lastUpdate, previousLastUpdated }) => {
  try {
    if (new Date(lastUpdate).getTime() - new Date(previousLastUpdated).getTime() < 0) return null;

    return <div className={styles.root} />;
  } catch (e) {}

  return null;
};

UpdatedIndicationComponent.defaultProps = {
  lastUpdate: 0,
  previousLastUpdated: Date.now(),
};

export { UpdatedIndicationComponent };
