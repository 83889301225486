import React from 'react';
import styles from '../search.page.module.scss';
import { Button } from 'src/components/design-system';
import { EVENTS } from 'shared/constants';
import { SEARCH_TYPE } from '../Search.page.component';
import { useSelector } from 'react-redux';
import { isImprovedSearchEnabled } from 'shared/store/selectors/features.selector';
import analyticsService from 'src/helpers/analytics.service';
import { getLang } from 'shared/store/selectors/lang.selector';

const SearchImprovedResults = ({ regularResultsCount, extendedSearch, renderByType }) => {
  const [showExtendedResults, setShowExtendedResults] = React.useState(false);
  const isImprovedSearch = useSelector(isImprovedSearchEnabled);
  const langScreen = useSelector(getLang('SEARCH_SCREEN'));
  const sessionId = useSelector(state => state.search.sessionId);
  const term = useSelector(state => state.search.term);

  // Killer switch - if improved search is disabled
  if (!isImprovedSearch) return null;

  // prepare of extended results
  const {
    resultsCount,
    knowledgeItems = [],
    labItems = [],
    admissionItems = [],
    contactItems = [],
  } = extendedSearch;

  // No results to display
  if (resultsCount === 0) return null;

  // Merge all results and sort by score ASC
  const allResults = [...knowledgeItems, ...labItems, ...admissionItems, ...contactItems].sort(
    ({ score: a }, { score: b }) => a - b,
  );

  const showExtendedResultsAction = (extendedResultsCount = 0) => {
    setShowExtendedResults(true);
    const eventDetails = {
      term,
      sessionId,
      resultsCount,
      extendedResultsCount,
    };

    analyticsService.track(EVENTS.SEARCH.SHOW_MORE, eventDetails);
  };

  const showExtendedResultsItems = showExtendedResults || regularResultsCount === 0;

  if (!showExtendedResultsItems) {
    return (
      <div className={styles.extendedResults}>
        <Button type="link" onClick={() => showExtendedResultsAction(resultsCount)}>
          {langScreen.SHOW_ADDITIONAL_RESULTS.replace('{number}', resultsCount)}
        </Button>
      </div>
    );
  }

  const options = {
    searchType: SEARCH_TYPE.EXTENDED,
    extendedResultsCount: resultsCount,
  };

  // Show results
  return (
    <>
      {/* Show header only if there regular results */}
      {regularResultsCount > 0 && (
        <h5 className={styles.extendedResultsHeader}>
          {langScreen.ADDITIONAL_RESULTS.replace('{term}', term)}
        </h5>
      )}
      {allResults.map(({ type, item }, index) => renderByType[type]({ item }, index, options))}
    </>
  );
};

export { SearchImprovedResults };
