export const CONTACT_TYPES = {
  USER: 'user',
  CONTACT: 'contact',
};

export const TEAM_GROUPS = {
  STAFF: 'staff',
  CONTACTS: 'contacts',
  TRAINEES: 'trainees',
};
