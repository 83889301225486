import React, { useState, useEffect } from 'react';
import {
  DeleteIcon,
  DragIcon,
  InformationIcon,
  MediaIcon,
  Pencil,
  DirectoryIcon,
  CommentIcon,
} from 'src/images';
import styles from './checklist-item.module.scss';
import classnames from 'classnames';
import { useDrag, useDrop } from 'react-dnd';
import { Checkbox } from 'antd';
import { Lang } from 'shared/constants';
import { Button, MrkdwnEditor, Tooltip } from 'src/components/core';

const ChecklistItemComponent = React.memo(function ChecklistItemComponent({
  item,
  categoryId,
  light,
  adminMode,
  deleteValue,
  editValue,
  moveAction,
  dragItem,
  findItem,
  dndGroup,
  draggable,
  finishDrag,
  checkedState,
  admissionDetails,
  addCommentCheck,
  updateChecklistState,
  lastUpdated,
  updateCheck,
  lang,
  langTooltip,
  canEdit = true,
}) {
  let { id, title, link, info } = item;

  let boxRef = React.useRef(null);

  const [titleInput, setTitleInput] = useState(title);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (titleInput !== title) {
      setTitleInput(title);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title, setTitleInput]);

  const originalIndex = findItem(id)?.index;

  const [{ isDragging }, drag] = useDrag({
    item: { type: dndGroup, id, originalIndex },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: () => adminMode && draggable,
    end: (dropResult, monitor) => {
      const { id: droppedId, originalIndex } = monitor.getItem();
      const didDrop = monitor.didDrop();
      if (!didDrop) {
        dragItem(droppedId, originalIndex);
      }

      finishDrag(droppedId);
    },
  });

  const [, drop] = useDrop({
    accept: dndGroup,
    canDrop: () => false,
    hover({ id: draggedId }) {
      if (draggedId !== id) {
        const { index: overIndex } = findItem(id);
        dragItem(draggedId, overIndex);
      }
    },
  });

  if (draggable) {
    boxRef = drag(drop(boxRef));
  }

  const onClick = () => {
    if (adminMode) {
      // TODO: analytics
      editValue({ item });
      return;
    }
  };

  const onCheckChange = e => {
    let { checked } = e.target;
    updateChecklistState({
      id: item.id,
      categoryId,
      checked,
    });
  };

  const openLink = React.useCallback(
    e => {
      e.stopPropagation();

      // TODO: analytics
      window.open(link);
    },
    [link],
  );

  const openInformationInner = React.useCallback(
    e => {
      e.stopPropagation();

      // TODO: analytics
      admissionDetails(item);
    },
    [admissionDetails, item],
  );

  const changeDirectory = React.useCallback(
    e => {
      e.stopPropagation();

      moveAction({ id, title });
    },
    [id, moveAction, title],
  );

  const deleteValueItem = React.useCallback(
    e => {
      e.stopPropagation();

      deleteValue({ id, title });
    },
    [deleteValue, id, title],
  );

  const addCommentCheckAction = React.useCallback(
    e => {
      e.stopPropagation();
      addCommentCheck({ id, title });
    },
    [addCommentCheck, id, title],
  );

  const onFocus = React.useCallback(() => {
    setDisabled(true);
  }, [setDisabled]);

  const onChangeValue = async (name, value) => {
    setTitleInput(value);

    if (value === item[name]) {
      setDisabled(false);
      return false;
    }

    await updateCheck({ checkId: id, categoryId, item: { title: value } });
    setDisabled(false);
  };

  const renderActions = () => {
    if (!adminMode) {
      return (
        <div className={styles.buttons}>
          <Tooltip title={langTooltip.ADD_COMMENT}>
            <button type="button" onClick={addCommentCheckAction} className={styles.buttonWrapper}>
              <CommentIcon className={styles.commentIcon} />
            </button>
          </Tooltip>
          {info && (
            <Tooltip title={langTooltip.INFO}>
              <button type="button" onClick={openInformationInner} className={styles.buttonWrapper}>
                <InformationIcon className={styles.informationIcon} />
              </button>
            </Tooltip>
          )}
          {link && (
            <Tooltip title={langTooltip.OPEN_ATTACHEMENT}>
              <button type="button" onClick={openLink} className={styles.buttonWrapper}>
                <MediaIcon className={styles.informationIcon} />
              </button>
            </Tooltip>
          )}
        </div>
      );
    }

    return (
      <div className={styles.buttons}>
        <Tooltip title={canEdit ? langTooltip.DELETE : langTooltip.NOT_ALLOWED_TO_EDIT}>
          <Button
            type="button"
            onClick={deleteValueItem}
            className={styles.buttonWrapper}
            disabled={!canEdit}
          >
            <DeleteIcon className={styles.deleteIcon} />
          </Button>
        </Tooltip>
        <Tooltip title={canEdit ? langTooltip.EDIT : langTooltip.NOT_ALLOWED_TO_EDIT}>
          <Button
            type="button"
            onClick={onClick}
            className={styles.buttonWrapper}
            disabled={disabled || !canEdit}
          >
            <Pencil className={styles.editIcon} />
          </Button>
        </Tooltip>

        {!!moveAction && (
          <Tooltip title={langTooltip.MOVE}>
            <button type="button" onClick={changeDirectory} className={styles.buttonWrapper}>
              <DirectoryIcon className={styles.changeDirectory} />
            </button>
          </Tooltip>
        )}
        {draggable && (
          <Tooltip title={langTooltip.DRAG}>
            <button
              type="button"
              className={classnames(styles.buttonWrapper, styles.dragIconWrapper)}
            >
              <DragIcon className={styles.dragIcon} />
            </button>
          </Tooltip>
        )}
      </div>
    );
  };

  let opacity = isDragging ? 0 : 1;

  return (
    <div
      ref={boxRef}
      style={{ opacity }}
      className={classnames(styles.root, light ? styles.light : {})}
    >
      <div className={styles.content}>
        <Checkbox
          key={lastUpdated}
          className={styles.check}
          defaultChecked={checkedState}
          onChange={onCheckChange}
        />
        <MrkdwnEditor
          className={styles.title}
          wrapperClass={styles.value}
          readOnly={!adminMode || !canEdit}
          value={titleInput}
          onChange={onChangeValue}
          placeholder={lang.ITEM_TITLE_PLACEHOLDER}
          hideControllers={!disabled}
          name="value"
          inputStyle="inline"
          onFocus={onFocus}
        />
      </div>

      {renderActions()}
    </div>
  );
});

ChecklistItemComponent.defaultProps = {
  id: null,
  item: {
    title: '',
    value: '',
  },
  light: null,
  categoryId: '',
  adminMode: false,
  draggable: true,
  deleteValue: () => {},
  editValue: () => {},
  moveAction: () => {},
  dragItem: () => {},
  findItem: () => {},
  dndGroup: '',
  checkedState: false,
  finishDrag: () => {},
  addCommentCheck: () => {},
  updateChecklistState: () => {},
  lastUpdated: '',
  updateCheck: () => {},
  lang: Lang.CHECKLIST_ITEM_EDITOR,
  langTooltip: Lang.ACTION_BUTTON_TOOLTIPS,
};

export { ChecklistItemComponent };
