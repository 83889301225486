import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import { Input } from 'antd';

const SearchBox = React.memo(
  /**
   * @param {import('antd').InputProps} param0
   * @returns
   */
  function SearchBox(props) {
    return (
      <Input
        suffix={<FontAwesomeIcon icon={faSearch} style={{ color: 'rgba(0,0,0,.45)' }} />}
        {...props}
      />
    );
  },
);

export { SearchBox };
