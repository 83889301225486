import styles from './sources-collapse.module.scss';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { Collapse, ConfigProvider } from 'antd';

export function SourcesCollapse({ children, header, expended = false, onOpen = () => {} }) {
  const onChange = keys => {
    if (keys.length > 0) {
      onOpen();
    }
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Collapse: {
            headerPadding: 0,
            contentPadding: 0,
          },
        },
      }}
    >
      <Collapse
        defaultActiveKey={expended ? '1' : undefined}
        bordered={false}
        className={styles.sourcesCollapse}
        expandIcon={({ isActive }) => (
          <FontAwesomeIcon
            className={styles.sourcesExpenderIcon}
            icon={faChevronRight}
            rotation={isActive ? 90 : 0}
          />
        )}
        onChange={onChange}
      >
        <Collapse.Panel key="1" header={header}>
          {children}
        </Collapse.Panel>
      </Collapse>
    </ConfigProvider>
  );
}
