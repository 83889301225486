import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30.282 30.282" {...props}>
      <g data-name="Group 345" fill="#fff">
        <path
          data-name="Path 874"
          d="M30.28 10.802a.309.309 0 00-.088-.217L20.033.097a.3.3 0 00-.038-.032.318.318 0 00-.162-.059h-.032l-.334-.008H7.801a7.828 7.828 0 00-7.8 7.805v14.672a7.828 7.828 0 007.8 7.805h14.672a7.828 7.828 0 007.805-7.805zm-2.733 16.745a7.128 7.128 0 01-5.07 2.109H7.801a7.128 7.128 0 01-5.07-2.109 7.206 7.206 0 01-1.552-2.3h27.922a7.206 7.206 0 01-1.554 2.3zm2.109-5.07a7.144 7.144 0 01-.354 2.222H.98a7.138 7.138 0 01-.354-2.222V14.17h29.03zm0-8.855H.626V7.806A7.2 7.2 0 017.805.627h11.7v2.346a7.827 7.827 0 007.8 7.805h2.346z"
        />
        <g data-name="Component 2 \u2013 1">
          <path
            data-name="Path 875"
            d="M8.229 21.855v-4.776a.36.36 0 01.36-.36h1.926a1.485 1.485 0 01.668.151 1.678 1.678 0 01.525.4 1.917 1.917 0 01.344.564 1.723 1.723 0 01.124.637 1.9 1.9 0 01-.116.656 1.811 1.811 0 01-.328.564 1.593 1.593 0 01-.51.394 1.471 1.471 0 01-.66.147h-1.5a.133.133 0 00-.133.133v1.489a.348.348 0 01-.348.348.347.347 0 01-.352-.347zm.7-2.372a.133.133 0 00.133.133h1.466a.843.843 0 00.39-.089.883.883 0 00.3-.247 1.219 1.219 0 00.193-.367 1.383 1.383 0 00.069-.44 1.225 1.225 0 00-.081-.448 1.209 1.209 0 00-.216-.363.954.954 0 00-.317-.239.892.892 0 00-.382-.085h-1.42a.133.133 0 00-.133.133z"
          />
          <path
            data-name="Path 876"
            d="M13.194 21.836v-4.749a.367.367 0 01.367-.367h1.533a2.881 2.881 0 011.154.216 2.355 2.355 0 01.83.587 2.445 2.445 0 01.5.869 3.337 3.337 0 01.166 1.062 3.267 3.267 0 01-.185 1.131 2.427 2.427 0 01-.533.865 2.392 2.392 0 01-.838.556 2.909 2.909 0 01-1.093.2h-1.533a.367.367 0 01-.368-.37zm3.846-2.382a2.737 2.737 0 00-.128-.853 1.836 1.836 0 00-.378-.668 1.767 1.767 0 00-.614-.436 2.051 2.051 0 00-.826-.158h-1.14a.191.191 0 00-.191.191v3.866a.191.191 0 00.191.191h1.14a2.052 2.052 0 00.838-.162 1.68 1.68 0 00.61-.448 1.976 1.976 0 00.371-.676 2.708 2.708 0 00.127-.847z"
          />
          <path
            data-name="Path 877"
            d="M18.878 21.912v-4.91a.282.282 0 01.282-.282h3.051a.29.29 0 01.29.29v.038a.29.29 0 01-.29.29h-2.519a.118.118 0 00-.118.119v1.712h2.174a.29.29 0 01.29.289.29.29 0 01-.29.29h-2.174v2.164a.29.29 0 01-.29.29h-.116a.29.29 0 01-.29-.29z"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgComponent;
