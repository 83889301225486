import { connect } from 'react-redux';
import { updateCategory, createCategory } from 'shared/store/actions/admission.actions';
import { AdmissionCategoryEditorComponent } from './admission-category-editor.component';
import { getLocations } from 'shared/store/selectors/admission.selector';

const mapStateToProps = ({ application, admission }, { entity }) => ({
  adminMode: application.adminMode,
  locations: getLocations(admission, { path: entity.path }) || [],
});

const mapDispatchToProps = {
  createCategory,
  updateCategory,
};

export const AdmissionCategoryEditor = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdmissionCategoryEditorComponent);
