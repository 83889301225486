import API from './API';
import { GET, POST } from '../utils/Executer';
import { HUB_SERVICE_URL as servicePath } from '../constants/APIconstants';

class HubServiceApi {
  async getOrganizations() {
    return await API.client.execute(GET, `${servicePath}organizations`);
  }

  async getWorkspaces({ organizationId }) {
    return await API.client.execute(
      GET,
      `${servicePath}organizations/${organizationId}/workspaces`,
    );
  }

  async getKnowledgeCategories({ organizationId, workspaceId, parentId }) {
    const id = parentId ? parentId : 'root';

    return await API.client.execute(
      GET,
      `${servicePath}organizations/${organizationId}/workspaces/${workspaceId}/categories/${id}?includeSubcategories=true`,
    );
  }

  async getCategory({ organizationId, workspaceId, categoryId }) {
    return await API.client.execute(
      GET,
      `${servicePath}organizations/${organizationId}/workspaces/${workspaceId}/categories/${categoryId}`,
    );
  }

  async getKnowledgeBinder({ organizationId, workspaceId, categoryId }) {
    return await API.client.execute(
      GET,
      `${servicePath}organizations/${organizationId}/workspaces/${workspaceId}/binders/${categoryId}`,
    );
  }

  async getItem({ id }) {
    return await API.client.execute(GET, `${servicePath}items/${id}`);
  }

  async prepareClone({ id }) {
    return await API.client.execute(POST, `${servicePath}knowledge-item/${id}/prepare-clone`);
  }

  async search({ query, filters = {} }) {
    return await API.client.execute(POST, `${servicePath}search`, { query, filters });
  }
}

export default new HubServiceApi();
