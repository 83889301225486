import React from 'react';
import styles from './progress-bar.module.scss';
import { Button } from '../../../core';
import { MenuDocumentIcon } from '../../../../images';
import { getLang } from 'shared/store/selectors/lang.selector';
import { useSelector } from 'react-redux';
import { Popup } from '../../../common';
import { useMemo } from 'react';

/**
 * @type {React.FC<any>}
 */
const ProgressBar = React.memo(function ProgressBar({
  progress = 0,
  onCancel,
  fileName = 'file',
  title,
  isDirectConversion = false,
}) {
  const lang = useSelector(getLang('PROGRESS_BAR'));
  const [isCancelOpen, setCancelOpen] = React.useState(false);

  const messages = useMemo(
    () => ({
      titleReady: isDirectConversion ? lang.CONVERT_READY : lang.UPLOAD_READY,
      titleOngoing: isDirectConversion ? lang.CONVERT_ONGOING : lang.UPLOAD_ONGOING,
      cancelButton: isDirectConversion ? lang.CONVERT_CANCEL : lang.CANCEL,
      dismissButton: isDirectConversion ? lang.CONTINUE_CONVERT : lang.CONTINUE_UPLOAD,
      confirmButton: isDirectConversion ? lang.CANCEL_CONVERT : lang.CANCEL_UPLOAD,
    }),
    [lang, isDirectConversion],
  );

  const uploadMessage =
    progress === 100
      ? messages.titleReady.replace('{fileName}', fileName)
      : messages.titleOngoing.replace('{fileName}', fileName).replace('{progress}', progress);

  const openCancelModal = React.useCallback(() => setCancelOpen(true), []);
  const closeCancelModal = React.useCallback(() => setCancelOpen(false), []);

  const cancelPopup = () => (
    <Popup className={styles.popup} isOpen={isCancelOpen}>
      <h4 className={styles.title}>{lang.CANCEL_TITLE}</h4>
      <div className={styles.buttons}>
        <Button className={styles.button} size="medium" onClick={closeCancelModal}>
          {messages.dismissButton}
        </Button>
        <Button className={styles.buttonCancel} size="medium" onClick={onCancel}>
          {messages.confirmButton}
        </Button>
      </div>
    </Popup>
  );

  return (
    <div className={styles.progressWrapper}>
      {cancelPopup()}
      <h4 className={styles.title}>{title}</h4>
      <div className={styles.fileWrapper}>
        <img src={MenuDocumentIcon} alt="document" />
        <p>{uploadMessage}</p>
      </div>
      <div className={styles.progressBar}>
        <div
          className={styles.progressBarFill}
          style={{
            width: `${progress}%`,
          }}
        />
      </div>
      <div className={styles.buttons}>
        <Button
          disabled={progress === 100}
          className={styles.button}
          size="medium"
          onClick={openCancelModal}
        >
          {messages.cancelButton}
        </Button>
      </div>
    </div>
  );
});

export { ProgressBar };
