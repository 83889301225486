import React from 'react';
import { FolderAddIcon } from '../../../images';
import { Button } from '../../core';
import styles from './add-section-button.module.scss';
import { useSelector } from 'react-redux';
import { getLang } from 'shared/store/selectors/lang.selector';

const AddSectionButton = React.memo(({ onClick }) => {
  const lang = useSelector(getLang('CALL_TO_ACTION'));

  return (
    <div className={styles.root}>
      <h3 className={styles.callToAction}>{lang.ADD_SECTION}</h3>
      <Button
        className={styles.button}
        buttonStyle="primary"
        size="large"
        onClick={onClick}
        data-key="add-first-section-button"
      >
        <img className={styles.icon} src={FolderAddIcon} alt={lang.ADD_SECTION_BUTTON} />
        {lang.ADD_SECTION_BUTTON}
      </Button>
    </div>
  );
});

export { AddSectionButton };
