import React, { useCallback } from 'react';
import { Popup } from '../common';
import { Button, ScreenTitle } from '../design-system';
import styles from './issue-report-popup.module.scss';
import { TextArea } from '../core';
import { Lang } from 'shared/constants';
import { Tag } from 'antd';
import classnames from 'classnames';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import analyticsService from 'src/helpers/analytics.service';
import { useDispatch } from 'src/store/use-dispatch';

const { CheckableTag } = Tag;

const ISSUE_SCHEMA = Yup.object().shape({
  problems: Yup.array().required('Required!'),
  description: Yup.string(),
});

const IssueReportPopupComponent = React.memo(
  ({
    lang = Lang.REPORT_PROBLEM,
    serviceMethod,
    serviceMethodProps,
    close,
    notificationApi,
    defaultOptions,
  }) => {
    const dispatch = useDispatch();
    const openNotification = useCallback(
      ({ message, type = 'success' }) => {
        notificationApi[type]({
          message,
          placement: 'bottomRight',
          duration: 5,
        });
      },
      [notificationApi],
    );

    const submitReport = useCallback(
      async data => {
        const sessionReplyLink = analyticsService.getSessionReplayLink();

        const res = await dispatch(
          serviceMethod({
            data: {
              issue: { ...data, timestamp: new Date().toISOString() },
              device: {
                platform: 'web',
                userAgent: window.navigator.userAgent,
                datadogSessionReplayLink: sessionReplyLink,
              },
              ...serviceMethodProps,
            },
          }),
        );

        if (res?.error) {
          openNotification({ message: lang.NOTIFICATION_ERROR, type: 'error' });
        } else {
          openNotification({ message: lang.NOTIFICATION_SUCCESS });
        }
        close();
      },
      [serviceMethod, serviceMethodProps, dispatch, lang, openNotification, close],
    );

    const { handleSubmit, handleChange, setFieldValue, values, errors } = useFormik({
      validationSchema: ISSUE_SCHEMA,
      initialValues: {
        problems: [],
        description: '',
      },
      onSubmit: submitReport,
    });

    const handleTagChange = useCallback(
      (tag, checked) => {
        const nextSelectedTags = checked
          ? [...values.problems, tag]
          : values.problems.filter(t => t !== tag);
        setFieldValue('problems', nextSelectedTags);
      },
      [values.problems, setFieldValue],
    );

    const renderProblemSelector = () =>
      defaultOptions.map(problem => {
        const selected = values.problems.includes(problem);

        return (
          <CheckableTag
            key={problem}
            checked={selected}
            onChange={checked => handleTagChange(problem, checked)}
            className={classnames([styles.tag, selected && styles.selected])}
          >
            {lang?.[problem]}
          </CheckableTag>
        );
      });

    return (
      <div className={styles.background}>
        <Popup isOpen={true}>
          <div className={styles.root}>
            <ScreenTitle title={lang.TITLE} />

            <label className={styles.label}>{lang.PROBLEMS_TITLE}</label>
            <div className={styles.tagWrapper}>{renderProblemSelector()}</div>
            <label className={styles.label}>{lang.DESCRIPTION}</label>
            <TextArea
              wrapperClass={styles.noMargin}
              className={styles.textArea}
              placeholder={lang.DESCRIPTION_PLACEHOLDER}
              name="description"
              value={values.description}
              error={errors.description}
              onChange={handleChange}
            />
            <div className={styles.buttons}>
              <Button type="default" onClick={close}>
                {lang.DISMISS}
              </Button>
              <Button onClick={handleSubmit}>{lang.SUBMIT}</Button>
            </div>
          </div>
        </Popup>
      </div>
    );
  },
);

export { IssueReportPopupComponent };
