import React from 'react';
import styles from './organization-box.module.scss';
import { ORGANIZATIONS_LOGO_URL } from 'shared/constants';
import { ArrowIcon } from '../../../images';
import classnames from 'classnames';

const OrganizationBoxComponent = React.memo(function OrganizationBoxComponent({
  id,
  name,
  logo,
  isActive,
  onClick,
}) {
  const switchOrganization = () => {
    // TODO: add analytics
    onClick({ id });
  };

  return (
    <div className={classnames(styles.box, isActive ? styles.active : undefined)}>
      <div className={styles.boxWrapper}>
        <button type="button" onClick={switchOrganization} className={styles.image}>
          <img src={ORGANIZATIONS_LOGO_URL + 'web/' + logo} title={name} alt={name} />
        </button>
        <div onClick={switchOrganization} className={styles.boxHeader}>
          <span className={styles.text}>{name}</span>
          <span className={styles.arrow}>
            <ArrowIcon />
          </span>
        </div>
      </div>
    </div>
  );
});

OrganizationBoxComponent.defaultProps = {
  id: null,
  name: '',
  logo: '',
  isActive: false,
};

export { OrganizationBoxComponent };
