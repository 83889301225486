import { connect } from 'react-redux';
import { deleteKnowledgeItem } from 'shared/store/actions/knowledge.items.actions';
import { KnowledgeDeletePopupComponent } from './KnowledgeDeletePopup.component';

const mapStateToProps = ({ lang }) => ({
  lang: lang.DELETE_KNOWLEDGE_CONFIRMATION,
});

export const KnowledgeDeletePopup = connect(mapStateToProps, {
  deleteKnowledgeItem,
})(KnowledgeDeletePopupComponent);
