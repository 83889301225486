import React, { useState, useRef, useEffect } from 'react';
import styles from './OtpInput.module.scss';
import classnames from 'classnames';
import { DATA_KEYS } from 'shared/constants';
const { VERIFY_SCREEN } = DATA_KEYS;

const OtpInput = ({ onChange, digits, editable, className }) => {
  const [otp, setOtp] = useState('');
  const codeInputs = useRef([]);

  useEffect(() => {
    if (otp.length === digits) {
      onChange({ code: otp });
    }
  }, [otp, digits, onChange]);

  const handleCodeChange = (event, index) => {
    const { value } = event.target;
    if (otp[index] !== '') {
      focusNextInput(index);
    }
    if (value.length <= 1) {
      setOtp(prevCode => prevCode.concat(value));

      if (value !== '') {
        focusNextInput(index);
      }
    }
  };

  const focusNextInput = index => {
    if (index < codeInputs.current.length - 1) {
      codeInputs.current[index + 1].focus();
    }
  };

  const focusPreviousInput = index => {
    if (index > 0) {
      codeInputs.current[index - 1].focus();
    }
  };

  const handleBackspace = (event, index) => {
    if (event.key === 'Backspace') {
      setOtp(prevCode => prevCode.slice(0, prevCode.length - 1));

      if (index > 0) {
        focusPreviousInput(index);
      }
    }
  };

  const handlePasteCode = event => {
    event.preventDefault();
    const clipboardContent = event.clipboardData.getData('text/plain');
    const sanitizedCode = clipboardContent.replace(/\D/g, '').slice(0, digits);

    setOtp(sanitizedCode);
    focusNextInput(sanitizedCode.length - 1);
  };

  const inputs = Array(digits).fill(0);
  const textInputs = inputs.map((i, j) => (
    <input
      data-key={`${VERIFY_SCREEN.CODE}-${j}`}
      value={(otp && otp[j]) || ''}
      key={`digit-${j}`}
      maxLength="1"
      type="number"
      className={styles.input}
      disabled={!editable}
      ref={ref => (codeInputs.current[j] = ref)}
      onPaste={handlePasteCode}
      onChange={e => handleCodeChange(e, j)}
      onKeyDown={e => handleBackspace(e, j)}
      autoFocus={j === 0}
    />
  ));

  return <div className={classnames(styles.root, className)}>{textInputs}</div>;
};

OtpInput.defaultProps = {
  digits: 6,
  onChange: () => {},
  editable: true,
  className: null,
};

export { OtpInput };
