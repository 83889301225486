import * as React from 'react';

function SvgComponent({ color = '#2c5789', ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 11 20" {...props}>
      <g id="prefix__drug" transform="translate(-340 -144.41)">
        <circle
          id="prefix__Ellipse_40"
          cx={2}
          cy={2}
          r={2}
          fill={color}
          transform="translate(340 144.41)"
        />
        <circle
          id="prefix__Ellipse_43"
          cx={2}
          cy={2}
          r={2}
          fill={color}
          transform="translate(347 144.41)"
        />
        <circle
          id="prefix__Ellipse_41"
          cx={2}
          cy={2}
          r={2}
          fill={color}
          transform="translate(340 152.41)"
        />
        <circle
          id="prefix__Ellipse_44"
          cx={2}
          cy={2}
          r={2}
          fill={color}
          transform="translate(347 152.41)"
        />
        <circle
          id="prefix__Ellipse_42"
          cx={2}
          cy={2}
          r={2}
          fill={color}
          transform="translate(340 160.41)"
        />
        <circle
          id="prefix__Ellipse_45"
          cx={2}
          cy={2}
          r={2}
          fill={color}
          transform="translate(347 160.41)"
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
