import React, { useMemo } from 'react';
import { DRUG_CLASSES } from 'shared/constants';
import { DrugClassSelectorComponent } from './drugClassSelector.component';
import { getLang } from 'shared/store/selectors/lang.selector';
import { useSelector } from 'react-redux';

const DrugClassSelector = React.memo(function DrugClassSelector({
  value,
  errors,
  onChange,
  placeholder,
}) {
  const LANG = useSelector(getLang('DRUG_CLASSES'));

  const options = useMemo(
    () =>
      Object.values(DRUG_CLASSES).map(el => ({
        id: el,
        value: LANG[el],
      })),
    [LANG],
  );

  return (
    <DrugClassSelectorComponent
      value={value}
      options={options}
      onChange={onChange}
      name="drugClass"
      placeholder={placeholder}
      error={errors?.drugClass}
    />
  );
});

export { DrugClassSelector };
