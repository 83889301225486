import React from 'react';
import { Button } from '../../core';
import { ContentHeader } from '../components/content-header';
import styles from './knowledge-document-download.module.scss';
import { useSelector } from 'react-redux';
import { DocumentDownload } from '../document-download/document-download.component';

import { haveEditPermissions } from 'shared/store/selectors/knowledge.selector';

const KnowledgeDocumentDownload = React.memo(
  ({ close, knowledge, toggleEditMode, linked, lang }) => {
    const hasEditPermission = useSelector(state =>
      haveEditPermissions(state, { ownerIds: knowledge.ownerIds }),
    );
    const allowedToEdit = hasEditPermission && !linked;

    const renderHeaderActions = () => {
      return (
        <>
          {allowedToEdit && (
            <Button size="small" buttonStyle="primary" onClick={toggleEditMode}>
              {lang.EDIT}
            </Button>
          )}
        </>
      );
    };

    return (
      <div className={styles.root}>
        <ContentHeader close={close} actionsRender={renderHeaderActions} />
        <div className={styles.container}>
          <div className={styles.marginBox}>
            <DocumentDownload knowledge={knowledge} lang={lang} />
          </div>
        </div>
      </div>
    );
  },
);

KnowledgeDocumentDownload.defaultProps = {
  close: () => {},
  knowledge: {},
  toggleEditMode: () => {},
  linked: false,
};

export { KnowledgeDocumentDownload };
