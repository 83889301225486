import React from 'react';
import { GooglePlay, AppStore, PandaAI } from '../../images';
import styles from './MobilePopover.module.scss';
import { getAppScheme, Lang } from 'shared/constants';
import { Button } from '../design-system';
import classnames from 'classnames';

const isMobile = navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i);
const appStoreLink = 'https://apple.co/34Bf5jL';
const playStoreLink = 'https://play.google.com/store/apps/details?id=com.headtotoe.app';

function MobilePopover({ children, lang }) {
  if (!isMobile) {
    return children;
  }

  // try to redirect to app automatically
  const redirectToAppUrl = window.location.href.replace(/(https|http)/, getAppScheme());
  window.location = redirectToAppUrl;

  const redirectToApp = () => {
    window.location = redirectToAppUrl;
  };

  return (
    <div className={classnames(['new-design-system', styles.root])}>
      <img src={PandaAI} className={styles.image} alt={lang.HEADER} />
      <h1 className={styles.header}>{lang.HEADER}</h1>
      <h3 className={styles.subHeader}>{lang.SUBHEADER}</h3>

      <Button type="primary" onClick={redirectToApp}>
        {lang.GO_TO_APP}
      </Button>

      <div className={styles.download}>
        <a href={appStoreLink}>
          <img src={AppStore} className={styles.downloadButton} alt="App Store" />
        </a>
        <a href={playStoreLink}>
          <img src={GooglePlay} className={styles.downloadButton} alt="Play Store" />
        </a>
      </div>
    </div>
  );
}

MobilePopover.defaultProps = {
  lang: Lang.MOBILE_POPOVER,
};

export { MobilePopover };
