import React from 'react';
import styles from './progress-bar.module.scss';
import classnames from 'classnames';

const SimpleProgressBar = ({ progress }) => {
  return (
    <div className={styles.simpleProgressBar}>
      <div
        className={classnames([
          styles.progressBarFill,
          progress === 100 && styles.progressBarFillComplete,
        ])}
        style={{
          width: `${progress}%`,
        }}
      />
    </div>
  );
};

export { SimpleProgressBar };
