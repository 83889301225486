import React from 'react';
import styles from './TagsIcons.module.scss';
import { TagIcon } from './TagIcon.component';

const SingleTagIcon = React.memo(function SingleTagIcon({ tag, darkBackground }) {
  return (
    <TagIcon
      tag={tag}
      className={[styles.singleTag, darkBackground ? styles.darkBackground : {}]}
    />
  );
});

export { SingleTagIcon };
