import React from 'react';
import { Button, SelectInput } from '../core';
import { Divider } from 'antd';
import styles from './owner-selector.module.scss';
import { OwnerEditor } from './owner-editor';
import classNames from 'classnames';
import { Lang } from 'shared/constants/lang';
const OwnerSelectorComponent = React.memo(function OwnerSelectorComponent({
  value,
  ownersList,
  onChange,
  name,
  placeholder,
  error,
  wrapperClass,
  inputStyle,
  canAddOwner,
  lang,
  currentOwnerId,
}) {
  const [isCreate, setIsCreate] = React.useState(false);
  const [ownerId, setOwnerId] = React.useState(value);

  // fallback in case owner was deleted but not properly replaced on all items/categories
  const isOwnerDeleted = !ownersList.find(owner => owner.id === ownerId);
  const valueToShow = !isOwnerDeleted && ownerId ? ownerId : lang.NO_OWNER;

  const options = React.useMemo(() => {
    return ownersList.filter(owner => owner.id !== currentOwnerId);
  }, [ownersList, currentOwnerId]);

  React.useEffect(() => {
    if (isOwnerDeleted) {
      setOwnerId(null);
      onChange(name, null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openCreateOwner = () => {
    setIsCreate(true);
    // TODO: analytics
  };

  const closeCreateOwner = () => {
    setIsCreate(false);
    // TODO: analytics
  };

  const handleOnChange = (name, value) => {
    setOwnerId(value);
    onChange(name, value);
  };

  const dropdownRender = menu => {
    return (
      <div>
        {menu}
        {canAddOwner && (
          <>
            <Divider style={{ margin: '4px 0' }} />
            <Button size="small" onClick={openCreateOwner} className={styles.addButton}>
              {lang.ADD_NEW_OWNER}
            </Button>
          </>
        )}
      </div>
    );
  };

  return (
    <>
      {!isCreate && (
        <SelectInput
          value={valueToShow}
          options={options}
          error={error}
          placeholder={placeholder}
          name={name}
          dropdownRender={dropdownRender}
          wrapperClass={classNames(styles.root, wrapperClass)}
          inputStyle={inputStyle}
          onChange={handleOnChange}
        />
      )}

      <OwnerEditor isOpen={isCreate} onFinish={closeCreateOwner} selectOwnerId={handleOnChange} />
    </>
  );
});

OwnerSelectorComponent.defaultProps = {
  name: 'ownerId',
  error: null,
  wrapperClass: null,
  inputStyle: null,
  lang: Lang.OWNER_SELECTOR,
};

export { OwnerSelectorComponent };
