import { faCirclePlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './create-box.module.scss';
import { useSelector } from 'react-redux';
import { getLang } from 'shared/store/selectors/lang.selector';
import { useViewSelector } from 'src/components/common/view-selector';

export function CreateBox({ adminMode, onClick }) {
  const lang = useSelector(getLang('ACTION_BUTTON_TOOLTIPS'));
  const { isGridView } = useViewSelector();

  if (!adminMode) return null;

  return (
    <div
      data-key="create-content-box"
      className={isGridView ? styles.createBox : styles.createRow}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={faCirclePlus} className={styles.icon} />
      <p>{lang.ADD_DIRECTORY}</p>
    </div>
  );
}
