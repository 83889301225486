import React from 'react';
import { useHistory } from 'react-router';
import { ROUTES, CATEGORY_TYPES } from 'shared/constants';
import styles from './knowledge-category.module.scss';
import classnames from 'classnames';
import _ from 'lodash';
import { useDrag, useDrop } from 'react-dnd';
import { Lang } from 'shared/constants/lang';
import { CategoryBoxComponent } from 'src/components/common/categories/category-box';
import { useViewSelector } from 'src/components/common/view-selector';
import { CategoryRowComponent } from 'src/components/common/categories/category-row';
import { useSelector } from 'react-redux';
import { haveEditPermissions } from 'shared/store/selectors/knowledge.selector';
import { ActionsDropdown } from 'src/components/common/actions-dropdown/actions-dropdown.component';

const KnowledgeCategory = React.memo(function KnowledgeCategory({
  id,
  title,
  subtitle,
  knowledgeItems,
  itemsLastUpdatedAt,
  knowledgeSelectItem,
  activeId,
  adminMode,
  deleteCategory,
  updateCategory,
  dragItem,
  findItem,
  finishDrag,
  dndGroup,
  type,
  editCategory,
  ownerIds,
  path,
  tags,
  metadata,
  options,
  lang,
  labels,
  shared,
}) {
  let history = useHistory();
  const isCategory = knowledgeItems.length > 0 || type === CATEGORY_TYPES.KNOWLEDGE;
  let focusStyle = !_.isNull(activeId) ? (activeId === id ? styles.active : styles.inActive) : null;

  const originalIndex = findItem(id)?.index;

  const hasEditPermission = useSelector(state => haveEditPermissions(state, { ownerIds }));

  const [{ isDragging }, drag] = useDrag({
    item: { type: dndGroup, id, originalIndex },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: () => adminMode,
    end: (dropResult, monitor) => {
      const { id: droppedId, originalIndex } = monitor.getItem();
      const didDrop = monitor.didDrop();
      if (!didDrop) {
        dragItem(droppedId, originalIndex);
      }

      finishDrag(droppedId);
    },
  });
  const [, drop] = useDrop({
    accept: dndGroup,
    canDrop: () => false,
    hover({ id: draggedId }) {
      if (draggedId !== id) {
        const { index: overIndex } = findItem(id);
        dragItem(draggedId, overIndex);
      }
    },
  });

  const onClick = React.useCallback(() => {
    if (isCategory) {
      knowledgeSelectItem(id);
      return;
    }

    history.push(ROUTES.CONTENT_ITEM.replace(':id', id));
  }, [history, id, isCategory, knowledgeSelectItem]);

  const onBlur = React.useCallback(
    e => {
      const { textContent: value } = e.target;

      if (value === title) return false;

      updateCategory({ id, title: value, ownerIds });
    },
    [id, title, updateCategory, ownerIds],
  );

  const deleteCategoryAction = React.useCallback(
    e => {
      e.stopPropagation();
      // TODO: analytics
      if (!hasEditPermission) {
        return;
      }
      deleteCategory({ id, title });
    },
    [hasEditPermission, deleteCategory, id, title],
  );

  const editCategoryAction = React.useCallback(
    e => {
      e.stopPropagation();
      // TODO: analytics
      if (!hasEditPermission) {
        return;
      }
      editCategory({ id, title, subtitle, ownerIds, path, type, tags, options, labels, shared });
    },
    [
      hasEditPermission,
      editCategory,
      id,
      title,
      subtitle,
      ownerIds,
      path,
      type,
      tags,
      options,
      labels,
      shared,
    ],
  );

  const adminActions = [
    {
      label: lang.EDIT,
      onClick: editCategoryAction,
      tooltip: !hasEditPermission && lang.NOT_ALLOWED_TO_EDIT,
      disabled: !hasEditPermission,
    },
    {
      label: lang.DELETE,
      onClick: deleteCategoryAction,
      tooltip: !hasEditPermission && lang.NOT_ALLOWED_TO_EDIT,
      disabled: !hasEditPermission,
    },
  ];

  const opacity = isDragging ? 0 : 1;

  const { isGridView } = useViewSelector();
  const CategoryComponent = isGridView ? CategoryBoxComponent : CategoryRowComponent;

  return (
    <div
      ref={node => drag(drop(node))}
      style={{ opacity }}
      className={classnames(
        isGridView ? styles.box : {},
        focusStyle,
        adminMode ? styles.admin : {},
      )}
    >
      <CategoryComponent
        title={title}
        tags={tags}
        labels={labels}
        adminMode={adminMode}
        hasEditPermission={hasEditPermission}
        type={type}
        onClick={onClick}
        onBlur={onBlur}
        actions={adminMode && <ActionsDropdown actions={adminActions} />}
        lastUpdatedAt={itemsLastUpdatedAt}
        categoryType={CATEGORY_TYPES.KNOWLEDGE}
        metadata={metadata}
        options={options}
      />
    </div>
  );
});

KnowledgeCategory.defaultProps = {
  title: '',
  knowledgeItems: [],
  knowledgeSelectItem: () => {},
  activeId: null,
  adminMode: false,
  ownerIds: [],
  itemsLastUpdatedAt: null,
  deleteCategory: () => {},
  updateCategory: () => {},
  editCategory: () => {},
  findItem: () => {},
  finishDrag: () => {},
  lang: Lang.ACTION_BUTTON_TOOLTIPS,
  metadata: {},
};

export { KnowledgeCategory };
