import { ADMISSION_SELECT_CHECKLIST, ADMISSION_CHECK_DETAILS } from 'shared/store/actions';
import update from 'immutability-helper';

const initialState = {
  id: null,
};

const selectItem = (state, { id = null }) => {
  return update(state, {
    $merge: { id },
  });
};

const setItemDetails = (state, { item = null }) => {
  return update(state, {
    $merge: { item },
  });
};

const actionsRepo = {
  [ADMISSION_SELECT_CHECKLIST]: selectItem,
  [ADMISSION_CHECK_DETAILS]: setItemDetails,
};

export default function (state = initialState, { type, payload }) {
  if (!!actionsRepo[type]) {
    return actionsRepo[type](state, payload);
  }

  return state;
}
