import React from 'react';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoice, faFilm } from '@fortawesome/pro-regular-svg-icons';

export function RenderMetadata({ metadata, adminMode, isCategory, styles }) {
  if (_.isEmpty(metadata) || isCategory) return;

  const hasItems = metadata?.items > 0;
  const hasMedia = metadata?.media > 0;
  const showMetadata = hasItems || hasMedia;

  const showItems = !adminMode ? hasItems : true;
  const showMedia = !adminMode ? hasMedia : true;

  if (!adminMode && !showMetadata) return;

  return (
    <div className={styles.metadata}>
      {showItems && (
        <div className={styles.metadataContainer}>
          <FontAwesomeIcon icon={faFileInvoice} className={styles.metadataIcon} />
          <h5 className={styles.metadataText}>{metadata.items}</h5>
        </div>
      )}
      {showMedia && (
        <div className={styles.metadataContainer}>
          <FontAwesomeIcon icon={faFilm} className={styles.metadataIcon} />
          <h5 className={styles.metadataText}>{metadata.media}</h5>
        </div>
      )}
    </div>
  );
}
