// @ts-check

import { useDispatch as _useDispatch } from "react-redux";
import { StoreAction, StoreState } from "./store";
import { ThunkDispatch } from 'redux-thunk'

export type StoreDispatch = ThunkDispatch<StoreState, unknown, StoreAction>

export function useDispatch() {
  return _useDispatch<StoreDispatch>()
}