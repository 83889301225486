import React from 'react';
import { Lang } from 'shared/constants';
import { Popup } from 'src/components/common';
import styles from './admission-move.module.scss';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button, Loader, SelectInput } from 'src/components/core';
import { normalizedContains } from 'src/utils';

const ADMISSION_ITEM_SCHEMA = Yup.object().shape({
  categoryId: Yup.string().required('Required'),
});

const AdmissionMovePopupComponent = React.memo(function AdmissionMovePopupComponent({
  lang,
  item,
  categoryId,
  moveChecklistAction,
  onFinish,
  locations,
}) {
  const [isLoading, setLoadingState] = React.useState(false);

  const moveAction = React.useCallback(
    async data => {
      setLoadingState(true);

      await moveChecklistAction({ ...data, sourceId: categoryId });

      onFinish(true);
      setLoadingState(false);
    },
    [categoryId, moveChecklistAction, onFinish],
  );

  const { handleSubmit, setFieldValue, values, errors } = useFormik({
    validationSchema: ADMISSION_ITEM_SCHEMA,
    initialValues: {
      id: item.id,
      categoryId,
    },
    onSubmit: moveAction,
  });

  const dismiss = React.useCallback(() => {
    // TODO: analytics
    onFinish();
  }, [onFinish]);

  return (
    <Popup title={lang.TITLE} isOpen>
      <p className={styles.content}>{item.title}</p>
      <form onSubmit={handleSubmit}>
        <label>{lang.LOCATION}</label>
        <SelectInput
          value={values.categoryId}
          error={errors.categoryId}
          placeholder={lang.LOCATION_PLACEHOLDER}
          options={locations}
          name="categoryId"
          onChange={setFieldValue}
          filterOption={(inputValue, option) => normalizedContains(option?.children, inputValue)}
        />

        {!isLoading && (
          <div className={styles.buttons}>
            <Button className={styles.button} onClick={dismiss} size="medium">
              {lang.DISMISS}
            </Button>
            <Button
              className={styles.button}
              size="medium"
              type="submit"
              disabled={categoryId === values.categoryId}
              buttonStyle="primary"
            >
              {lang.MOVE}
            </Button>
          </div>
        )}
        {isLoading && <Loader />}
      </form>
    </Popup>
  );
});

AdmissionMovePopupComponent.defaultProps = {
  lang: Lang.ADMISSION_MOVE,
  knowledge: {},
  categoryId: null,
  moveChecklistAction: () => {},
  onFinish: () => {},
  locations: [],
};

export { AdmissionMovePopupComponent };
