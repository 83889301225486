import { REMOTE_CONFIG_KEYS } from 'shared/constants';
import { GENERAL_CONFIG_UPDATE } from 'shared/store/actions';
import remoteConfigService from '../../helpers/remote-config.service';

export const initGeneralConfigStatus = () => dispatch => {
  const generalConfig = remoteConfigService.getJsonValue(REMOTE_CONFIG_KEYS.GENERAL_CONFIG);

  dispatch({
    type: GENERAL_CONFIG_UPDATE,
    payload: generalConfig,
  });
};
