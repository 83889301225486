import React from 'react';
import { Select } from 'antd';
import styles from './labels-selector.module.scss';
import { Label } from '../../core/label';
import _ from 'lodash';
import { SelectBox } from 'src/components/design-system/forms';
import classNames from 'classnames';
import { normalizedContains, sortByAlphabet } from 'shared/utils';

const { Option } = Select;

const LabelsSelectorComponent = ({
  onChange,
  error,
  list = [],
  labelsByIds = {},
  renderLabel = () => {},
  wrapperClass = '',
  ...props
}) => {
  const onChangeInternal = React.useCallback(
    (name, value) => {
      const localValue = value.filter(v => !_.isEmpty(v));
      onChange(name, localValue);
    },
    [onChange],
  );

  const renderOptions = options =>
    options
      .sort((a, b) => sortByAlphabet(a?.name, b?.name))
      .map(({ code, name, color }) => (
        <Option value={code} key={code}>
          <div className={styles.optionTag}>
            {name}
            <Label color={color} value={code} className={styles.tagInList} closable={false} />
          </div>
        </Option>
      ));

  const tagRender = ({ value, onClose }) => {
    const label = labelsByIds[value];

    if (!label) return null;

    return (
      <Label color={label.color} value={value} className={styles.tag} closable onClose={onClose} />
    );
  };

  if (_.isEmpty(list)) return null;

  return (
    <div className={classNames([styles.root, wrapperClass])}>
      {renderLabel && renderLabel()}
      <SelectBox
        onChange={onChangeInternal}
        filterOption={(inputValue, option) => normalizedContains(option?.value, inputValue)}
        optionsRender={renderOptions}
        tagRender={tagRender}
        mode="multiple"
        options={list}
        showArrow
        {...props}
      />
      {error && <span className={styles.error}>{error}</span>}
    </div>
  );
};

LabelsSelectorComponent.defaultProps = {
  onChange: () => {},
  error: '',
};

export { LabelsSelectorComponent };
