import React from 'react';
import { UploadIcon } from 'src/images';
import styles from './render-editor.module.scss';
import { useSelector } from 'src/store/use-selector';
import { FileUpload, Loader, TinyEditor, Button } from 'src/components/core';
import { KNOWLEDGE_TYPES, ROUTES } from 'shared/constants';
import { ExternalLinkViewer } from '../../components/knowledge-viewers';
import { KnowledgeContentEditorFormValue } from '../types';
import { Typography, theme } from 'antd';
import { TextInput } from 'src/components/design-system/forms';

function FileUploadBanner({
  isLoading,
  values,
  errors,
  onFileChange,
  onDuplicatedAssetCancel,
  onDuplicatedAssetLink,
  duplicatedAsset,
}: {
  isLoading: boolean;
  values: Partial<KnowledgeContentEditorFormValue>;
  errors: Partial<KnowledgeContentEditorFormValue>;
  onFileChange: (file: any) => void;
  duplicatedAsset: undefined | { knowledgeId: string };
  onDuplicatedAssetCancel: () => void;
  onDuplicatedAssetLink: () => void;
}) {
  const knowledgeEditorLang = useSelector(s => s.lang.KNOWLEDGE_EDITOR);
  const knowledgeUploadPopupLang = useSelector(s => s.lang.KNOWLEDGE_UPLOAD_POPUP);

  const extension = values.link?.split('.').pop();

  const { token } = theme.useToken();

  const wrapperStyles = {
    backgroundColor: token.colorBgBase,
    paddingLeft: token.padding,
    paddingRight: token.padding,
    display: 'flex',
    alignItems: 'center',
  };

  if (isLoading) {
    return (
      <div
        style={{
          ...wrapperStyles,
          justifyContent: 'center',
        }}
      >
        <Loader />
      </div>
    );
  }

  if (duplicatedAsset) {
    let parts = knowledgeUploadPopupLang.DUPLICATION_SUBTITLE.split('{link:');
    const beginning = parts[0]!.split('{link:')[0];
    parts = parts[1]!.split('}');
    const linkText = parts[0];
    const ending = parts[1];

    return (
      <div style={wrapperStyles}>
        <Typography.Text
          style={{
            marginRight: token.margin,
          }}
          className={styles.uploadTitle}
        >
          {beginning}
          <a
            href={`${window.location.origin}${ROUTES.KNOWLEDGE_ITEMS.replace(
              ':id',
              duplicatedAsset.knowledgeId,
            )}`}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: token.colorLink }}
          >
            {linkText}
          </a>
          {ending}
        </Typography.Text>
        <Button
          buttonStyle="primary"
          size="small"
          style={{
            marginRight: token.marginXS,
          }}
          onClick={onDuplicatedAssetLink}
        >
          {knowledgeUploadPopupLang.DUPLICATION_LINK_SHORT}
        </Button>
        <Button size="small" onClick={onDuplicatedAssetCancel}>
          {knowledgeUploadPopupLang.DUPLICATION_DISMISS}
        </Button>
      </div>
    );
  }

  const uploadIcon = () => (
    <div className={styles.uploadIcon}>
      <img src={UploadIcon} alt={knowledgeEditorLang.UPLOAD} />
      <div>
        {values.link && <h4 className={styles.uploadTitle}>{`${values.title}.${extension}`}</h4>}
        <h4>{knowledgeEditorLang.UPLOAD}</h4>
      </div>
    </div>
  );

  return (
    <FileUpload
      name="link"
      wrapperClass={styles.uploadFile}
      onChange={(name, file) => onFileChange(file)}
      value={values.link}
      error={errors.link}
      renderIcon={uploadIcon}
    />
  );
}

export function RenderEditor({
  knowledgeId,
  isLoading,
  values,
  errors,
  touched,
  duplicatedAsset,
  onDuplicatedAssetCancel,
  onDuplicatedAssetLink,
  onFileChange,
  onContentChange,
  setLoadingState,
  setEditorFocused,
  handleChange,
  handleBlur,
}: {
  knowledgeId: string | undefined;
  isLoading: boolean;
  values: Partial<KnowledgeContentEditorFormValue>;
  errors: Partial<
    {
      [K in keyof KnowledgeContentEditorFormValue]: string;
    }
  >;
  touched: Partial<
    {
      [K in keyof KnowledgeContentEditorFormValue]: boolean;
    }
  >;
  duplicatedAsset: undefined | { knowledgeId: string };
  onDuplicatedAssetCancel: () => void;
  onDuplicatedAssetLink: () => void;
  onFileChange: (file: any) => void;
  onContentChange: (content: string) => void;
  setLoadingState: (...args: any[]) => void;
  setEditorFocused: (...args: any[]) => void;
  handleChange: (...args: any[]) => void;
  handleBlur: (...args: any[]) => void;
}) {
  const lang = useSelector(s => s.lang.KNOWLEDGE_EDITOR);

  const knowledgeType = values.type;
  const isContent = values.type === KNOWLEDGE_TYPES.CONTENT;
  const isExternalLink = values.type === KNOWLEDGE_TYPES.EXTERNAL_LINK;
  const isUploadedFile =
    values.type === KNOWLEDGE_TYPES.DOCUMENT ||
    values.type === KNOWLEDGE_TYPES.PDF ||
    values.type === KNOWLEDGE_TYPES.VIDEO ||
    values.type === KNOWLEDGE_TYPES.IMAGE;

  if (isContent) {
    return (
      <>
        {values.link && (
          <FileUploadBanner
            isLoading={isLoading}
            values={values}
            errors={errors}
            onFileChange={onFileChange}
            duplicatedAsset={duplicatedAsset}
            onDuplicatedAssetCancel={onDuplicatedAssetCancel}
            onDuplicatedAssetLink={onDuplicatedAssetLink}
          />
        )}
        <TinyEditor
          disabled={isLoading}
          id={knowledgeId}
          initialValue={values.content}
          onChange={(content: string) => {
            onContentChange(content);
          }}
          height="100vh"
          setLoadingContent={setLoadingState}
          setEditorFocused={setEditorFocused}
        />
        <div
          id="content-container"
          className={styles.hiddenContent}
          dangerouslySetInnerHTML={{
            __html: values.content || '',
          }}
        />
      </>
    );
  }

  if (isUploadedFile) {
    if (isLoading) {
      return <Loader />;
    }

    return (
      <div className={styles.fileUploadWrapper}>
        <FileUploadBanner
          isLoading={isLoading}
          values={values}
          errors={errors}
          onFileChange={onFileChange}
          duplicatedAsset={duplicatedAsset}
          onDuplicatedAssetCancel={onDuplicatedAssetCancel}
          onDuplicatedAssetLink={onDuplicatedAssetLink}
        />
        {knowledgeType === KNOWLEDGE_TYPES.PDF && values.link && (
          <ExternalLinkViewer link={values.link} title={values.title} />
        )}
      </div>
    );
  }

  if (isExternalLink) {
    return (
      <>
        <label>{lang.LINK}</label>
        <TextInput
          inputStyle="border"
          name="link"
          value={values.link}
          error={errors.link}
          touched={touched.link}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={lang.LINK_PLACEHOLDER}
          wrapperClass={styles.externalLinkInput}
          small
        />
        <ExternalLinkViewer link={values.link} title={values.title} />
      </>
    );
  }
}
