import { useEffect } from 'react';
import { Comment, CommentInput } from './components';
import { useKnowledgeComments } from './hooks';
import styles from './knowledge-comments.module.scss';
import classNames from 'classnames';
import { Skeleton } from 'antd';
import { faMessages } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function KnowledgeComments() {
  const { lang, comments, count, fetching, isEnabled } = useKnowledgeComments();

  // scroll to the comment in the hash
  useEffect(() => {
    if (window.location.hash.includes('comment') && !fetching) {
      const commentIdFromHash = window.location.hash.split('-')[1];
      const commentFromHash = document.getElementById(`comment-${commentIdFromHash}`);
      commentFromHash?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [fetching]);

  if (!isEnabled) {
    return null;
  }

  return (
    <section id="comments" className={classNames(styles.root, styles.topBorder)}>
      <h2 className={styles.title}>{lang.TITLE.replace('{count}', count)}</h2>

      {fetching && (
        <div>
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
        </div>
      )}

      <div className={styles.commentsWrapper}>
        {comments.map(comment => (
          <Comment key={comment.id} comment={comment} />
        ))}

        {comments.length === 0 && !fetching && (
          <div className={styles.noComments}>
            <FontAwesomeIcon icon={faMessages} />
            <p>{lang.NO_COMMENTS}</p>
          </div>
        )}
      </div>

      <CommentInput />
    </section>
  );
}
