import { connect } from 'react-redux';
import { SwitchOrganizationDropdownComponent } from './switch-organization-dropdown.component';
import { switchOrganization } from 'shared/store/actions/organization.actions';
import { isSwitchOrganizationAvailable } from 'shared/store/selectors/available-organizations.selector';

const mapStateToProps = ({ availableOrganizations, organization, lang }) => {
  return {
    organizations: availableOrganizations.organizations,
    activeOrganization: organization,
    isSwitchOrganizationAvailable: isSwitchOrganizationAvailable({
      availableOrganizations,
    }),
    lang: lang.ADD_ACCOUNT,
  };
};

export const SwitchOrganizationDropdown = connect(mapStateToProps, { switchOrganization })(
  SwitchOrganizationDropdownComponent,
);
