import { connect } from 'react-redux';
import { SelectContentComponent } from './select-content.component';
import { improvedSearchMechanism } from 'shared/store/selectors/search.selector';

const mapStateToProps = state => {
  return {
    doSearch: (term, filters) => improvedSearchMechanism(state, term, filters),
    lang: state.lang.CONTENT_SELECTOR,
  };
};

export const SelectContent = connect(mapStateToProps)(SelectContentComponent);
