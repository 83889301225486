import React from 'react';
import { PandaNotFound } from '../../../images/panda';
import styles from './NotFound.module.scss';
import { useSelector } from 'react-redux';
import { getLang } from 'shared/store/selectors/lang.selector';
import classNames from 'classnames';

const NotFound = ({ text, className }) => {
  const lang = useSelector(getLang('GENERAL'));
  return (
    <div className={classNames('new-design-system', styles.root, className)}>
      <img src={PandaNotFound} className={styles.panda} alt={text || lang.NO_ITEMS_FOUND} />
      <span>{text || lang.NO_ITEMS_FOUND}</span>
    </div>
  );
};

NotFound.defaultProps = {
  text: '',
  className: null,
};

export { NotFound };
