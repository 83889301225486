import { WORKSPACES_SELECT_WORKSPACE, WORKSPACES_UPDATE_CONTENT } from '.';
import workspaceServiceApi from '../../api/workspace.service.api';

export const fetchWorkspaces = () => async dispatch => {
  const { error, data } = await workspaceServiceApi.getWorkspaces();

  if (error) {
    console.log('Failed to fetch workspaces');
    return { error: data.message };
  }

  dispatch({
    type: WORKSPACES_UPDATE_CONTENT,
    payload: { workspaces: data },
  });
};

export const selectWorkspace = workspaceId => async dispatch => {
  await workspaceServiceApi.selectWorkspace({ workspaceId });

  dispatch({
    type: WORKSPACES_SELECT_WORKSPACE,
    payload: { selected: workspaceId },
  });
};
