import React from 'react';
import { AdmissionSectionList } from './components';
import { AdmissionBreadcrumbs } from 'src/components/breadcrumbs';
import styles from './admission.module.scss';
import { ScreenTitle } from 'src/components/design-system';
import { ViewSelector } from 'src/components/common/view-selector';
import classnames from 'classnames';

const AdmissionPageComponent = React.memo(function AdmissionPageComponent({
  id,
  category,
  children,
  lastUpdated,
  isReady,
  showBreadcrumbs,
}) {
  const { title, pathParts = [], fullPath, ownerIds, tags, workspaceId } = category;

  return (
    <div className={classnames([styles.root, 'new-design-system'])}>
      <div className={styles.header}>
        <div className={styles.headerTitle}>
          {showBreadcrumbs && (
            <AdmissionBreadcrumbs
              title={title}
              pathParts={pathParts}
              initialWorkspaceId={workspaceId}
            />
          )}

          <ScreenTitle title={title} />
        </div>
        <ViewSelector />
      </div>
      <AdmissionSectionList
        list={children}
        key={lastUpdated}
        fullPath={fullPath}
        isReady={isReady}
        ownerIds={ownerIds}
        id={id}
        tags={tags}
      />
    </div>
  );
});

AdmissionPageComponent.defaultProps = {
  isReady: false,
  lastUpdated: Date.now(),
  children: null,
  fullPath: '',
  showBreadcrumbs: false,
  category: {},
};

export { AdmissionPageComponent };
