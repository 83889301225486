import { connect } from 'react-redux';
import { AdmissionMovePopupComponent } from './admission-move.component';
import { moveChecklistAction } from 'shared/store/actions/admission.actions';
import { getLocationsOfCategories } from 'shared/store/selectors/admission.selector';

const mapStateToProps = ({ admission, lang }) => ({
  locations: getLocationsOfCategories(admission) || [],
  lang: lang.ADMISSION_MOVE,
});

export const AdmissionMovePopup = connect(mapStateToProps, {
  moveChecklistAction,
})(AdmissionMovePopupComponent);
