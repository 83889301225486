import { connect } from 'react-redux';
import { sendNotification } from 'shared/store/actions/communication-center.actions';
import { CommunicationCenterPageComponent } from './communication-center.component';
import { getSelectedWorkspaceId } from 'shared/store/selectors/workspace.selector';
import { RotationOptionsProvider } from 'shared/hooks/profile.context';

const mapStateToProps = ({ lang, workspace }) => ({
  lang: lang.COMMUNICATION_CENTER,
  currentWorkspaceId: getSelectedWorkspaceId({ workspace }),
});

const CommunicationCenterPageWrapper = ({ ...props }) => {
  return (
    <RotationOptionsProvider>
      <CommunicationCenterPageComponent {...props} />;
    </RotationOptionsProvider>
  );
};

export const CommunicationCenterPage = connect(mapStateToProps, {
  sendNotification,
})(CommunicationCenterPageWrapper);
