import React from 'react';
import classnames from 'classnames';
import { Tooltip } from 'antd';
import styles from './TagsIcons.module.scss';
import { theme } from 'shared/constants/theme';
import { getTagIcon } from '../../../constants/tagIconsMapper';
import { getLang } from 'shared/store/selectors/lang.selector';
import { useSelector } from 'react-redux';

const TagIcon = React.memo(function Icon({ tag, tooltip = null, large = false, className = '' }) {
  const Tag = getTagIcon(tag);
  const label = useSelector(getLang('TAGS'))[tag];

  if (!Tag) return null;

  return (
    <>
      <Tooltip
        title={tooltip ?? label}
        placement="topLeft"
        color={theme.tooltipColor}
        key={theme.tooltipColor}>
        <img
          src={Tag}
          className={classnames(large ? styles.largeTag : styles.tag, className)}
          alt={tooltip ?? label}
        />
      </Tooltip>
    </>
  );
});

export { TagIcon };
