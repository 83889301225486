import React from 'react';
import classnames from 'classnames';
import { Button } from '../../../core';
import { Cactus } from '../../../../images';
import styles from '../knowledge-upload-file.module.scss';
import { SUPPORTED_FILES_HELPDESK_LINK } from 'shared/constants/assets.constants';
import { useSelector } from 'src/store/use-selector';
import { getLang } from 'shared/store/selectors/lang.selector';

type ErrorType =
  | 'unsupported-file'
  | 'unsupported-file-for-smart-format'
  | 'file-too-big'
  | 'video-too-big'
  | 'upload-failed'
  | 'file-too-small';

function FailUpload({
  error,
  onCancel,
  onRetry,
  disableRetry = false,
}: {
  error: ErrorType;
  onCancel: () => void;
  onRetry: () => void;
  disableRetry: boolean;
}) {
  const lang = useSelector(getLang('KNOWLEDGE_UPLOAD_POPUP'));
  const title = lang.FAIL_TITLE;

  const subtitles: { [K in ErrorType]: string } = {
    'unsupported-file': lang.UNSUPPORTED_FILE_SUBTITLE,
    'unsupported-file-for-smart-format': lang.UNSUPPORTED_SMART_FORMAT_SUBTITLE,
    'file-too-big': lang.FILE_TOO_BIG_SUBTITLE,
    'video-too-big': lang.VIDEO_TOO_BIG_SUBTITLE,
    'upload-failed': lang.FAIL_SUBTITLE,
    'file-too-small': lang.FILE_EMPTY_SUBTITLE,
  };

  const subtitle = subtitles[error];

  const renderSubtitle = () => {
    const linkPlaceholderMatches = subtitle.match(/\{supportedFormatsLink:.*?\}/);

    if (!linkPlaceholderMatches) {
      return subtitle;
    }

    const linkPlaceholder = linkPlaceholderMatches[0]!;
    const linkText = linkPlaceholder.slice(22, -1); // Extract display text between "{supportedFormatsLink:" and "}"

    const parts = subtitle.split(linkPlaceholder);

    return (
      <>
        {parts[0]}
        <a href={SUPPORTED_FILES_HELPDESK_LINK} target="_blank" rel="noopener noreferrer">
          {linkText}
        </a>
        {parts[1]}
      </>
    );
  };

  return (
    <>
      <div className={styles.loading}>
        <img className={styles.cactus} src={Cactus} alt={title} />
        <h4 className={classnames(styles.title, styles.error)}>{title}</h4>
        <h6 className={styles.error}>{renderSubtitle()}</h6>
      </div>
      <div className={styles.buttons}>
        <Button className={styles.button} size="medium" onClick={onCancel}>
          {lang.DISMISS}
        </Button>
        <Button
          className={styles.button}
          size="medium"
          buttonStyle="primary"
          onClick={onRetry}
          disabled={disableRetry}
        >
          {lang.RETRY}
        </Button>
      </div>
    </>
  );
}

export { FailUpload };
