import {
  LAB_UPDATE_CATEGORY,
  LAB_DELETE_CATEGORY,
  LAB_CREATE_CATEGORY,
  LAB_UPDATE_SUBCATEGORIES,
} from '.';
import labServiceApi from '../../api/lab.service.api';

export const updateLabCategory =
  ({ id, title, ownerIds, tags, drugClass, path }) =>
  async dispatch => {
    let res = await labServiceApi.updateCategory({ id, title, ownerIds, tags, drugClass, path });

    if (res.error) {
      // TODO: add monitor
      return false;
    }

    dispatch({
      type: LAB_UPDATE_CATEGORY,
      payload: { id, title, ownerIds, tags, drugClass, path },
    });
  };

export const deleteLabCategory =
  ({ id }) =>
  async dispatch => {
    let res = await labServiceApi.deleteCategory({ id });

    if (res.error) {
      // TODO: add monitor
      return false;
    }

    dispatch({
      type: LAB_DELETE_CATEGORY,
      payload: { id },
    });
  };

export const createLabCategory =
  ({ path, title, type, ownerIds, tags, drugClass }) =>
  async (dispatch, getState) => {
    const workspaceId = getState().workspace.selected;

    let { error, data } = await labServiceApi.createCategory({
      data: {
        path,
        title,
        type,
        ownerIds,
        tags,
        drugClass,
      },
      workspaceId,
    });

    if (error) {
      // TODO: add monitor
      return false;
    }

    let { category } = data;

    dispatch({
      type: LAB_CREATE_CATEGORY,
      payload: {
        category,
        path,
        tags,
        drugClass,
      },
    });

    return category;
  };

export const updateLabCategorySubcategories =
  ({ id, categoryId, index }) =>
  async dispatch => {
    let res = await labServiceApi.updateCategorySubcategories({ id, categoryId, index });

    if (res.error) {
      // TODO: add monitor
      return false;
    }

    dispatch({
      type: LAB_UPDATE_SUBCATEGORIES,
      payload: { id, categoryId, index },
    });
  };
