import * as React from 'react';

function SvgComponent({ color = '#e6e6e6', ...props }) {
  return (
    <svg viewBox="0 0 49.636 49.636" {...props}>
      <g id="Group_239" transform="translate(-1020.615 -609.069)">
        <line
          id="Line_117"
          y2="41.636"
          transform="translate(1045.433 613.069)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeWidth="8"
        />
        <line
          id="Line_118"
          y2="41.636"
          transform="translate(1024.615 633.888) rotate(-90)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeWidth="8"
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
