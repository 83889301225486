import React, { useMemo, useState } from 'react';
import styles from './tab-team.module.scss';
import { SectionComponent } from 'src/components/common/section/section.component';
import { Button } from 'src/components/design-system';
import { useRotationsContext } from '../../../rotations-context';
import { notification } from 'antd';
import { TeamRow } from './team-row.component';
import { formatFullName } from 'shared/utils';
import rotationsServiceApi from 'shared/api/rotations.service.api';
import { CONTACT_TYPES, TEAM_GROUPS } from 'shared/constants';
import { Select } from 'src/components/design-system/forms';
import { NotFound } from 'src/components/common';

const TeamTabComponent = ({ contacts, userLang, getContactById }) => {
  const { selectedRotation, addTeamMembers, lang, canEdit } = useRotationsContext();
  const [loadingAdd, setLoadingAdd] = useState(false);
  const [newStaff, setNewStaff] = useState([]);
  const [newContacts, setNewContacts] = useState([]);
  const [api, contextHolder] = notification.useNotification();

  const staffOptions = useMemo(
    () =>
      contacts
        .filter(({ isUser = false }) => isUser)
        .filter(({ id }) => !selectedRotation?.team?.staff?.some(member => member.id === id))
        .map(contact => ({
          label: formatFullName(contact),
          value: contact.id,
        })),
    [contacts, selectedRotation],
  );

  const contactOptions = useMemo(
    () =>
      contacts
        .filter(({ id }) => !selectedRotation?.team?.contacts?.some(member => member.id === id))
        .map(contact => ({
          label: formatFullName(contact),
          value: contact.id,
        })),
    [contacts, selectedRotation],
  );

  const handleStaffAdd = value => {
    setNewStaff(value);
  };

  const handleContactAdd = value => {
    setNewContacts(value);
  };

  const handleSubmitAdd = async group => {
    setLoadingAdd(true);
    try {
      let payload = newStaff;
      if (group === TEAM_GROUPS.CONTACTS) {
        payload = newContacts;
      }
      payload = payload.map(id => ({
        id,
        type: getContactById(id).isUser ? CONTACT_TYPES.USER : CONTACT_TYPES.CONTACT,
      }));
      const res = await rotationsServiceApi.addMembersToTeam({
        rotationId: selectedRotation.id,
        group,
        payload,
      });

      if (res.error) {
        api.error({
          message: res.data.message || lang.ERROR_OCCURRED,
          placement: 'bottomRight',
        });
        return;
      }

      addTeamMembers(payload, group);
    } catch (err) {
      api.error({
        message: err.message || lang.ERROR_OCCURRED,
        placement: 'bottomRight',
      });
    } finally {
      setLoadingAdd(false);
      if (group === TEAM_GROUPS.STAFF) setNewStaff([]);
      if (group === TEAM_GROUPS.CONTACTS) setNewContacts([]);
    }
  };

  return (
    <>
      {contextHolder}
      <div className={styles.tableHeader}>
        <h4 className={styles.column}>{userLang.NAME}</h4>
        <h4 className={styles.column}>{userLang.PHONE}</h4>
        <h4 className={styles.column}>{userLang.PAGER}</h4>
        <h4 className={styles.column}>{userLang.CISCO}</h4>
        <h4 className={styles.column}>{userLang.EMAIL}</h4>
        <h4 className={styles.actionColumn}>{lang.ACTIONS}</h4>
      </div>
      <SectionComponent
        title={lang.CORE_STAFF.replace('{size}', selectedRotation?.team?.staff?.length || 0)}
        isOpen={selectedRotation?.team?.staff?.length > 0}
        showActions={false}
      >
        {canEdit && (
          <div className={styles.addNew}>
            <Select
              multiple
              size="large"
              onChange={(f, v) => handleStaffAdd(v)}
              options={staffOptions}
              value={newStaff}
            />
            <Button
              type="primary"
              onClick={() => handleSubmitAdd(TEAM_GROUPS.STAFF)}
              size="large"
              disabled={loadingAdd || !newStaff.length}
            >
              {lang.ADD}
            </Button>
          </div>
        )}
        {selectedRotation?.team?.staff?.length ? (
          selectedRotation.team.staff
            .map(member => getContactById(member.id))
            .sort((a, b) => a?.lastName?.localeCompare(b?.lastName))
            .map((staff, i) => {
              return <TeamRow key={i} member={staff} group={TEAM_GROUPS.STAFF} removable />;
            })
        ) : (
          <NotFound text={lang.NOT_FOUND_STAFF} />
        )}
      </SectionComponent>
      <SectionComponent
        title={lang.IMPORTANT_CONTACTS.replace(
          '{size}',
          selectedRotation?.team?.contacts?.length || 0,
        )}
        isOpen={selectedRotation?.team?.contacts?.length > 0}
        showActions={false}
      >
        {canEdit && (
          <div className={styles.addNew}>
            <Select
              multiple
              size="large"
              onChange={(f, v) => handleContactAdd(v)}
              options={contactOptions}
              value={newContacts}
            />
            <Button
              type="primary"
              onClick={() => handleSubmitAdd(TEAM_GROUPS.CONTACTS)}
              size="large"
              disabled={loadingAdd || !newContacts.length}
            >
              {lang.ADD}
            </Button>
          </div>
        )}
        {selectedRotation?.team?.contacts?.length ? (
          selectedRotation.team.contacts
            .map(member => getContactById(member.id))
            .sort((a, b) => a?.lastName?.localeCompare(b?.lastName))
            .map((contact, i) => {
              return <TeamRow key={i} member={contact} group={TEAM_GROUPS.CONTACTS} removable />;
            })
        ) : (
          <NotFound text={lang.NOT_FOUND_CONTACTS} />
        )}
      </SectionComponent>
      <SectionComponent
        title={lang.TRAINEES.replace('{size}', selectedRotation?.team?.trainees?.length || 0)}
        isOpen={selectedRotation?.team?.trainees?.length > 0}
        showActions={false}
      >
        {selectedRotation?.team?.trainees?.length ? (
          selectedRotation.team.trainees
            .map(member => getContactById(member.id))
            .sort((a, b) => a?.lastName?.localeCompare(b?.lastName))
            .map((trainee, i) => {
              return <TeamRow key={i} member={trainee} />;
            })
        ) : (
          <NotFound text={lang.NOT_FOUND_TRAINEES} />
        )}
      </SectionComponent>
    </>
  );
};

export { TeamTabComponent };
