import { useEffect, useRef, useState } from 'react';
import { EVENTS, KNOWLEDGE_TYPES } from 'shared/constants';
import uploadService from 'shared/services/upload.service';
import { FileWithPreview } from 'src/components/core';
import analyticsService from 'src/helpers/analytics.service';
import { useUploadProgress } from './upload-progress.hooks';

type OcrConversionData =
  | {
      status: 'IN_PROGRESS' | 'FAILED' | 'TIMEOUT';
      results?: undefined;
    }
  | { status: 'DONE'; results: unknown };

type Asset = {
  assetId: string;
  type: string;
  url: string;
};

type Duplication = {
  knowledgeId: string;
};

const useUploadLogic = ({
  convertPastItemToSmartFormat,
  editContent,
  editExternal,
  close,
  props,
}: {
  convertPastItemToSmartFormat: boolean;
  editContent: (arg: {
    id: string;
    categoryId: string;
    ownerIds: string[];
    title: string;
    content: unknown;
    link: string;
    type: string;
    fileName: string;
  }) => void;
  editExternal: (arg: {
    id: string;
    categoryId: string;
    ownerIds: string[];
    title: string;
    link: string;
    type: string;
    fileName: string;
  }) => void;
  close: () => void;
  props: any; // previously saved knowledge item
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [failed, setFailed] = useState<boolean>(false);
  const asset = useRef<Asset | undefined>(undefined);
  const [file, setFile] = useState<FileWithPreview | null>(null);

  const [smartFormat, setSmartFormat] = useState<boolean>(convertPastItemToSmartFormat);
  const [duplication, setDuplication] = useState<Duplication | null>(null);
  const cancelled = useRef(false);

  const { progress, status, onUploadProgress, completeProgress } = useUploadProgress({
    smartFormat,
    file,
    isDirectConversion: convertPastItemToSmartFormat,
  });

  /*
    ---
    FILE UPLOAD
    ---
  */

  /*
    Start upload process
  */
  const uploadFile = async ({
    newFile,
    skipOpenEditor = false,
  }: {
    newFile?: FileWithPreview;
    skipOpenEditor?: boolean;
  } = {}) => {
    cancelled.current = false;
    setLoading(true);
    analyticsService.track(EVENTS.KNOWLEDGE_ITEMS.UPLOAD_FILE_START);

    // Upload the file
    const uploadFileRes = (await uploadService.uploadFile({
      file: newFile ?? file,
      onUploadProgress,
    })) as
      | {
          assetId: string;
          url: string;
          type: string;
          error?: undefined;
        }
      | {
          error: string;
        };

    if (uploadFileRes?.error != null) {
      setFailed(true);
      setLoading(false);
      return { error: uploadFileRes?.error };
    }

    // Set the asset
    asset.current = uploadFileRes;

    // finalize upload
    const finalizeRes = await uploadService.finalizeUploadFile({ assetId: uploadFileRes?.assetId });

    if (finalizeRes?.error) {
      setFailed(true);
      setLoading(false);
      return { error: finalizeRes?.error };
    }

    // Check for duplicates
    if (finalizeRes?.duplicate) {
      setLoading(false);
      analyticsService.track(EVENTS.KNOWLEDGE_ITEMS.DUPLICATION_DETECTED);
      // show message with the duplicate knowledge item
      setDuplication(finalizeRes?.duplicate);
      return { error: 'duplicated-file', duplicate: finalizeRes?.duplicate };
    }

    // Convert to pdf
    const pdfAsset = await uploadService.convertAssetToPdf({ assetId: uploadFileRes?.assetId });

    if (pdfAsset?.error) {
      setFailed(true);
      setLoading(false);
      return { error: pdfAsset?.error };
    }

    // asset was converted to pdf
    if (pdfAsset) {
      asset.current = {
        ...asset.current,
        url: pdfAsset?.convertedFilePath,
        type: pdfAsset?.newFormat,
      };
    }

    if (!smartFormat && !skipOpenEditor) {
      openEditor();
    }

    return { asset: asset.current, cancelled: cancelled.current };
  };

  /*
    ---
    SMART FORMAT CONVERSION
    ---
  */

  const convertToSmartFormat = async () => {
    cancelled.current = false;
    setLoading(true);
    analyticsService.track(EVENTS.SMART_FORMAT_CONVERSION.START, {
      conversionTime: EVENTS.SMART_FORMAT_CONVERSION_TIME.IMMEDIATE,
    });

    const { error } = await uploadFile();

    if (error) return;

    await uploadService.triggerOcr({ assetId: asset.current?.assetId });

    pollSmartFormatConversionStatus();
  };

  async function pollSmartFormatConversionStatus() {
    while (true) {
      await new Promise(resolve => setTimeout(resolve, 300));
      try {
        const data = (await uploadService.fetchOcrData({
          assetId: asset.current!.assetId,
        })) as unknown as OcrConversionData;

        if (data.status === 'FAILED' || data.status === 'TIMEOUT') {
          analyticsService.track(EVENTS.SMART_FORMAT_CONVERSION.FAILED, {
            id: props?.id,
            title: props?.title,
            conversionTime: EVENTS.SMART_FORMAT_CONVERSION_TIME.IMMEDIATE,
          });

          setLoading(false);
          setFailed(true);

          break;
        } else if (data.status === 'DONE') {
          openEditor(data);

          break;
        }
      } catch (e) {
        setLoading(false);
        setFailed(true);

        break;
      }
    }
  }

  /*
    If knowledge item already exists, start smart conversion right away
  */
  useEffect(() => {
    (async () => {
      if (convertPastItemToSmartFormat && !loading && !failed) {
        setLoading(true);

        cancelled.current = false;

        analyticsService.track(EVENTS.SMART_FORMAT_CONVERSION.START, {
          id: props?.id,
          title: props?.title,
          conversionTime: EVENTS.SMART_FORMAT_CONVERSION_TIME.LATER,
        });

        //Trigger OCR
        const newAsset = await uploadService.retroOcrConversion({ itemId: props?.id });

        asset.current = newAsset;

        pollSmartFormatConversionStatus();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [convertPastItemToSmartFormat, failed, loading, props]);

  /*
    ---
    OPEN EDITOR
    after the file is uploaded or converted to smart format
    ---
  */
  function openEditor(data?: OcrConversionData) {
    completeProgress();

    const editFunction = smartFormat
      ? () =>
          editContent({
            ...props,
            content: data?.results,
            link: asset.current?.url!,
            type: KNOWLEDGE_TYPES.CONTENT,
            fileName: file?.name || props?.title,
          })
      : () =>
          editExternal({
            ...props,
            link: asset.current?.url,
            type: asset.current?.type,
            fileName: file!.name,
          });

    if (smartFormat) {
      analyticsService.track(EVENTS.SMART_FORMAT_CONVERSION.DONE, {
        id: props?.id,
        title: props?.title,
        conversionTime: EVENTS.SMART_FORMAT_CONVERSION_TIME.IMMEDIATE,
      });
    } else {
      analyticsService.track(EVENTS.KNOWLEDGE_ITEMS.UPLOAD_FILE_DONE);
    }

    setTimeout(() => {
      setLoading(false);
      close();

      if (!cancelled.current) {
        editFunction();
      }
    }, 300);
  }

  /*
    ---
    CANCEL AND RETRY FUNCTIONS
    ---
  */
  const cancelOnDuplication = async () => {
    setLoading(true);
    analyticsService.track(EVENTS.KNOWLEDGE_ITEMS.DUPLICATION_DETECTED_DISMISS);
    // Reject the duplicated item - notify the server to remove

    await uploadService.rejectDuplicatedItem({ assetId: asset.current?.assetId });

    // Reset the state
    setFailed(false);
    asset.current = undefined;
    setLoading(false);
    close();
  };

  const cancelProgress = () => {
    close();
    cancelled.current = true;
  };

  const cancelUpload = () => {
    setLoading(false);
    setFailed(true);
    cancelled.current = true;
  };

  const retryUpload = () => {
    setFailed(false);
    setFile(null);
  };

  const uploadFileHandler = (name?: string, file?: FileWithPreview) => {
    setFile(file ?? null);
  };
  const toggleSmartFormat = (value: boolean) => {
    analyticsService.track(EVENTS.SMART_FORMAT_CONVERSION.TOGGLE_STATE, {
      state: value,
    });
    setSmartFormat(value);
  };

  return {
    loading,
    failed,
    duplication,
    file,
    asset: asset.current,
    progress,
    status,
    isSmartFormat: smartFormat,
    cancelled: cancelled.current,
    toggleSmartFormat,
    uploadFile,
    convertToSmartFormat,
    cancelOnDuplication,
    cancelProgress,
    retryUpload,
    uploadFileHandler,
    cancelUpload,
    completeProgress,
  };
};

export { useUploadLogic };
