import React, { useCallback, useMemo } from 'react';
import { ConfigProvider, Divider, Tabs } from 'antd';
import { RotationsList } from './rotations-list.component';
import { SearchBox } from 'src/components/design-system/forms/search-box.component';
import { useRotationsContext } from '../rotations-context';
import styles from './rotations-list.module.scss';

const RotationsTabs = () => {
  const { lang, myRotations, mySchedule, allRotations, filter, setTab, setFilter, tab } =
    useRotationsContext();

  const filterByName = useCallback(
    ({ name }) => {
      if (!filter?.length) {
        return true;
      }
      return name.toLowerCase().includes(filter.toLowerCase());
    },
    [filter],
  );

  const tabs = useMemo(() => {
    return [
      {
        key: lang.MY_SCHEDULE,
        label: lang.MY_SCHEDULE,
        children: <RotationsList rotations={mySchedule.filter(filterByName)} />,
        shouldRender: mySchedule.length > 0,
      },
      {
        key: lang.MY_ROTATIONS,
        label: lang.MY_ROTATIONS,
        children: <RotationsList rotations={myRotations.filter(filterByName)} />,
        shouldRender: myRotations.length > 0,
      },
      {
        key: lang.ALL_ROTATIONS,
        label: lang.ALL_ROTATIONS,
        children: <RotationsList rotations={allRotations.filter(filterByName)} />,
        shouldRender: allRotations.length > 0,
      },
    ];
  }, [lang, allRotations, mySchedule, myRotations, filterByName]);

  return (
    <div className={styles.viewSelector}>
      <SearchBox
        size="large"
        value={filter}
        onChange={e => setFilter(e.target.value)}
        placeholder={lang.FILTER_PLACEHOLDER}
        allowClear
      />
      <Divider style={{ marginBottom: 0, marginTop: '18px' }} />
      <ConfigProvider
        theme={{
          components: {
            Tabs: {
              colorBorderSecondary: '#D9D9D9',
              titleFontSize: '16px',
              horizontalMargin: 0,
            },
          },
        }}
      >
        <Tabs
          defaultActiveKey={tab || lang.MY_SCHEDULE}
          items={tabs.filter(({ shouldRender }) => shouldRender)}
          style={{ fontWeight: '500', minHeight: 0, overflowY: 'auto' }}
          onChange={setTab}
        />
      </ConfigProvider>
    </div>
  );
};

export { RotationsTabs };
