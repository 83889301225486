import React from 'react';
import { Lang } from 'shared/constants';
import { Popup } from 'src/components/common';
import { Button, Loader } from 'src/components/core';
import styles from './checklist-item-delete.module.scss';

const ChecklistItemDeleteComponent = React.memo(function ChecklistItemDeleteComponent({
  lang,
  deleteCheck,
  entity,
  categoryId,
  onFinish,
}) {
  const [isLoading, setLoadingState] = React.useState(false);

  const deleteLabAction = React.useCallback(async () => {
    setLoadingState(true);

    // TODO: analytics
    await deleteCheck({ categoryId, id: entity.id });

    onFinish();
    setLoadingState(false);
  }, [categoryId, deleteCheck, entity.id, onFinish]);

  const dismiss = React.useCallback(() => {
    // TODO: analytics

    onFinish();
  }, [onFinish]);

  if (!entity) return null;

  return (
    <Popup title={lang.TITLE} isOpen={true}>
      <p className={styles.content}>{lang.TEXT.replace('{name}', entity?.title)}</p>

      {!isLoading && (
        <div className={styles.buttons}>
          <Button
            className={styles.button}
            size="medium"
            onClick={deleteLabAction}
            buttonStyle="danger"
          >
            {lang.CONFIRM}
          </Button>
          <Button className={styles.button} onClick={dismiss} size="medium">
            {lang.DISMISS}
          </Button>
        </div>
      )}
      {isLoading && <Loader />}
    </Popup>
  );
});

ChecklistItemDeleteComponent.defaultProps = {
  lang: Lang.DELETE_CHECKLIST_ITEM_CONFIRMATION,
  entity: null,
  categoryId: null,
  deleteCheck: () => {},
  onFinish: () => {},
};

export { ChecklistItemDeleteComponent };
