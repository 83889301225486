import * as React from 'react';

function SvgComponent({ color = '#2c5789', ...props }) {
  return (
    <svg id="prefix__Extra_Checkmarks" data-name="Extra Checkmarks" viewBox="0 0 24 24" {...props}>
      <path
        id="prefix__Path_86"
        data-name="Path 86"
        fill={color}
        d="M21.65 24a1.72 1.72 0 01-1.21-.5l-5.18-5.17a.41.41 0 010-.57.4.4 0 01.56 0L21 22.94a.91.91 0 001.28 0l.55-.56a.91.91 0 000-1.28L17.69 16a.4.4 0 01.57-.56l5.15 5.14a1.7 1.7 0 010 2.4l-.56.56a1.68 1.68 0 01-1.2.5z"
      />
      <path
        id="prefix__Path_87"
        data-name="Path 87"
        fill={color}
        d="M10 .8a9.08 9.08 0 106.44 2.66A9.08 9.08 0 0010 .8zm0 19a9.9 9.9 0 119.9-9.9 9.9 9.9 0 01-9.9 9.9z"
      />
      <path
        id="prefix__Path_88"
        data-name="Path 88"
        fill={color}
        d="M7.78 16.14a.35.35 0 01-.14 0A6.46 6.46 0 015.3 14.6 6.65 6.65 0 014 7a.41.41 0 01.54-.19.4.4 0 01.18.54 5.86 5.86 0 002.76 7.81l.44.19a.4.4 0 01.24.51.43.43 0 01-.38.28z"
      />
    </svg>
  );
}

export default SvgComponent;
