import { LAB_SELECT_ITEM, LAB_SET_ITEM_DETAILS } from 'shared/store/actions';
import update from 'immutability-helper';

const initialState = {
  id: null,
  itemId: null,
};

const selectItem = (state, { id = null }) => {
  return update(state, {
    $merge: { id },
  });
};

const setItemDetails = (state, { itemId = null }) => {
  return update(state, {
    $merge: { itemId },
  });
};

const actionsRepo = {
  [LAB_SELECT_ITEM]: selectItem,
  [LAB_SET_ITEM_DETAILS]: setItemDetails,
};

export default function (state = initialState, { type, payload }) {
  if (!!actionsRepo[type]) {
    return actionsRepo[type](state, payload);
  }

  return state;
}
