import React from 'react';
import styles from './extend-expiration-buttons.module.scss';
import { DateTime } from 'luxon';
import moment from 'moment';
import { useCallback } from 'react';
import { notification } from 'antd';
import classNames from 'classnames';
import { LoadingOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { getLang, getLangKey } from 'shared/store/selectors/lang.selector';

const getExpirationIncrementOptions = lang => [
  { value: 0.5, label: lang.ADD_MONTHS ? lang.ADD_MONTHS.replace('{number}', '6') : '' },
  { value: 1, label: lang.ADD_YEAR ? lang.ADD_YEAR.replace('{number}', '1') : '' },
  { value: 2, label: lang.ADD_YEARS ? lang.ADD_YEARS.replace('{number}', '2') : '' },
];

const expirationMessageMapper = (lang, expiresAt, langKey) => {
  const timeObject = DateTime.fromISO(expiresAt);
  const daysDifference = parseInt(timeObject.diffNow(['days']).get('days'));
  const relativeTime = timeObject.setLocale(langKey.toLowerCase()).toRelative({ days: 1 });
  const textStyle = daysDifference < 0 ? styles.expirationMessage : styles.aboutToBeExpired;

  let expiresString = `${lang.EXPIRES_IN_N_DAYS} ${relativeTime}`;
  if (daysDifference < 0) {
    expiresString = `${lang.EXPIRED} ${relativeTime}`;
  } else if (daysDifference === 0) {
    expiresString = lang.EXPIRES_TODAY;
  } else if (daysDifference === 1) {
    expiresString = lang.EXPIRES_TOMORROW;
  }

  return <div className={textStyle}>{expiresString}</div>;
};

const onIncreaseExpirationDate = async (newValue, previousValue, callback) => {
  // antd date picker handles dates with moment and returns moment object
  if (moment.isMoment(previousValue)) {
    previousValue = previousValue.toISOString();
  }

  const date = DateTime.fromISO(previousValue).plus({ years: newValue }).toISO();
  await callback(date);
};

const ExtendExpirationButtons = ({
  onExtendExpiration,
  wrapperStyle,
  buttonStyle,
  expiresAt,
  showExpirationMessage = false,
  showToastMessage = false,
}) => {
  const lang = useSelector(getLang('KNOWLEDGE_EDITOR'));
  const langKey = useSelector(getLangKey());

  const [api, contextHolder] = notification.useNotification();
  const [isLoading, setLoader] = React.useState(false);
  const openNotification = useCallback(() => {
    api.success({
      message: lang.EXPIRATION_EXTENDED,
      placement: 'bottomRight',
    });
  }, [api, lang]);

  const extendAction = useCallback(
    async e => {
      if (showToastMessage) {
        setLoader(true);
      }

      await onIncreaseExpirationDate(e.target.value, expiresAt, onExtendExpiration);

      if (showToastMessage) {
        openNotification();
        setLoader(false);
      }
    },
    [expiresAt, onExtendExpiration, openNotification, showToastMessage],
  );

  return (
    <>
      {contextHolder}
      <div className={classNames(styles.root, showExpirationMessage && styles.separate)}>
        {showExpirationMessage && expirationMessageMapper(lang, expiresAt, langKey)}
        {!isLoading && (
          <div className={wrapperStyle}>
            {lang.EXTEND_BY}
            {getExpirationIncrementOptions(lang).map(({ value, label }) => (
              <button
                className={classNames(styles.extendTime, buttonStyle)}
                key={value}
                type="button"
                onClick={extendAction}
                value={value}
              >
                {label}
              </button>
            ))}
          </div>
        )}

        {isLoading && <LoadingOutlined />}
      </div>
    </>
  );
};

export { ExtendExpirationButtons };
