import { connect } from 'react-redux';
import { ContactsComponent } from './contacts.component';
import { getContacts } from 'shared/store/selectors/contact.selector';
import { deleteContact } from 'shared/store/actions/contacts.actions';
import { getSelectedWorkspaceId, getWorkspaces } from 'shared/store/selectors/workspace.selector';
import { RotationOptionsProvider } from 'shared/hooks/profile.context';

const mapStateToProps = ({ lang, contacts, usersManagement, workspace }) => {
  return {
    lang: lang.CONTACT_SCREEN,
    contacts: getContacts({ contacts, usersManagement }),
    departmentsLang: lang.TAGS,
    selectedWorkspaceId: getSelectedWorkspaceId({ workspace }),
    workspaces: getWorkspaces({ workspace }),
  };
};

const ContactsPageWrapper = ({ ...props }) => {
  return (
    <RotationOptionsProvider>
      <ContactsComponent {...props} />
    </RotationOptionsProvider>
  );
};

export const ContactsPage = connect(mapStateToProps, { deleteContact })(ContactsPageWrapper);
