export const TAGS_SPECIALTY = {
  ALLERGY_IMMUNOLOGY: 'ALLERGY_IMMUNOLOGY',
  ANESTHESIOLOGY: 'ANESTHESIOLOGY',
  DERMATOLOGY: 'DERMATOLOGY',
  RADIOLOGY: 'RADIOLOGY',
  EMERGENCY_MEDECINE: 'EMERGENCY_MEDECINE',
  INTENSIVE_CARE: 'INTENSIVE_CARE',
  FAMILY_MEDECINE: 'FAMILY_MEDECINE',
  INTERNAL_MEDECINE: 'INTERNAL_MEDECINE',
  NEUROLOGY: 'NEUROLOGY',
  CARDIOLOGY: 'CARDIOLOGY',
  NEPHROLOGY: 'NEPHROLOGY',
  OBSTETRICS: 'OBSTETRICS',
  GYNECOLOGY: 'GYNECOLOGY',
  OPHTHALMOLOGY: 'OPHTHALMOLOGY',
  PATHOLOGY: 'PATHOLOGY',
  PEDIATRICS: 'PEDIATRICS',
  PHYSICAL_MEDECINE_REHAB: 'PHYSICAL_MEDECINE_REHAB',
  PSYCHIATRY: 'PSYCHIATRY',
  ONCOLOGY: 'ONCOLOGY',
  UROLOGY: 'UROLOGY',
  PNEUMOLOGY: 'PNEUMOLOGY',
  ORTHOPEDICS: 'ORTHOPEDICS',
  NEUROSURGERY: 'NEUROSURGERY',
  EARS_NOSE_THROAT: 'EARS_NOSE_THROAT',
  SURGERY: 'SURGERY',
  DIGESTIVE_SURGERY: 'DIGESTIVE_SURGERY',
  INFECTIOUS_DISEASES: 'INFECTIOUS_DISEASES',
  HEMATHOLOGY: 'HEMATHOLOGY',
  ENDOCRINOLOGY: 'ENDOCRINOLOGY',
  PHARMACOLOGY: 'PHARMACOLOGY',
  ANGIOLOGY: 'ANGIOLOGY',
  HEMOSTASIS: 'HEMOSTASIS',
  COVID_19: 'COVID_19',
  PRE_HOSPITAL_EMERGENCY_MEDECINE: 'PRE_HOSPITAL_EMERGENCY_MEDECINE',
  MEDICAL_DIRECTORATE: 'MEDICAL_DIRECTORATE',
  THORACIC_SURGERY: 'THORACIC_SURGERY',
  INTERVENTIONAL_CARDIOLOGY: 'INTERVENTIONAL_CARDIOLOGY',
  PLASTIC_RECONSTRUCTIVE_SURGERY: 'PLASTIC_RECONSTRUCTIVE_SURGERY',
  GASTROENTEROLOGY: 'GASTROENTEROLOGY',
  QUALITY: 'QUALITY',
  TRANSPLANTATION: 'TRANSPLANTATION',
  PAIN_MEDICINE: 'PAIN_MEDICINE',
  PERIOPERATIVE_MEDICINE: 'PERIOPERATIVE_MEDICINE',
  CARDIAC_SURGERY: 'CARDIAC_SURGERY',
  VASCULAR_SURGERY: 'VASCULAR_SURGERY',
  CHILD_ADVOCACY: 'CHILD_ADVOCACY',
  CHILD_DEVELOPMENT: 'CHILD_DEVELOPMENT',
  NEONATOLOGY: 'NEONATOLOGY',
  PRIMARY_CARE: 'PRIMARY_CARE',
  INTEGRATIVE_MEDICINE: 'INTEGRATIVE_MEDICINE',
  RESEARCH: 'RESEARCH',
  GENETICS: 'GENETICS',
  GENERAL_SURGERY: 'GENERAL_SURGERY',
  BEHAVIORAL_AND_MENTAL_HEALTH: 'BEHAVIORAL_AND_MENTAL_HEALTH',
};

export const TAGS_ROTATION = {
  CARDIOTHORACIC_ANESTHESIA: 'CARDIOTHORACIC_ANESTHESIA',
  SICU: 'SICU',
  OBSTETRIC_ANESTHESIA: 'OBSTETRIC_ANESTHESIA',
  REGIONAL_ANESTHESIA: 'REGIONAL_ANESTHESIA',
  NEURO_ANESTHESIA: 'NEURO_ANESTHESIA',
  NORA: 'NORA',
};

export const TAGS_KNOWLEDGE_CONTENT_TYPE = {
  EDUCATION: 'EDUCATION',
  ADMINISTRATIVE: 'ADMINISTRATIVE',
  TECH_SKILLS: 'TECH_SKILLS',
  PRE_GRADUATE: 'PRE_GRADUATE',
  LOCAL_PROTOCOLS: 'LOCAL_PROTOCOLS',
  INTERNATIONAL_GUIDELINES: 'INTERNATIONAL_GUIDELINES',
  SCHEDULES: 'SCHEDULES',
  POLICIES_AND_PROCEDURES: 'POLICIES_AND_PROCEDURES',
  JOURNAL_ARTICLE: 'JOURNAL_ARTICLE',
  TEXTBOOK: 'TEXTBOOK',
};

export const TAGS_LAB_CONTENT_TYPE = {
  LAB_VALUES: 'LAB_VALUES',
  DRUG_DOSAGE: 'DRUG_DOSAGE',
  CLINICAL_DECISION: 'CLINICAL_DECISION',
};

export const TAGS_CHECKLIST_CONTENT_TYPE = {
  CLINICAL: 'CLINICAL',
  EDUCATION: 'EDUCATION',
  SETUP: 'SETUP',
  SAFETY: 'SAFETY',
  QUALITY: 'QUALITY',
  COMMUNICATION: 'COMMUNICATION',
};

export const TAGS_TARGET_AUDIENCE = {
  DOCTORS: 'DOCTORS',
  NURSES: 'NURSES',
  PARAMEDICAL: 'PARAMEDICAL',
  PHYSIOTHERAPISTS: 'PHYSIOTHERAPISTS',
  MIDWIVES: 'MIDWIVES',
  MEDICAL_RESIDENT: 'MEDICAL_RESIDENT',
  ANESTHESIA_TECHNICIAN: 'ANESTHESIA_TECHNICIAN',
  PHYSICIAN_ASSISTANT: 'PHYSICIAN_ASSISTANT',
};

export const TAGS_KEYS = {
  SPECIALTY: 'SPECIALTY',
  SUBSPECIALTY: 'SUBSPECIALTY',
  CONTENT_TYPE: 'CONTENT_TYPE',
  TARGET_AUDIENCE: 'TARGET_AUDIENCE',
};

export const TAGS_KEYS_RAW = {
  SPECIALTY: 'specialty',
  SUBSPECIALTY: 'subspecialty',
  CONTENT_TYPE: 'contentType',
  TARGET_AUDIENCE: 'targetAudience',
};
