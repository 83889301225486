import { INTERNAL_ROUTING, ROUTES } from 'shared/constants';
import { KnowledgeBinderComponent } from '../../knowledge-binder/knowledge-binder.component';
import { useKnowledgeHub } from '../hooks/use-knowledge-hub.hooks';
import { getQueryParam } from 'src/helpers/routing.helper';
import { useLocation } from 'react-router-dom-v5-compat';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { getLang } from 'shared/store/selectors/lang.selector';

export const RenderBinder = () => {
  const { binder, loadingBinder } = useKnowledgeHub();
  const history = useHistory();
  const { pathname, search } = useLocation();

  const lang = useSelector(getLang('KNOWLEDGE_BUTTONS'));
  const langTypes = useSelector(getLang('SEARCH_TYPES'));
  const langTooltip = useSelector(getLang('ACTION_BUTTON_TOOLTIPS'));

  const id = getQueryParam({ key: INTERNAL_ROUTING.QUERY_PARAMS.HUB });

  if (!id) return null;

  const selectBinder = id => {
    const searchParams = new URLSearchParams(search);
    if (id) {
      searchParams.set(INTERNAL_ROUTING.QUERY_PARAMS.HUB, id);
    } else {
      searchParams.delete(INTERNAL_ROUTING.QUERY_PARAMS.HUB);
    }

    history.push({ search: searchParams.toString() }, { shallow: true });
  };

  return (
    <KnowledgeBinderComponent
      id={id}
      {...binder}
      routingParam={INTERNAL_ROUTING.QUERY_PARAMS.HUB}
      knowledgeSelectItem={selectBinder}
      sharePath={`${pathname}${search}`}
      loading={loadingBinder}
      itemConfig={{
        hideFavorites: true,
        hideInfo: true,
        basePath: ROUTES.KNOWLEDGE_HUB_ITEMS,
      }}
      lang={lang}
      langTypes={langTypes}
      langTooltip={langTooltip}
    />
  );
};
