import React from 'react';
import { TextAreaInput } from 'src/components/design-system/forms';
import { faPaperPlaneTop } from '@fortawesome/pro-regular-svg-icons/faPaperPlaneTop';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormik } from 'formik';
import styles from './render-user-input.module.scss';
import { useChatConversation } from '../../hooks/use-chat-conversation';
import { CHAT_SCHEMA, MAX_MESSAGE_LENGTH } from '../chat-input-schema';
import ReactMarkdown from 'react-markdown';

export function RenderUserInput() {
  const { loading = false, onMessageSend, lang } = useChatConversation();
  const onSubmit = React.useCallback(
    async (values, { resetForm }) => {
      if (loading) return;

      onMessageSend(values);

      resetForm();
    },
    [loading, onMessageSend],
  );

  const { handleChange, handleBlur, handleSubmit, values, touched, isValid } = useFormik({
    validationSchema: CHAT_SCHEMA,
    initialValues: {
      message: '',
    },
    onSubmit,
  });

  const handleKeyDown = e => {
    // Check if Shift key is pressed along with Enter
    if (e.key === 'Enter' && e.shiftKey) {
      return;
    }
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSubmit();
    }
  };

  return (
    <form className={styles.root} onSubmit={handleSubmit}>
      <div className={styles.userInput}>
        <TextAreaInput
          placeholder={lang.PLACEHOLDER}
          className={styles.input}
          name="message"
          value={values.message}
          touched={touched.message}
          onChange={handleChange}
          onBlur={handleBlur}
          maxLength={MAX_MESSAGE_LENGTH}
          autoSize={{ minRows: 1, maxRows: 3 }}
          onKeyDown={handleKeyDown}
        />
        <button className={styles.sendBtn} disabled={!isValid || loading}>
          <FontAwesomeIcon icon={faPaperPlaneTop} />
        </button>
      </div>
      <ReactMarkdown className={styles.disclaimer}>{lang.DISCLAIMER}</ReactMarkdown>
    </form>
  );
}
