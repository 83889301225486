import { connect } from 'react-redux';
import { createContact, updateContact } from 'shared/store/actions/contacts.actions';
import { ContactDetailsFormComponent } from './contact-details-form.component';
import { isCollaborator } from 'shared/store/selectors/auth.selector';
import { getSelectedWorkspaceId, getWorkspaces } from 'shared/store/selectors/workspace.selector';

const mapStateToProps = ({ lang, auth, workspace }) => {
  return {
    lang: lang.CONTACT_SCREEN,
    canEdit: isCollaborator({
      auth,
    }),
    workspaces: getWorkspaces({ workspace }),
    selectedWorkspaceId: getSelectedWorkspaceId({ workspace }),
  };
};

export const ContactDetailsForm = connect(mapStateToProps, {
  createContact,
  updateContact,
})(ContactDetailsFormComponent);
