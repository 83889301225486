import API from './API';
import { GET, POST, PUT, DELETE } from '../utils/Executer';
import { USER_SERVICE_URL as servicePath } from '../constants/APIconstants';

class UserServiceAPI {
  async me() {
    return await API.client.execute(
      GET,
      `${servicePath}me`,
      {},
      {
        skipGenericErrorHandling: true,
      },
    );
  }

  async getUsers() {
    return await API.client.execute(GET, `${servicePath}`);
  }

  async getStats() {
    return await API.client.execute(GET, `${servicePath}groups/stats`);
  }

  async setUserPermission({ id, permissions }) {
    return await API.client.execute(PUT, `${servicePath}/${id}/permissions`, {
      permissions,
    });
  }

  async inviteUsers({ emails }) {
    return await API.client.execute(POST, `${servicePath}/invite-users`, {
      emails,
    });
  }

  async updateUserProfile({ user }) {
    return await API.client.execute(PUT, `${servicePath}profile`, user);
  }

  async snoozeProfile() {
    return await API.client.execute(PUT, `${servicePath}profile/snooze`);
  }

  async createUser({ user }) {
    return await API.client.execute(POST, servicePath, user);
  }

  async createUserInBulk({ users }) {
    return await API.client.execute(POST, `${servicePath}bulk`, users);
  }

  async updateUser({ id, user, altOwnerId }) {
    const urlSearchParams = new URLSearchParams();

    if (altOwnerId) {
      urlSearchParams.append('altOwnerId', altOwnerId);
    }

    return await API.client.execute(PUT, `${servicePath}${id}?${urlSearchParams}`, user);
  }
  async deleteUser({ id, ownerId }) {
    const urlSearchParams = new URLSearchParams();

    if (ownerId) {
      urlSearchParams.append('ownerId', ownerId);
    }

    return await API.client.execute(DELETE, `${servicePath}${id}?${urlSearchParams}`);
  }
}

export default new UserServiceAPI();
