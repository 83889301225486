import { connect } from 'react-redux';
import { OTPConfirm } from './otp-confirmation-modal.component';
import { activateAccount } from 'shared/store/actions/auth.actions';

const mapStateToProps = ({ lang }) => ({
  lang: lang.ADD_ACCOUNT,
});

const mapDispatchToProps = {
  next: activateAccount,
};

export default connect(mapStateToProps, mapDispatchToProps)(OTPConfirm);
