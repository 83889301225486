import React from 'react';
import { normalizedContains, sortByAlphabet } from 'shared/utils';
import styles from './forms.module.scss';
import { ConfigProvider, Select, Tag } from 'antd';
import { antdTheme as theme, colorPalette } from 'shared/constants';

const { Option } = Select;

const TagSelector = React.memo(function TagSelector({
  label = null,
  value,
  options,
  onChange,
  name = '',
  placeholder = '',
  error = null,
  disabled = false,
  multiple = false,
  optionsRender = null,
  ...props
}) {
  const handleOnChange = value => {
    onChange(name, value);
  };

  const tagRender = props => {
    const { value, closable, onClose } = props;
    const onPreventMouseDown = event => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        // color={value}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ margin: 3 }}
      >
        {value}
      </Tag>
    );
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Select: {
            borderRadius: 8,
            colorBorder: theme.colorBorder,
            colorTextPlaceholder: theme.colorTextPlaceholder,
          },
          Tag: {
            defaultBg: colorPalette.primaryMain1,
            colorBorder: colorPalette.primaryMain5,
            lineHeightSM: 2,
          },
        },
      }}
    >
      <div className={styles.root}>
        {label && <label>{label}</label>}
        <Select
          mode="tags"
          value={value && !disabled ? value : null}
          error={error}
          status={error && 'error'}
          placeholder={placeholder}
          name={name}
          onChange={handleOnChange}
          filterOption={(inputValue, option) => normalizedContains(option?.value, inputValue)}
          disabled={disabled}
          showSearch
          allowClear
          tagRender={tagRender}
          {...props}
        >
          {options
            .sort((a, b) => sortByAlphabet(a, b))
            .map(key => (
              <Option key={key.toString(36)}>{key}</Option>
            ))}
        </Select>
        <span className={styles.error}>{error}</span>
      </div>
    </ConfigProvider>
  );
});

export { TagSelector };
