import { connect } from 'react-redux';
import { KnowledgePageComponent } from './knowledge.page.component';
import { getKnowledgeContent } from 'shared/store/actions/knowledge.actions';
import {
  getCategoryById,
  getCategoriesById,
  getRoot,
} from 'shared/store/selectors/knowledge.selector';

const mapStateToProps = ({ knowledge }, { match }) => {
  let { id } = match.params;

  let isRoot = !id;

  let category = isRoot ? getRoot(knowledge) : getCategoryById(knowledge, { id });

  let children = getCategoriesById(knowledge, category.id);

  return {
    id: category.id,
    ...category,
    children,
    isRoot,
    lastUpdated: knowledge.lastUpdated,
    isReady: knowledge.isReady,
  };
};

export const KnowledgePage = connect(mapStateToProps, { getKnowledgeContent })(
  KnowledgePageComponent,
);
