import React, { useCallback, useState, useEffect } from 'react';
import { useHeadingsData } from './table-of-contents.hooks';
import styles from './table-of-contents.module.scss';
import { Anchor, ConfigProvider } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsRotate } from '@fortawesome/pro-solid-svg-icons';

const bullets = ['\u2022', '\u25E6', '\u25AA', '\u25CF', '\u25A0'];
const getBullet = level => bullets[(level - 1) % bullets.length];

const TOCTitle = ({ level, title }) => {
  return (
    <li className={styles.tocTitle} data-bullet={getBullet(level)}>
      {title}
    </li>
  );
};

const TableOfContents = ({
  itemId = null,
  content: itemContent = null,
  readOnly = false,
  lang = {},
}) => {
  const [content, setContent] = useState(itemContent);

  const refreshToc = useCallback(() => {
    setContent(itemContent);
  }, [setContent, itemContent]);

  useEffect(() => {
    refreshToc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { headings } = useHeadingsData({ content });

  return (
    <div className={styles.root}>
      {readOnly && (
        <div className={styles.refresh} onClick={refreshToc}>
          <FontAwesomeIcon icon={faArrowsRotate} className={styles.refreshIcon} />
          <p>{lang.REFRESH_TOC}</p>
        </div>
      )}
      <div className={styles.toc}>
        <ConfigProvider
          theme={{
            token: {
              lineWidth: 0,
              lineWidthBold: 0,
            },
          }}
        >
          <Anchor
            affix={false}
            showInkInFixed={false}
            items={headings}
            getContainer={() => document.getElementById('full-width')}
            className={styles.anchor}
          />
        </ConfigProvider>
      </div>
    </div>
  );
};

export { TableOfContents, TOCTitle };
