import React, { useCallback, useEffect } from 'react';
import { useSearchResultsPreparation } from 'shared/hooks/search.hooks';
import { colorPalette, SEARCH_TYPES_KEYS } from 'shared/constants';
import styles from './select-content.module.scss';
import { TextInput } from 'src/components/design-system/forms';
import { Button } from 'src/components/design-system';
import { Divider } from 'antd';
import { KnowledgeItemRow } from '../knowledge';
import { LabItemRow } from '../lab';
import { ChecklistItemRow } from '../admission';
import { CategoryItemRow } from './category-item-row.component';
import * as Yup from 'yup';
import { NotFound } from 'src/components/common';

const url = Yup.string().url().required('Required!');

const SelectContentComponent = ({
  doSearch,
  disabledIds,
  loading,
  lang,
  onCancel = () => {},
  onSubmit = () => {},
}) => {
  const [selected, setSelected] = React.useState({});
  const [results, setResults] = React.useState([]);
  const [error, setError] = React.useState('');
  const [filter, setFilter] = React.useState('');
  const [newTitle, setNewTitle] = React.useState(selected.title || '');
  const { prepareResults } = useSearchResultsPreparation({}, null);
  const isSelected = React.useCallback(
    ({ id, workspaceId }) => selected.id === id && selected.workspaceId === workspaceId,
    [selected],
  );
  const isDisabled = React.useCallback(id => disabledIds.includes(id), [disabledIds]);

  useEffect(() => {
    setError('');
    setSelected({});
    setNewTitle('');

    const search = doSearch(filter, {});
    const { resultsCount, labItems, ...restResults } = prepareResults(search.results, true, true);

    const sortedResults = Object.values(restResults)
      .flat()
      .sort((a, b) => {
        if (a.score === undefined) return 1;
        if (b.score === undefined) return -1;
        return a.score - b.score;
      });
    setResults(sortedResults);

    if (resultsCount === 0) {
      setNewTitle(filter);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const handleSubmit = useCallback(async () => {
    if (!selected?.id) {
      try {
        await url.validate(filter);
        setError('');
      } catch (e) {
        setError(e.message);
        return;
      }
      setFilter('');
    }
    onSubmit({ ...selected, title: newTitle, url: selected?.id ? undefined : filter });
    setSelected({});
    setNewTitle('');
  }, [onSubmit, selected, newTitle, filter]);

  const handleSelect = useCallback(
    item => {
      if (isSelected(item)) {
        setSelected({});
        setNewTitle('');
      } else {
        setSelected(item);
        setNewTitle(item.title);
      }
    },
    [isSelected],
  );

  const renderByType = React.useMemo(
    () => ({
      [SEARCH_TYPES_KEYS.documents]: ({ item }, i) => (
        <KnowledgeItemRow
          key={i}
          item={item}
          onSelect={handleSelect}
          disabled={isDisabled(item.id)}
          selected={isSelected(item)}
        />
      ),
      [SEARCH_TYPES_KEYS.compendium]: ({ item }, i) => (
        <LabItemRow
          key={i}
          item={item}
          onSelect={handleSelect}
          disabled={isDisabled(item.id)}
          selected={isSelected(item)}
        />
      ),
      [SEARCH_TYPES_KEYS.admission]: ({ item }, i) => (
        <ChecklistItemRow
          key={i}
          item={item}
          onSelect={handleSelect}
          disabled={isDisabled(item.id)}
          selected={isSelected(item)}
        />
      ),
      [SEARCH_TYPES_KEYS.category]: ({ item, type }, i) => (
        <CategoryItemRow
          key={i}
          item={item}
          type={type}
          onSelect={handleSelect}
          disabled={isDisabled(item.id)}
          selected={isSelected(item)}
        />
      ),
    }),

    [isSelected, handleSelect, isDisabled],
  );

  return (
    <div className="new-design-system">
      <Divider dashed style={{ borderColor: colorPalette.neutral5, margin: '15px 0' }} />
      <TextInput
        label={lang.SEARCH_OR_PASTE}
        onChange={e => setFilter(e.target.value)}
        value={filter}
        className="new-design-system"
        error={error}
        allowClear
      />
      <TextInput
        label={lang.ITEM_TITLE}
        onChange={e => setNewTitle(e.target.value)}
        value={newTitle}
        className="new-design-system"
        allowClear
      />
      <div className={styles.results}>
        {results.length > 0 ? (
          results.map(({ type, item }, i) => renderByType[type]?.({ item, type }, i))
        ) : (
          <NotFound text={lang.START_TYPING} className={styles.notFound} />
        )}
      </div>
      <Divider dashed style={{ borderColor: colorPalette.neutral5, margin: '15px 0' }} />
      <div className={styles.buttons}>
        <Button onClick={onCancel} disabled={loading}>
          {lang.CANCEL}
        </Button>
        <Button type="primary" onClick={handleSubmit} disabled={!newTitle} loading={loading}>
          {lang.SUBMIT}
        </Button>
      </div>
    </div>
  );
};

export { SelectContentComponent };
