import { useEffect, useState } from 'react';
import { KnowledgeItemsWidget } from './knowledge-items-widget';
import knowledgeServiceApi from 'shared/api/knowledge.service.api';
import { useSelector } from 'react-redux';
import { getSelectedWorkspaceId } from 'shared/store/selectors/workspace.selector';

export const actions = {
  RECENTLY_UPDATED_ITEMS: 'RECENTLY_UPDATED_ITEMS',
  RECENTLY_CREATED_ITEMS: 'RECENTLY_CREATED_ITEMS',
};

const actionTypes = {
  [actions.RECENTLY_UPDATED_ITEMS]: knowledgeServiceApi.getRecentlyUpdatedItems,
  [actions.RECENTLY_CREATED_ITEMS]: knowledgeServiceApi.getRecentlyCreatedItems,
};

export function RecentUpdatedWidget({
  widgetKey,
  title,
  type,
  action = actions.RECENTLY_UPDATED_ITEMS,
}) {
  const [items, setItems] = useState(null);

  const workspaceId = useSelector(getSelectedWorkspaceId);
  const lastUpdated = useSelector(state => state.knowledge.lastUpdated);

  useEffect(() => {
    if (workspaceId) {
      setItems(null);
      actionTypes[action]({ workspaceId }).then(res => {
        if (!res.error) {
          return setItems(res.data.items);
        }

        return setItems([]);
      });
    }
  }, [action, workspaceId, lastUpdated]);

  return <KnowledgeItemsWidget title={title} type={type} key={widgetKey} items={items} />;
}
