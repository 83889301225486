import React from 'react';
import { FloatButton, ConfigProvider } from 'antd';
import { colorPalette } from 'shared/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/pro-solid-svg-icons/faChevronUp';

const BackTop = React.memo(function Button(props) {
  return (
    <ConfigProvider
      theme={{
        components: {
          FloatButton: {
            colorPrimary: colorPalette.primaryMain6,
            colorPrimaryHover: colorPalette.primaryMain8,
          },
        },
      }}
    >
      <FloatButton.BackTop icon={<FontAwesomeIcon icon={faChevronUp} />} {...props} />
    </ConfigProvider>
  );
});

export { BackTop };
