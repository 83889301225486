import { connect } from 'react-redux';
import { KnowledgeMovePopupComponent } from './KnowledgeMove.component';
import {
  moveKnowledgeItem,
  createKnowledgeLink,
} from 'shared/store/actions/knowledge.items.actions';
import {
  getLocationsOfKnowledge,
  getItemCategoryId,
} from 'shared/store/selectors/knowledge.selector';

const mapStateToProps = ({ lang, knowledge }, { item, categoryId }) => {
  let itemCategoryId = categoryId;

  if (!itemCategoryId) {
    itemCategoryId = getItemCategoryId({ knowledge }, { id: item.id });
  }

  return {
    lang: lang.KNOWLEDGE_MOVE,
    locations: getLocationsOfKnowledge(knowledge) || [],
    item: { ...item, categoryId: itemCategoryId },
  };
};

export const KnowledgeMovePopup = connect(mapStateToProps, {
  moveKnowledgeItem,
  createKnowledgeLink,
})(KnowledgeMovePopupComponent);
