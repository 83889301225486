import React from 'react';
import { KnowledgeContentEditorFormValue } from '../types';
import { useSelector } from 'src/store/use-selector';
import _ from 'lodash';
import { KNOWLEDGE_TYPES } from 'shared/constants';
import { Button } from 'src/components/design-system';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-solid-svg-icons/faXmark';
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck';
import styles from './render-editor.module.scss';
import { useContentPreview } from 'src/pages/application/knowledge-content/hooks';
import { RenderPublishButton } from './hub/render-publish-button';

export function RenderHeaderActions({
  knowledgeId,
  isLoading,
  editorFocused,
  values,
  errors,
  initialValues,
  openConfirmationPopup,
  onRevertConversion,
  onSelectBinder,
  onSubmit,
}: {
  knowledgeId: string | undefined;
  isLoading: boolean;
  editorFocused: boolean;
  initialValues: Partial<KnowledgeContentEditorFormValue>;
  values: Partial<KnowledgeContentEditorFormValue>;
  errors: Partial<
    {
      [K in keyof KnowledgeContentEditorFormValue]: string;
    }
  >;
  openConfirmationPopup: () => void;
  onRevertConversion: () => void;
  onSelectBinder: () => void;
  onSubmit: () => void;
}) {
  const lang = useSelector(s => s.lang.KNOWLEDGE_EDITOR);

  const isSaveDisabled =
    isLoading || !_.isEmpty(errors) || (_.isEqual(initialValues, values) && !editorFocused);

  const isNew = !knowledgeId;
  const isContent = values.type === KNOWLEDGE_TYPES.CONTENT;

  const { isHub } = useContentPreview();

  return (
    <>
      {isHub ? (
        <RenderPublishButton
          onSelectBinder={onSelectBinder}
          onSubmit={onSubmit}
          disabled={isLoading || !_.isEmpty(errors)}
        />
      ) : (
        <Button
          htmlType="submit"
          loading={isLoading}
          type="primary"
          className={styles.headerActionsButton}
          disabled={isSaveDisabled || isLoading}
          icon={<FontAwesomeIcon icon={faCheck} />}
        >
          {isNew ? lang.CREATE : lang.UPDATE}
        </Button>
      )}
      <Button
        type="default"
        danger
        className={styles.headerActionsButton}
        onClick={openConfirmationPopup}
        disabled={isLoading}
        icon={<FontAwesomeIcon icon={faXmark} />}
      >
        {lang.DISMISS}
      </Button>
      {!isHub && isContent && !_.isEmpty(initialValues?.link) && (
        <Button
          type="default"
          className={styles.headerActionsButton}
          disabled={isLoading}
          onClick={onRevertConversion}
        >
          {lang.REVERT_CONVERSION}
        </Button>
      )}
    </>
  );
}
