import { connect } from 'react-redux';
import { KnowledgeLinkEditorComponent } from './knowledge-link-editor.component';
import { updateSearch } from 'shared/store/actions/search.actions';
import { createKnowledgeLink } from 'shared/store/actions/knowledge.items.actions';

const mapStateToProps = ({ search, lang }) => ({
  lang: lang.KNOWLEDGE_LINK_EDITOR,
  searchTerm: search?.term,
  knowledgeItems: search?.results?.knowledge,
});

export const KnowledgeLinkEditor = connect(mapStateToProps, { updateSearch, createKnowledgeLink })(
  KnowledgeLinkEditorComponent,
);
