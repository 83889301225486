import React from 'react';
import { useSelector } from 'react-redux';
import { getLang } from 'shared/store/selectors/lang.selector';
import styles from './text-tag.module.scss';
import classnames from 'classnames';

const TextTag = ({ tag, large = null, className }) => {
  const label = useSelector(getLang('TAGS'))[tag];

  if (!label) return null;

  return (
    <div className={classnames([styles.textTag, large && styles.largeTextTag, className])}>
      <h5 className={classnames([styles.text, large && styles.largeText])}>{label}</h5>
    </div>
  );
};

export { TextTag };
