import React from 'react';
import styles from './search-type.module.scss';
import { useSelector } from 'react-redux';
import { getLang } from 'shared/store/selectors/lang.selector';

import { SEARCH_TYPES_KEYS, SEARCH_TYPES, Lang } from 'shared/constants';

const SearchType = React.memo(function SearchType({ onChange, searchType, types: typesProp }) {
  const lang = useSelector(getLang('SEARCH_TYPES')) ?? Lang.SEARCH_TYPES;
  const types = typesProp ?? SEARCH_TYPES(lang);

  const selectType = ({ type, event }) => {
    if (searchType === type) return;
    onChange(type);
  };

  return (
    <div className={styles.searchType}>
      {types.map(({ label, type, event }) => (
        <button
          type="button"
          onClick={() => selectType({ type, event })}
          key={label}
          className={searchType === type ? styles.active : null}
        >
          {label}
        </button>
      ))}
    </div>
  );
});

SearchType.defaultProps = {
  onChange: () => {},
  searchType: SEARCH_TYPES_KEYS.none,
};

export { SearchType };
