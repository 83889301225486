import React from 'react';
import { LabelsList } from 'src/components/labels';

export function RenderLabels({ labels, styles }) {
  return (
    <div className={styles.labelsContainer}>
      <LabelsList labels={labels} className={styles.label} />
    </div>
  );
}
