import React, { useCallback } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { KnowledgeContentComponent } from '../../../components/knowledge-content';
import { EVENTS, ROUTES } from 'shared/constants';
import { ContentPreviewProvider } from '../knowledge-content/hooks';
import analyticsService from 'src/helpers/analytics.service';

const KnowledgeHubContentPage = React.memo(() => {
  const history = useHistory();
  const location = useLocation();
  const { id } = useParams();

  const onClose = useCallback(() => {
    if (!location.key) {
      history.push(ROUTES.HOME);
      return;
    }

    history.goBack();
  }, [history, location]);

  const analyticsTriggers = {
    onImport: payload => analyticsService.track(EVENTS.KNOWLEDGE_HUB.IMPORT, payload),
    onImportDismiss: payload =>
      analyticsService.track(EVENTS.KNOWLEDGE_HUB.IMPORT_DISMISS, payload),
    onImportEditContent: payload =>
      analyticsService.track(EVENTS.KNOWLEDGE_HUB.IMPORT_EDIT_CONTENT, payload),
    onPublish: payload => analyticsService.track(EVENTS.KNOWLEDGE_HUB.PUBLISH, payload),
    onShare: payload => analyticsService.track(EVENTS.KNOWLEDGE_HUB.SHARE, payload),
  };

  return (
    <ContentPreviewProvider isHub={true} analyticsTriggers={analyticsTriggers}>
      <KnowledgeContentComponent id={id} close={onClose} />;
    </ContentPreviewProvider>
  );
});

export { KnowledgeHubContentPage };
