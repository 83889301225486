import * as React from 'react';
function SvgComponent({ color = '#000', ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15" {...props}>
      <g fill="none" fill-rule="evenodd">
        <g fill={color}>
          <g transform="translate(-344 -17) translate(344 17)">
            <rect width="19" height="1.5" x="-2" y="7" rx=".5" transform="rotate(45 7.5 7.5)" />
            <rect width="19" height="1.5" x="-2" y="7" rx=".5" transform="rotate(135 7.5 7.5)" />
          </g>
        </g>
      </g>
    </svg>
  );
}
export default SvgComponent;
