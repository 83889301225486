import { KNOWLEDGE_SELECT_ITEM, KNOWLEDGE_SET_ITEM_DETAILS } from 'shared/store/actions';
import update from 'immutability-helper';

const initialState = {
  id: null,
  itemId: null,
  ownerId: null,
  categoryId: null,
};

const selectItem = (state, { id = null }) => {
  return update(state, {
    $merge: { id },
  });
};

const setItemDetails = (state, { itemId = null, ownerId = null, categoryId = null }) => {
  return update(state, {
    $merge: { itemId, ownerId, categoryId },
  });
};

const actionsRepo = {
  [KNOWLEDGE_SELECT_ITEM]: selectItem,
  [KNOWLEDGE_SET_ITEM_DETAILS]: setItemDetails,
};

export default function (state = initialState, { type, payload }) {
  if (!!actionsRepo[type]) {
    return actionsRepo[type](state, payload);
  }

  return state;
}
