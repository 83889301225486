import React from 'react';
import { useSelector } from 'react-redux';
import { Cross } from '../../images';
import styles from './user-details.module.scss';
import classNames from 'classnames';
import { getLang } from 'shared/store/selectors/lang.selector';
import _, { uniqueId } from 'lodash';
import { Tooltip } from '../core';
import { ScreenTitle } from '../design-system/titles';
import { UserDetailsForm } from './user-details-form';
import { formatFullName } from 'shared/utils';

const UserDetailsComponent = React.memo(function UserDetailsComponent({
  user: userData,
  close,
  open,
  ...props
}) {
  const lang = useSelector(getLang('USERS_MANAGEMENT'));
  const closeLabel = useSelector(getLang('ACTION_BUTTON_TOOLTIPS')).CLOSE;
  const isNewUser = _.isEmpty(userData);
  const isBulkUpdate = _.isArray(userData) && userData.length > 1;
  const user = _.isArray(userData) && !isBulkUpdate ? userData[0] : userData;
  const fullName = formatFullName(user);

  const title = isBulkUpdate
    ? lang.EDIT_USER.replace('{number}', user.length ?? '')
    : !isNewUser
    ? `${fullName}`
    : lang.ADD_NEW_USER;

  return (
    <>
      <div className={classNames('new-design-system', styles.root, open ? styles.open : undefined)}>
        <div className={styles.header}>
          <div className={styles.title}>
            <ScreenTitle title={title} />
          </div>

          <Tooltip title={closeLabel} placement="bottom">
            <div onClick={close} className={styles.close}>
              <img src={Cross} alt={closeLabel} />
            </div>
          </Tooltip>
        </div>
        <UserDetailsForm
          key={`details-form-${user?.id ?? uniqueId()}`}
          user={user}
          onFinish={close}
          isBulkUpdate={isBulkUpdate}
          {...props}
        />
      </div>
    </>
  );
});

export { UserDetailsComponent };
