import { connect } from 'react-redux';
import { SuggestKnowledgeItemWidgetComponent } from './suggest-knowledge-widget.component';

const mapStateToProps = ({ lang }) => ({
  lang: lang.SUGGEST_CONTENT,
});

export const SuggestKnowledgeItemWidget = connect(mapStateToProps)(
  SuggestKnowledgeItemWidgetComponent,
);
