import { Collapse, ConfigProvider, Divider } from 'antd';
import { useSelector } from 'react-redux';
import { SearchImprovedResults } from './search-improved-results.component';
import styles from '../search.page.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/pro-solid-svg-icons';
import classnames from 'classnames';
import { colorPalette } from 'shared/constants';
import { getLang } from 'shared/store/selectors/lang.selector';

const SearchGroups = ({ results, renderByType, options, extendedSearch }) => {
  const lang = useSelector(getLang('SEARCH_SCREEN'));
  const workspaces = useSelector(state => state.workspace).list;
  const selectedWorkspaceId = useSelector(state => state.workspace).selected;

  const {
    contactItems = [],
    knowledgeItems = [],
    labItems = [],
    admissionItems = [],
  } = results || {};
  const allWorkspaceItems = [...contactItems, ...knowledgeItems, ...labItems, ...admissionItems];

  const noWorkspaceFilter = ({ item }) => !item?.workspaceId;
  const users = contactItems.filter(noWorkspaceFilter);
  const usersFromExtendedSearch = extendedSearch?.contactItems?.filter(noWorkspaceFilter) || [];

  const filterExtendedSearch = ({ workspaceId }) => {
    const results = Object.keys(extendedSearch).reduce((acc, key) => {
      if (extendedSearch[key].length) {
        acc[key] = filterByWorkspace({ list: extendedSearch[key], workspaceId });
      }

      return acc;
    }, {});

    const resultsCount = Object.keys(results).reduce((acc, key) => acc + results[key].length, 0);
    return { ...results, resultsCount };
  };

  const filterByWorkspace = ({ list = [], workspaceId }) => {
    return list.filter(knowledge => {
      const { item } = knowledge || {};
      return item.workspaceId === workspaceId;
    });
  };

  // if there is one or no workspace, render simple search
  if (workspaces.length <= 1) {
    return (
      <>
        {allWorkspaceItems.map(({ type, item }, index) =>
          renderByType[type]({ item }, index, options),
        )}
        <SearchImprovedResults
          extendedSearch={extendedSearch}
          regularResultsCount={allWorkspaceItems.length}
          renderByType={renderByType}
        />
      </>
    );
  }

  const renderExpandIcon = ({ isActive }) => (
    <div className={classnames([styles.arrowContainer, isActive && styles.active])}>
      <FontAwesomeIcon
        icon={faAngleRight}
        className={classnames([styles.arrow, isActive && styles.active])}
      />
    </div>
  );

  return (
    <ConfigProvider
      theme={{
        components: {
          Collapse: {
            colorTextHeading: colorPalette.primaryMain9,
          },
        },
      }}
    >
      <div className={styles.marginTop}>
        <Collapse
          style={{
            marginLeft: -20,
            marginRight: -24,
          }}
          ghost
          size="large"
          expandIcon={renderExpandIcon}
          items={[
            {
              key: 'users',
              label: lang.SECTION_TITLE.replace('{name}', lang.USERS).replace(
                '{number}',
                users.length + usersFromExtendedSearch.length,
              ),
              children: [
                ...users.map(({ type, item }, index) =>
                  renderByType[type]({ item }, index, options),
                ),
                <SearchImprovedResults
                  extendedSearch={{
                    contactItems: usersFromExtendedSearch,
                    resultsCount: usersFromExtendedSearch.length,
                  }}
                  regularResultsCount={users.length}
                  renderByType={renderByType}
                />,
              ],
            },
          ]}
        />
        <Divider className={styles.divider} />

        {workspaces
          .sort(({ id }) => (id === selectedWorkspaceId ? -1 : 1))
          .map(({ id, name }) => {
            const items = filterByWorkspace({ list: allWorkspaceItems, workspaceId: id });
            const filteredExtendedSearch = filterExtendedSearch({ workspaceId: id });
            const totalCount = items.length + filteredExtendedSearch.resultsCount;

            if (totalCount === 0 && selectedWorkspaceId !== id) return <></>;

            return (
              <>
                <Collapse
                  style={{
                    marginLeft: -20,
                    marginRight: -24,
                  }}
                  ghost
                  size="large"
                  defaultActiveKey={selectedWorkspaceId === id ? id : ''}
                  expandIcon={renderExpandIcon}
                  items={[
                    {
                      key: id,
                      label: lang.SECTION_TITLE.replace('{name}', name).replace(
                        '{number}',
                        totalCount,
                      ),
                      children: [
                        ...items.map(({ type, item }, index) =>
                          renderByType[type]({ item }, index, options),
                        ),
                        <SearchImprovedResults
                          extendedSearch={filteredExtendedSearch}
                          regularResultsCount={items.length}
                          renderByType={renderByType}
                        />,
                      ],
                    },
                  ]}
                />
                <Divider className={styles.divider} />
              </>
            );
          })}
      </div>
    </ConfigProvider>
  );
};

export { SearchGroups };
