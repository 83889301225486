import { connect } from 'react-redux';
import {
  admissionDetails,
  admissionSelectChecklist,
} from 'src/store/actions/admission-viewer.actions';
import { AdmissionChecklistInfoComponent } from './admission-checklist-info.component';
import { getAdmissionParentsByItemId } from 'shared/store/selectors/admission.selector';

const mapStateToProps = ({ admissionViewer, admission, lang }) => {
  let { item } = admissionViewer;

  if (!item) {
    return { id: item?.id, lang: lang.ADMISSION_INFORMATION_MODAL };
  }

  const parents = getAdmissionParentsByItemId(admission, { id: item.id });

  return {
    lang: lang.ADMISSION_INFORMATION_MODAL,
    id: item?.id,
    item,
    parents,
  };
};

export const AdmissionChecklistInfo = connect(mapStateToProps, {
  admissionDetails,
  admissionSelectChecklist,
})(AdmissionChecklistInfoComponent);
