import { connect } from 'react-redux';
import { SwitchOrganizationPageComponent } from './switch-organization.components';
import { switchOrganization } from 'shared/store/actions/organization.actions';

const mapStateToProps = ({ lang, availableOrganizations, organization }) => {
  return {
    lang: lang.SWITCH_ORGANIZATION,
    organizations: availableOrganizations.organizations,
    activeOrganizationId: organization?.id,
  };
};

export const SwitchOrganizationPage = connect(mapStateToProps, { switchOrganization })(
  SwitchOrganizationPageComponent,
);
