import React from 'react';
import styles from './render-chat-messages.module.scss';
import { useSelector } from 'react-redux';
import { KnowledgeWrapperComponent } from 'src/pages/application/knowledge/components/knowledge-row';
import { getItemsByIds } from 'shared/store/selectors/knowledge.selector';
import { SourcesCollapse } from '../sources-collapse';
import { useChatConversation } from '../../hooks/use-chat-conversation';
import { ANSWER_STATE, EVENTS } from 'shared/constants';
import analyticsService from 'src/helpers/analytics.service';

export function MessageResources({ message, excludeKnowledgeItemId }) {
  const { lang, conversationId } = useChatConversation();

  const items = useSelector(({ knowledge }) =>
    getItemsByIds(
      knowledge,
      message.resources
        ?.map(({ knowledgeItemId }) => knowledgeItemId)
        .filter(id => id !== excludeKnowledgeItemId),
    ),
  );

  if (!message.resources?.length) {
    return <></>;
  }

  return (
    <SourcesCollapse
      onOpen={() => {
        analyticsService.track(EVENTS.AI_CHAT.OPEN_RESOURCES, { conversationId });
      }}
      expended={message.state === ANSWER_STATE.ONLY_RESOURCES_FOUND}
      header={
        <p className={styles.collapseHeader}>{`${lang.RELATED_RESOURCES} (${items.length})`}</p>
      }
    >
      <div className={styles.resourcesPanel}>
        {items.map(item => (
          <KnowledgeWrapperComponent key={item.id} item={item} />
        ))}
      </div>
    </SourcesCollapse>
  );
}
