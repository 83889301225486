import React from 'react';
import { useSelector } from 'react-redux';
import { Cross } from '../../images';
import styles from './contact-details.module.scss';
import classNames from 'classnames';
import { getLang } from 'shared/store/selectors/lang.selector';
import _, { uniqueId } from 'lodash';
import { Tooltip } from '../core';
import { ScreenTitle } from '../design-system/titles';
import { ContactDetailsForm } from './contact-details-form';
import analyticsService from 'src/helpers/analytics.service';
import { ENTITY_TYPES, EVENTS } from 'shared/constants';
import { formatFullName } from 'shared/utils';

const ContactDetailsComponent = React.memo(function ContactDetailsComponent({
  contact,
  close,
  open,
  ...props
}) {
  const lang = useSelector(getLang('CONTACT_SCREEN'));
  const closeLabel = useSelector(getLang('ACTION_BUTTON_TOOLTIPS')).CLOSE;
  const isNewContact = _.isEmpty(contact);
  const fullName = formatFullName(contact);
  const title = !isNewContact ? `${fullName}` : lang.ADD_NEW_CONTACT;

  React.useEffect(() => {
    if (!isNewContact) {
      analyticsService.track(EVENTS.CONTENT.OPEN, {
        id: contact.id,
        entityType: ENTITY_TYPES.contacts,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contact, isNewContact]);

  return (
    <>
      <div className={classNames('new-design-system', styles.root, open ? styles.open : undefined)}>
        <div className={styles.header}>
          {contact && (
            <div className={styles.title}>
              <ScreenTitle title={title} />
            </div>
          )}
          <Tooltip title={closeLabel} placement="bottom">
            <div onClick={close} className={styles.close}>
              <img src={Cross} alt={closeLabel} />
            </div>
          </Tooltip>
        </div>
        <ContactDetailsForm
          key={`details-form-${contact?.id ?? uniqueId()}`}
          contact={contact}
          onFinish={close}
          {...props}
        />
      </div>
    </>
  );
});

export { ContactDetailsComponent };
