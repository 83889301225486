import { useCallback, useEffect, useState } from 'react';
import { useKnowledgeHub } from './use-knowledge-hub.hooks';
import { useLocation } from 'react-router-dom-v5-compat';
import { INTERNAL_ROUTING } from 'shared/constants';

export function useBreadcrumbs() {
  const [breadcrumbItems, setBreadcrumbItems] = useState([]);
  const location = useLocation();

  const {
    organizationId,
    workspaceId,
    categoryId,
    workspacesList,
    organizationsList,
    parentCategory,
    previousParentCategories,
    getCategory,
    getOrganizations,
    getWorkspaces,
  } = useKnowledgeHub();

  const buildBreadcrumbs = useCallback(async () => {
    const searchParams = new URLSearchParams(location.search);

    if (searchParams.size === 0) {
      return [];
    }

    let paramsToParse = [];
    for (const [key, value] of searchParams) {
      paramsToParse = [...paramsToParse, { key, value }];
    }

    return paramsToParse?.map(async param => {
      const newSearchParams = new URLSearchParams();

      if (param.key === INTERNAL_ROUTING.QUERY_PARAMS.HUB) return null;

      if (param.key === 'cid' && param.value === parentCategory?.id) {
        const categoriesIds = parentCategory?.fullPath
          ?.split(',')
          .slice(0, -1) // remove last element, it's the current category
          .filter((el, i) => i % 2 === 0); //ignore sections

        const existingCategories =
          categoriesIds
            ?.map(id => previousParentCategories.find(c => c?.id === id))
            ?.filter(item => item) ?? [];

        const missingCategoriesIds =
          categoriesIds?.filter(id => {
            return !previousParentCategories?.find(c => c?.id === id);
          }) ?? [];

        let missingCategories = [];
        if (missingCategoriesIds?.length) {
          missingCategories = await Promise.all(
            missingCategoriesIds?.map(id =>
              getCategory({ organizationId, workspaceId, categoryId: id }),
            ),
          );
        }

        // sort to be same order as fullPath
        const allCategories = [...existingCategories, ...missingCategories]
          .filter(cat => cat.path !== 'root')
          .sort((a, b) => {
            return categoriesIds.indexOf(a.id) - categoriesIds.indexOf(b.id);
          });

        return allCategories?.map(cat => {
          newSearchParams.set('oid', organizationId);
          newSearchParams.set('wid', workspaceId);
          newSearchParams.set('cid', cat.id);

          return {
            title: cat?.title,
            path: `${location.pathname}?${newSearchParams.toString()}`,
          };
        });
      }

      if (param.key === 'wid' && workspaceId) {
        newSearchParams.set('oid', organizationId);
        newSearchParams.set('wid', workspaceId);

        let workspace = workspacesList?.find(ws => ws.id === workspaceId);

        if (!workspace && organizationId) {
          const workspaces = await getWorkspaces({ organizationId });
          workspace = workspaces?.find(ws => ws.id === workspaceId);
        }

        return {
          title: workspace?.name,
          path: `${location.pathname}?${newSearchParams.toString()}`,
        };
      }

      if (param.key === 'oid' && organizationId) {
        newSearchParams.set('oid', organizationId);

        let organization = organizationsList?.find(org => org.id === organizationId);

        if (!organization) {
          const orgs = await getOrganizations();
          organization = orgs?.find(org => org.id === organizationId);
        }

        return {
          title: organization?.name,
          path: `${location.pathname}?${newSearchParams.toString()}`,
        };
      }
      return null;
    });
  }, [
    location.search,
    location.pathname,
    organizationId,
    workspaceId,
    workspacesList,
    organizationsList,
    parentCategory,
    previousParentCategories,
    getCategory,
    getOrganizations,
    getWorkspaces,
  ]);

  useEffect(() => {
    async function prepareBreadcrumbs() {
      const items = await Promise.all((await buildBreadcrumbs()).flat());

      setBreadcrumbItems(items.flat());
    }
    prepareBreadcrumbs();
  }, [organizationId, workspaceId, categoryId, buildBreadcrumbs]);

  return { breadcrumbItems };
}
