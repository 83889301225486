import { sanitizeHtmlContent } from 'shared/utils';
import { ExternalLinkViewer } from './external-link-viewer';
import contentStyles from '../../content-style.module.scss';
import { KNOWLEDGE_TYPES } from 'shared/constants';
import styles from './knowledge-viewers.module.scss';

export function KnowledgeViewers({ id, title, link, content, type, isContent, smartFormatMode }) {
  if (isContent && smartFormatMode) {
    return (
      <div
        id="content-container"
        className={contentStyles.contentContainer}
        dangerouslySetInnerHTML={{
          __html: sanitizeHtmlContent(content),
        }}
      />
    );
  }

  if (type === KNOWLEDGE_TYPES.VIDEO) {
    return <video className={styles.video} title={title} src={link} controls />;
  }

  return <ExternalLinkViewer id={id} title={title} link={!isContent && link} />;
}
