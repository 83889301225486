import classnames from 'classnames';
import { useFileValidation } from '../hooks/validation.hooks';
import styles from './upload-file-binder.module.scss';
import { useSelector } from 'react-redux';
import { getLang } from 'shared/store/selectors/lang.selector';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons';

const FileWithErrorComponent = function FileWithErrorComponent({ file, error, duplicate }) {
  const lang = useSelector(getLang('KNOWLEDGE_UPLOAD_BINDER'));

  const subtitles = {
    'unsupported-file': lang.UNSUPPORTED_FILE_SUBTITLE,
    'file-too-big': lang.FILE_TOO_BIG_SUBTITLE,
    'video-too-big': lang.VIDEO_TOO_BIG_SUBTITLE,
    'upload-failed': lang.FAIL_SUBTITLE,
    'duplicated-file': lang.DUPLICATION_TITLE,
    'file-creation-error': lang.FILE_CREATION_ERROR_SUBTITLE,
  };

  const { fileValidationError } = useFileValidation({ file, error });

  if (fileValidationError || error || duplicate) {
    const errorMessage = fileValidationError || (duplicate ? 'duplicated-file' : '');
    const subtitle = subtitles[errorMessage] ?? subtitles['upload-failed'];

    const isUploadError = ['upload-failed', 'duplicated-file', 'file-creation-error'].includes(
      errorMessage,
    );

    return (
      <div className={classnames([styles.fileRow, styles.errorRow])}>
        <div className={styles.fileNameWrapper}>
          <p>{file.name}</p>
          {isUploadError && (
            <div className={classnames([styles.tag, styles.failed])}>
              <FontAwesomeIcon icon={faTriangleExclamation} className={styles.icon} />
              {lang.UPLOAD_FAILED}
            </div>
          )}
        </div>
        <p className={styles.error}>{subtitle}</p>
      </div>
    );
  }
};

export { FileWithErrorComponent };
