import { connect } from 'react-redux';
import { KnowledgeSectionComponent } from './knowledge-section.component';
import {
  updateCategory,
  updateCategorySubcategories,
} from 'shared/store/actions/knowledge.categories.actions';
import { getCategoriesById } from 'shared/store/selectors/knowledge.selector';
import { knowledgeSelectItem } from 'src/store/actions/knowledgeViewer.actions';
import { INTERNAL_ROUTING } from 'shared/constants';
import { getQueryParam } from 'src/helpers/routing.helper';

const mapStateToProps = ({ knowledge, application, lang }, { id }) => {
  const activeId = getQueryParam({ key: INTERNAL_ROUTING.QUERY_PARAMS.KNOWLEDGE });

  return {
    children: getCategoriesById(knowledge, id),
    activeId,
    adminMode: application.adminMode,
    lang: lang.ACTION_BUTTON_TOOLTIPS,
  };
};

const mapDispatchToProps = {
  knowledgeSelectItem,
  updateCategory,
  updateCategorySubcategories,
};

export const KnowledgeSection = connect(
  mapStateToProps,
  mapDispatchToProps,
)(KnowledgeSectionComponent);
