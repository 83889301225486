import { Anchor, ConfigProvider } from 'antd';
import styles from '../knowledge.module.scss';
import { scrollTo } from 'src/utils';
import React from 'react';
import { colorPalette, theme } from 'shared/constants';

export function RenderSectionList({ sections = [] }) {
  const items = React.useMemo(
    () => sections?.map(({ title, id }) => ({ key: id, title, href: `#${id}` })),
    [sections],
  );

  const onSubmenuClick = (e, { href }) => {
    e.preventDefault();
    scrollTo(href);
  };

  return (
    <div className={styles.navigatorContent}>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: colorPalette.primary,
            fontFamily: theme.newFontFamily,
            lineWidth: 0,
            lineWidthBold: 0,
          },
        }}
      >
        <Anchor affix={false} showInkInFixed={false} items={items} onClick={onSubmenuClick} />
      </ConfigProvider>
    </div>
  );
}
