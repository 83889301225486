import { connect } from 'react-redux';
import { isOnSearch } from 'shared/store/selectors/search.selector';
import { getItemById } from 'shared/store/selectors/knowledge.selector';
import { SearchPageComponent } from './Search.page.component';
import { admissionSelectChecklist } from '../../../store/actions/admission-viewer.actions';
import { updateCategory } from 'shared/store/actions/admission.actions';
import { addSearchFilters } from 'shared/store/actions/search.actions';
import { isContactsFeatureEnabled } from 'shared/store/selectors/features.selector';
import { getSelectedWorkspaceId } from 'shared/store/selectors/workspace.selector';

const mapStateToProps = (
  { search, knowledge, application, lang, organization, workspace },
  { location },
) => {
  const { id: itemId } = location?.state || {};
  let knowledgeItem = null;

  if (!!itemId) {
    knowledgeItem = getItemById(knowledge, { id: itemId });
  }

  return {
    results: search.results,
    term: search.term || '',
    sessionId: search.sessionId,
    searching: isOnSearch({ search }),
    adminMode: application.adminMode,
    lastUpdated: knowledge.lastUpdated,
    knowledgeItem,
    langScreen: lang.SEARCH_SCREEN,
    lang: lang.SEARCH_TYPES,
    isContactsEnabled: isContactsFeatureEnabled({ organization }),
    activeWorkspaceId: getSelectedWorkspaceId({ workspace }),
  };
};

const mapDispatchToProps = {
  admissionSelectChecklist,
  updateChecklistCategory: updateCategory,
  addSearchFilters,
};

export const SearchPage = connect(mapStateToProps, mapDispatchToProps)(SearchPageComponent);
