import React from 'react';
import {
  Lang,
  CATEGORY_TYPES,
  TAGS_LAB_CONTENT_TYPE,
  EVENTS,
  ANALYTICS_CONTENT_CATEGORIES_TYPES,
} from 'shared/constants';
import { Popup } from 'src/components/common';
import { Button, Input, Loader, SelectInput } from 'src/components/core';
import styles from './lab-category-editor.module.scss';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { TagsSelector, TAG_SELECTOR_TYPE } from 'src/components/tags/tagSelector';
import { DrugClassSelector } from 'src/components/drugClass/drugClassSelector';
import { HorizontalDirectoryCompendiumIcon, HorizontalCompendiumIcon } from 'src/images';
import { normalizedContains } from 'src/utils';
import analyticsService from 'src/helpers/analytics.service';

import { PopupFormButtons } from 'src/components/common/popup-form-buttons';
import { getCategoryType } from 'shared/utils';
import { MultipleOwnersSelector } from 'src/components/owners/owners-selector/multiple-owners-selector';

const ALLOWED_CATEGORIES = {
  [CATEGORY_TYPES.CATEGORY]: { label: 'Category' },
  [CATEGORY_TYPES.VALUES]: {
    label: 'Values',
  },
};

const LAB_CATEGORY_SCHEMA = Yup.object().shape({
  title: Yup.string().min(2, 'Too Short!').max(200, 'Too Long!').required('Required!'),
  type: Yup.string().required(),
  ownerIds: Yup.array()
    .of(Yup.string())
    .min(1, 'Please assign at least one owner')
    .required('Required!'),
  specialty: Yup.string().nullable(),
  subspecialty: Yup.string().nullable(),
  contentType: Yup.string().nullable(),
  targetAudience: Yup.array().of(Yup.string()),
  drugClass: Yup.string().nullable(),
});

const LabCategoryEditorComponent = React.memo(function LabCategoryEditorComponent({
  lang,
  entity,
  onFinish,
  createCategory,
  updateCategory,
  locations,
}) {
  let isNew = !entity.id;
  let { isSectionCreation, path } = entity;

  const [isLoading, setLoadingState] = React.useState(false);

  const createOrUpdateCategoryAction = React.useCallback(
    async values => {
      setLoadingState(true);

      // TODO: create the category
      let categoryRes;
      const { specialty, subspecialty, contentType, targetAudience, ...valuesData } = values;

      const data = {
        ...valuesData,
        tags: { specialty, subspecialty, contentType, targetAudience },
        drugClass: contentType === TAGS_LAB_CONTENT_TYPE.DRUG_DOSAGE ? values.drugClass : null,
      };

      if (isNew) {
        categoryRes = await createCategory(data);

        if (categoryRes) {
          analyticsService.track(EVENTS.CATEGORIES.ADD, {
            categoryType: getCategoryType({
              path: categoryRes.path,
              type: categoryRes.type,
              isSectionCreation,
              isNew: true,
            }),
            contentType: ANALYTICS_CONTENT_CATEGORIES_TYPES.LAB,
            categoryId: categoryRes.id,
            title: categoryRes.title,
            ownerIds: categoryRes.ownerIds,
          });
        }
      } else {
        categoryRes = await updateCategory(data);

        analyticsService.track(EVENTS.CATEGORIES.EDIT, {
          categoryType: getCategoryType({ path: values.path, type: values.type }),
          contentType: ANALYTICS_CONTENT_CATEGORIES_TYPES.LAB,
          categoryId: values.id,
          title: values.title,
          ownerIds: values.ownerIds,
        });
      }

      onFinish({ ...categoryRes, isSectionCreation });
      setLoadingState(false);
    },
    [createCategory, updateCategory, isNew, isSectionCreation, onFinish],
  );

  const dismiss = React.useCallback(() => {
    // TODO: analytics

    onFinish({});
  }, [onFinish]);

  const { handleChange, handleSubmit, setFieldValue, resetForm, values, errors, dirty, isValid } =
    useFormik({
      validationSchema: LAB_CATEGORY_SCHEMA,
      initialValues: {
        type: isSectionCreation ? CATEGORY_TYPES.CATEGORY : CATEGORY_TYPES.VALUES,
        ...entity.tags,
        ...entity,
        ownerIds: isNew ? [] : entity.ownerIds || [],
      },
      onSubmit: createOrUpdateCategoryAction,
    });

  const selectCategoryType = React.useCallback(
    e => {
      let { value } = e.target;
      setFieldValue('type', value);
    },
    [setFieldValue],
  );

  const resetField = React.useCallback(
    field => {
      const newValues = { values };
      delete newValues.values[field];
      resetForm(newValues);
    },
    [values, resetForm],
  );

  const renderIcon = key => {
    if (key === CATEGORY_TYPES.CATEGORY) {
      return <img src={HorizontalDirectoryCompendiumIcon} className={styles.icon} alt={key} />;
    }

    return <img src={HorizontalCompendiumIcon} alt={key} className={styles.icon} />;
  };

  const renderCategoryTypes = () => {
    if (!isNew || !path || isSectionCreation) return null;

    return (
      <>
        <p>{lang.SELECT_CATEGORY_TYPE}</p>
        <div className={styles.categoriesTypes}>
          {Object.keys(ALLOWED_CATEGORIES).map(key => (
            <Button
              key={key}
              value={key}
              className={styles.categorySelect}
              onClick={selectCategoryType}
              buttonStyle={key === values.type && 'secondary'}
              size="medium"
              type="button"
            >
              {renderIcon(key)}
              {ALLOWED_CATEGORIES[key].label}
            </Button>
          ))}
        </div>
      </>
    );
  };

  return (
    <Popup title={isNew ? lang.ADD_TITLE : lang.EDIT_TITLE} isOpen>
      <form onSubmit={handleSubmit}>
        {renderCategoryTypes()}
        <>
          <label>{lang.INSERT_CATEGORY_NAME[values.type]}</label>
          <Input
            name="title"
            value={values.title}
            onChange={handleChange}
            inputStyle="editor"
            placeholder={lang.INSERT_CATEGORY_NAME_PLACEHOLDER[values.type]}
          />
        </>

        <label>{lang.OWNER}</label>
        <MultipleOwnersSelector
          value={values.ownerIds}
          error={errors.ownerIds}
          placeholder={lang.OWNER_PLACEHOLDER}
          name="ownerIds"
          onChange={setFieldValue}
          bordered
          borderRadius={4}
          parentOwnerIds={entity?.ownerIds}
        />

        <TagsSelector
          selectedValues={values}
          errors={errors}
          onChange={setFieldValue}
          resetField={resetField}
          type={TAG_SELECTOR_TYPE.LAB}
        />

        {values.contentType === TAGS_LAB_CONTENT_TYPE.DRUG_DOSAGE && (
          <>
            <label>{lang.DRUG_CLASS}</label>
            <DrugClassSelector
              value={values.drugClass}
              error={errors.drugClass}
              placeholder={lang.DRUG_CLASS_PLACEHOLDER}
              name="drugClass"
              onChange={setFieldValue}
            />
          </>
        )}

        {!isNew && (
          <>
            <label>{lang.LOCATION}</label>
            <SelectInput
              value={values.path}
              error={errors.path}
              placeholder={lang.LOCATION_PLACEHOLDER}
              options={locations}
              name="path"
              onChange={setFieldValue}
              filterOption={(inputValue, option) =>
                normalizedContains(option?.children, inputValue)
              }
            />
          </>
        )}

        {!isLoading && (
          <PopupFormButtons
            dismiss={dismiss}
            language={{
              DISMISS: lang.DISMISS,
              CREATE_OR_UPDATE: isNew ? lang.CREATE : lang.UPDATE,
            }}
            disableUpdate={!dirty || !isValid}
          />
        )}
        {isLoading && <Loader />}
        {!isNew && <span className={styles.itemId}>Id: {entity?.id}</span>}
      </form>
    </Popup>
  );
});

LabCategoryEditorComponent.defaultProps = {
  lang: Lang.LAB_CATEGORY_EDITOR,
  entity: {
    path: null,
    isSectionCreation: false,
  },
  createCategory: () => {},
  updateCategory: () => {},
  onFinish: () => {},
  locations: [],
};

export { LabCategoryEditorComponent };
