import { useSelector } from 'react-redux';
import { useKnowledgeHub } from '../hooks/use-knowledge-hub.hooks';
import styles from './components.module.scss';
import { getLang } from 'shared/store/selectors/lang.selector';

export function RenderWelcomeMessage() {
  const lang = useSelector(getLang('KNOWLEDGE_HUB'));
  const { organizationId } = useKnowledgeHub();

  if (organizationId) return null;

  return <p className={styles.message}>{lang.WELCOME_MESSAGE}</p>;
}
