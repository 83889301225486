import * as React from 'react';

function SvgComponent({ color = '#2c5789', ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 31.999" {...props}>
      <path
        data-name="Path 1087"
        d="M23.563 15.173l-22-15A1 1 0 000 .999v30a1 1 0 001.563.826l22-15a1 1 0 000-1.652zM2 29.106V2.892l19.225 13.107z"
        fill={color}
      />
    </svg>
  );
}

export default SvgComponent;
