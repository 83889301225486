import styles from './knowledge-comments.module.scss';
import analyticsService from 'src/helpers/analytics.service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMessages } from '@fortawesome/pro-solid-svg-icons';
import { EVENTS } from 'shared/constants';
import { useKnowledgeComments } from './hooks';

const { KNOWLEDGE_COMMENTS } = EVENTS;

export function CommentsHeader() {
  const { lang, count, isEnabled, fetching } = useKnowledgeComments();

  if (!isEnabled || fetching) {
    return null;
  }

  const headerTitle = count > 0 ? lang.HEADER.replace('{count}', count) : lang.HEADER_NO_COMMENTS;

  const handleScroll = e => {
    e.preventDefault();

    analyticsService.track(KNOWLEDGE_COMMENTS.COMMENTS_LINK_CLICKED);

    const target = document.getElementById('comments');
    if (target) {
      target.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className={styles.header}>
      <a href="#comments" onClick={handleScroll}>
        <FontAwesomeIcon icon={faMessages} />
        {headerTitle}
      </a>
    </div>
  );
}
