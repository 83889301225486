import React from 'react';
import { Lang, EVENTS, colors } from 'shared/constants';
import classNames from 'classnames';
import analyticsService from '../../helpers/analytics.service';
import { LangIcon } from '../../images/ui';
import { Button, Tooltip } from '../core';
import { Popup } from '../common';
import styles from './lang-selector.module.scss';
import { LangSelectorImage } from '../../images/characters';

const LangSelectorComponent = ({ lang, options, setLang, className, langTooltip }) => {
  const [langSelectorState, setLangSelector] = React.useState(false);

  const openLangSelector = () => {
    analyticsService.track(EVENTS.LANGUAGE_SELECTOR.OPEN);
    setLangSelector(true);
  };

  const closeLangSelector = () => {
    setLangSelector(false);
  };

  const selectLang = key => {
    analyticsService.track(EVENTS.LANGUAGE_SELECTOR.SELECT_LANG, { key });
    setLang({ key });
    closeLangSelector();
  };

  return (
    <>
      <Tooltip title={langTooltip.CHANGE_LANGUAGE}>
        <img
          onClick={openLangSelector}
          src={LangIcon}
          className={classNames(styles.lang, className)}
          alt="lang"
        />
      </Tooltip>
      <Popup className={styles.popup} isOpen={langSelectorState} title={lang.HEADER}>
        <img src={LangSelectorImage} alt={lang.HEADER} className={styles.image} />
        <div className={styles.langList}>
          {options?.map(({ key, label }) => (
            <button className={styles.selectLang} onClick={() => selectLang(key)} key={key}>
              {label}
            </button>
          ))}
        </div>
        <Button className={styles.button} onClick={closeLangSelector}>
          {lang.DISMISS}
        </Button>
      </Popup>
    </>
  );
};

LangSelectorComponent.defaultProps = {
  lang: Lang.LANG_SELECTOR,
  options: [],
  fill: colors.black,
  style: null,
  langTooltip: Lang.ACTION_BUTTON_TOOLTIPS,
};

export { LangSelectorComponent };
