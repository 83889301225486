import { connect } from 'react-redux';
import { UserProfilePopupComponent } from './user-profile-popup.component';
import { needToUpdateProfile } from 'shared/store/selectors/user.selector';

const mapStateToProps = ({ lang, auth, generalConfig }) => {
  return {
    lang: lang.USER_PROFILE,
    showPopup: needToUpdateProfile({ auth, generalConfig }),
  };
};

export const UserProfilePopup = connect(mapStateToProps, {})(UserProfilePopupComponent);
