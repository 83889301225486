export function scrollTo(id = '') {
  var element = document.getElementById(id);

  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
}

const removeDiacritics = str => {
  return str?.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

// Function to escape special characters in the string to make it safe for RegExp
const escapeRegExp = str => {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // Escapes special characters
};

export const normalizedContains = (haystack, needle) => {
  const safeNeedle = escapeRegExp(removeDiacritics(needle)); // Escape special characters in needle
  const regExp = new RegExp(safeNeedle, 'gi');
  return regExp.test(removeDiacritics(haystack));
};
