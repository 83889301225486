import { connect } from 'react-redux';
import { Route } from './Route.component';
import { ROUTES } from 'shared/constants';
import UserService from 'shared/services/user.service';

const mapStateToProps = state => {
  return {
    isAllowedRoute: UserService.isAuthenticated,
    redirectRoute: ROUTES.ROOT,
  };
};

export const AuthRoute = connect(mapStateToProps)(Route);
