import React, { useCallback, useState } from 'react';
import styles from './otp-confirmation-modal.module.scss';
import { Button } from 'src/components/design-system';
import { loginSchema } from 'shared/schemas/user.schemas';
import { TextInput } from 'src/components/design-system/forms';
import { useFormik } from 'formik';
import { Panda, PandaClosesEyes } from 'shared/images/characters';
import { Loader, OtpInput } from 'src/components/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { AddAccountSuccess } from 'src/images';
import { OTP_ERROR_MESSAGES } from 'shared/constants';

const STAGES = {
  EMAIL: 'email',
  CONFIRM: 'confirm',
  SUCCESS: 'success',
};

const OTPConfirm = ({
  lang,
  showSuccess = true,
  next = () => {},
  submitEmailCallback,
  verifyCodeCallback,
}) => {
  const [stage, setStage] = useState(STAGES.EMAIL);
  const [userData, setUserData] = useState({ email: '', userId: '' });

  const renderStage = () => {
    if (stage === STAGES.SUCCESS) {
      return showSuccess ? <Success lang={lang} /> : null;
    } else if (stage === STAGES.CONFIRM) {
      return (
        <ConfirmOTP
          verifyCodeCallback={verifyCodeCallback}
          lang={lang}
          setNextStage={() => setStage(STAGES.SUCCESS)}
          userData={userData}
          next={next}
        />
      );
    } else {
      return (
        <SubmitEmail
          submitEmailCallback={submitEmailCallback}
          lang={lang}
          setNextStage={() => setStage(STAGES.CONFIRM)}
          setUserData={setUserData}
        />
      );
    }
  };

  return <div className={styles.container}>{renderStage()}</div>;
};

const SubmitEmail = ({ lang, setNextStage, setUserData, submitEmailCallback }) => {
  const [error, setError] = useState(null);
  const [loading, setLoadingState] = useState(false);

  const activateAccountAction = useCallback(
    async ({ email }) => {
      setError('');
      setLoadingState(true);

      const res = await submitEmailCallback(email);

      setLoadingState(false);

      if (res?.error) {
        if (res?.error?.response?.data?.code === 403) {
          setError(lang.WRONG_ACCESS.replace('{email}', email));
        } else if (res?.error?.response?.data?.code === 401) {
          setError(lang.EMAIL_NOT_ALLOWED);
        } else {
          setError(lang.CONTACT_SUPPORT);
        }
        return;
      }

      setUserData({ email, userId: res?.data?.id || '' });
      setNextStage();
    },
    [
      setNextStage,
      setUserData,
      lang.CONTACT_SUPPORT,
      lang.WRONG_ACCESS,
      lang.EMAIL_NOT_ALLOWED,
      submitEmailCallback,
    ],
  );

  const { handleChange, handleBlur, handleSubmit, values, errors, touched, isValid } = useFormik({
    validationSchema: loginSchema,
    initialValues: { email: '' },
    onSubmit: activateAccountAction,
  });

  return (
    <>
      <img src={Panda} alt="panda" className={styles.panda} />
      <div className={styles.content}>
        <h1 className={styles.title}>{lang.TITLE}</h1>
        <p className={styles.description}>{lang.DESCRIPTION}</p>
        <form onSubmit={handleSubmit}>
          <TextInput
            type="email"
            name="email"
            value={values.email}
            error={touched.email && errors.email}
            onChange={handleChange}
            onBlur={handleBlur}
            autoFocus={true}
            size="middle"
            placeholder={lang.EMAIL_PLACEHOLDER}
            disabled={loading}
            className={styles.input}
          />

          <Button disabled={!isValid} type="primary" htmlType="submit" loading={loading}>
            {lang.SEND_VERIFICATION_CODE}
          </Button>

          {error && <p className={styles.error}>{error}</p>}
        </form>
      </div>
    </>
  );
};

const ConfirmOTP = ({ lang, setNextStage, userData, next, verifyCodeCallback }) => {
  const [error, setError] = useState(null);
  const [loading, setLoadingState] = useState(false);

  const verifyActivateAccountAction = useCallback(
    async ({ code }) => {
      setError('');
      setLoadingState(true);

      const res = await verifyCodeCallback({ ...userData, code });

      setLoadingState(false);

      if (res?.error) {
        if (res.error === OTP_ERROR_MESSAGES.CODE_DOES_NOT_MATCH) {
          setError(lang.CODE_DOES_NOT_MATCH);
        } else if (res.error === OTP_ERROR_MESSAGES.VERIFICATION_ATTEMPTS_LIMIT_EXCEED) {
          setError(lang.VERIFICATION_ATTEMPTS_LIMIT_EXCEED);
        } else {
          setError(lang.CONTACT_SUPPORT);
        }
        return;
      }

      await next();
      setNextStage();
    },
    [
      setNextStage,
      userData,
      next,
      lang.CONTACT_SUPPORT,
      lang.CODE_DOES_NOT_MATCH,
      lang.VERIFICATION_ATTEMPTS_LIMIT_EXCEED,
      verifyCodeCallback,
    ],
  );

  return (
    <>
      <img src={PandaClosesEyes} alt="panda" className={styles.panda} />
      <div className={styles.content}>
        <h1 className={styles.title}>{lang.TITLE}</h1>
        <p className={styles.description}>{lang.OTP_CONFIRM}</p>
        {loading ? <Loader /> : <OtpInput onChange={verifyActivateAccountAction} />}
        {error && <p className={styles.error}>{error}</p>}
      </div>
    </>
  );
};

const Success = ({ lang }) => {
  return (
    <div className={styles.success}>
      <div className={styles.successContent}>
        <div className={styles.header}>
          <div className={styles.iconContainer}>
            <FontAwesomeIcon icon={faCheck} className={styles.icon} />
          </div>
          <div className={styles.headerTitle}>{lang.WEB_SUCCESS_TITLE}</div>
        </div>
        <p className={styles.description}>{lang.WEB_SUCCESS_CONTENT}</p>
      </div>

      <img src={AddAccountSuccess} alt="panda" className={styles.image} />
    </div>
  );
};

export { OTPConfirm };
