import styles from './render-chat-messages.module.scss';
import classnames from 'classnames';
import React, { useState, useEffect } from 'react';
import zeusServiceApi from 'shared/api/zeus.service.api';
import { useChatConversation } from '../../hooks/use-chat-conversation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardList } from '@fortawesome/pro-regular-svg-icons';
import { faThumbsDown, faThumbsUp, faCheck } from '@fortawesome/free-solid-svg-icons';
import {
  faThumbsDown as faThumbsDownRegular,
  faThumbsUp as faThumbsUpRegular,
} from '@fortawesome/free-regular-svg-icons';
import { CopyToClipboard } from 'src/components/common/copy-to-clipboard';
import { BAD_FEEDBACK_OPTIONS, FEEDBACK_TYPE } from 'shared/constants';
import { TextAreaInput } from 'src/components/design-system/forms';
import { Button } from 'src/components/design-system';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import analyticsService from 'src/helpers/analytics.service';
import { EVENTS } from 'shared/constants';

const MAX_FEEDBACK_LENGTH = 500;

const FEEDBACK_DESCRIPTION_SCHEMA = Yup.object().shape({
  description: Yup.string()
    .min(2, 'Too Short!')
    .max(MAX_FEEDBACK_LENGTH, 'Too Long!')
    .required('Required!'),
});

function BadFeedbackPanel({ isOpen, closePanel, messageId }) {
  const [selectedBadFeedback, setSelectedBadFeedback] = useState(null);
  const { conversationId, lang } = useChatConversation();
  const [showThanksMessage, setShowThanksMessage] = useState(false);
  const [unselectedBadFeedback, setUnselectedBadFeedback] = useState(false);

  useEffect(() => {
    if (!selectedBadFeedback || !conversationId || !messageId) return;

    upsertFeedback(
      conversationId,
      messageId,
      {
        type: FEEDBACK_TYPE.BAD,
        badFeedbackOption: selectedBadFeedback,
      },
      EVENTS.AI_CHAT.BAD_FEEDBACK_OPTION,
    );
  }, [selectedBadFeedback, conversationId, messageId]);

  useEffect(() => {
    if (!unselectedBadFeedback || !conversationId || !messageId) return;

    upsertFeedback(
      conversationId,
      messageId,
      { type: FEEDBACK_TYPE.BAD },
      EVENTS.AI_CHAT.UNSELECT_FEEDBACK_OPTION,
    );
  }, [unselectedBadFeedback, conversationId, messageId]);

  const onSubmit = async (values, { resetForm }) => {
    if (!conversationId || !messageId) return;

    const feedback = await zeusServiceApi.feedback(conversationId, messageId, {
      type: FEEDBACK_TYPE.BAD,
      description: values.description,
    });

    if (!feedback.success) return;

    analyticsService.track(EVENTS.AI_CHAT.SUBMIT_FEEDBACK, {
      conversationId,
      messageId,
      feedback: feedback.data,
    });

    resetForm();
    setShowThanksMessage(true);
  };

  const { handleChange, handleBlur, handleSubmit, values, touched, isValid, dirty } = useFormik({
    validationSchema: FEEDBACK_DESCRIPTION_SCHEMA,
    initialValues: {
      description: '',
    },
    onSubmit,
  });

  const handleBadFeedbackSelected = e => {
    setSelectedBadFeedback(prev => {
      if (prev === e.target.innerText) {
        setUnselectedBadFeedback(true);
        return '';
      }
      setUnselectedBadFeedback(false);
      return e.target.innerText;
    });
  };

  if (showThanksMessage) {
    return (
      <div className={styles.thanksMessage}>
        <FontAwesomeIcon icon={faCheck} size="xl" />
        <p>{lang.THANKS_FEEDBACK_MESSAGE}</p>
      </div>
    );
  }

  return (
    <div className={classnames(styles.badFeedbackPanel, isOpen ? styles.open : styles.close)}>
      <h2 className={styles.badFeedbackTitle}>{lang.WHAT_WENT_WRONG}</h2>
      <div className={styles.badFeedbackOptions}>
        {Object.values(BAD_FEEDBACK_OPTIONS).map(option => (
          <button
            key={option}
            onClick={handleBadFeedbackSelected}
            className={option === selectedBadFeedback ? styles.selected : ''}
          >
            {option}
          </button>
        ))}
      </div>
      <h2 className={styles.badFeedbackTitle}>{lang.TELL_US_MORE}</h2>
      <form onSubmit={handleSubmit}>
        <TextAreaInput
          name="description"
          value={values.description}
          touched={touched.description}
          onChange={handleChange}
          onBlur={handleBlur}
          maxLength={MAX_FEEDBACK_LENGTH}
          autoSize={{ minRows: 2, maxRows: 4 }}
          className={styles.feedbackFormTextArea}
          placeholder={lang.DESCRIPTION_PLACEHOLDER}
        />

        <div className={styles.buttons}>
          <Button type="primary" htmlType="submit" disabled={!isValid || !dirty}>
            {lang.SUBMIT}
          </Button>
          <Button onClick={closePanel}>{lang.CANCEL}</Button>
        </div>
      </form>
    </div>
  );
}

export function ResponseActions({ message }) {
  const [badFeedbackPanelOpen, setBadFeedbackPanelOpen] = useState(false);
  const [type, setType] = useState('');
  const { lang, conversationId, loading } = useChatConversation();
  const [feedbackToggle, setFeedbackToggle] = useState({
    good: false,
    bad: false,
  });

  useEffect(() => {
    if (!type || !conversationId || !message.id) return;

    upsertFeedback(conversationId, message.id, { type }, EVENTS.AI_CHAT.FEEDBACK[type]);
  }, [type, conversationId, message.id]);

  useEffect(() => {
    setBadFeedbackPanelOpen(feedbackToggle.bad);
  }, [feedbackToggle]);

  const toggleFeedback = feedbackType => {
    if (feedbackType === FEEDBACK_TYPE.BAD) {
      setFeedbackToggle(prev => {
        return { bad: !prev.bad, good: false };
      });
      setType(prev => {
        if (prev === FEEDBACK_TYPE.BAD) {
          return FEEDBACK_TYPE.CANCELED;
        }
        return FEEDBACK_TYPE.BAD;
      });
    } else {
      setFeedbackToggle(prev => {
        return { good: !prev.good, bad: false };
      });
      setType(prev => {
        if (prev === FEEDBACK_TYPE.GOOD) {
          return FEEDBACK_TYPE.CANCELED;
        }
        return FEEDBACK_TYPE.GOOD;
      });
      onCloseBadFeedbackPanel();
    }
  };

  const onCloseBadFeedbackPanel = () => {
    setBadFeedbackPanelOpen(false);
  };

  const onCopyToClipboard = () => {
    analyticsService.track(EVENTS.AI_CHAT.COPY_TO_CLIPBOARD, {
      conversationId,
      messageId: message.id,
    });
  };

  return (
    <>
      <div className={styles.bottomActions}>
        <CopyToClipboard onCopy={onCopyToClipboard} text={message.content}>
          <button className={styles.copyResponse}>
            <FontAwesomeIcon icon={faClipboardList} />
            <p>{lang.COPY_RESPONSE}</p>
          </button>
        </CopyToClipboard>

        <span>|</span>

        <button
          disabled={loading}
          className={styles.badResponse}
          onClick={() => toggleFeedback(FEEDBACK_TYPE.BAD)}
        >
          <FontAwesomeIcon icon={feedbackToggle.bad ? faThumbsDown : faThumbsDownRegular} />
          <p>{lang.BAD_RESPONSE}</p>
        </button>

        <span>|</span>

        <button
          disabled={loading}
          className={styles.goodResponse}
          onClick={() => toggleFeedback(FEEDBACK_TYPE.GOOD)}
        >
          <FontAwesomeIcon icon={feedbackToggle.good ? faThumbsUp : faThumbsUpRegular} />
          <p>{lang.GOOD_RESPONSE}</p>
        </button>
      </div>
      {badFeedbackPanelOpen && (
        <BadFeedbackPanel
          isOpen={badFeedbackPanelOpen}
          closePanel={onCloseBadFeedbackPanel}
          messageId={message.id}
        />
      )}
    </>
  );
}

async function upsertFeedback(conversationId, messageId, feedback, event) {
  const res = await zeusServiceApi.feedback(conversationId, messageId, feedback);

  if (!res.success) return;

  analyticsService.track(event, {
    conversationId,
    messageId,
    feedback: res.data,
  });
}
