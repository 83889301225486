import { useSelector } from 'react-redux';
import { getLang } from 'shared/store/selectors/lang.selector';

function CategoryTag({ tag, styles }) {
  //TODO: add the new icons
  const label = useSelector(getLang('TAGS'))[tag];

  return (
    <div className={styles.tag}>
      <p>{label}</p>
    </div>
  );
}

export function RenderTags({ tags, styles }) {
  return (
    <div className={styles.tags}>
      {tags?.specialty && <CategoryTag tag={tags.specialty} styles={styles} />}
      {tags?.subspecialty && <CategoryTag tag={tags.subspecialty} styles={styles} />}
    </div>
  );
}

export function RenderContentType({ tags, styles }) {
  const tag = tags?.contentType;
  const label = useSelector(getLang('TAGS'))[tag];

  if (!tag) return null;

  return (
    <div className={styles.contentType}>
      <p>{label}</p>
    </div>
  );
}
