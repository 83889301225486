import { CATEGORY_TYPES, KNOWLEDGE_RESOURCES_TYPES, KNOWLEDGE_TYPES } from 'shared/constants';
import { getExternalSyncBinderIcon } from 'src/constants/external-sync-icons-mapper';
import {
  DirectoryChecklistIcon,
  DirectoryCompendiumIcon,
  DirectoryKnowledgeIcon,
  DocumentIcon,
  ItemBinderIcon,
  ItemChecklistIcon,
  ItemCompendiumIcon,
  VideoIcon,
  WebsiteLinkIcon,
} from 'src/images';
import classnames from 'classnames';

export function RenderCategoryIcon({
  title,
  type,
  categoryType,
  isCategory,
  adminMode,
  options,
  styles,
}) {
  const CATEGORY_TYPE = {
    [CATEGORY_TYPES.KNOWLEDGE]: {
      icon: ItemBinderIcon,
      iconStyle: styles.knowledgeIcon,
      folderIcon: DirectoryKnowledgeIcon,
    },
    [CATEGORY_TYPES.CHECKS]: {
      icon: ItemChecklistIcon,
      iconStyle: styles.checklistIcon,
      folderIcon: DirectoryChecklistIcon,
    },
    [CATEGORY_TYPES.VALUES]: {
      icon: ItemCompendiumIcon,
      iconStyle: styles.labIcon,
      folderIcon: DirectoryCompendiumIcon,
    },
    [KNOWLEDGE_RESOURCES_TYPES.EXTERNAL_URL]: {
      icon: WebsiteLinkIcon,
      iconStyle: styles.knowledgeIcon,
      folderIcon: DirectoryKnowledgeIcon,
    },
    [KNOWLEDGE_TYPES.CONTENT]: {
      icon: DocumentIcon,
      iconStyle: styles.knowledgeIcon,
      folderIcon: DirectoryKnowledgeIcon,
    },
    [KNOWLEDGE_TYPES.VIDEO]: {
      icon: VideoIcon,
      iconStyle: styles.knowledgeIcon,
      folderIcon: DirectoryKnowledgeIcon,
    },
  };

  const { icon = null, iconStyle = null } = CATEGORY_TYPE[type] ?? {};
  if (isCategory) {
    const FolderIcon = CATEGORY_TYPE[categoryType]?.folderIcon || DirectoryKnowledgeIcon;
    return <img src={FolderIcon} className={styles.icon} alt={categoryType} />;
  }

  if (adminMode && options?.externalSyncProvider) {
    return (
      <img
        src={getExternalSyncBinderIcon(options.externalSyncProvider)}
        alt={title}
        className={classnames(styles.icon, styles.externalSyncIcon)}
      />
    );
  }
  return <img src={icon} alt={title} className={classnames(styles.icon, iconStyle)} />;
}
