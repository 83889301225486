import Mixpanel from 'mixpanel-browser';
import {
  MIXPANEL_TOKENS,
  SESSION_PROPERTIES,
  ENVIRONMENT_KEYS,
  ANALYTICS_TO_TRACK_VIA_API,
} from 'shared/constants';
import { currentEnvironment } from 'shared/services/Environment';
import { datadogRum } from '@datadog/browser-rum';
import { Executer } from 'shared/utils/Executer';
import { nanoid } from 'nanoid';
import AnalyticsAPI from 'shared/api/analytics.service.api';
import userService from 'shared/services/user.service';

class AnalyticsService {
  async init() {
    await Mixpanel.init(MIXPANEL_TOKENS[currentEnvironment()]);

    datadogRum.init({
      applicationId: 'f7b46c0a-9450-4b2d-b088-69fd1da47963',
      clientToken: 'pub67d15f7b9cecd75ef58d72757e638ce8',
      site: 'datadoghq.eu',
      service: 'platform',
      env: currentEnvironment(),
      version: process.env.REACT_APP_RELEASE_VERSION,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
    });

    // Session properties to be sent with every event
    this.sessionProperties = {
      [SESSION_PROPERTIES.CLIENT_APP]: 'web',
    };

    Mixpanel.register_once(this.sessionProperties);

    // Generate session id
    this.sessionProperties[SESSION_PROPERTIES.SESSION_ID] = nanoid();
  }

  track(eventName, properties = {}, options = {}) {
    const eventData = {
      ...properties,
      ...this.sessionProperties,
    };

    console.debug(`[Analytics] Track event - ${eventName}`, eventData);

    // Killer switch
    if (currentEnvironment() === ENVIRONMENT_KEYS.DEVELOPMENT) return false;

    Mixpanel.track(eventName, eventData);
    datadogRum.addAction(eventName, eventData);

    if (ANALYTICS_TO_TRACK_VIA_API.includes(eventName) && userService.isAuthenticated) {
      AnalyticsAPI.trackEvent({ event: `${eventName} {proxy}`, properties: eventData });
    }
  }

  setUserId(id) {
    console.debug(`[Analytics] Set user id - ${id}`);

    // Killer switch
    if (currentEnvironment() === ENVIRONMENT_KEYS.DEVELOPMENT) return false;

    Mixpanel.identify(id);
    datadogRum.setUser({ id });
  }

  registerUserData(data) {
    console.debug(`[Analytics] Set user data`, data);

    // Killer switch
    if (currentEnvironment() === ENVIRONMENT_KEYS.DEVELOPMENT) return false;

    Mixpanel.register(data);
  }

  setGroup({ key, value }) {
    console.debug(`[Analytics] Set group ${key} - ${value}`);

    // Killer switch
    if (currentEnvironment() === ENVIRONMENT_KEYS.DEVELOPMENT) return false;

    Mixpanel.set_group(key, value);
    datadogRum.setUserProperty(key, value);
    this.sessionProperties[key] = value;
    Mixpanel.register_once(this.sessionProperties);
  }

  userReady() {
    datadogRum.startSessionReplayRecording();

    Executer.logError = (message, data) => {
      setTimeout(() => {
        const sessionReplayLink = datadogRum.getSessionReplayLink();

        datadogRum.addError(new Error('401'), {
          ...data,
          message,
          sessionReplayLink,
        });
      }, 0);
    };
  }

  getSessionReplayLink() {
    return datadogRum.getSessionReplayLink();
  }

  setSessionProperty(key, value) {
    this.sessionProperties[key] = value;
  }
}

export default new AnalyticsService();
