// https://developer.mozilla.org/en-US/docs/Web/API/IndexedDB_API/Using_IndexedDB
// We migrate from localStorage to indexedDB to avoid the 5MB limit

class IndexedDBHelper {
  constructor() {
    this.dbName = 'c8health';
    this.storeName = 'application_data';
  }

  async openDB() {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(this.dbName, 1);

      request.onerror = event => {
        console.error('Unable to open database:', event.target.errorCode);
        reject(event.target.errorCode);
      };

      request.onsuccess = event => {
        resolve(event.target.result);
      };

      request.onupgradeneeded = event => {
        const db = event.target.result;
        if (!db.objectStoreNames.contains(this.storeName)) {
          db.createObjectStore(this.storeName);
        }
      };
    });
  }

  /**
   * Get data from indexedDB
   * @param {String} key
   * @return {Promise<any>}
   * @memberof IndexedDBHelper
   */
  async get(key) {
    const db = await this.openDB();
    return new Promise((resolve, reject) => {
      const transaction = db.transaction([this.storeName]);
      const objectStore = transaction.objectStore(this.storeName);
      const request = objectStore.get(key);

      request.onerror = event => {
        console.error(`[Storage] Could not get ${key} from store.`, {
          error: event.target.errorCode,
        });
        reject(null);
      };

      request.onsuccess = event => {
        const { result } = event.target;

        // Fallback for old data - TODO: remove in the future
        if (!result) {
          try {
            const jsonValue = localStorage.getItem(key);
            // If no data found, return null
            if (!jsonValue) return resolve(null);

            const data = JSON.parse(jsonValue);

            // Migrate to new storage
            this.set(key, data);

            // Remove old storage
            localStorage.removeItem(key);

            return resolve(data);
          } catch (err) {
            return resolve(null);
          }
        }
        resolve(result || null);
      };
    });
  }

  /**
   * Save data to indexedDB
   *
   * @param {String} key
   * @param {any} value
   * @return {Promise<Boolean>}
   * @memberof IndexedDBHelper
   */
  async set(key, value) {
    const db = await this.openDB();
    return new Promise((resolve, reject) => {
      const transaction = db.transaction([this.storeName], 'readwrite');
      const objectStore = transaction.objectStore(this.storeName);
      const request = objectStore.put(value, key);

      request.onerror = event => {
        console.error(`[Storage] Could not save store ${key}`, { error: event.target.errorCode });
        reject(false);
      };

      request.onsuccess = event => {
        resolve(true);
      };
    });
  }
}

export default new IndexedDBHelper();
