const DATA_KEYS = {
  WELCOME_SCREEN: {
    EMAIL_INPUT: 'email-input',
    SUBMIT_BUTTON: 'submit-button',
  },
  VERIFY_SCREEN: {
    CODE: 'otp-code',
  },
  NOT_ALLOWED: {
    ACCEPT_BUTTON: 'not-allowed-accept',
  },
  HOME_PAGE: {
    SWITCH_ORGANIZATION_POPUP: 'select-organization',
    HEADER_ACTIONS: 'header-actions',
    LOGOUT: 'logout',
    ADMIN_MODE: 'admin-mode',
    SIDEBAR: 'sidebar',
    KNOWLEDGE: 'knowledge-base',
  },
  DASHBOARD: {
    EXPIRED_ITEMS: 'dashboard-expired-items',
  },
  KNOWLEDGE_BASE: {
    ADD: {
      BUTTON: 'knowledge-add-button',
      FROM_SCRATCH: 'knowledge-add-from-scratch',
      LINK: 'knowledge-add-link',
      CONTENT: 'knowledge-add-content',
      EXTERNAL_LINK: 'knowledge-add-external-link',
    },
    LIST: 'knowledge-list',
    ITEM: 'knowlege-item',
    DELETE: 'knowledge-delete',
    INFO: 'knowledge-info',
    EDIT: 'knowledge-edit',
  },
};

export { DATA_KEYS };
