import { connect } from 'react-redux';
import { deleteKnowledgeLink } from 'shared/store/actions/knowledge.items.actions';
import { KnowledgeLinkDeletePopupComponent } from './knowledge-link-delete-popup.component';

const mapStateToProps = ({ lang }) => ({
  title: lang.DELETE_KNOWLEDGE_LINK_CONFIRMATION.TITLE,
  message: lang.DELETE_KNOWLEDGE_LINK_CONFIRMATION.TEXT,
  confirmMessage: lang.DELETE_KNOWLEDGE_LINK_CONFIRMATION.CONFIRM,
  dismissMessage: lang.DELETE_KNOWLEDGE_LINK_CONFIRMATION.DISMISS,
});

export const KnowledgeLinkDeletePopup = connect(mapStateToProps, { deleteKnowledgeLink })(
  KnowledgeLinkDeletePopupComponent,
);
