import React from 'react';
import style from './ask-panda-button.module.scss';
import { useDispatch } from 'src/store/use-dispatch';
import { setAIChatState } from 'shared/store/actions/ai.actions';
import { useSelector } from 'react-redux';
import { getLang } from 'shared/store/selectors/lang.selector';
import analyticsService from 'src/helpers/analytics.service';
import { EVENTS } from 'shared/constants';

export function AskPandaButton({ location = 'header' }) {
  const dispatch = useDispatch();
  const lang = useSelector(getLang('AI_CHAT'));

  const openAIChat = () => {
    dispatch(setAIChatState(true));

    analyticsService.track(EVENTS.AI_CHAT.OPEN, {
      location,
    });
  };

  return (
    <button type="button" className={style.root} onClick={openAIChat}>
      {lang.BUTTON_CTA}
    </button>
  );
}
