import React from 'react';
import styles from './TagsIcons.module.scss';
import { TagIcon } from './TagIcon.component';
import { getLang } from 'shared/store/selectors/lang.selector';
import { useSelector } from 'react-redux';

const AudienceIcon = React.memo(function AudienceIcon({ tags, large = false }) {
  const TAGS = useSelector(getLang('TAGS'));
  let [tag] = tags;
  let tooltip;

  if (tags.length > 1) {
    tag = 'EVERYONE';
    tooltip = tags.map(tag => TAGS[tag]).join(', ');
  }

  return <TagIcon tag={tag} tooltip={tooltip} large={large} className={styles.tagAudience} />;
});

export { AudienceIcon };
