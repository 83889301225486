import { useFormik } from 'formik';
import { useKnowledgeComments } from '../hooks';
import styles from '../knowledge-comments.module.scss';
import { TextAreaInput } from 'src/components/design-system/forms';
import { faPaperPlaneTop, faSpinner, faCircleXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Space } from 'antd';
import * as Yup from 'yup';
import analyticsService from 'src/helpers/analytics.service';
import { EVENTS, AUTH_USER_TYPE_NORMAL } from 'shared/constants';
import { useSelector } from 'react-redux';
import { getUser } from 'shared/store/selectors/user.selector';

const { KNOWLEDGE_COMMENTS } = EVENTS;

const MAX_COMMENT_LENGTH = 4000;
const COMMENT_SCHEMA = Yup.object().shape({
  text: Yup.string().max(MAX_COMMENT_LENGTH, 'Too Long!').required(),
});

export function CommentInput({ commentId = null, commentLevel = 0, closeInput = () => {} }) {
  const { lang, addComment, addSubComment, loading, knowledgeId } = useKnowledgeComments();
  const isReply = commentId !== null;

  const scrollToLastComment = () => {
    const id = isReply ? `comment-${commentId}` : 'comments';
    const lastComment = document.getElementById(id);
    lastComment?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  const handleOnSubmit = async (values, { resetForm }) => {
    if (loading) {
      return;
    }
    if (isReply) {
      await addSubComment(commentId, values);
      closeInput();
    } else {
      await addComment(values);
    }
    analyticsService.track(KNOWLEDGE_COMMENTS.COMMENT_SENT, {
      isReply,
      knowledgeId,
      commentId: commentId || null,
      depth: isReply ? commentLevel + 1 : 0,
    });

    setTimeout(scrollToLastComment, 100);

    resetForm();
  };

  const { handleChange, handleBlur, handleSubmit, values, dirty, isValid } = useFormik({
    initialValues: {
      text: '',
    },
    validationSchema: COMMENT_SCHEMA,
    onSubmit: handleOnSubmit,
  });

  const handleKeyDown = e => {
    if (!e.shiftKey && e.key === 'Enter') {
      e.preventDefault();
      handleSubmit();
    }
  };

  const currentUser = useSelector(getUser);

  if (currentUser.type !== AUTH_USER_TYPE_NORMAL) {
    // do not allow magic link users to comment
    return null;
  }

  return (
    <form
      onSubmit={handleSubmit}
      className={classNames({
        [styles.paddingTop]: isReply,
        [styles.stickyInput]: !isReply,
      })}
    >
      <Space.Compact className={styles.form}>
        <TextAreaInput
          name="text"
          autoFocus={isReply}
          value={values.text}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={isReply ? lang.REPLY_PLACEHOLDER : lang.COMMENT_PLACEHOLDER}
          autoSize={{ minRows: 1, maxRows: 4 }}
          maxLength={MAX_COMMENT_LENGTH}
          marginBottom={false}
          bordered={false}
          onKeyDown={handleKeyDown}
        />
        {loading ? (
          <div className={styles.loading}>
            <FontAwesomeIcon icon={faSpinner} spinPulse />
          </div>
        ) : (
          <div className={styles.sendCancelWrapper}>
            <button className={styles.send} type="submit" disabled={!isValid || !dirty}>
              <FontAwesomeIcon icon={faPaperPlaneTop} />
            </button>
            {isReply && (
              <button className={styles.cancel} type="button" onClick={closeInput}>
                <FontAwesomeIcon icon={faCircleXmark} />
              </button>
            )}
          </div>
        )}
      </Space.Compact>
    </form>
  );
}
