import React from 'react';
import styles from './titles.module.scss';
import classNames from 'classnames';

const ScreenTitle = React.memo(({ title = '', subtitle = null, containerStyle }) => (
  <div className={classNames([styles.screenTitleContainer, containerStyle])}>
    <h1>{title}</h1>
    {subtitle && (
      <div className={styles.screenTitleSubtitleContainer}>
        <p>{subtitle}</p>
      </div>
    )}
  </div>
));

export { ScreenTitle };
