import React from 'react';

const PagerIcon = ({ style, ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 24" {...props}>
      <g clipPath="url(#clip0_313_11051)">
        <path d="M21.707 3H3.70703C2.04906 3 0.707031 4.34203 0.707031 6V18C0.707031 19.6584 2.04859 21 3.70703 21H21.707C23.3655 21 24.707 19.6584 24.707 18V6C24.707 4.34203 23.3664 3 21.707 3ZM8.20703 17.25H4.45703C4.04125 17.25 3.70703 16.9172 3.70703 16.5V15.75C3.70703 15.3328 4.04125 15 4.45703 15H8.20703V17.25ZM14.207 16.5C14.207 16.916 13.873 17.25 13.457 17.25H9.70703V15H13.457C13.873 15 14.207 15.334 14.207 15.75V16.5ZM21.707 10.5C21.707 11.3259 21.033 12 20.207 12H5.20703C4.38109 12 3.70703 11.325 3.70703 10.5V7.5C3.70703 6.67406 4.38109 6 5.20703 6H20.207C21.033 6 21.707 6.67406 21.707 7.5V10.5Z" />
      </g>
    </svg>
  );
};

export default PagerIcon;
