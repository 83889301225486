export const BAD_FEEDBACK_OPTIONS = {
  CONTENT_MISSING: 'Content missing',
  NOT_READABLE: 'Content is not readable',
  TECHNICAL_ISSUE: 'Technical issue',
  SUGGESTION: 'I have suggestion',
  QUESTION: 'I have a question',
  OTHER: 'Other',
};

export const FEEDBACK_TYPE = {
  BAD: 'BAD',
  GOOD: 'GOOD',
  CANCELED: 'CANCELED',
};

export const ANSWER_STATE = {
  ANSWERED: 'ANSWERED',
  UNANSWERED: 'UNANSWERED',
  NO_INFORMATION_FOUND: 'NO_INFORMATION_FOUND',
  ONLY_RESOURCES_FOUND: 'ONLY_RESOURCES_FOUND',
};

export const HISTORY_STATE = {
  MAIN: 'MAIN',
  EDITED: 'EDITED',
  REGENERATED: 'REGENERATED',
};
