import knowledgeServiceApi from '../api/knowledge.service.api';
import userService from './user.service';
import { getAssetsServiceBaseURL } from '../constants/APIconstants';
import { KNOWLEDGE_TYPES } from '../constants';

class UploadService {
  async uploadFile({ file, onUploadProgress }) {
    const { type, size, name } = file;

    const res = await knowledgeServiceApi.getUploadUrl({ type, size, name });

    if (res.error) {
      return { error: res.error.message };
    }

    const { url, id: assetId, type: knowledgeType, fileUrl } = res.data;

    if (!fileUrl) {
      return { error: 'Failed to get upload url' };
    }

    await knowledgeServiceApi.uploadDirectlyToGCS(url, file, onUploadProgress);

    const region = userService.region;

    return {
      url: `${getAssetsServiceBaseURL(region)}${fileUrl}`,
      assetId,
      type: knowledgeType,
    };
  }

  async finalizeUploadFile({ assetId }) {
    const res = await knowledgeServiceApi.finalizedUploadFile({ id: assetId });

    if (res.error) {
      return { error: res.error.message };
    }

    return res.data;
  }

  async convertAssetToPdf({ assetId }) {
    const res = await knowledgeServiceApi.convertAssetToPdf({ assetId });

    if (res.error) {
      return { error: res.error.message };
    }

    return res.data;
  }

  async triggerOcr({ assetId }) {
    await knowledgeServiceApi.triggerOcrConversion({ assetId });
  }

  async fetchOcrData({ assetId }) {
    const res = await knowledgeServiceApi.fetchOcrData({ assetId });

    if (res.error) {
      throw new Error(res.data.message);
    }

    return res.data?.task;
  }

  async retroOcrConversion({ itemId }) {
    const res = await knowledgeServiceApi.getKnowledgeItemAsset({ itemId });

    if (res.error) {
      throw new Error(res.data.message);
    }

    const { asset } = res.data;

    if (!asset) {
      throw new Error('No asset found');
    }

    await knowledgeServiceApi.triggerOcrConversion({ assetId: asset.id });

    // only pdf is supported for now
    const assetType = KNOWLEDGE_TYPES.PDF;

    return { assetId: asset.id, url: asset.url, type: assetType };
  }

  async rejectDuplicatedItem({ assetId }) {
    await knowledgeServiceApi.rejectDuplicatedItem({ id: assetId });
  }
}

export default new UploadService();
