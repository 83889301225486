import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { RenderSection } from '../../components';
import { isSuggestKeywordsEnabled } from 'shared/store/selectors/features.selector';
import { useKeywords } from 'shared/hooks/knowledge-content.hooks';
import { useDispatch } from 'src/store/use-dispatch';
import { Button } from 'src/components/design-system';
import { getLang } from 'shared/store/selectors/lang.selector';
import { addKeywordsToKnowledgeItem } from 'shared/store/actions/knowledge.actions';
import { EVENTS } from 'shared/constants';
import analyticsService from 'src/helpers/analytics.service';
import { TagSelector } from 'src/components/design-system/forms/tag-selector.component';
import styles from '../../knowledge-content.module.scss';

export function RenderSuggestKeywords({ id, keywords = [], openNotification = () => {} }) {
  const lang = useSelector(getLang('KNOWLEDGE_EDITOR'));
  const showSuggestKeywords = useSelector(isSuggestKeywordsEnabled);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const { suggestedKeywords, newKeywords, updateKeywords, resetKeywords } = useKeywords({
    keywords,
  });

  const onChangeKeywords = useCallback(
    (propertyName, value) => {
      updateKeywords(value);
    },
    [updateKeywords],
  );

  const submitUpdateKeywords = useCallback(async () => {
    setLoading(true);

    const res = await dispatch(addKeywordsToKnowledgeItem({ itemId: id, keywords: newKeywords }));

    if (res?.error) {
      openNotification({ message: lang.KEYWORD_UPDATE_ERROR, type: 'error' });
    } else {
      analyticsService.track(EVENTS.SUGGEST_KEYWORDS.ADD, {
        id,
        keywords: newKeywords,
      });
      openNotification({ message: lang.KEYWORD_UPDATE_SUCCESS });
    }
    resetKeywords();
    setLoading(false);
  }, [id, dispatch, openNotification, lang, newKeywords, resetKeywords]);

  if (!showSuggestKeywords) return null;

  return (
    <RenderSection header={lang.MISSING_KEYWORDS}>
      <section className={styles.keywordsSection}>
        <TagSelector
          key={newKeywords.toString()}
          value={newKeywords}
          options={suggestedKeywords}
          name="keywords"
          placeholder={lang.KEYWORDS_PLACEHOLDER}
          onChange={onChangeKeywords}
        />
        <Button
          disabled={newKeywords.length === 0}
          onClick={submitUpdateKeywords}
          loading={loading}
        >
          {lang.ADD_KEYWORDS}
        </Button>
      </section>
    </RenderSection>
  );
}
