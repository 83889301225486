import React from 'react';
import { ScreenTitle } from '../../../components/design-system/titles';
import styles from './user-profile.module.scss';
import { UserProfile } from '../../../components/user-profile';
import { Lang } from 'shared/constants';

const UserProfileComponent = ({ lang = Lang.USER_PROFILE }) => {
  return (
    <div className={styles.root}>
      <ScreenTitle title={lang.PAGE_TITLE} />
      <UserProfile />
    </div>
  );
};

export { UserProfileComponent };
