import { connect } from 'react-redux';
import { Route } from './Route.component';
import { ROUTES, VIEWER_PERMISSIONS_GROUP } from 'shared/constants';
import UserService from 'shared/services/user.service';
import { isHavePermission } from 'shared/store/selectors/auth.selector';

const mapStateToProps = ({ auth }, { permissions = VIEWER_PERMISSIONS_GROUP }) => {
  return {
    rootPath: ROUTES.HOME,
    isHavePermissions: isHavePermission({ auth, permissions }),
    isAllowedRoute: !UserService.isAuthenticated,
    redirectRoute: ROUTES.WELCOME,
  };
};

export const PrivateRoute = connect(mapStateToProps)(Route);
