import React, { useCallback } from 'react';
import styles from './rotations.module.scss';
import { RotationsTabs } from './rotation-list/tabs-rotations-list.component';
import { RotationContent } from './rotation-content/rotation-content.component';
import classNames from 'classnames';
import { RotationsProvider, useRotationsContext } from './rotations-context';
import { Loader } from 'src/components/core';
import analyticsService from 'src/helpers/analytics.service';
import { EVENTS } from 'shared/constants';
import _ from 'lodash';

const RotationsPage = () => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchDebounced = useCallback(
    _.debounce(payload => analyticsService.track(EVENTS.ROTATIONS.SEARCH, payload), 600),
    [],
  );

  const analyticsTriggers = {
    onSidebarTabChange: payload =>
      analyticsService.track(EVENTS.ROTATIONS.SIDEBAR_TAB_SELECTED, payload),
    onSearch: payload => searchDebounced(payload),
    onRotationDetailsUpdated: payload =>
      analyticsService.track(EVENTS.ROTATIONS.ROTATION_DETAILS_UPDATED, payload),
    onResourceAdded: payload => analyticsService.track(EVENTS.ROTATIONS.ADD_RESOURCE, payload),
    onResourceDeleted: payload => analyticsService.track(EVENTS.ROTATIONS.DELETE_RESOURCE, payload),
    onAddTeamMember: payload => analyticsService.track(EVENTS.ROTATIONS.ADD_TEAM_MEMBER, payload),
    onRemoveTeamMember: payload =>
      analyticsService.track(EVENTS.ROTATIONS.REMOVE_TEAM_MEMBER, payload),
  };

  return (
    <RotationsProvider analyticsTriggers={analyticsTriggers}>
      <RotationsPageComponent />
    </RotationsProvider>
  );
};

const RotationsPageComponent = () => {
  const { loading } = useRotationsContext();
  return (
    <div className={loading ? styles.loading : classNames(styles.root, 'new-design-system')}>
      {loading ? (
        <Loader />
      ) : (
        <>
          <RotationsTabs />
          <RotationContent />
        </>
      )}
    </div>
  );
};

export { RotationsPage };
