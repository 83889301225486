import React from 'react';
import styles from './render-content.module.scss';
import { useKnowledgeHub } from '../hooks/use-knowledge-hub.hooks';
import { NotFound } from 'src/components/common';
import { CategoryBoxComponent } from 'src/components/common/categories/category-box';
import { CATEGORY_TYPES } from 'shared/constants';
import { SectionComponent } from 'src/components/common/section/section.component';
import { Loader } from 'src/components/core';
import { RenderBinder } from './render-binder';
import { notification } from 'antd';

export function RenderContent() {
  const {
    organizationId,
    workspaceId,
    categoryId,
    workspacesList,
    onSelectWorkspace,
    knowledgeCategories,
    onSelectCategory,
    onSelectBinder,
    loading,
    error,
  } = useKnowledgeHub();

  if (!organizationId) return null;

  const renderWorkspaceList = () => {
    if (loading || error || !workspacesList.length || workspaceId) return null;

    return (
      <div className={styles.workspaceContainer}>
        {workspacesList
          .sort((a, b) => a.name.localeCompare(b.name))
          .map(workspace => (
            <div id={workspace.id} className={styles.box} onClick={onSelectWorkspace}>
              <CategoryBoxComponent
                key={workspace.id}
                title={workspace.name}
                type={CATEGORY_TYPES.CATEGORY}
              />
            </div>
          ))}
      </div>
    );
  };

  const renderCategories = () => {
    if (loading || error || !workspaceId || !knowledgeCategories) return null;

    return knowledgeCategories.map(section => (
      <SectionComponent title={section.title}>
        {section.subcategories.map(cat => {
          const isBinder = cat?.type === CATEGORY_TYPES.KNOWLEDGE;
          const onClick = isBinder ? onSelectBinder : onSelectCategory;

          return (
            <div key={categoryId} id={cat?.id} className={styles.box} onClick={onClick}>
              <CategoryBoxComponent {...cat} />
            </div>
          );
        })}
      </SectionComponent>
    ));
  };

  const renderNotFound = () => {
    if (!loading && organizationId && workspaceId && !knowledgeCategories) {
      return <NotFound />;
    }
  };
  const renderLoading = () => {
    if (!loading) return;

    return (
      <div className={styles.loader}>
        <Loader />
      </div>
    );
  };

  const [api, contextHolder] = notification.useNotification();
  if (error) {
    api.error({
      message: 'Something went wrong',
      placement: 'bottomRight',
    });
  }

  return (
    <div className={styles.root}>
      {contextHolder}
      {renderWorkspaceList()}
      {renderCategories()}
      {renderLoading()}
      {renderNotFound()}
      <RenderBinder />
    </div>
  );
}
