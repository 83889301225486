import * as React from 'react';

function SvgComponent({ color = '#2c5789', ...props }) {
  return (
    <svg data-name="Extra Checkmarks" viewBox="0 0 20 20" {...props}>
      <path
        d="M6.31 17.36L13.68 10 6.31 2.64"
        fill="none"
        stroke={color}
        strokeLinecap="square"
        strokeMiterlimit={10}
        strokeWidth={2}
      />
    </svg>
  );
}

export default SvgComponent;
