import { useSelector } from 'react-redux';
import { isProvidedByEnabled } from '../store/selectors/features.selector';
import { getLang } from '../store/selectors/lang.selector';

export const useVettedOrProvidedLang = inputValue => {
  const isFeatureEnabled = useSelector(isProvidedByEnabled);
  const customLang = useSelector(getLang('CUSTOM_LANG'));

  if (!isFeatureEnabled) {
    return inputValue;
  }

  const normalizedValue = inputValue
    .replace(/Vetted|Validated/, customLang.PROVIDED)
    .replace(/vetted|validated/, customLang.PROVIDED_LOWERCASE);

  return normalizedValue;
};
