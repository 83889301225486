import React from 'react';
import { ConfigProvider, List } from 'antd';
import { RotationItem } from './rotations-row.component';
import { colorPalette } from 'shared/constants';
import { useRotationsContext } from '../rotations-context';

const RotationsList = ({ rotations }) => {
  const { loadingRotationsList } = useRotationsContext();

  return (
    rotations && (
      <ConfigProvider
        theme={{
          components: {
            List: {
              colorText: `${colorPalette.primary} !important`,
              colorTextBase: colorPalette.primary,
            },
          },
        }}
      >
        <List
          dataSource={rotations}
          renderItem={item => <RotationItem item={item} />}
          style={{ margin: '0' }}
          loading={loadingRotationsList}
        />
      </ConfigProvider>
    )
  );
};

export { RotationsList };
