import React from 'react';
import {
  KnowledgeBinder,
  KnowledgeInfo,
  UserProfilePopup,
  AdmissionBinder,
  LabBinderPage,
} from 'src/pages/application';
import { LabValueInfo } from 'src/pages/application/lab/components';
import { AdmissionChecklistInfo } from '../../pages/application/admission/components';
import { AskPandaChat } from '../ai';
import styles from './app-layout.module.scss';
import { Header } from './components';
import { Sidebar } from './components/sidebar/sidebar';
import { ViewSelectorProvider } from '../common/view-selector';
import { LoginBubble } from '../../pages/authentication';

export function AppLayout({ children }) {
  return (
    <ViewSelectorProvider>
      <div className={styles.root}>
        <Header />
        <div className={styles.main}>
          <Sidebar />
          <div className={styles.content}>{children}</div>
          <LoginBubble />
          <KnowledgeBinder />
          <KnowledgeInfo />
          <LabBinderPage />
          <LabValueInfo />
          <AdmissionBinder />
          <AdmissionChecklistInfo />
          <UserProfilePopup />
          <AskPandaChat />
        </div>
      </div>
    </ViewSelectorProvider>
  );
}
