import QRCode from 'react-qr-code';
import { QrCodeIcon } from 'shared/images/icons';
import { Lang } from 'shared/constants';
import { STORES_LINKS } from 'shared/constants/application.constants';
import styles from './mobile-qr-code.module.scss';
import React, { memo, useRef, forwardRef, useImperativeHandle } from 'react';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { faSparkles } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * @typedef {object} MobileQRCodeProps
 * @property {string} url
 * @property {object} lang
 * @exports MobileQRCodeProps
 */

/**
 * Mobile QR Code component for markting the mobile app
 * @param {MobileQRCodeProps} props
 * @returns {JSX.Element}
 */
const MobileQRCode = memo(
  forwardRef(function MobileQRCode({ url = STORES_LINKS.mobile, lang = Lang.HEADER }, ref) {
    const modal = useRef(null);
    const qrIcon = useRef(null);

    const calculateTransformOrigin = () => {
      const { x, y, width, height } = qrIcon?.current?.getBoundingClientRect();
      return `${x + width}px ${y + height}px`;
    };

    const openDialog = () => {
      modal?.current?.style.setProperty('transform-origin', calculateTransformOrigin());
      modal?.current?.showModal();
    };

    const closeDialog = () => {
      modal?.current?.classList.add(styles.close);
      setTimeout(() => {
        // wait for animation to finish
        modal?.current?.close();
        modal?.current?.classList.remove(styles.close);
      }, 300);
    };

    // Expose the closeDialog function through the ref
    useImperativeHandle(ref, () => ({
      closeDialog: () => closeDialog(),
    }));

    return (
      <>
        <div className={styles.qrContainer} onClick={openDialog}>
          <img ref={qrIcon} className={styles.qrIcon} src={QrCodeIcon} alt="qr icon" />
          <p className={styles.clickAndScan}>{lang.CLICK_AND_SCAN}</p>
          <FontAwesomeIcon icon={faSparkles} beat size="1x" />
        </div>
        <dialog ref={modal}>
          <button type="button" className={styles.closeModalBtn} onClick={closeDialog}>
            <FontAwesomeIcon icon={faXmark} className={styles.closeIcon} />
          </button>
          <div>
            <div className={styles.qrCode}>
              <QRCode className={styles.qrLarge} value={url} />
            </div>
            <p>{lang.SCAN_TO_DOWNLOAD_MOBILE_APP}</p>
          </div>
        </dialog>
      </>
    );
  }),
);

export { MobileQRCode };
