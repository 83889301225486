import { Radio } from 'antd';
import { useSelector } from 'react-redux';
import { getLang } from 'shared/store/selectors/lang.selector';
import styles from './view-selector.module.scss';
import { useViewSelector } from './use-view-selector.hooks';

export function ViewSelector() {
  const lang = useSelector(getLang('VIEW_SELECTOR'));
  const { viewType, onSelectViewType, VIEW_TYPES } = useViewSelector();

  return (
    <Radio.Group
      size="medium"
      defaultValue="grid"
      buttonStyle="solid"
      className={styles.viewSelector}
      value={viewType}
    >
      <Radio.Button
        className={styles.viewButton}
        value={VIEW_TYPES.GRID}
        onChange={onSelectViewType}
      >
        {lang.GRID}
      </Radio.Button>
      <Radio.Button
        className={styles.viewButton}
        value={VIEW_TYPES.LIST}
        onChange={onSelectViewType}
      >
        {lang.LIST}
      </Radio.Button>
    </Radio.Group>
  );
}
