import { connect } from 'react-redux';
import { UserProfileComponent } from './user-profile.component';

const mapStateToProps = ({ lang }) => {
  return {
    lang: lang.USER_PROFILE,
  };
};

export const UserProfilePage = connect(mapStateToProps, {})(UserProfileComponent);
