import { Tooltip } from '../../../components/core';
import styles from '../knowledge-content.module.scss';
import { useContributors } from 'shared/hooks/knowledge-content.hooks';

export function Contributors({ contributors, subtitleSeparator = '' }) {
  const { allContributors, lang } = useContributors({ contributors });

  if (!allContributors.length) {
    return null;
  }

  const renderContributor = ({ displayName, email }, index) => {
    const separator = index === allContributors.length - 1 ? '' : ', ';
    if (!email) {
      return (
        <Tooltip
          key={index}
          placement="rightTop"
          title={
            <p className={styles.contributorTooltip}>
              {lang.EXTERNAL_TOOLTIP_CONTRIBUTOR.replace('{name}', displayName)}
            </p>
          }
        >
          <p>{`${displayName}${separator}`}</p>
        </Tooltip>
      );
    }
    return (
      <p key={index}>
        <a href={`mailto:${email}`} target="_blank" rel="noreferrer">
          {displayName}
        </a>
        {separator}
      </p>
    );
  };

  return (
    <div className={styles.contributors}>
      <h6>{`${subtitleSeparator}${lang.CONTRIBUTORS}: `}</h6>
      {allContributors.map(renderContributor)}
    </div>
  );
}
