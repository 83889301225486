import React from 'react';
import { faCircleXmark } from '@fortawesome/pro-regular-svg-icons/faCircleXmark';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './ask-panda-chat.module.scss';
import { RenderChatMessages, RenderUserInput } from './components/';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { setAIChatState } from 'shared/store/actions/ai.actions';
import { useChatConversation, ChatProvider } from './hooks/use-chat-conversation';
import analyticsService from 'src/helpers/analytics.service';
import { EVENTS } from 'shared/constants';

export function AskPandaChat() {
  const analyticsTriggers = {
    onMessage: payload => analyticsService.track(EVENTS.AI_CHAT.MESSAGE, payload),
    onErrorMessage: payload => analyticsService.track(EVENTS.AI_CHAT.ERROR, payload),
    onEditMessage: payload => analyticsService.track(EVENTS.AI_CHAT.EDIT_MESSAGE, payload),
    onClear: payload => analyticsService.track(EVENTS.AI_CHAT.CLEAR_CHAT, payload),
  };

  return (
    <ChatProvider analyticsTriggers={analyticsTriggers}>
      <Chat />
    </ChatProvider>
  );
}

function Chat() {
  const dispatch = useDispatch();
  const { conversationId, lang, isOpen, isChatEnabled } = useChatConversation();

  const closeChat = () => {
    dispatch(setAIChatState(false));

    analyticsService.track(EVENTS.AI_CHAT.CLOSE);
  };

  if (!isChatEnabled) {
    return null;
  }

  return (
    <div className={classNames(styles.root, isOpen ? styles.open : undefined, 'new-design-system')}>
      <div className={styles.header}>
        <h2>
          {lang.HEADER} <span className={styles.beta}>{lang.BETA_TAG}</span>
        </h2>
        {conversationId && (
          <span className={styles.chatId}>
            {lang.CHAT_ID} {conversationId}
          </span>
        )}

        <button type="button" className={styles.close} onClick={closeChat}>
          <FontAwesomeIcon className={styles.icon} icon={faCircleXmark} />
        </button>
      </div>
      <RenderChatMessages />
      <RenderUserInput />
    </div>
  );
}
