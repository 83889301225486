import React from 'react';
import styles from './Input.module.scss';
import classnames from 'classnames';

/**
 * @type {React.FC<any>}
 */
const Input = React.memo(function Input({
  error,
  touched,
  wrapperClass,
  inputStyle,
  className,
  small,
  ...props
}) {
  return (
    <div className={classnames(styles.root, small ? styles.smallWrapper : null, wrapperClass)}>
      <input
        className={classnames(
          small ? styles.smallInput : styles.input,
          styles[inputStyle],
          className,
        )}
        {...props}
      />
      <span className={styles.error}>{error}</span>
    </div>
  );
});

Input.defaultProps = {
  error: null,
  wrapperClass: null,
  className: {},
  inputStyle: 'user',
  small: false,
};

export { Input };
