import React from 'react';
import styles from './calendar-widget.module.scss';

const CalendarWidget = React.memo(function CalendarWidget({ title = '', url }) {
  if (!url) {
    return null;
  }

  return (
    <>
      <label className={styles.title}>{title}</label>
      <div className={styles.calendar}>
        <iframe title={title} src={url} />
      </div>
    </>
  );
});

export { CalendarWidget };
