import { connect } from 'react-redux';
import { createCategory, updateCategory } from 'shared/store/actions/knowledge.categories.actions';
import { getLocations } from 'shared/store/selectors/knowledge.selector';
import { KnowledgeCategoryEditorPopupComponent } from './knowledge-category-editor-popup.component';

const mapStateToProps = ({ knowledge, lang }, { entity }) => ({
  locations: getLocations(knowledge, { path: entity.path }) || [],
  lang: lang.CATEGORY_EDITOR,
});

export const KnowledgeCategoryEditorPopup = connect(mapStateToProps, {
  createCategory,
  updateCategory,
})(KnowledgeCategoryEditorPopupComponent);
