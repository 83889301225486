import { connect } from 'react-redux';
import { KnowledgeMovePopupComponent } from '../knowledge-row/knowledgeMove/KnowledgeMove.component';
import { getLocationsOfKnowledge } from 'shared/store/selectors/knowledge.selector';
import { moveKnowledgeLink } from 'shared/store/actions/knowledge.items.actions';

const mapStateToProps = ({ lang, knowledge }) => ({
  lang: { ...lang.KNOWLEDGE_MOVE, TITLE: lang.KNOWLEDGE_LINK_EDITOR.MOVE_TITLE },
  locations: getLocationsOfKnowledge(knowledge) || [],
});

export const KnowledgeLinkMovePopup = connect(mapStateToProps, {
  moveKnowledgeItem: moveKnowledgeLink,
})(KnowledgeMovePopupComponent);
