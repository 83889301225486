import { CATEGORY_TYPES, KNOWLEDGE_RESOURCES_TYPES, KNOWLEDGE_TYPES } from 'shared/constants';
import { INTERNAL_ROUTING, ROUTES } from 'shared/constants/routes.js';
import { isVideoUrl } from 'shared/utils';

const KNOWLEDGE_ITEM_LINK = `${ROUTES.KNOWLEDGE}/items/{id}`;
const KNOWLEDGE_CATEGORY_LINK = `${ROUTES.KNOWLEDGE}/{id}`;
const KNOWLEDGE_BINDER_LINK = `${ROUTES.KNOWLEDGE}?${INTERNAL_ROUTING.QUERY_PARAMS.KNOWLEDGE}={id}`;
const LABS_CATEGORY_LINK = `${ROUTES.LABS}/{id}`;
const LABS_BINDER_LINK = `${ROUTES.LABS}?${INTERNAL_ROUTING.QUERY_PARAMS.COMPENDIUM}={id}`;
const ADMISSION_CATEGORY_LINK = `${ROUTES.ADMISSION}/{id}`;
const ADMISSION_BINDER_LINK = `${ROUTES.ADMISSION}?${INTERNAL_ROUTING.QUERY_PARAMS.CHECKLIST}={id}`;

const ALL_CATEGORY_TYPES = [
  CATEGORY_TYPES.CHECKS,
  CATEGORY_TYPES.VALUES,
  CATEGORY_TYPES.KNOWLEDGE,
  CATEGORY_TYPES.CATEGORY,
];

const CATEGORY_TYPE_TO_LINK = {
  [KNOWLEDGE_RESOURCES_TYPES.KNOWLEDGE_CATEGORY]: {
    get: (item, store) => {
      if (!item) {
        return null;
      }

      const { id, title } = item;
      const category = store?.knowledge?.categoriesById?.[id];

      if (!category) {
        return null;
      }

      return {
        ...category,
        title,
        navigateTo:
          category.type === CATEGORY_TYPES.CATEGORY
            ? KNOWLEDGE_CATEGORY_LINK.replace('{id}', id)
            : KNOWLEDGE_BINDER_LINK.replace('{id}', id),
      };
    },
  },
  [KNOWLEDGE_RESOURCES_TYPES.KNOWLEDGE_ITEM]: {
    get: (item, store) => {
      if (!item) {
        return null;
      }
      const { id, title } = item;
      const MAPPER = {
        [KNOWLEDGE_TYPES.PDF]: [KNOWLEDGE_TYPES.CONTENT],
        [KNOWLEDGE_TYPES.DOCUMENT]: [KNOWLEDGE_TYPES.CONTENT],
        [KNOWLEDGE_TYPES.CONTENT]: [KNOWLEDGE_TYPES.CONTENT],
      };
      const knowledgeItem = store?.knowledge?.itemsById?.[id];

      if (!knowledgeItem) {
        return null;
      }

      const isVideo =
        knowledgeItem.type === KNOWLEDGE_TYPES.VIDEO || isVideoUrl(knowledgeItem.link);
      const type = isVideo
        ? KNOWLEDGE_TYPES.VIDEO
        : MAPPER[knowledgeItem.type] || KNOWLEDGE_TYPES.CONTENT;

      return {
        ...knowledgeItem,
        title,
        type,
        navigateTo: KNOWLEDGE_ITEM_LINK.replace('{id}', id),
      };
    },
  },
  [KNOWLEDGE_RESOURCES_TYPES.CHECKLIST]: {
    get: (item, store) => {
      if (!item) {
        return null;
      }

      const { id, title } = item;
      const category = store?.admission?.categoriesById?.[id];

      if (!category) {
        return null;
      }

      return {
        ...category,
        title,
        type: category.type,
        categoryType: CATEGORY_TYPES.CHECKS,
        navigateTo:
          category.type === CATEGORY_TYPES.CATEGORY
            ? ADMISSION_CATEGORY_LINK.replace('{id}', id)
            : ADMISSION_BINDER_LINK.replace('{id}', id),
      };
    },
  },
  [KNOWLEDGE_RESOURCES_TYPES.LAB]: {
    get: (item, store) => {
      if (!item) {
        return null;
      }

      const { id, title } = item;
      const category = store?.lab?.categoriesById?.[id];

      if (!category) {
        return null;
      }

      return {
        ...category,
        title,
        type: category.type,
        categoryType: CATEGORY_TYPES.VALUES,
        navigateTo:
          category.type === CATEGORY_TYPES.CATEGORY
            ? LABS_CATEGORY_LINK.replace('{id}', id)
            : LABS_BINDER_LINK.replace('{id}', id),
      };
    },
  },
  [KNOWLEDGE_RESOURCES_TYPES.EXTERNAL_URL]: {
    get: item => ({ ...item, title: item.title || item.url, navigateTo: item.url }),
  },
};

const parse = (item, store) => {
  const mapper = CATEGORY_TYPE_TO_LINK[item.type];
  return mapper.get(item, store);
};

const createKnowledgePayload = item => {
  if (item.url) {
    return {
      type: KNOWLEDGE_RESOURCES_TYPES.EXTERNAL_URL,
      ...item,
    };
  }

  let type = KNOWLEDGE_RESOURCES_TYPES.KNOWLEDGE_ITEM;
  if (ALL_CATEGORY_TYPES.includes(item.type)) {
    if (item.values) {
      type = KNOWLEDGE_RESOURCES_TYPES.LAB;
    } else if (item.checks) {
      type = KNOWLEDGE_RESOURCES_TYPES.CHECKLIST;
    } else {
      type = KNOWLEDGE_RESOURCES_TYPES.KNOWLEDGE_CATEGORY;
    }
  }

  if (item?.type === CATEGORY_TYPES.VALUES) {
    type = KNOWLEDGE_RESOURCES_TYPES.LAB;
  } else if (item?.type === CATEGORY_TYPES.CHECKS) {
    type = KNOWLEDGE_RESOURCES_TYPES.CHECKLIST;
  }

  return {
    type,
    id: item.id,
    title: item.title,
  };
};

export const useKnowledgeParser = ({ knowledge, admission, lab }) => {
  return {
    parse: item => parse(item, { knowledge, admission, lab }),
    compose: item => createKnowledgePayload(item),
  };
};
