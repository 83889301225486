import React from 'react';
import styles from './admission-checklist-comment.module.scss';
import { Lang } from 'shared/constants';
import { Button, TextArea } from 'src/components/core';
import { Popup } from 'src/components/common';
import { debounce } from 'lodash';

const AdmissionChecklistCommentComponent = React.memo(function AdmissionChecklistCommentComponent({
  item,
  lang,
  updateComment,
  onFinish,
  comment,
  categoryId,
}) {
  const onChangeText = debounce(text => {
    updateComment({
      comment: text,
      categoryId,
      id: item.id,
    });
  }, 1000);

  const onChange = e => {
    onChangeText(e.target.value);
  };

  if (!item?.id) {
    return null;
  }

  let { title } = item;

  return (
    <Popup isOpen={true} title={lang.HEADER.replace('{name}', title)}>
      <div className={styles.detail}>
        <h6>{lang.INFO}</h6>
        <div className={styles.expiryDate}>
          <TextArea
            inputStyle="editor"
            defaultValue={comment}
            onChange={onChange}
            className={styles.textArea}
          />
        </div>
      </div>
      <div className={styles.buttons}>
        <Button onClick={onFinish}>{lang.DISMISS}</Button>
      </div>
    </Popup>
  );
});

AdmissionChecklistCommentComponent.defaultProps = {
  categoryId: null,
  lang: Lang.ADMISSION_COMMENT,
  item: {},
  comment: '',
  onFinish: () => {},
  updateComment: () => {},
};

export { AdmissionChecklistCommentComponent };
